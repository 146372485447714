export enum Routes {
  ADVERTISEMENTS = "/portal/advertisements",
  EDIT_ADVERTISEMENT = "/portal/advertisements/edit/:id",
  ARTICLES = "/portal/articles/:key?",
  CAROUSELS = "/portal/carousels",
  CREATE_CAROUSEL = "/portal/carousels/create/:id?",
  CREATE_CATEGORY = "/portal/categories/create/:slug?",
  CREATE_ARTICLE = "/portal/article/create/:slug?",
  CREATE_ADVERTISEMENT_SETTING = "/portal/settings/advertisement/create/:id?",
  CREATE_SERVICE_SETTING = "/portal/settings/service/create/:id?",
  CREATE_ADVERTISEMENT_SETTING_TYPE = "/portal/settings/advertisement/type/create/:id?",
  HELP = "/portal/help",
  HOME = "/",
  SETTINGS = "/portal/settings/:key?",
  TAXONOMY = "/portal/taxonomy/:key?",
  EDIT_WORKER_TYPE_TAXONOMY = "/portal/taxonomy/workerType/edit/:country/:level/:id?",
  CREATE_WORKER_TYPE_TAXONOMY = "/portal/taxonomy/workerType/create/:country/:level/:parentId?",
  CREATE_SUITABLE_FOR_TAXONOMY = "/portal/taxonomy/suitableFor/create/:country/:level/:parentId?",
  EDIT_SUITABLE_FOR_TAXONOMY = "/portal/taxonomy/suitableFor/edit/:country/:level/:id?",
  CREATE_EMPLOYER_TAXONOMY = "/portal/taxonomy/employer/create/:country/:level/:parentId?",
  EDIT_EMPLOYER_TAXONOMY = "/portal/taxonomy/employer/edit/:country/:level/:id?",
  USERS = "/portal/users",
  PROFILE_SETTINGS = "/portal/profile/settings",
  PORTAL = "/portal",
}
