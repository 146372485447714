import { Row } from "src/constants/globalStyles";
import styled from "styled-components";
import { AdvertisementState } from "./_types";

export const ActiveAdvertisement = ({
  state,
  id,
}: {
  state: AdvertisementState;
  id: string;
}) => {
  return (
    <Wrap>
      <Circle state={state} />
      <FriendlyId>{id}</FriendlyId>
    </Wrap>
  );
};

const Circle = styled.div<{ state: AdvertisementState }>`
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.state === AdvertisementState.DELETED
      ? "rgb(246, 121, 121)"
      : props.state === AdvertisementState.WAITING_FOR_APPROVAL
      ? "orange"
      : props.state === AdvertisementState.TEMPLATE
      ? "brown"
      : props.state === AdvertisementState.APPROVED
      ? "green"
      : props.state === AdvertisementState.DISAPPROVED
      ? "purple"
      : "green"};
`;

const FriendlyId = styled.span`
  margin-left: 5px;
  margin-bottom: 1px;
`;

const Wrap = styled(Row)`
  margin-left: 7px;
`;
