import { Tabs } from "antd";
import styled from "styled-components";

export const StyledTabs = styled(Tabs)`
  .ant-tabs-ink-bar {
    background: ${(props) => props.theme.primary};
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.primary};
  }
  .ant-tabs-tab:hover {
    color: ${(props) => props.theme.primary};
  }
  .ant-tabs-tab-btn:focus,
  .ant-tabs-tab-remove:focus,
  .ant-tabs-tab-btn:active,
  .ant-tabs-tab-remove:active {
    color: ${(props) => props.theme.primary};
  }
  .ant-tabs-tab {
    padding: 0 0 12px 0;
  }
`;

export const Load = styled.button`
  border: 1px solid white;
  padding: 4px 8px;
  color: ${(props) => props.theme.primary};
  background: white;
  cursor: pointer;
  margin-top: 17px;
  margin-left: 4px;
  margin-right: 4px;

  :hover {
    border: 1px solid ${(props) => props.theme.primary};
    border-radius: 4px;
    background-color: #f8f8f8;
  }
`;

export const Flex = styled.div`
  display: flex;
`;

export const Row = styled(Flex)`
  flex-direction: row;
  align-items: center;
`;

export const Column = styled(Flex)`
  flex-direction: column;
  width: 100%;
`;

export const ColumnStart = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
`;
export const ColumnCenter = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ColumnEnd = styled(Flex)`
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const SpaceBetween = styled(Row)`
  justify-content: space-between;
  width: 100%;
`;

export const RowStart = styled(Row)`
  justify-content: flex-start;
  align-items: flex-start;
`;

export const RowCenter = styled(Row)`
  justify-content: center;
`;

export const RowEnd = styled(Row)`
  justify-content: flex-end;
  align-items: end;
`;

export const RowAlignEnd = styled(Row)`
  align-items: flex-end;
`;

export const Center = styled(Flex)`
  align-items: center;
  justify-content: center;
  margin-top: 1em;
`;

export const Title = styled.h4`
  color: ${(props) => props.theme.text.blue};
  font-family: "NeueAlteGrotesk-Bold";
  text-transform: uppercase;
  text-align: center;
  padding: 10px 0;
  font-size: 1.7em;
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 0.4em;
`;

export const Form = styled.form`
  min-height: calc(100vh - 80px);
  background-color: #ececec;
`;

export const Mid = styled.div<{ leftMargin?: boolean; rightMargin?: boolean }>`
  width: 50%;
  margin-left: ${(props) => (props.leftMargin ? "10px" : "0")};
  margin-right: ${(props) => (props.rightMargin ? "10px" : "0")};
`;

export const RowContainer = styled(Row)`
  margin-bottom: 22px;
`;

export const AddInputField = styled(RowStart)`
  color: #6f6f6f;
  font-size: 15px;
  margin-left: 15px;
  font-family: NeueAlteGrotesk-Medium;
  cursor: pointer;

  svg {
    font-size: 12px;
    margin-right: 7px;
  }
`;

export const Container = styled(Column)`
  max-width: 1440px;
  margin: auto;
`;

export const Href = styled.a`
  color: ${(props) => props.theme.text.blue};

  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.text.blue};
  }
`;

export const LayoutHeader = styled(SpaceBetween)`
  margin-bottom: 15px;
`;

export const Wrap = styled.div`
  overflow: auto;
  background-color: #ececec;
  padding: 1em 2.5em;
  min-height: calc(100vh - 80px);
`;
