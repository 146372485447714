import { Row } from "optimify-mediportal-components";
import styled from "styled-components";

export const SearchUsersWrapper = styled.div`
  margin: 8px 0 14px 0;
`;

export const NameContainer = styled(Row)`
  align-items: center;
`;

export const Name = styled.span`
  padding-left: 8px;
`;

export const Selection = styled(Row)`
  padding: 8px 0;
`;
