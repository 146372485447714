import { Editor, EditorState } from "react-draft-wysiwyg";
import React from "react";
import { FormikProps } from "formik";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { convertToRaw } from "draft-js";
import { Col } from "optimify-mediportal-components";
import styled from "styled-components";
import { Column } from "../../constants/globalStyles";
import { ErrorMessage } from "./ErrorMessage";

export const ContentEditor = ({
  formik,
  name,
  label,
}: {
  formik: FormikProps<any>;
  name: string;
  label?: string;
}) => {
  const onEditorStateChange = (event: any) => {
    if (formik && event) {
      formik.setFieldValue(name, event);
    }
  };

  return (
    <Column>
      {label && <Label>{label}</Label>}
      <Editor
        editorState={
          formik && name && formik.values[name] && formik.values[name]
        }
        onEditorStateChange={onEditorStateChange}
      />
      <ErrorMessage formik={formik} name={name} />
    </Column>
  );
};

const Label = styled.span`
  font-size: 13px;
  color: #555555;
  padding-bottom: 3px;
  padding-left: 1px;
`;
