import { useQuery } from "@oasa/amplify-query";
import { UsersByCompanyIDQuery } from "src/API";
import { usersByCompanyID } from "src/graphql/queries";

export const CompanyUsers = ({ companyID }: { companyID?: string | null }) => {
  const { data, loading } = useQuery<UsersByCompanyIDQuery>(usersByCompanyID, {
    companyID: companyID,
  });

  if (loading) <></>;

  if (!data?.usersByCompanyID?.items) <>0</>;

  return <span>{data?.usersByCompanyID?.items.length}</span>;
};
