import {
  createEmployer,
  createEmployerEdge,
  deleteEmployer,
  deleteEmployerEdge,
  updateEmployer,
} from "../../../../../graphql/mutations";
import { message } from "antd";
import { routeTo } from "../../../../../utils/createRoute";
import { Routes } from "../../../../../routes";
import { TaxonomyPage } from "../index";
import { mutation } from "../../../../../utils/useQuery";

/**
 * Create employer
 * @param values
 * @param mutation
 * @param id
 * @param history
 */
export const handleSubmit = async (
  values: any,
  employer: any,
  history: any,
  id?: string,
  parentId?: string
) => {
  try {
    const { children, ...data } = values;

    if (id) {
      await mutation(updateEmployer, {
        input: data,
      });
    } else {
      const result: any = await mutation(createEmployer, {
        input: data,
      });
      parentId &&
        (await mutation(createEmployerEdge, {
          input: {
            parentNodeId: parentId,
            country: values.country,
            childNodeId: result?.createEmployer?.id,
          },
        }));
    }

    const currentEdges = employer?.childEdges?.items || [];
    for (const e of currentEdges) {
      if (children && !children?.includes(e?.childNodeId)) {
        await mutation(deleteEmployerEdge, { input: { id: e?.id } });
      }
    }

    if (children) {
      for (const childrenIterator of children) {
        if (
          !currentEdges?.find((e: any) => e?.childNodeId === childrenIterator)
        ) {
          await mutation(createEmployerEdge, {
            input: {
              parentNodeId: data.id,
              country: values.country,
              childNodeId: childrenIterator,
            },
          });
        }
      }
    }

    message.success(`Taxonomie byla úspěšně uložena.`);

    routeTo(history, Routes.TAXONOMY, [TaxonomyPage.Employer]);
  } catch (e) {
    message.error(`Exception: ${e}`);
  }
};
