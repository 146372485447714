import { Tabs } from "antd";
import { Title } from "optimify-mediportal-components";
import { useTranslation } from "react-i18next";
import {
  LayoutHeader,
  StyledTabs,
  Wrap,
} from "../../../../constants/globalStyles";
import { AdSettingsTable } from "./components/AdSettingsTable";
import { AdTypeSettingsTable } from "./components/AdTypeSettingsTable";
import { Routes } from "../../../../routes";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ServiceSettingsTable } from "./components/SerivceSettingsTable";
import { ArticlePages } from "../articles";
import { routeTo } from "../../../../utils/createRoute";
import { Button } from "src/components/Form/Button/Button";

const { TabPane } = Tabs;

export enum SettingPage {
  AdvertisementsSettings = "advertisementsSettings",
  ServiceSettings = "serviceSettings",
  AdvertisementsTypeSettings = "advertisementsTypeSettings",
}

export const Settings = () => {
  const { t } = useTranslation(["common", "settings"]);
  const { key } = useParams<{ key?: SettingPage }>();
  const [page, setPage] = useState(SettingPage.AdvertisementsSettings);
  const history = useHistory();

  useEffect(() => {
    key && setPage(key);
  }, [key]);

  const callback = (key: string) => {
    routeTo(history, Routes.SETTINGS, [key]);
  };

  return (
    <Wrap>
      <LayoutHeader>
        <Title>{t("settings:title")} </Title>
        <Button
          type="button"
          variant="outline"
          onClick={() =>
            routeTo(
              history,
              page === SettingPage.AdvertisementsSettings
                ? Routes.CREATE_ADVERTISEMENT_SETTING
                : page === SettingPage.ServiceSettings
                ? Routes.CREATE_SERVICE_SETTING
                : Routes.CREATE_ADVERTISEMENT_SETTING_TYPE
            )
          }
        >
          {t(
            page === SettingPage.AdvertisementsSettings
              ? "settings:addAdvertisementsSettings"
              : page === SettingPage.ServiceSettings
              ? "settings:addServiceSettings"
              : "settings:addAdvertisementsTypeSettings"
          )}
        </Button>
      </LayoutHeader>

      <StyledTabs activeKey={page} onChange={callback}>
        <TabPane
          tab={t("settings:tab.advertisements")}
          key={SettingPage.AdvertisementsSettings}
        >
          <AdSettingsTable />
        </TabPane>
        <TabPane
          tab={t("settings:tab.services")}
          key={SettingPage.ServiceSettings}
        >
          <ServiceSettingsTable />
        </TabPane>
        <TabPane
          tab={t("settings:tab.advertisementsType")}
          key={SettingPage.AdvertisementsTypeSettings}
        >
          <AdTypeSettingsTable />
        </TabPane>
      </StyledTabs>
    </Wrap>
  );
};
