import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import { Row } from "src/constants/globalStyles";
import styled from "styled-components";

export type ReactSelectOption = {
  label: string;
  value: string | undefined;
};

const Select = ({
  options,
  label,
  name,
  formik,
  selectWidth,
  unselectable,
}: {
  options: ReactSelectOption[];
  label?: string;
  name: string;
  formik: any;
  selectWidth?: number;
  unselectable?: boolean;
}) => {
  const { t } = useTranslation(["form"]);

  const customStyles = useMemo(
    () => ({
      option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected
          ? "rgba(19,33,150,0.95)"
          : state.isFocused
          ? "#717AA3"
          : "white",
        color: state.isFocused ? "white" : state.isSelected ? "white" : "black",
        padding: 5,
        ":active": {
          ...provided[":active"],
          backgroundColor: "rgba(19,33,150,0.95)",
        },
      }),
      control: (provided: any) => ({
        ...provided,
        minWidth: selectWidth ? selectWidth : 150,
        border: 0,
        boxShadow: "none",
      }),
      singleValue: (provided: any, state: any) => ({
        ...provided,
      }),
    }),
    []
  );

  return (
    <Wrap>
      <ReactSelect
        onChange={(option: any) => formik.setFieldValue(name, option?.value)}
        options={options}
        value={
          options?.find(
            (i: ReactSelectOption) => i?.value === formik.values[name]
          ) || ""
        }
        styles={customStyles}
        placeholder={label ? label : t("form:placeholder.state")}
      />
      {unselectable && formik.values[name] && (
        <UnSelect onClick={() => formik.setFieldValue(name, undefined)}>
          <FontAwesomeIcon icon={faTimes} />
        </UnSelect>
      )}
    </Wrap>
  );
};

export default Select;

const UnSelect = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.primary};
  margin-left: 10px;
`;

const Wrap = styled(Row)`
  width: 190px;
  margin: 0 8px 0 0;

  > div {
    width: 100%;
  }

  > div > div {
    min-height: 31px;
    border-color: ${(props) => props.theme.primary};
    border-radius: 14px;
    color: darkgray;
    font-size: 13px;

    overflow: hidden;

    &:first-of-type {
      > div:last-child {
        height: 26px;

        > span {
          display: none;
        }

        > div {
          padding-left: 0;
          padding-top: 12px;
          color: darkgray;
        }
      }
    }

    &:not(:first-of-type) {
      > div {
        padding: 0;
      }
    }
  }
`;
