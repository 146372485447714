import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import { Icon } from "../../../../../components/Form/Button/_styles";
import { useLazyQuery, useMutation, useQuery } from "@oasa/amplify-query";
import {
  Company,
  GetCompanyQuery,
  GetProfileQuery,
  UpdateCompanyInput,
  UpdateCompanyMutation,
} from "../../../../../API";
import { getCompany } from "../../../../../graphql/queries";
import { useFormik } from "formik";
import { DialogForm } from "../../../../../components/DialogForm";
import { useTranslation } from "react-i18next";
import { formatCompany } from "../_services";
import { updateCompany } from "../../../../../graphql/mutations";
import { message } from "antd";
import NumberInput from "../../../../../components/Form/NumberInput";
import { getProfile } from "../graphql/queries";

export const CompanyCredits = ({
  companyID,
  refetch,
}: {
  companyID: string;
  refetch: any;
}) => {
  const { t } = useTranslation(["profile"]);

  const [query, { data }] = useLazyQuery<GetCompanyQuery>(getCompany);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    query({
      id: companyID,
    });
  };

  const [update] = useMutation<UpdateCompanyInput, UpdateCompanyMutation>(
    updateCompany
  );

  const formik = useFormik<UpdateCompanyInput | any>({
    initialValues: formatCompany(data?.getCompany as Company),
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      try {
        if (
          values.credits === null ||
          values.credits === undefined ||
          Number(values.credits) < 0
        ) {
          await message.warning(t("profile:credits.invalid"));
          return;
        }

        const response = await update({
          input: { ...values, credits: parseInt(values.credits) },
        });
        formik.setValues(formatCompany(response?.updateCompany as Company));
        await message.success(t("profile:credits.successMessage"));
      } catch (e) {
        await message.error(e);
      }
    },
  });

  useEffect(() => {
    formik.setValues(formatCompany(data?.getCompany as Company));
  }, [data]);

  return (
    <DialogForm
      title={t("profile:credits.title")}
      okText={t("profile:credits.ok")}
      cancelText={t("profile:credits.cancel")}
      formik={formik}
      customCancel={() => getData()}
      form={
        <form onSubmit={formik.handleSubmit}>
          <NumberInput
            title={t("common:table.creditsCount")}
            name="credits"
            formik={formik}
          />
        </form>
      }
    >
      <Icon>
        <FontAwesomeIcon icon={faCoins} color={"#1321b1"} />
      </Icon>
    </DialogForm>
  );
};
