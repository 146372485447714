import React from 'react';
import { AdminButton, Table } from 'optimify-mediportal-components';

export default () => (
    <Table
        pagination={{ pageSize: 1 }}
        columns={[
            {
                title: 'Interní název',
                dataIndex: 'iname',
                key: 'iname'
            },
            {
                title: 'Název',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'Cena inzerátu',
                dataIndex: 'iprice',
                key: 'iprice'
            },
            {
                title: 'Cena reaktivace',
                dataIndex: 'rprice',
                key: 'rprice'
            },
            {
                title: 'Nastavit pro',
                dataIndex: 'for',
                key: 'for'
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status'
            },
            {
                title: 'Podléhá schválení',
                dataIndex: 'acceptation',
                key: 'acceptation'
            },
            {
                title: 'Typ',
                dataIndex: 'type',
                key: 'type'
            },
            {
                title: '',
                key: 'action',
                render: (_text: any, record: any) => (
                    <AdminButton
                        variant='primary'
                        type='button'
                        onClick={() => alert(record.name)}
                    >
                        Nějaká akce
                    </AdminButton>
                )
            }
        ]}
        dataSource={[
            {
                key: 1,
                iname: 'Standart',
                name: 'Standart',
                iprice: '8000 kr.',
                rprice: '8000 kr.',
                for: 'Pro všechny',
                status: 'Aktivní',
                acceptation: 'Ano',
                type: 'Inzerát'
            },
            {
                key: 2,
                iname: 'AGEL',
                name: 'AGEL',
                iprice: '0',
                rprice: '0',
                for: 'AGEL, ...',
                status: 'Neaktivní',
                acceptation: 'Ne',
                type: 'Služba'
            },
            {
                key: 3,
                iname: 'AGEL',
                name: 'AGEL',
                iprice: '0',
                rprice: '0',
                for: 'AGEL, ...',
                status: 'Neaktivní',
                acceptation: 'Ne',
                type: 'Služba'
            },
            {
                key: 4,
                iname: 'AGEL',
                name: 'AGEL',
                iprice: '0',
                rprice: '0',
                for: 'AGEL, ...',
                status: 'Neaktivní',
                acceptation: 'Ne',
                type: 'Služba'
            },
            {
                key: 5,
                iname: 'AGEL',
                name: 'AGEL',
                iprice: '0',
                rprice: '0',
                for: 'AGEL, ...',
                status: 'Neaktivní',
                acceptation: 'Ne',
                type: 'Služba'
            },
            {
                key: 6,
                iname: 'AGEL',
                name: 'AGEL',
                iprice: '0',
                rprice: '0',
                for: 'AGEL, ...',
                status: 'Neaktivní',
                acceptation: 'Ne',
                type: 'Služba'
            },
            {
                key: 7,
                iname: 'AGEL',
                name: 'AGEL',
                iprice: '0',
                rprice: '0',
                for: 'AGEL, ...',
                status: 'Neaktivní',
                acceptation: 'Ne',
                type: 'Služba'
            },
            {
                key: 8,
                iname: 'AGEL',
                name: 'AGEL',
                iprice: '0',
                rprice: '0',
                for: 'AGEL, ...',
                status: 'Neaktivní',
                acceptation: 'Ne',
                type: 'Služba'
            }
        ]}
    />
)