import { Popover } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { TooltipPlacement } from "antd/lib/tooltip";
import { useTranslation } from "react-i18next";

export const PopOver = ({
  title,
  content,
  placement,
}: {
  title: string;
  content: React.ReactNode;
  placement?: TooltipPlacement;
}) => {
  const { t } = useTranslation(["form"]);

  const [visible, setVisible] = useState(false);
  const hide = () => {
    setVisible(false);
  };

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  return (
    <StyledPopOver
      content={<Close onClick={hide}>{t("form:button.close")}</Close>}
      title={content}
      trigger="click"
      placement={placement || "bottomLeft"}
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <Button>{title}</Button>
    </StyledPopOver>
  );
};

const StyledPopOver = styled(Popover)`
  margin-right: 8px;
  margin-top: 8px;
`;

const Button = styled.button`
  min-width: 150px;
  border-radius: 14px;
  background-color: ${(props) => props.theme.background.white};
  border: 0;
  color: darkgray;
  height: 32px;
  font-size: 13px;
`;

const Close = styled.a`
  color: ${(props) => props.theme.darkGray};

  &:hover {
    color: ${(props) => props.theme.darkGray};
  }
`;
