import { PopOver } from "../../../../../../components/Form/PopOver";
import { CheckboxGroup } from "../../../../../../components/Form/CheckBox";
import React from "react";
import { FormData } from "../_types";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { useQuery } from "../../../../../../utils/useQuery";
import { ListProfilesQuery, Profile } from "../../../../../../API";
import { listProfiles } from "../../../../../../graphql/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { Row } from "src/constants/globalStyles";

export const PersonalFilter = ({
  formik,
}: {
  formik: FormikProps<FormData>;
}) => {
  const { t } = useTranslation(["form"]);

  const { data } = useQuery<ListProfilesQuery>(listProfiles, {
    limit: 1000,
  });

  const options = (data?.listProfiles?.items as Profile[])?.map?.(
    (e: Profile) => {
      return {
        value: e?.id,
        label: `${e?.email} ${e?.forename} ${e?.surname}`,
      };
    }
  );
  return (
    <Row>
      <PopOver
        title={t("form:filter.personal")}
        content={
          <CheckboxGroup
            formik={formik}
            name={"profileID"}
            options={options || []}
            customTrigger={() => formik.setFieldValue("companyID", null)}
            singleChoice
          />
        }
      />
      {formik.values["profileID"] && (
        <UnSelect onClick={() => formik.setFieldValue("profileID", undefined)}>
          <FontAwesomeIcon icon={faTimes} />
        </UnSelect>
      )}
    </Row>
  );
};

const UnSelect = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.primary};
  margin-top: 8px;
  margin-right: 10px;
`;
