import { Title } from "optimify-mediportal-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PrivatePerson } from "./components/PrivatePerson/PrivatePerson";
import { Corporation } from "./components/Corporation";
import { StyledTabs, Wrap } from "../../../../constants/globalStyles";
import { Tabs } from "antd";

const { TabPane } = Tabs;

export const Users = () => {
  const [key, setKey] = useState("1");
  const { t } = useTranslation(["common", "users"]);

  const callback = (key: string) => {
    setKey(key);
  };

  return (
    <Wrap>
      <Title>
        {t("common:menu.users")} -{" "}
        {t(
          key === "1"
            ? "users:privatePersonLowerCase"
            : "users:corporationLowerCase"
        )}
      </Title>
      <StyledTabs defaultActiveKey={key} onChange={callback}>
        <TabPane tab={t("users:privatePerson")} key="1">
          <PrivatePerson />
        </TabPane>
        <TabPane tab={t("users:corporation")} key="2">
          <Corporation />
        </TabPane>
      </StyledTabs>
    </Wrap>
  );
};
