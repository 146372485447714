import styled from "styled-components";
import {
  Col,
  Row,
  Select,
  SpaceBetween,
  Title,
  Center,
} from "optimify-mediportal-components";

export const Column = styled(Col)`
  height: 700px;
  padding: 0;
  z-index: 99999999;
  overflow: auto;
  background-color: ${(props) => props.theme.background.white};
  margin-bottom: 10px;
  ::-webkit-scrollbar {
    width: 20px;

    cursor: pointer;
  }

  ::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 5px solid transparent;
    background-clip: content-box;
    background-color: ${(props) => props.theme.background.blue};
  }
`;

export const Legislative = styled(Select)`
  margin-bottom: 10px;
  margin-left: 5px;
`;

export const Line = styled.line`
  stroke-width: 2px;
  stroke: ${(props) => props.theme.primary};
  z-index: -999;
`;

export const ContainerTitle = styled.div`
  width: 100%;
  text-align: center;
  padding: 15px 0;
  color: ${(props) => props.theme.primary};
`;

export const Container = styled.div`
  border-right: 1px dashed ${(props) => props.theme.primary};
  width: 350px;
  min-height: 100vh;
`;

export const StyledRow = styled.div<{ arrayCount: number }>`
  display: flex;
  flex-direction: row;

  min-width: ${(props) => (props.arrayCount > 3 ? "3000px" : "100vw")};
  min-height: ${(props) => (props.arrayCount > 1 ? "15000px" : "100vh")};
  margin-top: 3em;
  width: 100%;

  ::-webkit-scrollbar {
    width: 20px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 5px solid transparent;
    background-clip: content-box;
    background-color: ${(props) => props.theme.background.blue};
  }
`;

export const ScrollBox = styled.div`
  width: 5000px;
  height: calc(100vh - 250px);
  position: relative;
`;

export const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30000px;
`;

export const TitleContainer = styled(SpaceBetween)`
  margin-bottom: 15px;

  ${Title} {
    margin-bottom: 0;
  }
`;

export const Initial = styled(Center)`
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  width: 100%;
`;
