import styled from "styled-components";
import React from "react";
import { ErrorMessage } from "src/components/Form/ErrorMessage";

export const AdminTextArea = ({
  title,
  name,
  formik,
  rows,
}: {
  title: string;
  name: string;
  formik: any;
  rows?: number;
}) => {
  const handleChange = (e: any) => {
    formik.setFieldValue(name, e.target.value);
  };

  return (
    <Column>
      <Label>{title}</Label>
      <TextArea name={name} onChange={handleChange} rows={rows ? rows : 4} />
      <ErrorMessage formik={formik} name={name} />
    </Column>
  );
};

const TextArea = styled.textarea`
  width: 100%;
  padding: 9px 16px 10px;
  border: 1px solid #c3c3c3;
  border-radius: 7px;
  box-shadow: 0px 1px 8px rgb(0 0 0 / 5%);

  &:focus {
    outline: none !important;
    border-color: #1321b1;
    box-shadow: none;
  }
`;

const Label = styled.span`
  font-size: 13px;
  color: #555555;
  padding-bottom: 3px;
  padding-left: 1px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 13px;
`;
