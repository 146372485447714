import { CheckboxGroup } from "../../../../../../components/Form/CheckBox";
import React, { useEffect, useState } from "react";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { useQuery } from "../../../../../../utils/useQuery";
import {
  CompaniesByStateQuery,
  Company,
  CreateAdvertisementSettingInput,
  ListProfilesQuery,
  ModelSortDirection,
  Profile,
  PublicAdvertisementSetting,
} from "../../../../../../API";
import {
  companiesByState,
  listProfiles,
} from "../../../../../../graphql/queries";
import { EntityState } from "../../../../../../types/_types";
import { FormSelect } from "../../../../../../components/Form/Select";

export const AppliedTo = ({
  formik,
}: {
  formik: FormikProps<CreateAdvertisementSettingInput>;
}) => {
  const { t } = useTranslation(["form"]);

  const [options, setOptions] = useState([]);

  const { data: profiles } = useQuery<ListProfilesQuery>(listProfiles, {
    limit: 1000,
  });

  const { data: companies } = useQuery<CompaniesByStateQuery>(
    companiesByState,
    {
      entityState: EntityState.ACTIVE,
      sort: ModelSortDirection.ASC,
      limit: 1000,
    }
  );

  useEffect(() => {
    if (companies && profiles) {
      const profileOptions =
        profiles?.listProfiles?.items &&
        profiles?.listProfiles?.items?.length > 0
          ? (profiles?.listProfiles?.items as Profile[])?.map?.(
              (e: Profile) => {
                return {
                  value: e?.id,
                  label: `${e?.email} ${e?.forename} ${e?.surname}`,
                };
              }
            )
          : [];
      const companyOptions =
        companies?.companiesByState?.items &&
        companies?.companiesByState?.items.length > 0
          ? (companies?.companiesByState?.items as Company[])?.map?.(
              (e: Company) => {
                return { value: e?.id, label: e?.name };
              }
            )
          : [];
      const options: any = [...companyOptions, ...profileOptions];
      setOptions(options);
    }
  }, [companies, profiles]);

  return (
    <>
      <FormSelect
        title={t("common:checkbox.settingPublic")}
        placeholder={t("common:checkbox.settingPublic")}
        name="public"
        formik={formik}
        options={[
          {
            value: PublicAdvertisementSetting.PUBLIC,
            title: t("common:checkbox.public"),
          },
          {
            value: PublicAdvertisementSetting.PRIVATE,
            title: t("common:checkbox.private"),
          },
        ]}
      />
      {formik.values.public?.includes(PublicAdvertisementSetting.PRIVATE) && (
        <CheckboxGroup
          formik={formik}
          name={"appliedTo"}
          title={t("common:table.appliedTo")}
          options={options}
          all
        />
      )}
    </>
  );
};
