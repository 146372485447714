import {
  createAdvertisementSetting,
  createAdvertisementType,
  updateAdvertisementSetting,
  updateAdvertisementType,
} from "../../../../../graphql/mutations";
import { message } from "antd";
import { routeTo } from "../../../../../utils/createRoute";
import { Routes } from "../../../../../routes";
import {
  CreateAdvertisementSettingInput,
  CreateAdvertisementTypeInput,
} from "../../../../../API";
import { SettingPage } from "../index";

/**
 * Create advertisement
 * @param values
 * @param mutation
 * @param id
 * @param history
 */
export const handleSubmit = async (
  values: CreateAdvertisementSettingInput,
  mutation: any,
  history: any,
  id?: string
) => {
  try {
    const formattedValues = {
      ...values,
      appliedTo: JSON.stringify(values.appliedTo),
    };

    await mutation(
      id ? updateAdvertisementSetting : createAdvertisementSetting,
      {
        input: formattedValues,
      }
    );

    message.success(`Nastavení ceny inzerátů bylo úspěšně uloženo.`);

    routeTo(history, Routes.SETTINGS, [SettingPage.AdvertisementsSettings]);
  } catch (e) {
    message.error(`Exception: ${e}`);
  }
};
