/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateEmailNewsletterInput = {
  id?: string | null;
  email?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelEmailNewsletterConditionInput = {
  email?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelEmailNewsletterConditionInput | null> | null;
  or?: Array<ModelEmailNewsletterConditionInput | null> | null;
  not?: ModelEmailNewsletterConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type EmailNewsletter = {
  __typename: "EmailNewsletter";
  id: string;
  email?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateEmailNewsletterInput = {
  id: string;
  email?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteEmailNewsletterInput = {
  id: string;
};

export type CreateArticleCategoryInput = {
  id?: string | null;
  entity: EntityType;
  name: string;
  slug: string;
  order?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export enum EntityType {
  ARTICLE_CATEGORY = "ARTICLE_CATEGORY",
  ARTICLE = "ARTICLE",
  CAROUSEL = "CAROUSEL",
  ADVERTISEMENT_SETTING = "ADVERTISEMENT_SETTING",
  SERVICE_SETTING = "SERVICE_SETTING",
  ADVERTISEMENT_TYPE = "ADVERTISEMENT_TYPE",
  ADVERTISEMENT = "ADVERTISEMENT",
  COMPANY = "COMPANY",
  COMPANY_USER = "COMPANY_USER",
  PROFILE = "PROFILE",
}

export type ModelArticleCategoryConditionInput = {
  entity?: ModelEntityTypeInput | null;
  name?: ModelStringInput | null;
  order?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelArticleCategoryConditionInput | null> | null;
  or?: Array<ModelArticleCategoryConditionInput | null> | null;
  not?: ModelArticleCategoryConditionInput | null;
};

export type ModelEntityTypeInput = {
  eq?: EntityType | null;
  ne?: EntityType | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ArticleCategory = {
  __typename: "ArticleCategory";
  id: string;
  entity: EntityType;
  name: string;
  slug: string;
  order?: number | null;
  createdAt: string;
  updatedAt: string;
  articles?: ModelArticleConnection | null;
};

export type ModelArticleConnection = {
  __typename: "ModelArticleConnection";
  items: Array<Article | null>;
  nextToken?: string | null;
};

export type Article = {
  __typename: "Article";
  id: string;
  active?: boolean | null;
  content?: string | null;
  description?: string | null;
  entity: EntityType;
  image?: string | null;
  title: string;
  slug: string;
  createdBy?: string | null;
  hashtags?: string | null;
  type?: string | null;
  order?: number | null;
  articleCategoryID: string;
  createdAt: string;
  updatedAt: string;
  category?: ArticleCategory | null;
};

export type UpdateArticleCategoryInput = {
  id?: string | null;
  entity?: EntityType | null;
  name?: string | null;
  slug: string;
  order?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteArticleCategoryInput = {
  slug: string;
};

export type CreateArticleInput = {
  id?: string | null;
  active?: boolean | null;
  content?: string | null;
  description?: string | null;
  entity: EntityType;
  image?: string | null;
  title: string;
  slug: string;
  createdBy?: string | null;
  hashtags?: string | null;
  type?: string | null;
  order?: number | null;
  articleCategoryID: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelArticleConditionInput = {
  active?: ModelBooleanInput | null;
  content?: ModelStringInput | null;
  description?: ModelStringInput | null;
  entity?: ModelEntityTypeInput | null;
  image?: ModelStringInput | null;
  title?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  hashtags?: ModelStringInput | null;
  type?: ModelStringInput | null;
  order?: ModelIntInput | null;
  articleCategoryID?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelArticleConditionInput | null> | null;
  or?: Array<ModelArticleConditionInput | null> | null;
  not?: ModelArticleConditionInput | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateArticleInput = {
  id?: string | null;
  active?: boolean | null;
  content?: string | null;
  description?: string | null;
  entity?: EntityType | null;
  image?: string | null;
  title?: string | null;
  slug: string;
  createdBy?: string | null;
  hashtags?: string | null;
  type?: string | null;
  order?: number | null;
  articleCategoryID?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteArticleInput = {
  slug: string;
};

export type CreateAdvertisementTypeInput = {
  id?: string | null;
  active: boolean;
  entity: EntityType;
  name: string;
  jobDescription?: string | null;
  order: number;
  approvalRequired: string;
  components?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelAdvertisementTypeConditionInput = {
  active?: ModelBooleanInput | null;
  entity?: ModelEntityTypeInput | null;
  name?: ModelStringInput | null;
  jobDescription?: ModelStringInput | null;
  order?: ModelIntInput | null;
  approvalRequired?: ModelStringInput | null;
  components?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelAdvertisementTypeConditionInput | null> | null;
  or?: Array<ModelAdvertisementTypeConditionInput | null> | null;
  not?: ModelAdvertisementTypeConditionInput | null;
};

export type AdvertisementType = {
  __typename: "AdvertisementType";
  id: string;
  active: boolean;
  entity: EntityType;
  name: string;
  jobDescription?: string | null;
  order: number;
  approvalRequired: string;
  components?: string | null;
  createdAt: string;
  updatedAt: string;
  advertisements?: ModelAdvertisementSettingConnection | null;
};

export type ModelAdvertisementSettingConnection = {
  __typename: "ModelAdvertisementSettingConnection";
  items: Array<AdvertisementSetting | null>;
  nextToken?: string | null;
};

export type AdvertisementSetting = {
  __typename: "AdvertisementSetting";
  id: string;
  active: boolean;
  creditsCount: number;
  description?: string | null;
  entity: EntityType;
  internalName: string;
  name: string;
  numberOfDays: number;
  reactivationCreditsCount: number;
  order: number;
  appliedTo: string;
  public?: PublicAdvertisementSetting | null;
  advertisementTypeID: string;
  createdAt: string;
  updatedAt: string;
  advertisementType?: AdvertisementType | null;
};

export enum PublicAdvertisementSetting {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}

export type UpdateAdvertisementTypeInput = {
  id: string;
  active?: boolean | null;
  entity?: EntityType | null;
  name?: string | null;
  jobDescription?: string | null;
  order?: number | null;
  approvalRequired?: string | null;
  components?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteAdvertisementTypeInput = {
  id: string;
};

export type CreateServiceSettingInput = {
  id?: string | null;
  active: boolean;
  creditsCount: number;
  entity: EntityType;
  name: string;
  public?: PublicAdvertisementSetting | null;
  appliedTo: string;
  serviceType: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelServiceSettingConditionInput = {
  active?: ModelBooleanInput | null;
  creditsCount?: ModelIntInput | null;
  entity?: ModelEntityTypeInput | null;
  name?: ModelStringInput | null;
  public?: ModelPublicAdvertisementSettingInput | null;
  appliedTo?: ModelStringInput | null;
  serviceType?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelServiceSettingConditionInput | null> | null;
  or?: Array<ModelServiceSettingConditionInput | null> | null;
  not?: ModelServiceSettingConditionInput | null;
};

export type ModelPublicAdvertisementSettingInput = {
  eq?: PublicAdvertisementSetting | null;
  ne?: PublicAdvertisementSetting | null;
};

export type ServiceSetting = {
  __typename: "ServiceSetting";
  id: string;
  active: boolean;
  creditsCount: number;
  entity: EntityType;
  name: string;
  public?: PublicAdvertisementSetting | null;
  appliedTo: string;
  serviceType: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateServiceSettingInput = {
  id: string;
  active?: boolean | null;
  creditsCount?: number | null;
  entity?: EntityType | null;
  name?: string | null;
  public?: PublicAdvertisementSetting | null;
  appliedTo?: string | null;
  serviceType?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteServiceSettingInput = {
  id: string;
};

export type CreateAdvertisementSettingInput = {
  id?: string | null;
  active: boolean;
  creditsCount: number;
  description?: string | null;
  entity: EntityType;
  internalName: string;
  name: string;
  numberOfDays: number;
  reactivationCreditsCount: number;
  order: number;
  appliedTo: string;
  public?: PublicAdvertisementSetting | null;
  advertisementTypeID: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelAdvertisementSettingConditionInput = {
  active?: ModelBooleanInput | null;
  creditsCount?: ModelIntInput | null;
  description?: ModelStringInput | null;
  entity?: ModelEntityTypeInput | null;
  internalName?: ModelStringInput | null;
  name?: ModelStringInput | null;
  numberOfDays?: ModelIntInput | null;
  reactivationCreditsCount?: ModelIntInput | null;
  order?: ModelIntInput | null;
  appliedTo?: ModelStringInput | null;
  public?: ModelPublicAdvertisementSettingInput | null;
  advertisementTypeID?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelAdvertisementSettingConditionInput | null> | null;
  or?: Array<ModelAdvertisementSettingConditionInput | null> | null;
  not?: ModelAdvertisementSettingConditionInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type UpdateAdvertisementSettingInput = {
  id: string;
  active?: boolean | null;
  creditsCount?: number | null;
  description?: string | null;
  entity?: EntityType | null;
  internalName?: string | null;
  name?: string | null;
  numberOfDays?: number | null;
  reactivationCreditsCount?: number | null;
  order?: number | null;
  appliedTo?: string | null;
  public?: PublicAdvertisementSetting | null;
  advertisementTypeID?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteAdvertisementSettingInput = {
  id: string;
};

export type CreateWorkerTypeInput = {
  id?: string | null;
  name: string;
  country: Country;
  level: number;
  order: number;
};

export enum Country {
  EU = "EU",
  USA = "USA",
  RUSSIA = "RUSSIA",
  JAPAN = "JAPAN",
}

export type ModelWorkerTypeConditionInput = {
  name?: ModelStringInput | null;
  country?: ModelCountryInput | null;
  level?: ModelIntInput | null;
  order?: ModelIntInput | null;
  and?: Array<ModelWorkerTypeConditionInput | null> | null;
  or?: Array<ModelWorkerTypeConditionInput | null> | null;
  not?: ModelWorkerTypeConditionInput | null;
};

export type ModelCountryInput = {
  eq?: Country | null;
  ne?: Country | null;
};

export type WorkerType = {
  __typename: "WorkerType";
  id: string;
  name: string;
  country: Country;
  level: number;
  order: number;
  createdAt: string;
  updatedAt: string;
  childEdges?: ModelWorkerTypeEdgeConnection | null;
};

export type ModelWorkerTypeEdgeConnection = {
  __typename: "ModelWorkerTypeEdgeConnection";
  items: Array<WorkerTypeEdge | null>;
  nextToken?: string | null;
};

export type WorkerTypeEdge = {
  __typename: "WorkerTypeEdge";
  id: string;
  parentNodeId: string;
  childNodeId: string;
  country: Country;
  createdAt: string;
  updatedAt: string;
  parentNode?: WorkerType | null;
  childNode?: WorkerType | null;
};

export type UpdateWorkerTypeInput = {
  id: string;
  name?: string | null;
  country?: Country | null;
  level?: number | null;
  order?: number | null;
};

export type DeleteWorkerTypeInput = {
  id: string;
};

export type CreateWorkerTypeEdgeInput = {
  id?: string | null;
  parentNodeId: string;
  childNodeId: string;
  country: Country;
};

export type ModelWorkerTypeEdgeConditionInput = {
  parentNodeId?: ModelIDInput | null;
  childNodeId?: ModelIDInput | null;
  country?: ModelCountryInput | null;
  and?: Array<ModelWorkerTypeEdgeConditionInput | null> | null;
  or?: Array<ModelWorkerTypeEdgeConditionInput | null> | null;
  not?: ModelWorkerTypeEdgeConditionInput | null;
};

export type UpdateWorkerTypeEdgeInput = {
  id: string;
  parentNodeId?: string | null;
  childNodeId?: string | null;
  country?: Country | null;
};

export type DeleteWorkerTypeEdgeInput = {
  id: string;
};

export type CreateSuitableForInput = {
  id?: string | null;
  name: string;
  country: Country;
  level: number;
  order: number;
};

export type ModelSuitableForConditionInput = {
  name?: ModelStringInput | null;
  country?: ModelCountryInput | null;
  level?: ModelIntInput | null;
  order?: ModelIntInput | null;
  and?: Array<ModelSuitableForConditionInput | null> | null;
  or?: Array<ModelSuitableForConditionInput | null> | null;
  not?: ModelSuitableForConditionInput | null;
};

export type SuitableFor = {
  __typename: "SuitableFor";
  id: string;
  name: string;
  country: Country;
  level: number;
  order: number;
  createdAt: string;
  updatedAt: string;
  childEdges?: ModelSuitableForEdgeConnection | null;
};

export type ModelSuitableForEdgeConnection = {
  __typename: "ModelSuitableForEdgeConnection";
  items: Array<SuitableForEdge | null>;
  nextToken?: string | null;
};

export type SuitableForEdge = {
  __typename: "SuitableForEdge";
  id: string;
  parentNodeId: string;
  childNodeId: string;
  country: Country;
  createdAt: string;
  updatedAt: string;
  parentNode?: SuitableFor | null;
  childNode?: SuitableFor | null;
};

export type UpdateSuitableForInput = {
  id: string;
  name?: string | null;
  country?: Country | null;
  level?: number | null;
  order?: number | null;
};

export type DeleteSuitableForInput = {
  id: string;
};

export type CreateSuitableForEdgeInput = {
  id?: string | null;
  parentNodeId: string;
  childNodeId: string;
  country: Country;
};

export type ModelSuitableForEdgeConditionInput = {
  parentNodeId?: ModelIDInput | null;
  childNodeId?: ModelIDInput | null;
  country?: ModelCountryInput | null;
  and?: Array<ModelSuitableForEdgeConditionInput | null> | null;
  or?: Array<ModelSuitableForEdgeConditionInput | null> | null;
  not?: ModelSuitableForEdgeConditionInput | null;
};

export type UpdateSuitableForEdgeInput = {
  id: string;
  parentNodeId?: string | null;
  childNodeId?: string | null;
  country?: Country | null;
};

export type DeleteSuitableForEdgeInput = {
  id: string;
};

export type CreateEmployerInput = {
  id?: string | null;
  name: string;
  country: Country;
  level: number;
  order: number;
};

export type ModelEmployerConditionInput = {
  name?: ModelStringInput | null;
  country?: ModelCountryInput | null;
  level?: ModelIntInput | null;
  order?: ModelIntInput | null;
  and?: Array<ModelEmployerConditionInput | null> | null;
  or?: Array<ModelEmployerConditionInput | null> | null;
  not?: ModelEmployerConditionInput | null;
};

export type Employer = {
  __typename: "Employer";
  id: string;
  name: string;
  country: Country;
  level: number;
  order: number;
  createdAt: string;
  updatedAt: string;
  childEdges?: ModelEmployerEdgeConnection | null;
};

export type ModelEmployerEdgeConnection = {
  __typename: "ModelEmployerEdgeConnection";
  items: Array<EmployerEdge | null>;
  nextToken?: string | null;
};

export type EmployerEdge = {
  __typename: "EmployerEdge";
  id: string;
  parentNodeId: string;
  childNodeId: string;
  country: Country;
  createdAt: string;
  updatedAt: string;
  parentNode?: Employer | null;
  childNode?: Employer | null;
};

export type UpdateEmployerInput = {
  id: string;
  name?: string | null;
  country?: Country | null;
  level?: number | null;
  order?: number | null;
};

export type DeleteEmployerInput = {
  id: string;
};

export type CreateEmployerEdgeInput = {
  id?: string | null;
  parentNodeId: string;
  childNodeId: string;
  country: Country;
};

export type ModelEmployerEdgeConditionInput = {
  parentNodeId?: ModelIDInput | null;
  childNodeId?: ModelIDInput | null;
  country?: ModelCountryInput | null;
  and?: Array<ModelEmployerEdgeConditionInput | null> | null;
  or?: Array<ModelEmployerEdgeConditionInput | null> | null;
  not?: ModelEmployerEdgeConditionInput | null;
};

export type UpdateEmployerEdgeInput = {
  id: string;
  parentNodeId?: string | null;
  childNodeId?: string | null;
  country?: Country | null;
};

export type DeleteEmployerEdgeInput = {
  id: string;
};

export type CreateProfileInput = {
  id?: string | null;
  credits?: number | null;
  sub?: string | null;
  email: string;
  entity?: EntityType | null;
  entityState?: string | null;
  profileImage?: string | null;
  firstDegree?: string | null;
  forename?: string | null;
  surname?: string | null;
  secondDegree?: string | null;
  aboutMe?: string | null;
  educations?: string | null;
  workExperiences?: string | null;
  publications?: string | null;
  conferences?: string | null;
  certificates?: string | null;
  languages?: string | null;
  otherInformation?: string | null;
  qualifications?: string | null;
  name?: string | null;
  crn?: string | null;
  vatRegNumber?: string | null;
  street?: string | null;
  buildingIdentificationNumber?: string | null;
  municipality?: string | null;
  postCode?: string | null;
  language?: string | null;
  phone?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelProfileConditionInput = {
  credits?: ModelIntInput | null;
  sub?: ModelStringInput | null;
  email?: ModelStringInput | null;
  entity?: ModelEntityTypeInput | null;
  entityState?: ModelStringInput | null;
  profileImage?: ModelStringInput | null;
  firstDegree?: ModelStringInput | null;
  forename?: ModelStringInput | null;
  surname?: ModelStringInput | null;
  secondDegree?: ModelStringInput | null;
  aboutMe?: ModelStringInput | null;
  educations?: ModelStringInput | null;
  workExperiences?: ModelStringInput | null;
  publications?: ModelStringInput | null;
  conferences?: ModelStringInput | null;
  certificates?: ModelStringInput | null;
  languages?: ModelStringInput | null;
  otherInformation?: ModelStringInput | null;
  qualifications?: ModelStringInput | null;
  name?: ModelStringInput | null;
  crn?: ModelStringInput | null;
  vatRegNumber?: ModelStringInput | null;
  street?: ModelStringInput | null;
  buildingIdentificationNumber?: ModelStringInput | null;
  municipality?: ModelStringInput | null;
  postCode?: ModelStringInput | null;
  language?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelProfileConditionInput | null> | null;
  or?: Array<ModelProfileConditionInput | null> | null;
  not?: ModelProfileConditionInput | null;
};

export type Profile = {
  __typename: "Profile";
  id: string;
  credits?: number | null;
  sub?: string | null;
  email: string;
  entity?: EntityType | null;
  entityState?: string | null;
  profileImage?: string | null;
  firstDegree?: string | null;
  forename?: string | null;
  surname?: string | null;
  secondDegree?: string | null;
  aboutMe?: string | null;
  educations?: string | null;
  workExperiences?: string | null;
  publications?: string | null;
  conferences?: string | null;
  certificates?: string | null;
  languages?: string | null;
  otherInformation?: string | null;
  qualifications?: string | null;
  name?: string | null;
  crn?: string | null;
  vatRegNumber?: string | null;
  street?: string | null;
  buildingIdentificationNumber?: string | null;
  municipality?: string | null;
  postCode?: string | null;
  language?: string | null;
  phone?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  messages?: ModelMessageInboxConnection | null;
};

export type ModelMessageInboxConnection = {
  __typename: "ModelMessageInboxConnection";
  items: Array<MessageInbox | null>;
  nextToken?: string | null;
};

export type MessageInbox = {
  __typename: "MessageInbox";
  id: string;
  name: string;
  email: string;
  phoneNumber?: string | null;
  message: string;
  file?: string | null;
  state?: MessageState | null;
  createdAt: string;
  updatedAt: string;
  profileID: string;
  advertisementID: string;
  profile?: Profile | null;
  advertisement?: Advertisement | null;
};

export enum MessageState {
  READ = "READ",
  UNREAD = "UNREAD",
}

export type Advertisement = {
  __typename: "Advertisement";
  id: string;
  friendlyId?: number | null;
  entity: EntityType;
  active?: Active | null;
  state?: string | null;
  stateDescription?: string | null;
  paymentState?: string | null;
  jobPosition: string;
  jobShortDescription: string;
  jobDescription?: string | null;
  place: string;
  salaryFrom?: number | null;
  salaryTo?: number | null;
  salarySpecifiedInText?: boolean | null;
  reactivationCount?: number | null;
  currentReactivationCount?: number | null;
  automaticReactivation?: boolean | null;
  leadingPosition?: boolean | null;
  residentialPlace?: boolean | null;
  typeOfCooperation?: string | null;
  jobType?: string | null;
  financialConditions?: string | null;
  typeOfInternship?: string | null;
  contactFirstDegree?: string | null;
  contactForename?: string | null;
  contactSurname?: string | null;
  contactSecondDegree?: string | null;
  contactEmail?: string | null;
  contactPhoneNumber?: string | null;
  hideContactInformation?: boolean | null;
  workType?: string | null;
  suitableFor?: string | null;
  workTypeStringified?: string | null;
  suitableForStringified?: string | null;
  employmentTypesStringified?: string | null;
  employmentTypes?: string | null;
  paymentType?: PaymentType | null;
  advertisementSettingID: string;
  advertisementTypeID?: string | null;
  companyID?: string | null;
  profileID?: string | null;
  expirationDate: string;
  createdAt: string;
  updatedAt: string;
  advertisementType?: AdvertisementType | null;
  advertisementSetting?: AdvertisementSetting | null;
  profile?: Profile | null;
  company?: Company | null;
  owner?: string | null;
  analytics?: ModelAdvertisementAnalyticConnection | null;
};

export enum Active {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum PaymentType {
  CREDITS = "CREDITS",
  CREDIT_CARD = "CREDIT_CARD",
}

export type Company = {
  __typename: "Company";
  id: string;
  entity: EntityType;
  entityState?: string | null;
  credits?: number | null;
  name: string;
  logo?: string | null;
  forename?: string | null;
  surname?: string | null;
  address?: string | null;
  phone?: string | null;
  email?: string | null;
  crn?: string | null;
  vatRegNumber?: string | null;
  street?: string | null;
  buildingIdentificationNumber?: string | null;
  municipality?: string | null;
  postCode?: string | null;
  language?: string | null;
  employerTaxonomy?: string | null;
  profileImage?: string | null;
  qualification?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  messages?: ModelCompanyMessageInboxConnection | null;
  users?: ModelCompanyUserConnection | null;
};

export type ModelCompanyMessageInboxConnection = {
  __typename: "ModelCompanyMessageInboxConnection";
  items: Array<CompanyMessageInbox | null>;
  nextToken?: string | null;
};

export type CompanyMessageInbox = {
  __typename: "CompanyMessageInbox";
  id: string;
  name: string;
  email: string;
  state?: MessageState | null;
  phoneNumber?: string | null;
  message: string;
  file?: string | null;
  createdAt: string;
  updatedAt: string;
  companyID: string;
  advertisementID: string;
  company?: Company | null;
  advertisement?: Advertisement | null;
};

export type ModelCompanyUserConnection = {
  __typename: "ModelCompanyUserConnection";
  items: Array<CompanyUser | null>;
  nextToken?: string | null;
};

export type CompanyUser = {
  __typename: "CompanyUser";
  id: string;
  entity: EntityType;
  name?: string | null;
  email: string;
  role: CompanyUserRole;
  createdAt: string;
  updatedAt: string;
  companyID: string;
  company?: Company | null;
  owner?: string | null;
};

export enum CompanyUserRole {
  OWNER = "OWNER",
  ADVERTISER = "ADVERTISER",
}

export type ModelAdvertisementAnalyticConnection = {
  __typename: "ModelAdvertisementAnalyticConnection";
  items: Array<AdvertisementAnalytic | null>;
  nextToken?: string | null;
};

export type AdvertisementAnalytic = {
  __typename: "AdvertisementAnalytic";
  id: string;
  name?: string | null;
  info?: string | null;
  advertisementID: string;
  createdAt: string;
  updatedAt: string;
  advertisement?: Advertisement | null;
};

export type UpdateProfileInput = {
  id: string;
  credits?: number | null;
  sub?: string | null;
  email?: string | null;
  entity?: EntityType | null;
  entityState?: string | null;
  profileImage?: string | null;
  firstDegree?: string | null;
  forename?: string | null;
  surname?: string | null;
  secondDegree?: string | null;
  aboutMe?: string | null;
  educations?: string | null;
  workExperiences?: string | null;
  publications?: string | null;
  conferences?: string | null;
  certificates?: string | null;
  languages?: string | null;
  otherInformation?: string | null;
  qualifications?: string | null;
  name?: string | null;
  crn?: string | null;
  vatRegNumber?: string | null;
  street?: string | null;
  buildingIdentificationNumber?: string | null;
  municipality?: string | null;
  postCode?: string | null;
  language?: string | null;
  phone?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteProfileInput = {
  id: string;
};

export type CreateMessageInboxInput = {
  id?: string | null;
  name: string;
  email: string;
  phoneNumber?: string | null;
  message: string;
  file?: string | null;
  state?: MessageState | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  profileID: string;
  advertisementID: string;
};

export type ModelMessageInboxConditionInput = {
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phoneNumber?: ModelStringInput | null;
  message?: ModelStringInput | null;
  file?: ModelStringInput | null;
  state?: ModelMessageStateInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  profileID?: ModelIDInput | null;
  advertisementID?: ModelIDInput | null;
  and?: Array<ModelMessageInboxConditionInput | null> | null;
  or?: Array<ModelMessageInboxConditionInput | null> | null;
  not?: ModelMessageInboxConditionInput | null;
};

export type ModelMessageStateInput = {
  eq?: MessageState | null;
  ne?: MessageState | null;
};

export type UpdateMessageInboxInput = {
  id: string;
  name?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  message?: string | null;
  file?: string | null;
  state?: MessageState | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  profileID?: string | null;
  advertisementID?: string | null;
};

export type DeleteMessageInboxInput = {
  id: string;
};

export type CreateCompanyInput = {
  id?: string | null;
  entity: EntityType;
  entityState?: string | null;
  credits?: number | null;
  name: string;
  logo?: string | null;
  forename?: string | null;
  surname?: string | null;
  address?: string | null;
  phone?: string | null;
  email?: string | null;
  crn?: string | null;
  vatRegNumber?: string | null;
  street?: string | null;
  buildingIdentificationNumber?: string | null;
  municipality?: string | null;
  postCode?: string | null;
  language?: string | null;
  employerTaxonomy?: string | null;
  profileImage?: string | null;
  qualification?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelCompanyConditionInput = {
  entity?: ModelEntityTypeInput | null;
  entityState?: ModelStringInput | null;
  credits?: ModelIntInput | null;
  name?: ModelStringInput | null;
  logo?: ModelStringInput | null;
  forename?: ModelStringInput | null;
  surname?: ModelStringInput | null;
  address?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  email?: ModelStringInput | null;
  crn?: ModelStringInput | null;
  vatRegNumber?: ModelStringInput | null;
  street?: ModelStringInput | null;
  buildingIdentificationNumber?: ModelStringInput | null;
  municipality?: ModelStringInput | null;
  postCode?: ModelStringInput | null;
  language?: ModelStringInput | null;
  employerTaxonomy?: ModelStringInput | null;
  profileImage?: ModelStringInput | null;
  qualification?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelCompanyConditionInput | null> | null;
  or?: Array<ModelCompanyConditionInput | null> | null;
  not?: ModelCompanyConditionInput | null;
};

export type UpdateCompanyInput = {
  id: string;
  entity?: EntityType | null;
  entityState?: string | null;
  credits?: number | null;
  name?: string | null;
  logo?: string | null;
  forename?: string | null;
  surname?: string | null;
  address?: string | null;
  phone?: string | null;
  email?: string | null;
  crn?: string | null;
  vatRegNumber?: string | null;
  street?: string | null;
  buildingIdentificationNumber?: string | null;
  municipality?: string | null;
  postCode?: string | null;
  language?: string | null;
  employerTaxonomy?: string | null;
  profileImage?: string | null;
  qualification?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteCompanyInput = {
  id: string;
};

export type CreateCompanyMessageInboxInput = {
  id?: string | null;
  name: string;
  email: string;
  state?: MessageState | null;
  phoneNumber?: string | null;
  message: string;
  file?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  companyID: string;
  advertisementID: string;
};

export type ModelCompanyMessageInboxConditionInput = {
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  state?: ModelMessageStateInput | null;
  phoneNumber?: ModelStringInput | null;
  message?: ModelStringInput | null;
  file?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  companyID?: ModelIDInput | null;
  advertisementID?: ModelIDInput | null;
  and?: Array<ModelCompanyMessageInboxConditionInput | null> | null;
  or?: Array<ModelCompanyMessageInboxConditionInput | null> | null;
  not?: ModelCompanyMessageInboxConditionInput | null;
};

export type UpdateCompanyMessageInboxInput = {
  id: string;
  name?: string | null;
  email?: string | null;
  state?: MessageState | null;
  phoneNumber?: string | null;
  message?: string | null;
  file?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  companyID?: string | null;
  advertisementID?: string | null;
};

export type DeleteCompanyMessageInboxInput = {
  id: string;
};

export type CreateCompanyUserInput = {
  id?: string | null;
  entity: EntityType;
  name?: string | null;
  email: string;
  role: CompanyUserRole;
  createdAt?: string | null;
  updatedAt?: string | null;
  companyID: string;
};

export type ModelCompanyUserConditionInput = {
  entity?: ModelEntityTypeInput | null;
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  role?: ModelCompanyUserRoleInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  companyID?: ModelIDInput | null;
  and?: Array<ModelCompanyUserConditionInput | null> | null;
  or?: Array<ModelCompanyUserConditionInput | null> | null;
  not?: ModelCompanyUserConditionInput | null;
};

export type ModelCompanyUserRoleInput = {
  eq?: CompanyUserRole | null;
  ne?: CompanyUserRole | null;
};

export type UpdateCompanyUserInput = {
  id: string;
  entity?: EntityType | null;
  name?: string | null;
  email?: string | null;
  role?: CompanyUserRole | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  companyID?: string | null;
};

export type DeleteCompanyUserInput = {
  id: string;
};

export type CreateAdvertisementInput = {
  id?: string | null;
  friendlyId?: number | null;
  entity: EntityType;
  active?: Active | null;
  state?: string | null;
  stateDescription?: string | null;
  paymentState?: string | null;
  jobPosition: string;
  jobShortDescription: string;
  jobDescription?: string | null;
  place: string;
  salaryFrom?: number | null;
  salaryTo?: number | null;
  salarySpecifiedInText?: boolean | null;
  reactivationCount?: number | null;
  currentReactivationCount?: number | null;
  automaticReactivation?: boolean | null;
  leadingPosition?: boolean | null;
  residentialPlace?: boolean | null;
  typeOfCooperation?: string | null;
  jobType?: string | null;
  financialConditions?: string | null;
  typeOfInternship?: string | null;
  contactFirstDegree?: string | null;
  contactForename?: string | null;
  contactSurname?: string | null;
  contactSecondDegree?: string | null;
  contactEmail?: string | null;
  contactPhoneNumber?: string | null;
  hideContactInformation?: boolean | null;
  workType?: string | null;
  suitableFor?: string | null;
  workTypeStringified?: string | null;
  suitableForStringified?: string | null;
  employmentTypesStringified?: string | null;
  employmentTypes?: string | null;
  paymentType?: PaymentType | null;
  advertisementSettingID: string;
  advertisementTypeID?: string | null;
  companyID?: string | null;
  profileID?: string | null;
  expirationDate: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelAdvertisementConditionInput = {
  friendlyId?: ModelIntInput | null;
  entity?: ModelEntityTypeInput | null;
  active?: ModelActiveInput | null;
  state?: ModelStringInput | null;
  stateDescription?: ModelStringInput | null;
  paymentState?: ModelStringInput | null;
  jobPosition?: ModelStringInput | null;
  jobShortDescription?: ModelStringInput | null;
  jobDescription?: ModelStringInput | null;
  place?: ModelStringInput | null;
  salaryFrom?: ModelIntInput | null;
  salaryTo?: ModelIntInput | null;
  salarySpecifiedInText?: ModelBooleanInput | null;
  reactivationCount?: ModelIntInput | null;
  currentReactivationCount?: ModelIntInput | null;
  automaticReactivation?: ModelBooleanInput | null;
  leadingPosition?: ModelBooleanInput | null;
  residentialPlace?: ModelBooleanInput | null;
  typeOfCooperation?: ModelStringInput | null;
  jobType?: ModelStringInput | null;
  financialConditions?: ModelStringInput | null;
  typeOfInternship?: ModelStringInput | null;
  contactFirstDegree?: ModelStringInput | null;
  contactForename?: ModelStringInput | null;
  contactSurname?: ModelStringInput | null;
  contactSecondDegree?: ModelStringInput | null;
  contactEmail?: ModelStringInput | null;
  contactPhoneNumber?: ModelStringInput | null;
  hideContactInformation?: ModelBooleanInput | null;
  workType?: ModelStringInput | null;
  suitableFor?: ModelStringInput | null;
  workTypeStringified?: ModelStringInput | null;
  suitableForStringified?: ModelStringInput | null;
  employmentTypesStringified?: ModelStringInput | null;
  employmentTypes?: ModelStringInput | null;
  paymentType?: ModelPaymentTypeInput | null;
  advertisementSettingID?: ModelIDInput | null;
  advertisementTypeID?: ModelIDInput | null;
  companyID?: ModelIDInput | null;
  profileID?: ModelIDInput | null;
  expirationDate?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelAdvertisementConditionInput | null> | null;
  or?: Array<ModelAdvertisementConditionInput | null> | null;
  not?: ModelAdvertisementConditionInput | null;
};

export type ModelActiveInput = {
  eq?: Active | null;
  ne?: Active | null;
};

export type ModelPaymentTypeInput = {
  eq?: PaymentType | null;
  ne?: PaymentType | null;
};

export type UpdateAdvertisementInput = {
  id: string;
  friendlyId?: number | null;
  entity?: EntityType | null;
  active?: Active | null;
  state?: string | null;
  stateDescription?: string | null;
  paymentState?: string | null;
  jobPosition?: string | null;
  jobShortDescription?: string | null;
  jobDescription?: string | null;
  place?: string | null;
  salaryFrom?: number | null;
  salaryTo?: number | null;
  salarySpecifiedInText?: boolean | null;
  reactivationCount?: number | null;
  currentReactivationCount?: number | null;
  automaticReactivation?: boolean | null;
  leadingPosition?: boolean | null;
  residentialPlace?: boolean | null;
  typeOfCooperation?: string | null;
  jobType?: string | null;
  financialConditions?: string | null;
  typeOfInternship?: string | null;
  contactFirstDegree?: string | null;
  contactForename?: string | null;
  contactSurname?: string | null;
  contactSecondDegree?: string | null;
  contactEmail?: string | null;
  contactPhoneNumber?: string | null;
  hideContactInformation?: boolean | null;
  workType?: string | null;
  suitableFor?: string | null;
  workTypeStringified?: string | null;
  suitableForStringified?: string | null;
  employmentTypesStringified?: string | null;
  employmentTypes?: string | null;
  paymentType?: PaymentType | null;
  advertisementSettingID?: string | null;
  advertisementTypeID?: string | null;
  companyID?: string | null;
  profileID?: string | null;
  expirationDate?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteAdvertisementInput = {
  id: string;
};

export type CreateAdvertisementAnalyticInput = {
  id?: string | null;
  name?: string | null;
  info?: string | null;
  advertisementID: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelAdvertisementAnalyticConditionInput = {
  name?: ModelStringInput | null;
  info?: ModelStringInput | null;
  advertisementID?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelAdvertisementAnalyticConditionInput | null> | null;
  or?: Array<ModelAdvertisementAnalyticConditionInput | null> | null;
  not?: ModelAdvertisementAnalyticConditionInput | null;
};

export type UpdateAdvertisementAnalyticInput = {
  id: string;
  name?: string | null;
  info?: string | null;
  advertisementID?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteAdvertisementAnalyticInput = {
  id: string;
};

export type CreateShowCaseInput = {
  id?: string | null;
  title: string;
  text: string;
  file?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  companyID: string;
};

export type ModelShowCaseConditionInput = {
  title?: ModelStringInput | null;
  text?: ModelStringInput | null;
  file?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelShowCaseConditionInput | null> | null;
  or?: Array<ModelShowCaseConditionInput | null> | null;
  not?: ModelShowCaseConditionInput | null;
};

export type ShowCase = {
  __typename: "ShowCase";
  id: string;
  title: string;
  text: string;
  file?: string | null;
  createdAt: string;
  updatedAt: string;
  companyID: string;
  company?: Company | null;
};

export type UpdateShowCaseInput = {
  id?: string | null;
  title?: string | null;
  text?: string | null;
  file?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  companyID: string;
};

export type DeleteShowCaseInput = {
  companyID: string;
};

export type CreateTransactionInput = {
  id?: string | null;
  amount: number;
  currency: string;
  description?: string | null;
  paymentIntent?: string | null;
  companyID?: string | null;
  profileID?: string | null;
  initiatedBy?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelTransactionConditionInput = {
  amount?: ModelIntInput | null;
  currency?: ModelStringInput | null;
  description?: ModelStringInput | null;
  paymentIntent?: ModelStringInput | null;
  companyID?: ModelIDInput | null;
  profileID?: ModelIDInput | null;
  initiatedBy?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelTransactionConditionInput | null> | null;
  or?: Array<ModelTransactionConditionInput | null> | null;
  not?: ModelTransactionConditionInput | null;
};

export type Transaction = {
  __typename: "Transaction";
  id: string;
  amount: number;
  currency: string;
  description?: string | null;
  paymentIntent?: string | null;
  companyID?: string | null;
  profileID?: string | null;
  initiatedBy?: string | null;
  createdAt: string;
  updatedAt: string;
  profile?: Profile | null;
  initiatedByProfile?: Profile | null;
  company?: Company | null;
};

export type UpdateTransactionInput = {
  id: string;
  amount?: number | null;
  currency?: string | null;
  description?: string | null;
  paymentIntent?: string | null;
  companyID?: string | null;
  profileID?: string | null;
  initiatedBy?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteTransactionInput = {
  id: string;
};

export type CreateCarouselInput = {
  id?: string | null;
  active: boolean;
  entity: EntityType;
  image?: string | null;
  title: string;
  type?: CarouselType | null;
  companyID?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export enum CarouselType {
  GLOBAL = "GLOBAL",
  MAIN_PAGE = "MAIN_PAGE",
  ARTICLES = "ARTICLES",
}

export type ModelCarouselConditionInput = {
  active?: ModelBooleanInput | null;
  entity?: ModelEntityTypeInput | null;
  image?: ModelStringInput | null;
  title?: ModelStringInput | null;
  type?: ModelCarouselTypeInput | null;
  companyID?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelCarouselConditionInput | null> | null;
  or?: Array<ModelCarouselConditionInput | null> | null;
  not?: ModelCarouselConditionInput | null;
};

export type ModelCarouselTypeInput = {
  eq?: CarouselType | null;
  ne?: CarouselType | null;
};

export type Carousel = {
  __typename: "Carousel";
  id: string;
  active: boolean;
  entity: EntityType;
  image?: string | null;
  title: string;
  type?: CarouselType | null;
  companyID?: string | null;
  createdAt: string;
  updatedAt: string;
  company?: Company | null;
};

export type UpdateCarouselInput = {
  id: string;
  active?: boolean | null;
  entity?: EntityType | null;
  image?: string | null;
  title?: string | null;
  type?: CarouselType | null;
  companyID?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteCarouselInput = {
  id: string;
};

export type ModelEmailNewsletterFilterInput = {
  id?: ModelIDInput | null;
  email?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelEmailNewsletterFilterInput | null> | null;
  or?: Array<ModelEmailNewsletterFilterInput | null> | null;
  not?: ModelEmailNewsletterFilterInput | null;
};

export type ModelEmailNewsletterConnection = {
  __typename: "ModelEmailNewsletterConnection";
  items: Array<EmailNewsletter | null>;
  nextToken?: string | null;
};

export type ModelArticleCategoryFilterInput = {
  id?: ModelIDInput | null;
  entity?: ModelEntityTypeInput | null;
  name?: ModelStringInput | null;
  slug?: ModelStringInput | null;
  order?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelArticleCategoryFilterInput | null> | null;
  or?: Array<ModelArticleCategoryFilterInput | null> | null;
  not?: ModelArticleCategoryFilterInput | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export type ModelArticleCategoryConnection = {
  __typename: "ModelArticleCategoryConnection";
  items: Array<ArticleCategory | null>;
  nextToken?: string | null;
};

export type ModelIntKeyConditionInput = {
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type SearchableArticleCategoryFilterInput = {
  id?: SearchableIDFilterInput | null;
  name?: SearchableStringFilterInput | null;
  slug?: SearchableStringFilterInput | null;
  order?: SearchableIntFilterInput | null;
  createdAt?: SearchableStringFilterInput | null;
  updatedAt?: SearchableStringFilterInput | null;
  and?: Array<SearchableArticleCategoryFilterInput | null> | null;
  or?: Array<SearchableArticleCategoryFilterInput | null> | null;
  not?: SearchableArticleCategoryFilterInput | null;
};

export type SearchableIDFilterInput = {
  ne?: string | null;
  gt?: string | null;
  lt?: string | null;
  gte?: string | null;
  lte?: string | null;
  eq?: string | null;
  match?: string | null;
  matchPhrase?: string | null;
  matchPhrasePrefix?: string | null;
  multiMatch?: string | null;
  exists?: boolean | null;
  wildcard?: string | null;
  regexp?: string | null;
  range?: Array<string | null> | null;
};

export type SearchableStringFilterInput = {
  ne?: string | null;
  gt?: string | null;
  lt?: string | null;
  gte?: string | null;
  lte?: string | null;
  eq?: string | null;
  match?: string | null;
  matchPhrase?: string | null;
  matchPhrasePrefix?: string | null;
  multiMatch?: string | null;
  exists?: boolean | null;
  wildcard?: string | null;
  regexp?: string | null;
  range?: Array<string | null> | null;
};

export type SearchableIntFilterInput = {
  ne?: number | null;
  gt?: number | null;
  lt?: number | null;
  gte?: number | null;
  lte?: number | null;
  eq?: number | null;
  range?: Array<number | null> | null;
};

export type SearchableArticleCategorySortInput = {
  field?: SearchableArticleCategorySortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableArticleCategorySortableFields {
  id = "id",
  name = "name",
  slug = "slug",
  order = "order",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}

export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}

export type SearchableArticleCategoryConnection = {
  __typename: "SearchableArticleCategoryConnection";
  items: Array<ArticleCategory | null>;
  nextToken?: string | null;
  total?: number | null;
};

export type ModelArticleFilterInput = {
  id?: ModelIDInput | null;
  active?: ModelBooleanInput | null;
  content?: ModelStringInput | null;
  description?: ModelStringInput | null;
  entity?: ModelEntityTypeInput | null;
  image?: ModelStringInput | null;
  title?: ModelStringInput | null;
  slug?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  hashtags?: ModelStringInput | null;
  type?: ModelStringInput | null;
  order?: ModelIntInput | null;
  articleCategoryID?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelArticleFilterInput | null> | null;
  or?: Array<ModelArticleFilterInput | null> | null;
  not?: ModelArticleFilterInput | null;
};

export type SearchableArticleFilterInput = {
  id?: SearchableIDFilterInput | null;
  active?: SearchableBooleanFilterInput | null;
  content?: SearchableStringFilterInput | null;
  description?: SearchableStringFilterInput | null;
  image?: SearchableStringFilterInput | null;
  title?: SearchableStringFilterInput | null;
  slug?: SearchableStringFilterInput | null;
  createdBy?: SearchableStringFilterInput | null;
  hashtags?: SearchableStringFilterInput | null;
  type?: SearchableStringFilterInput | null;
  order?: SearchableIntFilterInput | null;
  articleCategoryID?: SearchableStringFilterInput | null;
  createdAt?: SearchableStringFilterInput | null;
  updatedAt?: SearchableStringFilterInput | null;
  and?: Array<SearchableArticleFilterInput | null> | null;
  or?: Array<SearchableArticleFilterInput | null> | null;
  not?: SearchableArticleFilterInput | null;
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null;
  ne?: boolean | null;
};

export type SearchableArticleSortInput = {
  field?: SearchableArticleSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableArticleSortableFields {
  id = "id",
  active = "active",
  content = "content",
  description = "description",
  image = "image",
  title = "title",
  slug = "slug",
  createdBy = "createdBy",
  hashtags = "hashtags",
  type = "type",
  order = "order",
  articleCategoryID = "articleCategoryID",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}

export type SearchableArticleConnection = {
  __typename: "SearchableArticleConnection";
  items: Array<Article | null>;
  nextToken?: string | null;
  total?: number | null;
};

export type ModelAdvertisementTypeFilterInput = {
  id?: ModelIDInput | null;
  active?: ModelBooleanInput | null;
  entity?: ModelEntityTypeInput | null;
  name?: ModelStringInput | null;
  jobDescription?: ModelStringInput | null;
  order?: ModelIntInput | null;
  approvalRequired?: ModelStringInput | null;
  components?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelAdvertisementTypeFilterInput | null> | null;
  or?: Array<ModelAdvertisementTypeFilterInput | null> | null;
  not?: ModelAdvertisementTypeFilterInput | null;
};

export type ModelAdvertisementTypeConnection = {
  __typename: "ModelAdvertisementTypeConnection";
  items: Array<AdvertisementType | null>;
  nextToken?: string | null;
};

export type ModelServiceSettingFilterInput = {
  id?: ModelIDInput | null;
  active?: ModelBooleanInput | null;
  creditsCount?: ModelIntInput | null;
  entity?: ModelEntityTypeInput | null;
  name?: ModelStringInput | null;
  public?: ModelPublicAdvertisementSettingInput | null;
  appliedTo?: ModelStringInput | null;
  serviceType?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelServiceSettingFilterInput | null> | null;
  or?: Array<ModelServiceSettingFilterInput | null> | null;
  not?: ModelServiceSettingFilterInput | null;
};

export type ModelServiceSettingConnection = {
  __typename: "ModelServiceSettingConnection";
  items: Array<ServiceSetting | null>;
  nextToken?: string | null;
};

export type ModelAdvertisementSettingFilterInput = {
  id?: ModelIDInput | null;
  active?: ModelBooleanInput | null;
  creditsCount?: ModelIntInput | null;
  description?: ModelStringInput | null;
  entity?: ModelEntityTypeInput | null;
  internalName?: ModelStringInput | null;
  name?: ModelStringInput | null;
  numberOfDays?: ModelIntInput | null;
  reactivationCreditsCount?: ModelIntInput | null;
  order?: ModelIntInput | null;
  appliedTo?: ModelStringInput | null;
  public?: ModelPublicAdvertisementSettingInput | null;
  advertisementTypeID?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelAdvertisementSettingFilterInput | null> | null;
  or?: Array<ModelAdvertisementSettingFilterInput | null> | null;
  not?: ModelAdvertisementSettingFilterInput | null;
};

export type ModelWorkerTypeFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  country?: ModelCountryInput | null;
  level?: ModelIntInput | null;
  order?: ModelIntInput | null;
  and?: Array<ModelWorkerTypeFilterInput | null> | null;
  or?: Array<ModelWorkerTypeFilterInput | null> | null;
  not?: ModelWorkerTypeFilterInput | null;
};

export type ModelWorkerTypeConnection = {
  __typename: "ModelWorkerTypeConnection";
  items: Array<WorkerType | null>;
  nextToken?: string | null;
};

export type ModelWorkerTypeEdgeFilterInput = {
  id?: ModelIDInput | null;
  parentNodeId?: ModelIDInput | null;
  childNodeId?: ModelIDInput | null;
  country?: ModelCountryInput | null;
  and?: Array<ModelWorkerTypeEdgeFilterInput | null> | null;
  or?: Array<ModelWorkerTypeEdgeFilterInput | null> | null;
  not?: ModelWorkerTypeEdgeFilterInput | null;
};

export type ModelSuitableForFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  country?: ModelCountryInput | null;
  level?: ModelIntInput | null;
  order?: ModelIntInput | null;
  and?: Array<ModelSuitableForFilterInput | null> | null;
  or?: Array<ModelSuitableForFilterInput | null> | null;
  not?: ModelSuitableForFilterInput | null;
};

export type ModelSuitableForConnection = {
  __typename: "ModelSuitableForConnection";
  items: Array<SuitableFor | null>;
  nextToken?: string | null;
};

export type ModelSuitableForEdgeFilterInput = {
  id?: ModelIDInput | null;
  parentNodeId?: ModelIDInput | null;
  childNodeId?: ModelIDInput | null;
  country?: ModelCountryInput | null;
  and?: Array<ModelSuitableForEdgeFilterInput | null> | null;
  or?: Array<ModelSuitableForEdgeFilterInput | null> | null;
  not?: ModelSuitableForEdgeFilterInput | null;
};

export type ModelEmployerFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  country?: ModelCountryInput | null;
  level?: ModelIntInput | null;
  order?: ModelIntInput | null;
  and?: Array<ModelEmployerFilterInput | null> | null;
  or?: Array<ModelEmployerFilterInput | null> | null;
  not?: ModelEmployerFilterInput | null;
};

export type ModelEmployerConnection = {
  __typename: "ModelEmployerConnection";
  items: Array<Employer | null>;
  nextToken?: string | null;
};

export type ModelEmployerEdgeFilterInput = {
  id?: ModelIDInput | null;
  parentNodeId?: ModelIDInput | null;
  childNodeId?: ModelIDInput | null;
  country?: ModelCountryInput | null;
  and?: Array<ModelEmployerEdgeFilterInput | null> | null;
  or?: Array<ModelEmployerEdgeFilterInput | null> | null;
  not?: ModelEmployerEdgeFilterInput | null;
};

export type ModelProfileFilterInput = {
  id?: ModelIDInput | null;
  credits?: ModelIntInput | null;
  sub?: ModelStringInput | null;
  email?: ModelStringInput | null;
  entity?: ModelEntityTypeInput | null;
  entityState?: ModelStringInput | null;
  profileImage?: ModelStringInput | null;
  firstDegree?: ModelStringInput | null;
  forename?: ModelStringInput | null;
  surname?: ModelStringInput | null;
  secondDegree?: ModelStringInput | null;
  aboutMe?: ModelStringInput | null;
  educations?: ModelStringInput | null;
  workExperiences?: ModelStringInput | null;
  publications?: ModelStringInput | null;
  conferences?: ModelStringInput | null;
  certificates?: ModelStringInput | null;
  languages?: ModelStringInput | null;
  otherInformation?: ModelStringInput | null;
  qualifications?: ModelStringInput | null;
  name?: ModelStringInput | null;
  crn?: ModelStringInput | null;
  vatRegNumber?: ModelStringInput | null;
  street?: ModelStringInput | null;
  buildingIdentificationNumber?: ModelStringInput | null;
  municipality?: ModelStringInput | null;
  postCode?: ModelStringInput | null;
  language?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelProfileFilterInput | null> | null;
  or?: Array<ModelProfileFilterInput | null> | null;
  not?: ModelProfileFilterInput | null;
};

export type ModelProfileConnection = {
  __typename: "ModelProfileConnection";
  items: Array<Profile | null>;
  nextToken?: string | null;
};

export type SearchableProfileFilterInput = {
  id?: SearchableIDFilterInput | null;
  credits?: SearchableIntFilterInput | null;
  sub?: SearchableStringFilterInput | null;
  email?: SearchableStringFilterInput | null;
  entityState?: SearchableStringFilterInput | null;
  profileImage?: SearchableStringFilterInput | null;
  firstDegree?: SearchableStringFilterInput | null;
  forename?: SearchableStringFilterInput | null;
  surname?: SearchableStringFilterInput | null;
  secondDegree?: SearchableStringFilterInput | null;
  aboutMe?: SearchableStringFilterInput | null;
  educations?: SearchableStringFilterInput | null;
  workExperiences?: SearchableStringFilterInput | null;
  publications?: SearchableStringFilterInput | null;
  conferences?: SearchableStringFilterInput | null;
  certificates?: SearchableStringFilterInput | null;
  languages?: SearchableStringFilterInput | null;
  otherInformation?: SearchableStringFilterInput | null;
  qualifications?: SearchableStringFilterInput | null;
  name?: SearchableStringFilterInput | null;
  crn?: SearchableStringFilterInput | null;
  vatRegNumber?: SearchableStringFilterInput | null;
  street?: SearchableStringFilterInput | null;
  buildingIdentificationNumber?: SearchableStringFilterInput | null;
  municipality?: SearchableStringFilterInput | null;
  postCode?: SearchableStringFilterInput | null;
  language?: SearchableStringFilterInput | null;
  phone?: SearchableStringFilterInput | null;
  createdAt?: SearchableStringFilterInput | null;
  updatedAt?: SearchableStringFilterInput | null;
  and?: Array<SearchableProfileFilterInput | null> | null;
  or?: Array<SearchableProfileFilterInput | null> | null;
  not?: SearchableProfileFilterInput | null;
};

export type SearchableProfileSortInput = {
  field?: SearchableProfileSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableProfileSortableFields {
  id = "id",
  credits = "credits",
  sub = "sub",
  email = "email",
  entityState = "entityState",
  profileImage = "profileImage",
  firstDegree = "firstDegree",
  forename = "forename",
  surname = "surname",
  secondDegree = "secondDegree",
  aboutMe = "aboutMe",
  educations = "educations",
  workExperiences = "workExperiences",
  publications = "publications",
  conferences = "conferences",
  certificates = "certificates",
  languages = "languages",
  otherInformation = "otherInformation",
  qualifications = "qualifications",
  name = "name",
  crn = "crn",
  vatRegNumber = "vatRegNumber",
  street = "street",
  buildingIdentificationNumber = "buildingIdentificationNumber",
  municipality = "municipality",
  postCode = "postCode",
  language = "language",
  phone = "phone",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}

export type SearchableProfileConnection = {
  __typename: "SearchableProfileConnection";
  items: Array<Profile | null>;
  nextToken?: string | null;
  total?: number | null;
};

export type ModelMessageInboxFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phoneNumber?: ModelStringInput | null;
  message?: ModelStringInput | null;
  file?: ModelStringInput | null;
  state?: ModelMessageStateInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  profileID?: ModelIDInput | null;
  advertisementID?: ModelIDInput | null;
  and?: Array<ModelMessageInboxFilterInput | null> | null;
  or?: Array<ModelMessageInboxFilterInput | null> | null;
  not?: ModelMessageInboxFilterInput | null;
};

export type SearchableMessageInboxFilterInput = {
  id?: SearchableIDFilterInput | null;
  name?: SearchableStringFilterInput | null;
  email?: SearchableStringFilterInput | null;
  phoneNumber?: SearchableStringFilterInput | null;
  message?: SearchableStringFilterInput | null;
  file?: SearchableStringFilterInput | null;
  createdAt?: SearchableStringFilterInput | null;
  updatedAt?: SearchableStringFilterInput | null;
  profileID?: SearchableIDFilterInput | null;
  advertisementID?: SearchableIDFilterInput | null;
  and?: Array<SearchableMessageInboxFilterInput | null> | null;
  or?: Array<SearchableMessageInboxFilterInput | null> | null;
  not?: SearchableMessageInboxFilterInput | null;
};

export type SearchableMessageInboxSortInput = {
  field?: SearchableMessageInboxSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableMessageInboxSortableFields {
  id = "id",
  name = "name",
  email = "email",
  phoneNumber = "phoneNumber",
  message = "message",
  file = "file",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  profileID = "profileID",
  advertisementID = "advertisementID",
}

export type SearchableMessageInboxConnection = {
  __typename: "SearchableMessageInboxConnection";
  items: Array<MessageInbox | null>;
  nextToken?: string | null;
  total?: number | null;
};

export type ModelCompanyFilterInput = {
  id?: ModelIDInput | null;
  entity?: ModelEntityTypeInput | null;
  entityState?: ModelStringInput | null;
  credits?: ModelIntInput | null;
  name?: ModelStringInput | null;
  logo?: ModelStringInput | null;
  forename?: ModelStringInput | null;
  surname?: ModelStringInput | null;
  address?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  email?: ModelStringInput | null;
  crn?: ModelStringInput | null;
  vatRegNumber?: ModelStringInput | null;
  street?: ModelStringInput | null;
  buildingIdentificationNumber?: ModelStringInput | null;
  municipality?: ModelStringInput | null;
  postCode?: ModelStringInput | null;
  language?: ModelStringInput | null;
  employerTaxonomy?: ModelStringInput | null;
  profileImage?: ModelStringInput | null;
  qualification?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelCompanyFilterInput | null> | null;
  or?: Array<ModelCompanyFilterInput | null> | null;
  not?: ModelCompanyFilterInput | null;
};

export type ModelCompanyConnection = {
  __typename: "ModelCompanyConnection";
  items: Array<Company | null>;
  nextToken?: string | null;
};

export type SearchableCompanyFilterInput = {
  id?: SearchableIDFilterInput | null;
  entityState?: SearchableStringFilterInput | null;
  credits?: SearchableIntFilterInput | null;
  name?: SearchableStringFilterInput | null;
  logo?: SearchableStringFilterInput | null;
  forename?: SearchableStringFilterInput | null;
  surname?: SearchableStringFilterInput | null;
  address?: SearchableStringFilterInput | null;
  phone?: SearchableStringFilterInput | null;
  email?: SearchableStringFilterInput | null;
  crn?: SearchableStringFilterInput | null;
  vatRegNumber?: SearchableStringFilterInput | null;
  street?: SearchableStringFilterInput | null;
  buildingIdentificationNumber?: SearchableStringFilterInput | null;
  municipality?: SearchableStringFilterInput | null;
  postCode?: SearchableStringFilterInput | null;
  language?: SearchableStringFilterInput | null;
  employerTaxonomy?: SearchableStringFilterInput | null;
  profileImage?: SearchableStringFilterInput | null;
  qualification?: SearchableStringFilterInput | null;
  createdAt?: SearchableStringFilterInput | null;
  updatedAt?: SearchableStringFilterInput | null;
  and?: Array<SearchableCompanyFilterInput | null> | null;
  or?: Array<SearchableCompanyFilterInput | null> | null;
  not?: SearchableCompanyFilterInput | null;
};

export type SearchableCompanySortInput = {
  field?: SearchableCompanySortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableCompanySortableFields {
  id = "id",
  entityState = "entityState",
  credits = "credits",
  name = "name",
  logo = "logo",
  forename = "forename",
  surname = "surname",
  address = "address",
  phone = "phone",
  email = "email",
  crn = "crn",
  vatRegNumber = "vatRegNumber",
  street = "street",
  buildingIdentificationNumber = "buildingIdentificationNumber",
  municipality = "municipality",
  postCode = "postCode",
  language = "language",
  employerTaxonomy = "employerTaxonomy",
  profileImage = "profileImage",
  qualification = "qualification",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}

export type SearchableCompanyConnection = {
  __typename: "SearchableCompanyConnection";
  items: Array<Company | null>;
  nextToken?: string | null;
  total?: number | null;
};

export type ModelCompanyMessageInboxFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  state?: ModelMessageStateInput | null;
  phoneNumber?: ModelStringInput | null;
  message?: ModelStringInput | null;
  file?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  companyID?: ModelIDInput | null;
  advertisementID?: ModelIDInput | null;
  and?: Array<ModelCompanyMessageInboxFilterInput | null> | null;
  or?: Array<ModelCompanyMessageInboxFilterInput | null> | null;
  not?: ModelCompanyMessageInboxFilterInput | null;
};

export type SearchableCompanyMessageInboxFilterInput = {
  id?: SearchableIDFilterInput | null;
  name?: SearchableStringFilterInput | null;
  email?: SearchableStringFilterInput | null;
  phoneNumber?: SearchableStringFilterInput | null;
  message?: SearchableStringFilterInput | null;
  file?: SearchableStringFilterInput | null;
  createdAt?: SearchableStringFilterInput | null;
  updatedAt?: SearchableStringFilterInput | null;
  companyID?: SearchableIDFilterInput | null;
  advertisementID?: SearchableIDFilterInput | null;
  and?: Array<SearchableCompanyMessageInboxFilterInput | null> | null;
  or?: Array<SearchableCompanyMessageInboxFilterInput | null> | null;
  not?: SearchableCompanyMessageInboxFilterInput | null;
};

export type SearchableCompanyMessageInboxSortInput = {
  field?: SearchableCompanyMessageInboxSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableCompanyMessageInboxSortableFields {
  id = "id",
  name = "name",
  email = "email",
  phoneNumber = "phoneNumber",
  message = "message",
  file = "file",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  companyID = "companyID",
  advertisementID = "advertisementID",
}

export type SearchableCompanyMessageInboxConnection = {
  __typename: "SearchableCompanyMessageInboxConnection";
  items: Array<CompanyMessageInbox | null>;
  nextToken?: string | null;
  total?: number | null;
};

export type ModelCompanyUserFilterInput = {
  id?: ModelIDInput | null;
  entity?: ModelEntityTypeInput | null;
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  role?: ModelCompanyUserRoleInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  companyID?: ModelIDInput | null;
  and?: Array<ModelCompanyUserFilterInput | null> | null;
  or?: Array<ModelCompanyUserFilterInput | null> | null;
  not?: ModelCompanyUserFilterInput | null;
};

export type SearchableCompanyUserFilterInput = {
  id?: SearchableIDFilterInput | null;
  name?: SearchableStringFilterInput | null;
  email?: SearchableStringFilterInput | null;
  createdAt?: SearchableStringFilterInput | null;
  updatedAt?: SearchableStringFilterInput | null;
  companyID?: SearchableIDFilterInput | null;
  and?: Array<SearchableCompanyUserFilterInput | null> | null;
  or?: Array<SearchableCompanyUserFilterInput | null> | null;
  not?: SearchableCompanyUserFilterInput | null;
};

export type SearchableCompanyUserSortInput = {
  field?: SearchableCompanyUserSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableCompanyUserSortableFields {
  id = "id",
  name = "name",
  email = "email",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  companyID = "companyID",
}

export type SearchableCompanyUserConnection = {
  __typename: "SearchableCompanyUserConnection";
  items: Array<CompanyUser | null>;
  nextToken?: string | null;
  total?: number | null;
};

export type ModelAdvertisementFilterInput = {
  id?: ModelIDInput | null;
  friendlyId?: ModelIntInput | null;
  entity?: ModelEntityTypeInput | null;
  active?: ModelActiveInput | null;
  state?: ModelStringInput | null;
  stateDescription?: ModelStringInput | null;
  paymentState?: ModelStringInput | null;
  jobPosition?: ModelStringInput | null;
  jobShortDescription?: ModelStringInput | null;
  jobDescription?: ModelStringInput | null;
  place?: ModelStringInput | null;
  salaryFrom?: ModelIntInput | null;
  salaryTo?: ModelIntInput | null;
  salarySpecifiedInText?: ModelBooleanInput | null;
  reactivationCount?: ModelIntInput | null;
  currentReactivationCount?: ModelIntInput | null;
  automaticReactivation?: ModelBooleanInput | null;
  leadingPosition?: ModelBooleanInput | null;
  residentialPlace?: ModelBooleanInput | null;
  typeOfCooperation?: ModelStringInput | null;
  jobType?: ModelStringInput | null;
  financialConditions?: ModelStringInput | null;
  typeOfInternship?: ModelStringInput | null;
  contactFirstDegree?: ModelStringInput | null;
  contactForename?: ModelStringInput | null;
  contactSurname?: ModelStringInput | null;
  contactSecondDegree?: ModelStringInput | null;
  contactEmail?: ModelStringInput | null;
  contactPhoneNumber?: ModelStringInput | null;
  hideContactInformation?: ModelBooleanInput | null;
  workType?: ModelStringInput | null;
  suitableFor?: ModelStringInput | null;
  workTypeStringified?: ModelStringInput | null;
  suitableForStringified?: ModelStringInput | null;
  employmentTypesStringified?: ModelStringInput | null;
  employmentTypes?: ModelStringInput | null;
  paymentType?: ModelPaymentTypeInput | null;
  advertisementSettingID?: ModelIDInput | null;
  advertisementTypeID?: ModelIDInput | null;
  companyID?: ModelIDInput | null;
  profileID?: ModelIDInput | null;
  expirationDate?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelAdvertisementFilterInput | null> | null;
  or?: Array<ModelAdvertisementFilterInput | null> | null;
  not?: ModelAdvertisementFilterInput | null;
};

export type ModelAdvertisementConnection = {
  __typename: "ModelAdvertisementConnection";
  items: Array<Advertisement | null>;
  nextToken?: string | null;
};

export type SearchableAdvertisementFilterInput = {
  id?: SearchableIDFilterInput | null;
  friendlyId?: SearchableIntFilterInput | null;
  state?: SearchableStringFilterInput | null;
  stateDescription?: SearchableStringFilterInput | null;
  paymentState?: SearchableStringFilterInput | null;
  jobPosition?: SearchableStringFilterInput | null;
  jobShortDescription?: SearchableStringFilterInput | null;
  jobDescription?: SearchableStringFilterInput | null;
  place?: SearchableStringFilterInput | null;
  salaryFrom?: SearchableIntFilterInput | null;
  salaryTo?: SearchableIntFilterInput | null;
  salarySpecifiedInText?: SearchableBooleanFilterInput | null;
  reactivationCount?: SearchableIntFilterInput | null;
  currentReactivationCount?: SearchableIntFilterInput | null;
  automaticReactivation?: SearchableBooleanFilterInput | null;
  leadingPosition?: SearchableBooleanFilterInput | null;
  residentialPlace?: SearchableBooleanFilterInput | null;
  typeOfCooperation?: SearchableStringFilterInput | null;
  jobType?: SearchableStringFilterInput | null;
  financialConditions?: SearchableStringFilterInput | null;
  typeOfInternship?: SearchableStringFilterInput | null;
  contactFirstDegree?: SearchableStringFilterInput | null;
  contactForename?: SearchableStringFilterInput | null;
  contactSurname?: SearchableStringFilterInput | null;
  contactSecondDegree?: SearchableStringFilterInput | null;
  contactEmail?: SearchableStringFilterInput | null;
  contactPhoneNumber?: SearchableStringFilterInput | null;
  hideContactInformation?: SearchableBooleanFilterInput | null;
  workType?: SearchableStringFilterInput | null;
  suitableFor?: SearchableStringFilterInput | null;
  workTypeStringified?: SearchableStringFilterInput | null;
  suitableForStringified?: SearchableStringFilterInput | null;
  employmentTypesStringified?: SearchableStringFilterInput | null;
  employmentTypes?: SearchableStringFilterInput | null;
  advertisementSettingID?: SearchableIDFilterInput | null;
  advertisementTypeID?: SearchableIDFilterInput | null;
  companyID?: SearchableIDFilterInput | null;
  profileID?: SearchableIDFilterInput | null;
  expirationDate?: SearchableStringFilterInput | null;
  createdAt?: SearchableStringFilterInput | null;
  updatedAt?: SearchableStringFilterInput | null;
  and?: Array<SearchableAdvertisementFilterInput | null> | null;
  or?: Array<SearchableAdvertisementFilterInput | null> | null;
  not?: SearchableAdvertisementFilterInput | null;
};

export type SearchableAdvertisementSortInput = {
  field?: SearchableAdvertisementSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableAdvertisementSortableFields {
  id = "id",
  friendlyId = "friendlyId",
  state = "state",
  stateDescription = "stateDescription",
  paymentState = "paymentState",
  jobPosition = "jobPosition",
  jobShortDescription = "jobShortDescription",
  jobDescription = "jobDescription",
  place = "place",
  salaryFrom = "salaryFrom",
  salaryTo = "salaryTo",
  salarySpecifiedInText = "salarySpecifiedInText",
  reactivationCount = "reactivationCount",
  currentReactivationCount = "currentReactivationCount",
  automaticReactivation = "automaticReactivation",
  leadingPosition = "leadingPosition",
  residentialPlace = "residentialPlace",
  typeOfCooperation = "typeOfCooperation",
  jobType = "jobType",
  financialConditions = "financialConditions",
  typeOfInternship = "typeOfInternship",
  contactFirstDegree = "contactFirstDegree",
  contactForename = "contactForename",
  contactSurname = "contactSurname",
  contactSecondDegree = "contactSecondDegree",
  contactEmail = "contactEmail",
  contactPhoneNumber = "contactPhoneNumber",
  hideContactInformation = "hideContactInformation",
  workType = "workType",
  suitableFor = "suitableFor",
  workTypeStringified = "workTypeStringified",
  suitableForStringified = "suitableForStringified",
  employmentTypesStringified = "employmentTypesStringified",
  employmentTypes = "employmentTypes",
  advertisementSettingID = "advertisementSettingID",
  advertisementTypeID = "advertisementTypeID",
  companyID = "companyID",
  profileID = "profileID",
  expirationDate = "expirationDate",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}

export type SearchableAdvertisementConnection = {
  __typename: "SearchableAdvertisementConnection";
  items: Array<Advertisement | null>;
  nextToken?: string | null;
  total?: number | null;
};

export type ModelAdvertisementAnalyticFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  info?: ModelStringInput | null;
  advertisementID?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelAdvertisementAnalyticFilterInput | null> | null;
  or?: Array<ModelAdvertisementAnalyticFilterInput | null> | null;
  not?: ModelAdvertisementAnalyticFilterInput | null;
};

export type ModelShowCaseFilterInput = {
  id?: ModelIDInput | null;
  title?: ModelStringInput | null;
  text?: ModelStringInput | null;
  file?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  companyID?: ModelIDInput | null;
  and?: Array<ModelShowCaseFilterInput | null> | null;
  or?: Array<ModelShowCaseFilterInput | null> | null;
  not?: ModelShowCaseFilterInput | null;
};

export type ModelShowCaseConnection = {
  __typename: "ModelShowCaseConnection";
  items: Array<ShowCase | null>;
  nextToken?: string | null;
};

export type ModelTransactionFilterInput = {
  id?: ModelIDInput | null;
  amount?: ModelIntInput | null;
  currency?: ModelStringInput | null;
  description?: ModelStringInput | null;
  paymentIntent?: ModelStringInput | null;
  companyID?: ModelIDInput | null;
  profileID?: ModelIDInput | null;
  initiatedBy?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelTransactionFilterInput | null> | null;
  or?: Array<ModelTransactionFilterInput | null> | null;
  not?: ModelTransactionFilterInput | null;
};

export type ModelTransactionConnection = {
  __typename: "ModelTransactionConnection";
  items: Array<Transaction | null>;
  nextToken?: string | null;
};

export type ModelCarouselFilterInput = {
  id?: ModelIDInput | null;
  active?: ModelBooleanInput | null;
  entity?: ModelEntityTypeInput | null;
  image?: ModelStringInput | null;
  title?: ModelStringInput | null;
  type?: ModelCarouselTypeInput | null;
  companyID?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelCarouselFilterInput | null> | null;
  or?: Array<ModelCarouselFilterInput | null> | null;
  not?: ModelCarouselFilterInput | null;
};

export type ModelCarouselConnection = {
  __typename: "ModelCarouselConnection";
  items: Array<Carousel | null>;
  nextToken?: string | null;
};

export type RemoveAllUserDataMutationVariables = {
  profileID: string;
  email: string;
  owner: string;
};

export type RemoveAllUserDataMutation = {
  removeAllUserData?: string | null;
};

export type RemoveAllCompanyDataMutationVariables = {
  companyID: string;
};

export type RemoveAllCompanyDataMutation = {
  removeAllCompanyData?: string | null;
};

export type CreateEmailNewsletterMutationVariables = {
  input: CreateEmailNewsletterInput;
  condition?: ModelEmailNewsletterConditionInput | null;
};

export type CreateEmailNewsletterMutation = {
  createEmailNewsletter?: {
    __typename: "EmailNewsletter";
    id: string;
    email?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateEmailNewsletterMutationVariables = {
  input: UpdateEmailNewsletterInput;
  condition?: ModelEmailNewsletterConditionInput | null;
};

export type UpdateEmailNewsletterMutation = {
  updateEmailNewsletter?: {
    __typename: "EmailNewsletter";
    id: string;
    email?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteEmailNewsletterMutationVariables = {
  input: DeleteEmailNewsletterInput;
  condition?: ModelEmailNewsletterConditionInput | null;
};

export type DeleteEmailNewsletterMutation = {
  deleteEmailNewsletter?: {
    __typename: "EmailNewsletter";
    id: string;
    email?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateArticleCategoryMutationVariables = {
  input: CreateArticleCategoryInput;
  condition?: ModelArticleCategoryConditionInput | null;
};

export type CreateArticleCategoryMutation = {
  createArticleCategory?: {
    __typename: "ArticleCategory";
    id: string;
    entity: EntityType;
    name: string;
    slug: string;
    order?: number | null;
    createdAt: string;
    updatedAt: string;
    articles?: {
      __typename: "ModelArticleConnection";
      items: Array<{
        __typename: "Article";
        id: string;
        active?: boolean | null;
        content?: string | null;
        description?: string | null;
        entity: EntityType;
        image?: string | null;
        title: string;
        slug: string;
        createdBy?: string | null;
        hashtags?: string | null;
        type?: string | null;
        order?: number | null;
        articleCategoryID: string;
        createdAt: string;
        updatedAt: string;
        category?: {
          __typename: "ArticleCategory";
          id: string;
          entity: EntityType;
          name: string;
          slug: string;
          order?: number | null;
          createdAt: string;
          updatedAt: string;
          articles?: {
            __typename: "ModelArticleConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type UpdateArticleCategoryMutationVariables = {
  input: UpdateArticleCategoryInput;
  condition?: ModelArticleCategoryConditionInput | null;
};

export type UpdateArticleCategoryMutation = {
  updateArticleCategory?: {
    __typename: "ArticleCategory";
    id: string;
    entity: EntityType;
    name: string;
    slug: string;
    order?: number | null;
    createdAt: string;
    updatedAt: string;
    articles?: {
      __typename: "ModelArticleConnection";
      items: Array<{
        __typename: "Article";
        id: string;
        active?: boolean | null;
        content?: string | null;
        description?: string | null;
        entity: EntityType;
        image?: string | null;
        title: string;
        slug: string;
        createdBy?: string | null;
        hashtags?: string | null;
        type?: string | null;
        order?: number | null;
        articleCategoryID: string;
        createdAt: string;
        updatedAt: string;
        category?: {
          __typename: "ArticleCategory";
          id: string;
          entity: EntityType;
          name: string;
          slug: string;
          order?: number | null;
          createdAt: string;
          updatedAt: string;
          articles?: {
            __typename: "ModelArticleConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type DeleteArticleCategoryMutationVariables = {
  input: DeleteArticleCategoryInput;
  condition?: ModelArticleCategoryConditionInput | null;
};

export type DeleteArticleCategoryMutation = {
  deleteArticleCategory?: {
    __typename: "ArticleCategory";
    id: string;
    entity: EntityType;
    name: string;
    slug: string;
    order?: number | null;
    createdAt: string;
    updatedAt: string;
    articles?: {
      __typename: "ModelArticleConnection";
      items: Array<{
        __typename: "Article";
        id: string;
        active?: boolean | null;
        content?: string | null;
        description?: string | null;
        entity: EntityType;
        image?: string | null;
        title: string;
        slug: string;
        createdBy?: string | null;
        hashtags?: string | null;
        type?: string | null;
        order?: number | null;
        articleCategoryID: string;
        createdAt: string;
        updatedAt: string;
        category?: {
          __typename: "ArticleCategory";
          id: string;
          entity: EntityType;
          name: string;
          slug: string;
          order?: number | null;
          createdAt: string;
          updatedAt: string;
          articles?: {
            __typename: "ModelArticleConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateArticleMutationVariables = {
  input: CreateArticleInput;
  condition?: ModelArticleConditionInput | null;
};

export type CreateArticleMutation = {
  createArticle?: {
    __typename: "Article";
    id: string;
    active?: boolean | null;
    content?: string | null;
    description?: string | null;
    entity: EntityType;
    image?: string | null;
    title: string;
    slug: string;
    createdBy?: string | null;
    hashtags?: string | null;
    type?: string | null;
    order?: number | null;
    articleCategoryID: string;
    createdAt: string;
    updatedAt: string;
    category?: {
      __typename: "ArticleCategory";
      id: string;
      entity: EntityType;
      name: string;
      slug: string;
      order?: number | null;
      createdAt: string;
      updatedAt: string;
      articles?: {
        __typename: "ModelArticleConnection";
        items: Array<{
          __typename: "Article";
          id: string;
          active?: boolean | null;
          content?: string | null;
          description?: string | null;
          entity: EntityType;
          image?: string | null;
          title: string;
          slug: string;
          createdBy?: string | null;
          hashtags?: string | null;
          type?: string | null;
          order?: number | null;
          articleCategoryID: string;
          createdAt: string;
          updatedAt: string;
          category?: {
            __typename: "ArticleCategory";
            id: string;
            entity: EntityType;
            name: string;
            slug: string;
            order?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateArticleMutationVariables = {
  input: UpdateArticleInput;
  condition?: ModelArticleConditionInput | null;
};

export type UpdateArticleMutation = {
  updateArticle?: {
    __typename: "Article";
    id: string;
    active?: boolean | null;
    content?: string | null;
    description?: string | null;
    entity: EntityType;
    image?: string | null;
    title: string;
    slug: string;
    createdBy?: string | null;
    hashtags?: string | null;
    type?: string | null;
    order?: number | null;
    articleCategoryID: string;
    createdAt: string;
    updatedAt: string;
    category?: {
      __typename: "ArticleCategory";
      id: string;
      entity: EntityType;
      name: string;
      slug: string;
      order?: number | null;
      createdAt: string;
      updatedAt: string;
      articles?: {
        __typename: "ModelArticleConnection";
        items: Array<{
          __typename: "Article";
          id: string;
          active?: boolean | null;
          content?: string | null;
          description?: string | null;
          entity: EntityType;
          image?: string | null;
          title: string;
          slug: string;
          createdBy?: string | null;
          hashtags?: string | null;
          type?: string | null;
          order?: number | null;
          articleCategoryID: string;
          createdAt: string;
          updatedAt: string;
          category?: {
            __typename: "ArticleCategory";
            id: string;
            entity: EntityType;
            name: string;
            slug: string;
            order?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteArticleMutationVariables = {
  input: DeleteArticleInput;
  condition?: ModelArticleConditionInput | null;
};

export type DeleteArticleMutation = {
  deleteArticle?: {
    __typename: "Article";
    id: string;
    active?: boolean | null;
    content?: string | null;
    description?: string | null;
    entity: EntityType;
    image?: string | null;
    title: string;
    slug: string;
    createdBy?: string | null;
    hashtags?: string | null;
    type?: string | null;
    order?: number | null;
    articleCategoryID: string;
    createdAt: string;
    updatedAt: string;
    category?: {
      __typename: "ArticleCategory";
      id: string;
      entity: EntityType;
      name: string;
      slug: string;
      order?: number | null;
      createdAt: string;
      updatedAt: string;
      articles?: {
        __typename: "ModelArticleConnection";
        items: Array<{
          __typename: "Article";
          id: string;
          active?: boolean | null;
          content?: string | null;
          description?: string | null;
          entity: EntityType;
          image?: string | null;
          title: string;
          slug: string;
          createdBy?: string | null;
          hashtags?: string | null;
          type?: string | null;
          order?: number | null;
          articleCategoryID: string;
          createdAt: string;
          updatedAt: string;
          category?: {
            __typename: "ArticleCategory";
            id: string;
            entity: EntityType;
            name: string;
            slug: string;
            order?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateAdvertisementTypeMutationVariables = {
  input: CreateAdvertisementTypeInput;
  condition?: ModelAdvertisementTypeConditionInput | null;
};

export type CreateAdvertisementTypeMutation = {
  createAdvertisementType?: {
    __typename: "AdvertisementType";
    id: string;
    active: boolean;
    entity: EntityType;
    name: string;
    jobDescription?: string | null;
    order: number;
    approvalRequired: string;
    components?: string | null;
    createdAt: string;
    updatedAt: string;
    advertisements?: {
      __typename: "ModelAdvertisementSettingConnection";
      items: Array<{
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type UpdateAdvertisementTypeMutationVariables = {
  input: UpdateAdvertisementTypeInput;
  condition?: ModelAdvertisementTypeConditionInput | null;
};

export type UpdateAdvertisementTypeMutation = {
  updateAdvertisementType?: {
    __typename: "AdvertisementType";
    id: string;
    active: boolean;
    entity: EntityType;
    name: string;
    jobDescription?: string | null;
    order: number;
    approvalRequired: string;
    components?: string | null;
    createdAt: string;
    updatedAt: string;
    advertisements?: {
      __typename: "ModelAdvertisementSettingConnection";
      items: Array<{
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type DeleteAdvertisementTypeMutationVariables = {
  input: DeleteAdvertisementTypeInput;
  condition?: ModelAdvertisementTypeConditionInput | null;
};

export type DeleteAdvertisementTypeMutation = {
  deleteAdvertisementType?: {
    __typename: "AdvertisementType";
    id: string;
    active: boolean;
    entity: EntityType;
    name: string;
    jobDescription?: string | null;
    order: number;
    approvalRequired: string;
    components?: string | null;
    createdAt: string;
    updatedAt: string;
    advertisements?: {
      __typename: "ModelAdvertisementSettingConnection";
      items: Array<{
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateServiceSettingMutationVariables = {
  input: CreateServiceSettingInput;
  condition?: ModelServiceSettingConditionInput | null;
};

export type CreateServiceSettingMutation = {
  createServiceSetting?: {
    __typename: "ServiceSetting";
    id: string;
    active: boolean;
    creditsCount: number;
    entity: EntityType;
    name: string;
    public?: PublicAdvertisementSetting | null;
    appliedTo: string;
    serviceType: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateServiceSettingMutationVariables = {
  input: UpdateServiceSettingInput;
  condition?: ModelServiceSettingConditionInput | null;
};

export type UpdateServiceSettingMutation = {
  updateServiceSetting?: {
    __typename: "ServiceSetting";
    id: string;
    active: boolean;
    creditsCount: number;
    entity: EntityType;
    name: string;
    public?: PublicAdvertisementSetting | null;
    appliedTo: string;
    serviceType: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteServiceSettingMutationVariables = {
  input: DeleteServiceSettingInput;
  condition?: ModelServiceSettingConditionInput | null;
};

export type DeleteServiceSettingMutation = {
  deleteServiceSetting?: {
    __typename: "ServiceSetting";
    id: string;
    active: boolean;
    creditsCount: number;
    entity: EntityType;
    name: string;
    public?: PublicAdvertisementSetting | null;
    appliedTo: string;
    serviceType: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateAdvertisementSettingMutationVariables = {
  input: CreateAdvertisementSettingInput;
  condition?: ModelAdvertisementSettingConditionInput | null;
};

export type CreateAdvertisementSettingMutation = {
  createAdvertisementSetting?: {
    __typename: "AdvertisementSetting";
    id: string;
    active: boolean;
    creditsCount: number;
    description?: string | null;
    entity: EntityType;
    internalName: string;
    name: string;
    numberOfDays: number;
    reactivationCreditsCount: number;
    order: number;
    appliedTo: string;
    public?: PublicAdvertisementSetting | null;
    advertisementTypeID: string;
    createdAt: string;
    updatedAt: string;
    advertisementType?: {
      __typename: "AdvertisementType";
      id: string;
      active: boolean;
      entity: EntityType;
      name: string;
      jobDescription?: string | null;
      order: number;
      approvalRequired: string;
      components?: string | null;
      createdAt: string;
      updatedAt: string;
      advertisements?: {
        __typename: "ModelAdvertisementSettingConnection";
        items: Array<{
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateAdvertisementSettingMutationVariables = {
  input: UpdateAdvertisementSettingInput;
  condition?: ModelAdvertisementSettingConditionInput | null;
};

export type UpdateAdvertisementSettingMutation = {
  updateAdvertisementSetting?: {
    __typename: "AdvertisementSetting";
    id: string;
    active: boolean;
    creditsCount: number;
    description?: string | null;
    entity: EntityType;
    internalName: string;
    name: string;
    numberOfDays: number;
    reactivationCreditsCount: number;
    order: number;
    appliedTo: string;
    public?: PublicAdvertisementSetting | null;
    advertisementTypeID: string;
    createdAt: string;
    updatedAt: string;
    advertisementType?: {
      __typename: "AdvertisementType";
      id: string;
      active: boolean;
      entity: EntityType;
      name: string;
      jobDescription?: string | null;
      order: number;
      approvalRequired: string;
      components?: string | null;
      createdAt: string;
      updatedAt: string;
      advertisements?: {
        __typename: "ModelAdvertisementSettingConnection";
        items: Array<{
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteAdvertisementSettingMutationVariables = {
  input: DeleteAdvertisementSettingInput;
  condition?: ModelAdvertisementSettingConditionInput | null;
};

export type DeleteAdvertisementSettingMutation = {
  deleteAdvertisementSetting?: {
    __typename: "AdvertisementSetting";
    id: string;
    active: boolean;
    creditsCount: number;
    description?: string | null;
    entity: EntityType;
    internalName: string;
    name: string;
    numberOfDays: number;
    reactivationCreditsCount: number;
    order: number;
    appliedTo: string;
    public?: PublicAdvertisementSetting | null;
    advertisementTypeID: string;
    createdAt: string;
    updatedAt: string;
    advertisementType?: {
      __typename: "AdvertisementType";
      id: string;
      active: boolean;
      entity: EntityType;
      name: string;
      jobDescription?: string | null;
      order: number;
      approvalRequired: string;
      components?: string | null;
      createdAt: string;
      updatedAt: string;
      advertisements?: {
        __typename: "ModelAdvertisementSettingConnection";
        items: Array<{
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateWorkerTypeMutationVariables = {
  input: CreateWorkerTypeInput;
  condition?: ModelWorkerTypeConditionInput | null;
};

export type CreateWorkerTypeMutation = {
  createWorkerType?: {
    __typename: "WorkerType";
    id: string;
    name: string;
    country: Country;
    level: number;
    order: number;
    createdAt: string;
    updatedAt: string;
    childEdges?: {
      __typename: "ModelWorkerTypeEdgeConnection";
      items: Array<{
        __typename: "WorkerTypeEdge";
        id: string;
        parentNodeId: string;
        childNodeId: string;
        country: Country;
        createdAt: string;
        updatedAt: string;
        parentNode?: {
          __typename: "WorkerType";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelWorkerTypeEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        childNode?: {
          __typename: "WorkerType";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelWorkerTypeEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type UpdateWorkerTypeMutationVariables = {
  input: UpdateWorkerTypeInput;
  condition?: ModelWorkerTypeConditionInput | null;
};

export type UpdateWorkerTypeMutation = {
  updateWorkerType?: {
    __typename: "WorkerType";
    id: string;
    name: string;
    country: Country;
    level: number;
    order: number;
    createdAt: string;
    updatedAt: string;
    childEdges?: {
      __typename: "ModelWorkerTypeEdgeConnection";
      items: Array<{
        __typename: "WorkerTypeEdge";
        id: string;
        parentNodeId: string;
        childNodeId: string;
        country: Country;
        createdAt: string;
        updatedAt: string;
        parentNode?: {
          __typename: "WorkerType";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelWorkerTypeEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        childNode?: {
          __typename: "WorkerType";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelWorkerTypeEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type DeleteWorkerTypeMutationVariables = {
  input: DeleteWorkerTypeInput;
  condition?: ModelWorkerTypeConditionInput | null;
};

export type DeleteWorkerTypeMutation = {
  deleteWorkerType?: {
    __typename: "WorkerType";
    id: string;
    name: string;
    country: Country;
    level: number;
    order: number;
    createdAt: string;
    updatedAt: string;
    childEdges?: {
      __typename: "ModelWorkerTypeEdgeConnection";
      items: Array<{
        __typename: "WorkerTypeEdge";
        id: string;
        parentNodeId: string;
        childNodeId: string;
        country: Country;
        createdAt: string;
        updatedAt: string;
        parentNode?: {
          __typename: "WorkerType";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelWorkerTypeEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        childNode?: {
          __typename: "WorkerType";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelWorkerTypeEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateWorkerTypeEdgeMutationVariables = {
  input: CreateWorkerTypeEdgeInput;
  condition?: ModelWorkerTypeEdgeConditionInput | null;
};

export type CreateWorkerTypeEdgeMutation = {
  createWorkerTypeEdge?: {
    __typename: "WorkerTypeEdge";
    id: string;
    parentNodeId: string;
    childNodeId: string;
    country: Country;
    createdAt: string;
    updatedAt: string;
    parentNode?: {
      __typename: "WorkerType";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelWorkerTypeEdgeConnection";
        items: Array<{
          __typename: "WorkerTypeEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    childNode?: {
      __typename: "WorkerType";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelWorkerTypeEdgeConnection";
        items: Array<{
          __typename: "WorkerTypeEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateWorkerTypeEdgeMutationVariables = {
  input: UpdateWorkerTypeEdgeInput;
  condition?: ModelWorkerTypeEdgeConditionInput | null;
};

export type UpdateWorkerTypeEdgeMutation = {
  updateWorkerTypeEdge?: {
    __typename: "WorkerTypeEdge";
    id: string;
    parentNodeId: string;
    childNodeId: string;
    country: Country;
    createdAt: string;
    updatedAt: string;
    parentNode?: {
      __typename: "WorkerType";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelWorkerTypeEdgeConnection";
        items: Array<{
          __typename: "WorkerTypeEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    childNode?: {
      __typename: "WorkerType";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelWorkerTypeEdgeConnection";
        items: Array<{
          __typename: "WorkerTypeEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteWorkerTypeEdgeMutationVariables = {
  input: DeleteWorkerTypeEdgeInput;
  condition?: ModelWorkerTypeEdgeConditionInput | null;
};

export type DeleteWorkerTypeEdgeMutation = {
  deleteWorkerTypeEdge?: {
    __typename: "WorkerTypeEdge";
    id: string;
    parentNodeId: string;
    childNodeId: string;
    country: Country;
    createdAt: string;
    updatedAt: string;
    parentNode?: {
      __typename: "WorkerType";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelWorkerTypeEdgeConnection";
        items: Array<{
          __typename: "WorkerTypeEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    childNode?: {
      __typename: "WorkerType";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelWorkerTypeEdgeConnection";
        items: Array<{
          __typename: "WorkerTypeEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateSuitableForMutationVariables = {
  input: CreateSuitableForInput;
  condition?: ModelSuitableForConditionInput | null;
};

export type CreateSuitableForMutation = {
  createSuitableFor?: {
    __typename: "SuitableFor";
    id: string;
    name: string;
    country: Country;
    level: number;
    order: number;
    createdAt: string;
    updatedAt: string;
    childEdges?: {
      __typename: "ModelSuitableForEdgeConnection";
      items: Array<{
        __typename: "SuitableForEdge";
        id: string;
        parentNodeId: string;
        childNodeId: string;
        country: Country;
        createdAt: string;
        updatedAt: string;
        parentNode?: {
          __typename: "SuitableFor";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelSuitableForEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        childNode?: {
          __typename: "SuitableFor";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelSuitableForEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type UpdateSuitableForMutationVariables = {
  input: UpdateSuitableForInput;
  condition?: ModelSuitableForConditionInput | null;
};

export type UpdateSuitableForMutation = {
  updateSuitableFor?: {
    __typename: "SuitableFor";
    id: string;
    name: string;
    country: Country;
    level: number;
    order: number;
    createdAt: string;
    updatedAt: string;
    childEdges?: {
      __typename: "ModelSuitableForEdgeConnection";
      items: Array<{
        __typename: "SuitableForEdge";
        id: string;
        parentNodeId: string;
        childNodeId: string;
        country: Country;
        createdAt: string;
        updatedAt: string;
        parentNode?: {
          __typename: "SuitableFor";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelSuitableForEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        childNode?: {
          __typename: "SuitableFor";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelSuitableForEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type DeleteSuitableForMutationVariables = {
  input: DeleteSuitableForInput;
  condition?: ModelSuitableForConditionInput | null;
};

export type DeleteSuitableForMutation = {
  deleteSuitableFor?: {
    __typename: "SuitableFor";
    id: string;
    name: string;
    country: Country;
    level: number;
    order: number;
    createdAt: string;
    updatedAt: string;
    childEdges?: {
      __typename: "ModelSuitableForEdgeConnection";
      items: Array<{
        __typename: "SuitableForEdge";
        id: string;
        parentNodeId: string;
        childNodeId: string;
        country: Country;
        createdAt: string;
        updatedAt: string;
        parentNode?: {
          __typename: "SuitableFor";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelSuitableForEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        childNode?: {
          __typename: "SuitableFor";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelSuitableForEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateSuitableForEdgeMutationVariables = {
  input: CreateSuitableForEdgeInput;
  condition?: ModelSuitableForEdgeConditionInput | null;
};

export type CreateSuitableForEdgeMutation = {
  createSuitableForEdge?: {
    __typename: "SuitableForEdge";
    id: string;
    parentNodeId: string;
    childNodeId: string;
    country: Country;
    createdAt: string;
    updatedAt: string;
    parentNode?: {
      __typename: "SuitableFor";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelSuitableForEdgeConnection";
        items: Array<{
          __typename: "SuitableForEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    childNode?: {
      __typename: "SuitableFor";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelSuitableForEdgeConnection";
        items: Array<{
          __typename: "SuitableForEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateSuitableForEdgeMutationVariables = {
  input: UpdateSuitableForEdgeInput;
  condition?: ModelSuitableForEdgeConditionInput | null;
};

export type UpdateSuitableForEdgeMutation = {
  updateSuitableForEdge?: {
    __typename: "SuitableForEdge";
    id: string;
    parentNodeId: string;
    childNodeId: string;
    country: Country;
    createdAt: string;
    updatedAt: string;
    parentNode?: {
      __typename: "SuitableFor";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelSuitableForEdgeConnection";
        items: Array<{
          __typename: "SuitableForEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    childNode?: {
      __typename: "SuitableFor";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelSuitableForEdgeConnection";
        items: Array<{
          __typename: "SuitableForEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteSuitableForEdgeMutationVariables = {
  input: DeleteSuitableForEdgeInput;
  condition?: ModelSuitableForEdgeConditionInput | null;
};

export type DeleteSuitableForEdgeMutation = {
  deleteSuitableForEdge?: {
    __typename: "SuitableForEdge";
    id: string;
    parentNodeId: string;
    childNodeId: string;
    country: Country;
    createdAt: string;
    updatedAt: string;
    parentNode?: {
      __typename: "SuitableFor";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelSuitableForEdgeConnection";
        items: Array<{
          __typename: "SuitableForEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    childNode?: {
      __typename: "SuitableFor";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelSuitableForEdgeConnection";
        items: Array<{
          __typename: "SuitableForEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateEmployerMutationVariables = {
  input: CreateEmployerInput;
  condition?: ModelEmployerConditionInput | null;
};

export type CreateEmployerMutation = {
  createEmployer?: {
    __typename: "Employer";
    id: string;
    name: string;
    country: Country;
    level: number;
    order: number;
    createdAt: string;
    updatedAt: string;
    childEdges?: {
      __typename: "ModelEmployerEdgeConnection";
      items: Array<{
        __typename: "EmployerEdge";
        id: string;
        parentNodeId: string;
        childNodeId: string;
        country: Country;
        createdAt: string;
        updatedAt: string;
        parentNode?: {
          __typename: "Employer";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelEmployerEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        childNode?: {
          __typename: "Employer";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelEmployerEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type UpdateEmployerMutationVariables = {
  input: UpdateEmployerInput;
  condition?: ModelEmployerConditionInput | null;
};

export type UpdateEmployerMutation = {
  updateEmployer?: {
    __typename: "Employer";
    id: string;
    name: string;
    country: Country;
    level: number;
    order: number;
    createdAt: string;
    updatedAt: string;
    childEdges?: {
      __typename: "ModelEmployerEdgeConnection";
      items: Array<{
        __typename: "EmployerEdge";
        id: string;
        parentNodeId: string;
        childNodeId: string;
        country: Country;
        createdAt: string;
        updatedAt: string;
        parentNode?: {
          __typename: "Employer";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelEmployerEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        childNode?: {
          __typename: "Employer";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelEmployerEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type DeleteEmployerMutationVariables = {
  input: DeleteEmployerInput;
  condition?: ModelEmployerConditionInput | null;
};

export type DeleteEmployerMutation = {
  deleteEmployer?: {
    __typename: "Employer";
    id: string;
    name: string;
    country: Country;
    level: number;
    order: number;
    createdAt: string;
    updatedAt: string;
    childEdges?: {
      __typename: "ModelEmployerEdgeConnection";
      items: Array<{
        __typename: "EmployerEdge";
        id: string;
        parentNodeId: string;
        childNodeId: string;
        country: Country;
        createdAt: string;
        updatedAt: string;
        parentNode?: {
          __typename: "Employer";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelEmployerEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        childNode?: {
          __typename: "Employer";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelEmployerEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateEmployerEdgeMutationVariables = {
  input: CreateEmployerEdgeInput;
  condition?: ModelEmployerEdgeConditionInput | null;
};

export type CreateEmployerEdgeMutation = {
  createEmployerEdge?: {
    __typename: "EmployerEdge";
    id: string;
    parentNodeId: string;
    childNodeId: string;
    country: Country;
    createdAt: string;
    updatedAt: string;
    parentNode?: {
      __typename: "Employer";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelEmployerEdgeConnection";
        items: Array<{
          __typename: "EmployerEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    childNode?: {
      __typename: "Employer";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelEmployerEdgeConnection";
        items: Array<{
          __typename: "EmployerEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateEmployerEdgeMutationVariables = {
  input: UpdateEmployerEdgeInput;
  condition?: ModelEmployerEdgeConditionInput | null;
};

export type UpdateEmployerEdgeMutation = {
  updateEmployerEdge?: {
    __typename: "EmployerEdge";
    id: string;
    parentNodeId: string;
    childNodeId: string;
    country: Country;
    createdAt: string;
    updatedAt: string;
    parentNode?: {
      __typename: "Employer";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelEmployerEdgeConnection";
        items: Array<{
          __typename: "EmployerEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    childNode?: {
      __typename: "Employer";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelEmployerEdgeConnection";
        items: Array<{
          __typename: "EmployerEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteEmployerEdgeMutationVariables = {
  input: DeleteEmployerEdgeInput;
  condition?: ModelEmployerEdgeConditionInput | null;
};

export type DeleteEmployerEdgeMutation = {
  deleteEmployerEdge?: {
    __typename: "EmployerEdge";
    id: string;
    parentNodeId: string;
    childNodeId: string;
    country: Country;
    createdAt: string;
    updatedAt: string;
    parentNode?: {
      __typename: "Employer";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelEmployerEdgeConnection";
        items: Array<{
          __typename: "EmployerEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    childNode?: {
      __typename: "Employer";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelEmployerEdgeConnection";
        items: Array<{
          __typename: "EmployerEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateProfileMutationVariables = {
  input: CreateProfileInput;
  condition?: ModelProfileConditionInput | null;
};

export type CreateProfileMutation = {
  createProfile?: {
    __typename: "Profile";
    id: string;
    credits?: number | null;
    sub?: string | null;
    email: string;
    entity?: EntityType | null;
    entityState?: string | null;
    profileImage?: string | null;
    firstDegree?: string | null;
    forename?: string | null;
    surname?: string | null;
    secondDegree?: string | null;
    aboutMe?: string | null;
    educations?: string | null;
    workExperiences?: string | null;
    publications?: string | null;
    conferences?: string | null;
    certificates?: string | null;
    languages?: string | null;
    otherInformation?: string | null;
    qualifications?: string | null;
    name?: string | null;
    crn?: string | null;
    vatRegNumber?: string | null;
    street?: string | null;
    buildingIdentificationNumber?: string | null;
    municipality?: string | null;
    postCode?: string | null;
    language?: string | null;
    phone?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    messages?: {
      __typename: "ModelMessageInboxConnection";
      items: Array<{
        __typename: "MessageInbox";
        id: string;
        name: string;
        email: string;
        phoneNumber?: string | null;
        message: string;
        file?: string | null;
        state?: MessageState | null;
        createdAt: string;
        updatedAt: string;
        profileID: string;
        advertisementID: string;
        profile?: {
          __typename: "Profile";
          id: string;
          credits?: number | null;
          sub?: string | null;
          email: string;
          entity?: EntityType | null;
          entityState?: string | null;
          profileImage?: string | null;
          firstDegree?: string | null;
          forename?: string | null;
          surname?: string | null;
          secondDegree?: string | null;
          aboutMe?: string | null;
          educations?: string | null;
          workExperiences?: string | null;
          publications?: string | null;
          conferences?: string | null;
          certificates?: string | null;
          languages?: string | null;
          otherInformation?: string | null;
          qualifications?: string | null;
          name?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          phone?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelMessageInboxConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        advertisement?: {
          __typename: "Advertisement";
          id: string;
          friendlyId?: number | null;
          entity: EntityType;
          active?: Active | null;
          state?: string | null;
          stateDescription?: string | null;
          paymentState?: string | null;
          jobPosition: string;
          jobShortDescription: string;
          jobDescription?: string | null;
          place: string;
          salaryFrom?: number | null;
          salaryTo?: number | null;
          salarySpecifiedInText?: boolean | null;
          reactivationCount?: number | null;
          currentReactivationCount?: number | null;
          automaticReactivation?: boolean | null;
          leadingPosition?: boolean | null;
          residentialPlace?: boolean | null;
          typeOfCooperation?: string | null;
          jobType?: string | null;
          financialConditions?: string | null;
          typeOfInternship?: string | null;
          contactFirstDegree?: string | null;
          contactForename?: string | null;
          contactSurname?: string | null;
          contactSecondDegree?: string | null;
          contactEmail?: string | null;
          contactPhoneNumber?: string | null;
          hideContactInformation?: boolean | null;
          workType?: string | null;
          suitableFor?: string | null;
          workTypeStringified?: string | null;
          suitableForStringified?: string | null;
          employmentTypesStringified?: string | null;
          employmentTypes?: string | null;
          paymentType?: PaymentType | null;
          advertisementSettingID: string;
          advertisementTypeID?: string | null;
          companyID?: string | null;
          profileID?: string | null;
          expirationDate: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          advertisementSetting?: {
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
          analytics?: {
            __typename: "ModelAdvertisementAnalyticConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type UpdateProfileMutationVariables = {
  input: UpdateProfileInput;
  condition?: ModelProfileConditionInput | null;
};

export type UpdateProfileMutation = {
  updateProfile?: {
    __typename: "Profile";
    id: string;
    credits?: number | null;
    sub?: string | null;
    email: string;
    entity?: EntityType | null;
    entityState?: string | null;
    profileImage?: string | null;
    firstDegree?: string | null;
    forename?: string | null;
    surname?: string | null;
    secondDegree?: string | null;
    aboutMe?: string | null;
    educations?: string | null;
    workExperiences?: string | null;
    publications?: string | null;
    conferences?: string | null;
    certificates?: string | null;
    languages?: string | null;
    otherInformation?: string | null;
    qualifications?: string | null;
    name?: string | null;
    crn?: string | null;
    vatRegNumber?: string | null;
    street?: string | null;
    buildingIdentificationNumber?: string | null;
    municipality?: string | null;
    postCode?: string | null;
    language?: string | null;
    phone?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    messages?: {
      __typename: "ModelMessageInboxConnection";
      items: Array<{
        __typename: "MessageInbox";
        id: string;
        name: string;
        email: string;
        phoneNumber?: string | null;
        message: string;
        file?: string | null;
        state?: MessageState | null;
        createdAt: string;
        updatedAt: string;
        profileID: string;
        advertisementID: string;
        profile?: {
          __typename: "Profile";
          id: string;
          credits?: number | null;
          sub?: string | null;
          email: string;
          entity?: EntityType | null;
          entityState?: string | null;
          profileImage?: string | null;
          firstDegree?: string | null;
          forename?: string | null;
          surname?: string | null;
          secondDegree?: string | null;
          aboutMe?: string | null;
          educations?: string | null;
          workExperiences?: string | null;
          publications?: string | null;
          conferences?: string | null;
          certificates?: string | null;
          languages?: string | null;
          otherInformation?: string | null;
          qualifications?: string | null;
          name?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          phone?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelMessageInboxConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        advertisement?: {
          __typename: "Advertisement";
          id: string;
          friendlyId?: number | null;
          entity: EntityType;
          active?: Active | null;
          state?: string | null;
          stateDescription?: string | null;
          paymentState?: string | null;
          jobPosition: string;
          jobShortDescription: string;
          jobDescription?: string | null;
          place: string;
          salaryFrom?: number | null;
          salaryTo?: number | null;
          salarySpecifiedInText?: boolean | null;
          reactivationCount?: number | null;
          currentReactivationCount?: number | null;
          automaticReactivation?: boolean | null;
          leadingPosition?: boolean | null;
          residentialPlace?: boolean | null;
          typeOfCooperation?: string | null;
          jobType?: string | null;
          financialConditions?: string | null;
          typeOfInternship?: string | null;
          contactFirstDegree?: string | null;
          contactForename?: string | null;
          contactSurname?: string | null;
          contactSecondDegree?: string | null;
          contactEmail?: string | null;
          contactPhoneNumber?: string | null;
          hideContactInformation?: boolean | null;
          workType?: string | null;
          suitableFor?: string | null;
          workTypeStringified?: string | null;
          suitableForStringified?: string | null;
          employmentTypesStringified?: string | null;
          employmentTypes?: string | null;
          paymentType?: PaymentType | null;
          advertisementSettingID: string;
          advertisementTypeID?: string | null;
          companyID?: string | null;
          profileID?: string | null;
          expirationDate: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          advertisementSetting?: {
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
          analytics?: {
            __typename: "ModelAdvertisementAnalyticConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type DeleteProfileMutationVariables = {
  input: DeleteProfileInput;
  condition?: ModelProfileConditionInput | null;
};

export type DeleteProfileMutation = {
  deleteProfile?: {
    __typename: "Profile";
    id: string;
    credits?: number | null;
    sub?: string | null;
    email: string;
    entity?: EntityType | null;
    entityState?: string | null;
    profileImage?: string | null;
    firstDegree?: string | null;
    forename?: string | null;
    surname?: string | null;
    secondDegree?: string | null;
    aboutMe?: string | null;
    educations?: string | null;
    workExperiences?: string | null;
    publications?: string | null;
    conferences?: string | null;
    certificates?: string | null;
    languages?: string | null;
    otherInformation?: string | null;
    qualifications?: string | null;
    name?: string | null;
    crn?: string | null;
    vatRegNumber?: string | null;
    street?: string | null;
    buildingIdentificationNumber?: string | null;
    municipality?: string | null;
    postCode?: string | null;
    language?: string | null;
    phone?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    messages?: {
      __typename: "ModelMessageInboxConnection";
      items: Array<{
        __typename: "MessageInbox";
        id: string;
        name: string;
        email: string;
        phoneNumber?: string | null;
        message: string;
        file?: string | null;
        state?: MessageState | null;
        createdAt: string;
        updatedAt: string;
        profileID: string;
        advertisementID: string;
        profile?: {
          __typename: "Profile";
          id: string;
          credits?: number | null;
          sub?: string | null;
          email: string;
          entity?: EntityType | null;
          entityState?: string | null;
          profileImage?: string | null;
          firstDegree?: string | null;
          forename?: string | null;
          surname?: string | null;
          secondDegree?: string | null;
          aboutMe?: string | null;
          educations?: string | null;
          workExperiences?: string | null;
          publications?: string | null;
          conferences?: string | null;
          certificates?: string | null;
          languages?: string | null;
          otherInformation?: string | null;
          qualifications?: string | null;
          name?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          phone?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelMessageInboxConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        advertisement?: {
          __typename: "Advertisement";
          id: string;
          friendlyId?: number | null;
          entity: EntityType;
          active?: Active | null;
          state?: string | null;
          stateDescription?: string | null;
          paymentState?: string | null;
          jobPosition: string;
          jobShortDescription: string;
          jobDescription?: string | null;
          place: string;
          salaryFrom?: number | null;
          salaryTo?: number | null;
          salarySpecifiedInText?: boolean | null;
          reactivationCount?: number | null;
          currentReactivationCount?: number | null;
          automaticReactivation?: boolean | null;
          leadingPosition?: boolean | null;
          residentialPlace?: boolean | null;
          typeOfCooperation?: string | null;
          jobType?: string | null;
          financialConditions?: string | null;
          typeOfInternship?: string | null;
          contactFirstDegree?: string | null;
          contactForename?: string | null;
          contactSurname?: string | null;
          contactSecondDegree?: string | null;
          contactEmail?: string | null;
          contactPhoneNumber?: string | null;
          hideContactInformation?: boolean | null;
          workType?: string | null;
          suitableFor?: string | null;
          workTypeStringified?: string | null;
          suitableForStringified?: string | null;
          employmentTypesStringified?: string | null;
          employmentTypes?: string | null;
          paymentType?: PaymentType | null;
          advertisementSettingID: string;
          advertisementTypeID?: string | null;
          companyID?: string | null;
          profileID?: string | null;
          expirationDate: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          advertisementSetting?: {
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
          analytics?: {
            __typename: "ModelAdvertisementAnalyticConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateMessageInboxMutationVariables = {
  input: CreateMessageInboxInput;
  condition?: ModelMessageInboxConditionInput | null;
};

export type CreateMessageInboxMutation = {
  createMessageInbox?: {
    __typename: "MessageInbox";
    id: string;
    name: string;
    email: string;
    phoneNumber?: string | null;
    message: string;
    file?: string | null;
    state?: MessageState | null;
    createdAt: string;
    updatedAt: string;
    profileID: string;
    advertisementID: string;
    profile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    advertisement?: {
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateMessageInboxMutationVariables = {
  input: UpdateMessageInboxInput;
  condition?: ModelMessageInboxConditionInput | null;
};

export type UpdateMessageInboxMutation = {
  updateMessageInbox?: {
    __typename: "MessageInbox";
    id: string;
    name: string;
    email: string;
    phoneNumber?: string | null;
    message: string;
    file?: string | null;
    state?: MessageState | null;
    createdAt: string;
    updatedAt: string;
    profileID: string;
    advertisementID: string;
    profile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    advertisement?: {
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteMessageInboxMutationVariables = {
  input: DeleteMessageInboxInput;
  condition?: ModelMessageInboxConditionInput | null;
};

export type DeleteMessageInboxMutation = {
  deleteMessageInbox?: {
    __typename: "MessageInbox";
    id: string;
    name: string;
    email: string;
    phoneNumber?: string | null;
    message: string;
    file?: string | null;
    state?: MessageState | null;
    createdAt: string;
    updatedAt: string;
    profileID: string;
    advertisementID: string;
    profile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    advertisement?: {
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateCompanyMutationVariables = {
  input: CreateCompanyInput;
  condition?: ModelCompanyConditionInput | null;
};

export type CreateCompanyMutation = {
  createCompany?: {
    __typename: "Company";
    id: string;
    entity: EntityType;
    entityState?: string | null;
    credits?: number | null;
    name: string;
    logo?: string | null;
    forename?: string | null;
    surname?: string | null;
    address?: string | null;
    phone?: string | null;
    email?: string | null;
    crn?: string | null;
    vatRegNumber?: string | null;
    street?: string | null;
    buildingIdentificationNumber?: string | null;
    municipality?: string | null;
    postCode?: string | null;
    language?: string | null;
    employerTaxonomy?: string | null;
    profileImage?: string | null;
    qualification?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    messages?: {
      __typename: "ModelCompanyMessageInboxConnection";
      items: Array<{
        __typename: "CompanyMessageInbox";
        id: string;
        name: string;
        email: string;
        state?: MessageState | null;
        phoneNumber?: string | null;
        message: string;
        file?: string | null;
        createdAt: string;
        updatedAt: string;
        companyID: string;
        advertisementID: string;
        company?: {
          __typename: "Company";
          id: string;
          entity: EntityType;
          entityState?: string | null;
          credits?: number | null;
          name: string;
          logo?: string | null;
          forename?: string | null;
          surname?: string | null;
          address?: string | null;
          phone?: string | null;
          email?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          employerTaxonomy?: string | null;
          profileImage?: string | null;
          qualification?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelCompanyMessageInboxConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelCompanyUserConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        advertisement?: {
          __typename: "Advertisement";
          id: string;
          friendlyId?: number | null;
          entity: EntityType;
          active?: Active | null;
          state?: string | null;
          stateDescription?: string | null;
          paymentState?: string | null;
          jobPosition: string;
          jobShortDescription: string;
          jobDescription?: string | null;
          place: string;
          salaryFrom?: number | null;
          salaryTo?: number | null;
          salarySpecifiedInText?: boolean | null;
          reactivationCount?: number | null;
          currentReactivationCount?: number | null;
          automaticReactivation?: boolean | null;
          leadingPosition?: boolean | null;
          residentialPlace?: boolean | null;
          typeOfCooperation?: string | null;
          jobType?: string | null;
          financialConditions?: string | null;
          typeOfInternship?: string | null;
          contactFirstDegree?: string | null;
          contactForename?: string | null;
          contactSurname?: string | null;
          contactSecondDegree?: string | null;
          contactEmail?: string | null;
          contactPhoneNumber?: string | null;
          hideContactInformation?: boolean | null;
          workType?: string | null;
          suitableFor?: string | null;
          workTypeStringified?: string | null;
          suitableForStringified?: string | null;
          employmentTypesStringified?: string | null;
          employmentTypes?: string | null;
          paymentType?: PaymentType | null;
          advertisementSettingID: string;
          advertisementTypeID?: string | null;
          companyID?: string | null;
          profileID?: string | null;
          expirationDate: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          advertisementSetting?: {
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
          analytics?: {
            __typename: "ModelAdvertisementAnalyticConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelCompanyUserConnection";
      items: Array<{
        __typename: "CompanyUser";
        id: string;
        entity: EntityType;
        name?: string | null;
        email: string;
        role: CompanyUserRole;
        createdAt: string;
        updatedAt: string;
        companyID: string;
        company?: {
          __typename: "Company";
          id: string;
          entity: EntityType;
          entityState?: string | null;
          credits?: number | null;
          name: string;
          logo?: string | null;
          forename?: string | null;
          surname?: string | null;
          address?: string | null;
          phone?: string | null;
          email?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          employerTaxonomy?: string | null;
          profileImage?: string | null;
          qualification?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelCompanyMessageInboxConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelCompanyUserConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type UpdateCompanyMutationVariables = {
  input: UpdateCompanyInput;
  condition?: ModelCompanyConditionInput | null;
};

export type UpdateCompanyMutation = {
  updateCompany?: {
    __typename: "Company";
    id: string;
    entity: EntityType;
    entityState?: string | null;
    credits?: number | null;
    name: string;
    logo?: string | null;
    forename?: string | null;
    surname?: string | null;
    address?: string | null;
    phone?: string | null;
    email?: string | null;
    crn?: string | null;
    vatRegNumber?: string | null;
    street?: string | null;
    buildingIdentificationNumber?: string | null;
    municipality?: string | null;
    postCode?: string | null;
    language?: string | null;
    employerTaxonomy?: string | null;
    profileImage?: string | null;
    qualification?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    messages?: {
      __typename: "ModelCompanyMessageInboxConnection";
      items: Array<{
        __typename: "CompanyMessageInbox";
        id: string;
        name: string;
        email: string;
        state?: MessageState | null;
        phoneNumber?: string | null;
        message: string;
        file?: string | null;
        createdAt: string;
        updatedAt: string;
        companyID: string;
        advertisementID: string;
        company?: {
          __typename: "Company";
          id: string;
          entity: EntityType;
          entityState?: string | null;
          credits?: number | null;
          name: string;
          logo?: string | null;
          forename?: string | null;
          surname?: string | null;
          address?: string | null;
          phone?: string | null;
          email?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          employerTaxonomy?: string | null;
          profileImage?: string | null;
          qualification?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelCompanyMessageInboxConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelCompanyUserConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        advertisement?: {
          __typename: "Advertisement";
          id: string;
          friendlyId?: number | null;
          entity: EntityType;
          active?: Active | null;
          state?: string | null;
          stateDescription?: string | null;
          paymentState?: string | null;
          jobPosition: string;
          jobShortDescription: string;
          jobDescription?: string | null;
          place: string;
          salaryFrom?: number | null;
          salaryTo?: number | null;
          salarySpecifiedInText?: boolean | null;
          reactivationCount?: number | null;
          currentReactivationCount?: number | null;
          automaticReactivation?: boolean | null;
          leadingPosition?: boolean | null;
          residentialPlace?: boolean | null;
          typeOfCooperation?: string | null;
          jobType?: string | null;
          financialConditions?: string | null;
          typeOfInternship?: string | null;
          contactFirstDegree?: string | null;
          contactForename?: string | null;
          contactSurname?: string | null;
          contactSecondDegree?: string | null;
          contactEmail?: string | null;
          contactPhoneNumber?: string | null;
          hideContactInformation?: boolean | null;
          workType?: string | null;
          suitableFor?: string | null;
          workTypeStringified?: string | null;
          suitableForStringified?: string | null;
          employmentTypesStringified?: string | null;
          employmentTypes?: string | null;
          paymentType?: PaymentType | null;
          advertisementSettingID: string;
          advertisementTypeID?: string | null;
          companyID?: string | null;
          profileID?: string | null;
          expirationDate: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          advertisementSetting?: {
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
          analytics?: {
            __typename: "ModelAdvertisementAnalyticConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelCompanyUserConnection";
      items: Array<{
        __typename: "CompanyUser";
        id: string;
        entity: EntityType;
        name?: string | null;
        email: string;
        role: CompanyUserRole;
        createdAt: string;
        updatedAt: string;
        companyID: string;
        company?: {
          __typename: "Company";
          id: string;
          entity: EntityType;
          entityState?: string | null;
          credits?: number | null;
          name: string;
          logo?: string | null;
          forename?: string | null;
          surname?: string | null;
          address?: string | null;
          phone?: string | null;
          email?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          employerTaxonomy?: string | null;
          profileImage?: string | null;
          qualification?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelCompanyMessageInboxConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelCompanyUserConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type DeleteCompanyMutationVariables = {
  input: DeleteCompanyInput;
  condition?: ModelCompanyConditionInput | null;
};

export type DeleteCompanyMutation = {
  deleteCompany?: {
    __typename: "Company";
    id: string;
    entity: EntityType;
    entityState?: string | null;
    credits?: number | null;
    name: string;
    logo?: string | null;
    forename?: string | null;
    surname?: string | null;
    address?: string | null;
    phone?: string | null;
    email?: string | null;
    crn?: string | null;
    vatRegNumber?: string | null;
    street?: string | null;
    buildingIdentificationNumber?: string | null;
    municipality?: string | null;
    postCode?: string | null;
    language?: string | null;
    employerTaxonomy?: string | null;
    profileImage?: string | null;
    qualification?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    messages?: {
      __typename: "ModelCompanyMessageInboxConnection";
      items: Array<{
        __typename: "CompanyMessageInbox";
        id: string;
        name: string;
        email: string;
        state?: MessageState | null;
        phoneNumber?: string | null;
        message: string;
        file?: string | null;
        createdAt: string;
        updatedAt: string;
        companyID: string;
        advertisementID: string;
        company?: {
          __typename: "Company";
          id: string;
          entity: EntityType;
          entityState?: string | null;
          credits?: number | null;
          name: string;
          logo?: string | null;
          forename?: string | null;
          surname?: string | null;
          address?: string | null;
          phone?: string | null;
          email?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          employerTaxonomy?: string | null;
          profileImage?: string | null;
          qualification?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelCompanyMessageInboxConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelCompanyUserConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        advertisement?: {
          __typename: "Advertisement";
          id: string;
          friendlyId?: number | null;
          entity: EntityType;
          active?: Active | null;
          state?: string | null;
          stateDescription?: string | null;
          paymentState?: string | null;
          jobPosition: string;
          jobShortDescription: string;
          jobDescription?: string | null;
          place: string;
          salaryFrom?: number | null;
          salaryTo?: number | null;
          salarySpecifiedInText?: boolean | null;
          reactivationCount?: number | null;
          currentReactivationCount?: number | null;
          automaticReactivation?: boolean | null;
          leadingPosition?: boolean | null;
          residentialPlace?: boolean | null;
          typeOfCooperation?: string | null;
          jobType?: string | null;
          financialConditions?: string | null;
          typeOfInternship?: string | null;
          contactFirstDegree?: string | null;
          contactForename?: string | null;
          contactSurname?: string | null;
          contactSecondDegree?: string | null;
          contactEmail?: string | null;
          contactPhoneNumber?: string | null;
          hideContactInformation?: boolean | null;
          workType?: string | null;
          suitableFor?: string | null;
          workTypeStringified?: string | null;
          suitableForStringified?: string | null;
          employmentTypesStringified?: string | null;
          employmentTypes?: string | null;
          paymentType?: PaymentType | null;
          advertisementSettingID: string;
          advertisementTypeID?: string | null;
          companyID?: string | null;
          profileID?: string | null;
          expirationDate: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          advertisementSetting?: {
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
          analytics?: {
            __typename: "ModelAdvertisementAnalyticConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelCompanyUserConnection";
      items: Array<{
        __typename: "CompanyUser";
        id: string;
        entity: EntityType;
        name?: string | null;
        email: string;
        role: CompanyUserRole;
        createdAt: string;
        updatedAt: string;
        companyID: string;
        company?: {
          __typename: "Company";
          id: string;
          entity: EntityType;
          entityState?: string | null;
          credits?: number | null;
          name: string;
          logo?: string | null;
          forename?: string | null;
          surname?: string | null;
          address?: string | null;
          phone?: string | null;
          email?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          employerTaxonomy?: string | null;
          profileImage?: string | null;
          qualification?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelCompanyMessageInboxConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelCompanyUserConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateCompanyMessageInboxMutationVariables = {
  input: CreateCompanyMessageInboxInput;
  condition?: ModelCompanyMessageInboxConditionInput | null;
};

export type CreateCompanyMessageInboxMutation = {
  createCompanyMessageInbox?: {
    __typename: "CompanyMessageInbox";
    id: string;
    name: string;
    email: string;
    state?: MessageState | null;
    phoneNumber?: string | null;
    message: string;
    file?: string | null;
    createdAt: string;
    updatedAt: string;
    companyID: string;
    advertisementID: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    advertisement?: {
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateCompanyMessageInboxMutationVariables = {
  input: UpdateCompanyMessageInboxInput;
  condition?: ModelCompanyMessageInboxConditionInput | null;
};

export type UpdateCompanyMessageInboxMutation = {
  updateCompanyMessageInbox?: {
    __typename: "CompanyMessageInbox";
    id: string;
    name: string;
    email: string;
    state?: MessageState | null;
    phoneNumber?: string | null;
    message: string;
    file?: string | null;
    createdAt: string;
    updatedAt: string;
    companyID: string;
    advertisementID: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    advertisement?: {
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteCompanyMessageInboxMutationVariables = {
  input: DeleteCompanyMessageInboxInput;
  condition?: ModelCompanyMessageInboxConditionInput | null;
};

export type DeleteCompanyMessageInboxMutation = {
  deleteCompanyMessageInbox?: {
    __typename: "CompanyMessageInbox";
    id: string;
    name: string;
    email: string;
    state?: MessageState | null;
    phoneNumber?: string | null;
    message: string;
    file?: string | null;
    createdAt: string;
    updatedAt: string;
    companyID: string;
    advertisementID: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    advertisement?: {
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateCompanyUserMutationVariables = {
  input: CreateCompanyUserInput;
  condition?: ModelCompanyUserConditionInput | null;
};

export type CreateCompanyUserMutation = {
  createCompanyUser?: {
    __typename: "CompanyUser";
    id: string;
    entity: EntityType;
    name?: string | null;
    email: string;
    role: CompanyUserRole;
    createdAt: string;
    updatedAt: string;
    companyID: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    owner?: string | null;
  } | null;
};

export type UpdateCompanyUserMutationVariables = {
  input: UpdateCompanyUserInput;
  condition?: ModelCompanyUserConditionInput | null;
};

export type UpdateCompanyUserMutation = {
  updateCompanyUser?: {
    __typename: "CompanyUser";
    id: string;
    entity: EntityType;
    name?: string | null;
    email: string;
    role: CompanyUserRole;
    createdAt: string;
    updatedAt: string;
    companyID: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    owner?: string | null;
  } | null;
};

export type DeleteCompanyUserMutationVariables = {
  input: DeleteCompanyUserInput;
  condition?: ModelCompanyUserConditionInput | null;
};

export type DeleteCompanyUserMutation = {
  deleteCompanyUser?: {
    __typename: "CompanyUser";
    id: string;
    entity: EntityType;
    name?: string | null;
    email: string;
    role: CompanyUserRole;
    createdAt: string;
    updatedAt: string;
    companyID: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    owner?: string | null;
  } | null;
};

export type CreateAdvertisementMutationVariables = {
  input: CreateAdvertisementInput;
  condition?: ModelAdvertisementConditionInput | null;
};

export type CreateAdvertisementMutation = {
  createAdvertisement?: {
    __typename: "Advertisement";
    id: string;
    friendlyId?: number | null;
    entity: EntityType;
    active?: Active | null;
    state?: string | null;
    stateDescription?: string | null;
    paymentState?: string | null;
    jobPosition: string;
    jobShortDescription: string;
    jobDescription?: string | null;
    place: string;
    salaryFrom?: number | null;
    salaryTo?: number | null;
    salarySpecifiedInText?: boolean | null;
    reactivationCount?: number | null;
    currentReactivationCount?: number | null;
    automaticReactivation?: boolean | null;
    leadingPosition?: boolean | null;
    residentialPlace?: boolean | null;
    typeOfCooperation?: string | null;
    jobType?: string | null;
    financialConditions?: string | null;
    typeOfInternship?: string | null;
    contactFirstDegree?: string | null;
    contactForename?: string | null;
    contactSurname?: string | null;
    contactSecondDegree?: string | null;
    contactEmail?: string | null;
    contactPhoneNumber?: string | null;
    hideContactInformation?: boolean | null;
    workType?: string | null;
    suitableFor?: string | null;
    workTypeStringified?: string | null;
    suitableForStringified?: string | null;
    employmentTypesStringified?: string | null;
    employmentTypes?: string | null;
    paymentType?: PaymentType | null;
    advertisementSettingID: string;
    advertisementTypeID?: string | null;
    companyID?: string | null;
    profileID?: string | null;
    expirationDate: string;
    createdAt: string;
    updatedAt: string;
    advertisementType?: {
      __typename: "AdvertisementType";
      id: string;
      active: boolean;
      entity: EntityType;
      name: string;
      jobDescription?: string | null;
      order: number;
      approvalRequired: string;
      components?: string | null;
      createdAt: string;
      updatedAt: string;
      advertisements?: {
        __typename: "ModelAdvertisementSettingConnection";
        items: Array<{
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    advertisementSetting?: {
      __typename: "AdvertisementSetting";
      id: string;
      active: boolean;
      creditsCount: number;
      description?: string | null;
      entity: EntityType;
      internalName: string;
      name: string;
      numberOfDays: number;
      reactivationCreditsCount: number;
      order: number;
      appliedTo: string;
      public?: PublicAdvertisementSetting | null;
      advertisementTypeID: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null;
    profile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    owner?: string | null;
    analytics?: {
      __typename: "ModelAdvertisementAnalyticConnection";
      items: Array<{
        __typename: "AdvertisementAnalytic";
        id: string;
        name?: string | null;
        info?: string | null;
        advertisementID: string;
        createdAt: string;
        updatedAt: string;
        advertisement?: {
          __typename: "Advertisement";
          id: string;
          friendlyId?: number | null;
          entity: EntityType;
          active?: Active | null;
          state?: string | null;
          stateDescription?: string | null;
          paymentState?: string | null;
          jobPosition: string;
          jobShortDescription: string;
          jobDescription?: string | null;
          place: string;
          salaryFrom?: number | null;
          salaryTo?: number | null;
          salarySpecifiedInText?: boolean | null;
          reactivationCount?: number | null;
          currentReactivationCount?: number | null;
          automaticReactivation?: boolean | null;
          leadingPosition?: boolean | null;
          residentialPlace?: boolean | null;
          typeOfCooperation?: string | null;
          jobType?: string | null;
          financialConditions?: string | null;
          typeOfInternship?: string | null;
          contactFirstDegree?: string | null;
          contactForename?: string | null;
          contactSurname?: string | null;
          contactSecondDegree?: string | null;
          contactEmail?: string | null;
          contactPhoneNumber?: string | null;
          hideContactInformation?: boolean | null;
          workType?: string | null;
          suitableFor?: string | null;
          workTypeStringified?: string | null;
          suitableForStringified?: string | null;
          employmentTypesStringified?: string | null;
          employmentTypes?: string | null;
          paymentType?: PaymentType | null;
          advertisementSettingID: string;
          advertisementTypeID?: string | null;
          companyID?: string | null;
          profileID?: string | null;
          expirationDate: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          advertisementSetting?: {
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
          analytics?: {
            __typename: "ModelAdvertisementAnalyticConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type UpdateAdvertisementMutationVariables = {
  input: UpdateAdvertisementInput;
  condition?: ModelAdvertisementConditionInput | null;
};

export type UpdateAdvertisementMutation = {
  updateAdvertisement?: {
    __typename: "Advertisement";
    id: string;
    friendlyId?: number | null;
    entity: EntityType;
    active?: Active | null;
    state?: string | null;
    stateDescription?: string | null;
    paymentState?: string | null;
    jobPosition: string;
    jobShortDescription: string;
    jobDescription?: string | null;
    place: string;
    salaryFrom?: number | null;
    salaryTo?: number | null;
    salarySpecifiedInText?: boolean | null;
    reactivationCount?: number | null;
    currentReactivationCount?: number | null;
    automaticReactivation?: boolean | null;
    leadingPosition?: boolean | null;
    residentialPlace?: boolean | null;
    typeOfCooperation?: string | null;
    jobType?: string | null;
    financialConditions?: string | null;
    typeOfInternship?: string | null;
    contactFirstDegree?: string | null;
    contactForename?: string | null;
    contactSurname?: string | null;
    contactSecondDegree?: string | null;
    contactEmail?: string | null;
    contactPhoneNumber?: string | null;
    hideContactInformation?: boolean | null;
    workType?: string | null;
    suitableFor?: string | null;
    workTypeStringified?: string | null;
    suitableForStringified?: string | null;
    employmentTypesStringified?: string | null;
    employmentTypes?: string | null;
    paymentType?: PaymentType | null;
    advertisementSettingID: string;
    advertisementTypeID?: string | null;
    companyID?: string | null;
    profileID?: string | null;
    expirationDate: string;
    createdAt: string;
    updatedAt: string;
    advertisementType?: {
      __typename: "AdvertisementType";
      id: string;
      active: boolean;
      entity: EntityType;
      name: string;
      jobDescription?: string | null;
      order: number;
      approvalRequired: string;
      components?: string | null;
      createdAt: string;
      updatedAt: string;
      advertisements?: {
        __typename: "ModelAdvertisementSettingConnection";
        items: Array<{
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    advertisementSetting?: {
      __typename: "AdvertisementSetting";
      id: string;
      active: boolean;
      creditsCount: number;
      description?: string | null;
      entity: EntityType;
      internalName: string;
      name: string;
      numberOfDays: number;
      reactivationCreditsCount: number;
      order: number;
      appliedTo: string;
      public?: PublicAdvertisementSetting | null;
      advertisementTypeID: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null;
    profile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    owner?: string | null;
    analytics?: {
      __typename: "ModelAdvertisementAnalyticConnection";
      items: Array<{
        __typename: "AdvertisementAnalytic";
        id: string;
        name?: string | null;
        info?: string | null;
        advertisementID: string;
        createdAt: string;
        updatedAt: string;
        advertisement?: {
          __typename: "Advertisement";
          id: string;
          friendlyId?: number | null;
          entity: EntityType;
          active?: Active | null;
          state?: string | null;
          stateDescription?: string | null;
          paymentState?: string | null;
          jobPosition: string;
          jobShortDescription: string;
          jobDescription?: string | null;
          place: string;
          salaryFrom?: number | null;
          salaryTo?: number | null;
          salarySpecifiedInText?: boolean | null;
          reactivationCount?: number | null;
          currentReactivationCount?: number | null;
          automaticReactivation?: boolean | null;
          leadingPosition?: boolean | null;
          residentialPlace?: boolean | null;
          typeOfCooperation?: string | null;
          jobType?: string | null;
          financialConditions?: string | null;
          typeOfInternship?: string | null;
          contactFirstDegree?: string | null;
          contactForename?: string | null;
          contactSurname?: string | null;
          contactSecondDegree?: string | null;
          contactEmail?: string | null;
          contactPhoneNumber?: string | null;
          hideContactInformation?: boolean | null;
          workType?: string | null;
          suitableFor?: string | null;
          workTypeStringified?: string | null;
          suitableForStringified?: string | null;
          employmentTypesStringified?: string | null;
          employmentTypes?: string | null;
          paymentType?: PaymentType | null;
          advertisementSettingID: string;
          advertisementTypeID?: string | null;
          companyID?: string | null;
          profileID?: string | null;
          expirationDate: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          advertisementSetting?: {
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
          analytics?: {
            __typename: "ModelAdvertisementAnalyticConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type DeleteAdvertisementMutationVariables = {
  input: DeleteAdvertisementInput;
  condition?: ModelAdvertisementConditionInput | null;
};

export type DeleteAdvertisementMutation = {
  deleteAdvertisement?: {
    __typename: "Advertisement";
    id: string;
    friendlyId?: number | null;
    entity: EntityType;
    active?: Active | null;
    state?: string | null;
    stateDescription?: string | null;
    paymentState?: string | null;
    jobPosition: string;
    jobShortDescription: string;
    jobDescription?: string | null;
    place: string;
    salaryFrom?: number | null;
    salaryTo?: number | null;
    salarySpecifiedInText?: boolean | null;
    reactivationCount?: number | null;
    currentReactivationCount?: number | null;
    automaticReactivation?: boolean | null;
    leadingPosition?: boolean | null;
    residentialPlace?: boolean | null;
    typeOfCooperation?: string | null;
    jobType?: string | null;
    financialConditions?: string | null;
    typeOfInternship?: string | null;
    contactFirstDegree?: string | null;
    contactForename?: string | null;
    contactSurname?: string | null;
    contactSecondDegree?: string | null;
    contactEmail?: string | null;
    contactPhoneNumber?: string | null;
    hideContactInformation?: boolean | null;
    workType?: string | null;
    suitableFor?: string | null;
    workTypeStringified?: string | null;
    suitableForStringified?: string | null;
    employmentTypesStringified?: string | null;
    employmentTypes?: string | null;
    paymentType?: PaymentType | null;
    advertisementSettingID: string;
    advertisementTypeID?: string | null;
    companyID?: string | null;
    profileID?: string | null;
    expirationDate: string;
    createdAt: string;
    updatedAt: string;
    advertisementType?: {
      __typename: "AdvertisementType";
      id: string;
      active: boolean;
      entity: EntityType;
      name: string;
      jobDescription?: string | null;
      order: number;
      approvalRequired: string;
      components?: string | null;
      createdAt: string;
      updatedAt: string;
      advertisements?: {
        __typename: "ModelAdvertisementSettingConnection";
        items: Array<{
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    advertisementSetting?: {
      __typename: "AdvertisementSetting";
      id: string;
      active: boolean;
      creditsCount: number;
      description?: string | null;
      entity: EntityType;
      internalName: string;
      name: string;
      numberOfDays: number;
      reactivationCreditsCount: number;
      order: number;
      appliedTo: string;
      public?: PublicAdvertisementSetting | null;
      advertisementTypeID: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null;
    profile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    owner?: string | null;
    analytics?: {
      __typename: "ModelAdvertisementAnalyticConnection";
      items: Array<{
        __typename: "AdvertisementAnalytic";
        id: string;
        name?: string | null;
        info?: string | null;
        advertisementID: string;
        createdAt: string;
        updatedAt: string;
        advertisement?: {
          __typename: "Advertisement";
          id: string;
          friendlyId?: number | null;
          entity: EntityType;
          active?: Active | null;
          state?: string | null;
          stateDescription?: string | null;
          paymentState?: string | null;
          jobPosition: string;
          jobShortDescription: string;
          jobDescription?: string | null;
          place: string;
          salaryFrom?: number | null;
          salaryTo?: number | null;
          salarySpecifiedInText?: boolean | null;
          reactivationCount?: number | null;
          currentReactivationCount?: number | null;
          automaticReactivation?: boolean | null;
          leadingPosition?: boolean | null;
          residentialPlace?: boolean | null;
          typeOfCooperation?: string | null;
          jobType?: string | null;
          financialConditions?: string | null;
          typeOfInternship?: string | null;
          contactFirstDegree?: string | null;
          contactForename?: string | null;
          contactSurname?: string | null;
          contactSecondDegree?: string | null;
          contactEmail?: string | null;
          contactPhoneNumber?: string | null;
          hideContactInformation?: boolean | null;
          workType?: string | null;
          suitableFor?: string | null;
          workTypeStringified?: string | null;
          suitableForStringified?: string | null;
          employmentTypesStringified?: string | null;
          employmentTypes?: string | null;
          paymentType?: PaymentType | null;
          advertisementSettingID: string;
          advertisementTypeID?: string | null;
          companyID?: string | null;
          profileID?: string | null;
          expirationDate: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          advertisementSetting?: {
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
          analytics?: {
            __typename: "ModelAdvertisementAnalyticConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateAdvertisementAnalyticMutationVariables = {
  input: CreateAdvertisementAnalyticInput;
  condition?: ModelAdvertisementAnalyticConditionInput | null;
};

export type CreateAdvertisementAnalyticMutation = {
  createAdvertisementAnalytic?: {
    __typename: "AdvertisementAnalytic";
    id: string;
    name?: string | null;
    info?: string | null;
    advertisementID: string;
    createdAt: string;
    updatedAt: string;
    advertisement?: {
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateAdvertisementAnalyticMutationVariables = {
  input: UpdateAdvertisementAnalyticInput;
  condition?: ModelAdvertisementAnalyticConditionInput | null;
};

export type UpdateAdvertisementAnalyticMutation = {
  updateAdvertisementAnalytic?: {
    __typename: "AdvertisementAnalytic";
    id: string;
    name?: string | null;
    info?: string | null;
    advertisementID: string;
    createdAt: string;
    updatedAt: string;
    advertisement?: {
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteAdvertisementAnalyticMutationVariables = {
  input: DeleteAdvertisementAnalyticInput;
  condition?: ModelAdvertisementAnalyticConditionInput | null;
};

export type DeleteAdvertisementAnalyticMutation = {
  deleteAdvertisementAnalytic?: {
    __typename: "AdvertisementAnalytic";
    id: string;
    name?: string | null;
    info?: string | null;
    advertisementID: string;
    createdAt: string;
    updatedAt: string;
    advertisement?: {
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateShowCaseMutationVariables = {
  input: CreateShowCaseInput;
  condition?: ModelShowCaseConditionInput | null;
};

export type CreateShowCaseMutation = {
  createShowCase?: {
    __typename: "ShowCase";
    id: string;
    title: string;
    text: string;
    file?: string | null;
    createdAt: string;
    updatedAt: string;
    companyID: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateShowCaseMutationVariables = {
  input: UpdateShowCaseInput;
  condition?: ModelShowCaseConditionInput | null;
};

export type UpdateShowCaseMutation = {
  updateShowCase?: {
    __typename: "ShowCase";
    id: string;
    title: string;
    text: string;
    file?: string | null;
    createdAt: string;
    updatedAt: string;
    companyID: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteShowCaseMutationVariables = {
  input: DeleteShowCaseInput;
  condition?: ModelShowCaseConditionInput | null;
};

export type DeleteShowCaseMutation = {
  deleteShowCase?: {
    __typename: "ShowCase";
    id: string;
    title: string;
    text: string;
    file?: string | null;
    createdAt: string;
    updatedAt: string;
    companyID: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateTransactionMutationVariables = {
  input: CreateTransactionInput;
  condition?: ModelTransactionConditionInput | null;
};

export type CreateTransactionMutation = {
  createTransaction?: {
    __typename: "Transaction";
    id: string;
    amount: number;
    currency: string;
    description?: string | null;
    paymentIntent?: string | null;
    companyID?: string | null;
    profileID?: string | null;
    initiatedBy?: string | null;
    createdAt: string;
    updatedAt: string;
    profile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    initiatedByProfile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateTransactionMutationVariables = {
  input: UpdateTransactionInput;
  condition?: ModelTransactionConditionInput | null;
};

export type UpdateTransactionMutation = {
  updateTransaction?: {
    __typename: "Transaction";
    id: string;
    amount: number;
    currency: string;
    description?: string | null;
    paymentIntent?: string | null;
    companyID?: string | null;
    profileID?: string | null;
    initiatedBy?: string | null;
    createdAt: string;
    updatedAt: string;
    profile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    initiatedByProfile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteTransactionMutationVariables = {
  input: DeleteTransactionInput;
  condition?: ModelTransactionConditionInput | null;
};

export type DeleteTransactionMutation = {
  deleteTransaction?: {
    __typename: "Transaction";
    id: string;
    amount: number;
    currency: string;
    description?: string | null;
    paymentIntent?: string | null;
    companyID?: string | null;
    profileID?: string | null;
    initiatedBy?: string | null;
    createdAt: string;
    updatedAt: string;
    profile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    initiatedByProfile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateCarouselMutationVariables = {
  input: CreateCarouselInput;
  condition?: ModelCarouselConditionInput | null;
};

export type CreateCarouselMutation = {
  createCarousel?: {
    __typename: "Carousel";
    id: string;
    active: boolean;
    entity: EntityType;
    image?: string | null;
    title: string;
    type?: CarouselType | null;
    companyID?: string | null;
    createdAt: string;
    updatedAt: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateCarouselMutationVariables = {
  input: UpdateCarouselInput;
  condition?: ModelCarouselConditionInput | null;
};

export type UpdateCarouselMutation = {
  updateCarousel?: {
    __typename: "Carousel";
    id: string;
    active: boolean;
    entity: EntityType;
    image?: string | null;
    title: string;
    type?: CarouselType | null;
    companyID?: string | null;
    createdAt: string;
    updatedAt: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteCarouselMutationVariables = {
  input: DeleteCarouselInput;
  condition?: ModelCarouselConditionInput | null;
};

export type DeleteCarouselMutation = {
  deleteCarousel?: {
    __typename: "Carousel";
    id: string;
    active: boolean;
    entity: EntityType;
    image?: string | null;
    title: string;
    type?: CarouselType | null;
    companyID?: string | null;
    createdAt: string;
    updatedAt: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetEmailNewsletterQueryVariables = {
  id: string;
};

export type GetEmailNewsletterQuery = {
  getEmailNewsletter?: {
    __typename: "EmailNewsletter";
    id: string;
    email?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListEmailNewslettersQueryVariables = {
  filter?: ModelEmailNewsletterFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListEmailNewslettersQuery = {
  listEmailNewsletters?: {
    __typename: "ModelEmailNewsletterConnection";
    items: Array<{
      __typename: "EmailNewsletter";
      id: string;
      email?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetArticleCategoryQueryVariables = {
  slug: string;
};

export type GetArticleCategoryQuery = {
  getArticleCategory?: {
    __typename: "ArticleCategory";
    id: string;
    entity: EntityType;
    name: string;
    slug: string;
    order?: number | null;
    createdAt: string;
    updatedAt: string;
    articles?: {
      __typename: "ModelArticleConnection";
      items: Array<{
        __typename: "Article";
        id: string;
        active?: boolean | null;
        content?: string | null;
        description?: string | null;
        entity: EntityType;
        image?: string | null;
        title: string;
        slug: string;
        createdBy?: string | null;
        hashtags?: string | null;
        type?: string | null;
        order?: number | null;
        articleCategoryID: string;
        createdAt: string;
        updatedAt: string;
        category?: {
          __typename: "ArticleCategory";
          id: string;
          entity: EntityType;
          name: string;
          slug: string;
          order?: number | null;
          createdAt: string;
          updatedAt: string;
          articles?: {
            __typename: "ModelArticleConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type ListArticleCategoriesQueryVariables = {
  slug?: string | null;
  filter?: ModelArticleCategoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: ModelSortDirection | null;
};

export type ListArticleCategoriesQuery = {
  listArticleCategories?: {
    __typename: "ModelArticleCategoryConnection";
    items: Array<{
      __typename: "ArticleCategory";
      id: string;
      entity: EntityType;
      name: string;
      slug: string;
      order?: number | null;
      createdAt: string;
      updatedAt: string;
      articles?: {
        __typename: "ModelArticleConnection";
        items: Array<{
          __typename: "Article";
          id: string;
          active?: boolean | null;
          content?: string | null;
          description?: string | null;
          entity: EntityType;
          image?: string | null;
          title: string;
          slug: string;
          createdBy?: string | null;
          hashtags?: string | null;
          type?: string | null;
          order?: number | null;
          articleCategoryID: string;
          createdAt: string;
          updatedAt: string;
          category?: {
            __typename: "ArticleCategory";
            id: string;
            entity: EntityType;
            name: string;
            slug: string;
            order?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ArticleCategoriesByOrderQueryVariables = {
  entity?: EntityType | null;
  order?: ModelIntKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelArticleCategoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ArticleCategoriesByOrderQuery = {
  articleCategoriesByOrder?: {
    __typename: "ModelArticleCategoryConnection";
    items: Array<{
      __typename: "ArticleCategory";
      id: string;
      entity: EntityType;
      name: string;
      slug: string;
      order?: number | null;
      createdAt: string;
      updatedAt: string;
      articles?: {
        __typename: "ModelArticleConnection";
        items: Array<{
          __typename: "Article";
          id: string;
          active?: boolean | null;
          content?: string | null;
          description?: string | null;
          entity: EntityType;
          image?: string | null;
          title: string;
          slug: string;
          createdBy?: string | null;
          hashtags?: string | null;
          type?: string | null;
          order?: number | null;
          articleCategoryID: string;
          createdAt: string;
          updatedAt: string;
          category?: {
            __typename: "ArticleCategory";
            id: string;
            entity: EntityType;
            name: string;
            slug: string;
            order?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ArticleCategoriesByCreatedAtQueryVariables = {
  entity?: EntityType | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelArticleCategoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ArticleCategoriesByCreatedAtQuery = {
  articleCategoriesByCreatedAt?: {
    __typename: "ModelArticleCategoryConnection";
    items: Array<{
      __typename: "ArticleCategory";
      id: string;
      entity: EntityType;
      name: string;
      slug: string;
      order?: number | null;
      createdAt: string;
      updatedAt: string;
      articles?: {
        __typename: "ModelArticleConnection";
        items: Array<{
          __typename: "Article";
          id: string;
          active?: boolean | null;
          content?: string | null;
          description?: string | null;
          entity: EntityType;
          image?: string | null;
          title: string;
          slug: string;
          createdBy?: string | null;
          hashtags?: string | null;
          type?: string | null;
          order?: number | null;
          articleCategoryID: string;
          createdAt: string;
          updatedAt: string;
          category?: {
            __typename: "ArticleCategory";
            id: string;
            entity: EntityType;
            name: string;
            slug: string;
            order?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ArticleCategoriesByUpdatedAtQueryVariables = {
  entity?: EntityType | null;
  updatedAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelArticleCategoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ArticleCategoriesByUpdatedAtQuery = {
  articleCategoriesByUpdatedAt?: {
    __typename: "ModelArticleCategoryConnection";
    items: Array<{
      __typename: "ArticleCategory";
      id: string;
      entity: EntityType;
      name: string;
      slug: string;
      order?: number | null;
      createdAt: string;
      updatedAt: string;
      articles?: {
        __typename: "ModelArticleConnection";
        items: Array<{
          __typename: "Article";
          id: string;
          active?: boolean | null;
          content?: string | null;
          description?: string | null;
          entity: EntityType;
          image?: string | null;
          title: string;
          slug: string;
          createdBy?: string | null;
          hashtags?: string | null;
          type?: string | null;
          order?: number | null;
          articleCategoryID: string;
          createdAt: string;
          updatedAt: string;
          category?: {
            __typename: "ArticleCategory";
            id: string;
            entity: EntityType;
            name: string;
            slug: string;
            order?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SearchArticleCategorysQueryVariables = {
  filter?: SearchableArticleCategoryFilterInput | null;
  sort?: SearchableArticleCategorySortInput | null;
  limit?: number | null;
  nextToken?: string | null;
  from?: number | null;
};

export type SearchArticleCategorysQuery = {
  searchArticleCategorys?: {
    __typename: "SearchableArticleCategoryConnection";
    items: Array<{
      __typename: "ArticleCategory";
      id: string;
      entity: EntityType;
      name: string;
      slug: string;
      order?: number | null;
      createdAt: string;
      updatedAt: string;
      articles?: {
        __typename: "ModelArticleConnection";
        items: Array<{
          __typename: "Article";
          id: string;
          active?: boolean | null;
          content?: string | null;
          description?: string | null;
          entity: EntityType;
          image?: string | null;
          title: string;
          slug: string;
          createdBy?: string | null;
          hashtags?: string | null;
          type?: string | null;
          order?: number | null;
          articleCategoryID: string;
          createdAt: string;
          updatedAt: string;
          category?: {
            __typename: "ArticleCategory";
            id: string;
            entity: EntityType;
            name: string;
            slug: string;
            order?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    total?: number | null;
  } | null;
};

export type GetArticleQueryVariables = {
  slug: string;
};

export type GetArticleQuery = {
  getArticle?: {
    __typename: "Article";
    id: string;
    active?: boolean | null;
    content?: string | null;
    description?: string | null;
    entity: EntityType;
    image?: string | null;
    title: string;
    slug: string;
    createdBy?: string | null;
    hashtags?: string | null;
    type?: string | null;
    order?: number | null;
    articleCategoryID: string;
    createdAt: string;
    updatedAt: string;
    category?: {
      __typename: "ArticleCategory";
      id: string;
      entity: EntityType;
      name: string;
      slug: string;
      order?: number | null;
      createdAt: string;
      updatedAt: string;
      articles?: {
        __typename: "ModelArticleConnection";
        items: Array<{
          __typename: "Article";
          id: string;
          active?: boolean | null;
          content?: string | null;
          description?: string | null;
          entity: EntityType;
          image?: string | null;
          title: string;
          slug: string;
          createdBy?: string | null;
          hashtags?: string | null;
          type?: string | null;
          order?: number | null;
          articleCategoryID: string;
          createdAt: string;
          updatedAt: string;
          category?: {
            __typename: "ArticleCategory";
            id: string;
            entity: EntityType;
            name: string;
            slug: string;
            order?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListArticlesQueryVariables = {
  slug?: string | null;
  filter?: ModelArticleFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: ModelSortDirection | null;
};

export type ListArticlesQuery = {
  listArticles?: {
    __typename: "ModelArticleConnection";
    items: Array<{
      __typename: "Article";
      id: string;
      active?: boolean | null;
      content?: string | null;
      description?: string | null;
      entity: EntityType;
      image?: string | null;
      title: string;
      slug: string;
      createdBy?: string | null;
      hashtags?: string | null;
      type?: string | null;
      order?: number | null;
      articleCategoryID: string;
      createdAt: string;
      updatedAt: string;
      category?: {
        __typename: "ArticleCategory";
        id: string;
        entity: EntityType;
        name: string;
        slug: string;
        order?: number | null;
        createdAt: string;
        updatedAt: string;
        articles?: {
          __typename: "ModelArticleConnection";
          items: Array<{
            __typename: "Article";
            id: string;
            active?: boolean | null;
            content?: string | null;
            description?: string | null;
            entity: EntityType;
            image?: string | null;
            title: string;
            slug: string;
            createdBy?: string | null;
            hashtags?: string | null;
            type?: string | null;
            order?: number | null;
            articleCategoryID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ArticlesByCreatedAtQueryVariables = {
  entity?: EntityType | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelArticleFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ArticlesByCreatedAtQuery = {
  articlesByCreatedAt?: {
    __typename: "ModelArticleConnection";
    items: Array<{
      __typename: "Article";
      id: string;
      active?: boolean | null;
      content?: string | null;
      description?: string | null;
      entity: EntityType;
      image?: string | null;
      title: string;
      slug: string;
      createdBy?: string | null;
      hashtags?: string | null;
      type?: string | null;
      order?: number | null;
      articleCategoryID: string;
      createdAt: string;
      updatedAt: string;
      category?: {
        __typename: "ArticleCategory";
        id: string;
        entity: EntityType;
        name: string;
        slug: string;
        order?: number | null;
        createdAt: string;
        updatedAt: string;
        articles?: {
          __typename: "ModelArticleConnection";
          items: Array<{
            __typename: "Article";
            id: string;
            active?: boolean | null;
            content?: string | null;
            description?: string | null;
            entity: EntityType;
            image?: string | null;
            title: string;
            slug: string;
            createdBy?: string | null;
            hashtags?: string | null;
            type?: string | null;
            order?: number | null;
            articleCategoryID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ArticlesByUpdatedAtQueryVariables = {
  entity?: EntityType | null;
  updatedAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelArticleFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ArticlesByUpdatedAtQuery = {
  articlesByUpdatedAt?: {
    __typename: "ModelArticleConnection";
    items: Array<{
      __typename: "Article";
      id: string;
      active?: boolean | null;
      content?: string | null;
      description?: string | null;
      entity: EntityType;
      image?: string | null;
      title: string;
      slug: string;
      createdBy?: string | null;
      hashtags?: string | null;
      type?: string | null;
      order?: number | null;
      articleCategoryID: string;
      createdAt: string;
      updatedAt: string;
      category?: {
        __typename: "ArticleCategory";
        id: string;
        entity: EntityType;
        name: string;
        slug: string;
        order?: number | null;
        createdAt: string;
        updatedAt: string;
        articles?: {
          __typename: "ModelArticleConnection";
          items: Array<{
            __typename: "Article";
            id: string;
            active?: boolean | null;
            content?: string | null;
            description?: string | null;
            entity: EntityType;
            image?: string | null;
            title: string;
            slug: string;
            createdBy?: string | null;
            hashtags?: string | null;
            type?: string | null;
            order?: number | null;
            articleCategoryID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ArticlesByTypeByCreatedAtQueryVariables = {
  type?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelArticleFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ArticlesByTypeByCreatedAtQuery = {
  articlesByTypeByCreatedAt?: {
    __typename: "ModelArticleConnection";
    items: Array<{
      __typename: "Article";
      id: string;
      active?: boolean | null;
      content?: string | null;
      description?: string | null;
      entity: EntityType;
      image?: string | null;
      title: string;
      slug: string;
      createdBy?: string | null;
      hashtags?: string | null;
      type?: string | null;
      order?: number | null;
      articleCategoryID: string;
      createdAt: string;
      updatedAt: string;
      category?: {
        __typename: "ArticleCategory";
        id: string;
        entity: EntityType;
        name: string;
        slug: string;
        order?: number | null;
        createdAt: string;
        updatedAt: string;
        articles?: {
          __typename: "ModelArticleConnection";
          items: Array<{
            __typename: "Article";
            id: string;
            active?: boolean | null;
            content?: string | null;
            description?: string | null;
            entity: EntityType;
            image?: string | null;
            title: string;
            slug: string;
            createdBy?: string | null;
            hashtags?: string | null;
            type?: string | null;
            order?: number | null;
            articleCategoryID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ArticlesByTypeByUpdatedAtQueryVariables = {
  type?: string | null;
  updatedAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelArticleFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ArticlesByTypeByUpdatedAtQuery = {
  articlesByTypeByUpdatedAt?: {
    __typename: "ModelArticleConnection";
    items: Array<{
      __typename: "Article";
      id: string;
      active?: boolean | null;
      content?: string | null;
      description?: string | null;
      entity: EntityType;
      image?: string | null;
      title: string;
      slug: string;
      createdBy?: string | null;
      hashtags?: string | null;
      type?: string | null;
      order?: number | null;
      articleCategoryID: string;
      createdAt: string;
      updatedAt: string;
      category?: {
        __typename: "ArticleCategory";
        id: string;
        entity: EntityType;
        name: string;
        slug: string;
        order?: number | null;
        createdAt: string;
        updatedAt: string;
        articles?: {
          __typename: "ModelArticleConnection";
          items: Array<{
            __typename: "Article";
            id: string;
            active?: boolean | null;
            content?: string | null;
            description?: string | null;
            entity: EntityType;
            image?: string | null;
            title: string;
            slug: string;
            createdBy?: string | null;
            hashtags?: string | null;
            type?: string | null;
            order?: number | null;
            articleCategoryID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ArticlesByTypeQueryVariables = {
  type?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelArticleFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ArticlesByTypeQuery = {
  articlesByType?: {
    __typename: "ModelArticleConnection";
    items: Array<{
      __typename: "Article";
      id: string;
      active?: boolean | null;
      content?: string | null;
      description?: string | null;
      entity: EntityType;
      image?: string | null;
      title: string;
      slug: string;
      createdBy?: string | null;
      hashtags?: string | null;
      type?: string | null;
      order?: number | null;
      articleCategoryID: string;
      createdAt: string;
      updatedAt: string;
      category?: {
        __typename: "ArticleCategory";
        id: string;
        entity: EntityType;
        name: string;
        slug: string;
        order?: number | null;
        createdAt: string;
        updatedAt: string;
        articles?: {
          __typename: "ModelArticleConnection";
          items: Array<{
            __typename: "Article";
            id: string;
            active?: boolean | null;
            content?: string | null;
            description?: string | null;
            entity: EntityType;
            image?: string | null;
            title: string;
            slug: string;
            createdBy?: string | null;
            hashtags?: string | null;
            type?: string | null;
            order?: number | null;
            articleCategoryID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ArticlesByCategorySlugQueryVariables = {
  articleCategoryID?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelArticleFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ArticlesByCategorySlugQuery = {
  articlesByCategorySlug?: {
    __typename: "ModelArticleConnection";
    items: Array<{
      __typename: "Article";
      id: string;
      active?: boolean | null;
      content?: string | null;
      description?: string | null;
      entity: EntityType;
      image?: string | null;
      title: string;
      slug: string;
      createdBy?: string | null;
      hashtags?: string | null;
      type?: string | null;
      order?: number | null;
      articleCategoryID: string;
      createdAt: string;
      updatedAt: string;
      category?: {
        __typename: "ArticleCategory";
        id: string;
        entity: EntityType;
        name: string;
        slug: string;
        order?: number | null;
        createdAt: string;
        updatedAt: string;
        articles?: {
          __typename: "ModelArticleConnection";
          items: Array<{
            __typename: "Article";
            id: string;
            active?: boolean | null;
            content?: string | null;
            description?: string | null;
            entity: EntityType;
            image?: string | null;
            title: string;
            slug: string;
            createdBy?: string | null;
            hashtags?: string | null;
            type?: string | null;
            order?: number | null;
            articleCategoryID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SearchArticlesQueryVariables = {
  filter?: SearchableArticleFilterInput | null;
  sort?: SearchableArticleSortInput | null;
  limit?: number | null;
  nextToken?: string | null;
  from?: number | null;
};

export type SearchArticlesQuery = {
  searchArticles?: {
    __typename: "SearchableArticleConnection";
    items: Array<{
      __typename: "Article";
      id: string;
      active?: boolean | null;
      content?: string | null;
      description?: string | null;
      entity: EntityType;
      image?: string | null;
      title: string;
      slug: string;
      createdBy?: string | null;
      hashtags?: string | null;
      type?: string | null;
      order?: number | null;
      articleCategoryID: string;
      createdAt: string;
      updatedAt: string;
      category?: {
        __typename: "ArticleCategory";
        id: string;
        entity: EntityType;
        name: string;
        slug: string;
        order?: number | null;
        createdAt: string;
        updatedAt: string;
        articles?: {
          __typename: "ModelArticleConnection";
          items: Array<{
            __typename: "Article";
            id: string;
            active?: boolean | null;
            content?: string | null;
            description?: string | null;
            entity: EntityType;
            image?: string | null;
            title: string;
            slug: string;
            createdBy?: string | null;
            hashtags?: string | null;
            type?: string | null;
            order?: number | null;
            articleCategoryID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    total?: number | null;
  } | null;
};

export type GetAdvertisementTypeQueryVariables = {
  id: string;
};

export type GetAdvertisementTypeQuery = {
  getAdvertisementType?: {
    __typename: "AdvertisementType";
    id: string;
    active: boolean;
    entity: EntityType;
    name: string;
    jobDescription?: string | null;
    order: number;
    approvalRequired: string;
    components?: string | null;
    createdAt: string;
    updatedAt: string;
    advertisements?: {
      __typename: "ModelAdvertisementSettingConnection";
      items: Array<{
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type ListAdvertisementTypesQueryVariables = {
  filter?: ModelAdvertisementTypeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListAdvertisementTypesQuery = {
  listAdvertisementTypes?: {
    __typename: "ModelAdvertisementTypeConnection";
    items: Array<{
      __typename: "AdvertisementType";
      id: string;
      active: boolean;
      entity: EntityType;
      name: string;
      jobDescription?: string | null;
      order: number;
      approvalRequired: string;
      components?: string | null;
      createdAt: string;
      updatedAt: string;
      advertisements?: {
        __typename: "ModelAdvertisementSettingConnection";
        items: Array<{
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AdvertisementTypesByCreatedAtQueryVariables = {
  entity?: EntityType | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelAdvertisementTypeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AdvertisementTypesByCreatedAtQuery = {
  advertisementTypesByCreatedAt?: {
    __typename: "ModelAdvertisementTypeConnection";
    items: Array<{
      __typename: "AdvertisementType";
      id: string;
      active: boolean;
      entity: EntityType;
      name: string;
      jobDescription?: string | null;
      order: number;
      approvalRequired: string;
      components?: string | null;
      createdAt: string;
      updatedAt: string;
      advertisements?: {
        __typename: "ModelAdvertisementSettingConnection";
        items: Array<{
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AdvertisementTypesByUpdatedAtQueryVariables = {
  entity?: EntityType | null;
  updatedAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelAdvertisementTypeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AdvertisementTypesByUpdatedAtQuery = {
  advertisementTypesByUpdatedAt?: {
    __typename: "ModelAdvertisementTypeConnection";
    items: Array<{
      __typename: "AdvertisementType";
      id: string;
      active: boolean;
      entity: EntityType;
      name: string;
      jobDescription?: string | null;
      order: number;
      approvalRequired: string;
      components?: string | null;
      createdAt: string;
      updatedAt: string;
      advertisements?: {
        __typename: "ModelAdvertisementSettingConnection";
        items: Array<{
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AdvertisementTypesByOrderQueryVariables = {
  entity?: EntityType | null;
  order?: ModelIntKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelAdvertisementTypeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AdvertisementTypesByOrderQuery = {
  advertisementTypesByOrder?: {
    __typename: "ModelAdvertisementTypeConnection";
    items: Array<{
      __typename: "AdvertisementType";
      id: string;
      active: boolean;
      entity: EntityType;
      name: string;
      jobDescription?: string | null;
      order: number;
      approvalRequired: string;
      components?: string | null;
      createdAt: string;
      updatedAt: string;
      advertisements?: {
        __typename: "ModelAdvertisementSettingConnection";
        items: Array<{
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetServiceSettingQueryVariables = {
  id: string;
};

export type GetServiceSettingQuery = {
  getServiceSetting?: {
    __typename: "ServiceSetting";
    id: string;
    active: boolean;
    creditsCount: number;
    entity: EntityType;
    name: string;
    public?: PublicAdvertisementSetting | null;
    appliedTo: string;
    serviceType: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListServiceSettingsQueryVariables = {
  filter?: ModelServiceSettingFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListServiceSettingsQuery = {
  listServiceSettings?: {
    __typename: "ModelServiceSettingConnection";
    items: Array<{
      __typename: "ServiceSetting";
      id: string;
      active: boolean;
      creditsCount: number;
      entity: EntityType;
      name: string;
      public?: PublicAdvertisementSetting | null;
      appliedTo: string;
      serviceType: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ServiceByNameQueryVariables = {
  name?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelServiceSettingFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ServiceByNameQuery = {
  serviceByName?: {
    __typename: "ModelServiceSettingConnection";
    items: Array<{
      __typename: "ServiceSetting";
      id: string;
      active: boolean;
      creditsCount: number;
      entity: EntityType;
      name: string;
      public?: PublicAdvertisementSetting | null;
      appliedTo: string;
      serviceType: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ServiceSettingsByCreatedAtQueryVariables = {
  entity?: EntityType | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelServiceSettingFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ServiceSettingsByCreatedAtQuery = {
  serviceSettingsByCreatedAt?: {
    __typename: "ModelServiceSettingConnection";
    items: Array<{
      __typename: "ServiceSetting";
      id: string;
      active: boolean;
      creditsCount: number;
      entity: EntityType;
      name: string;
      public?: PublicAdvertisementSetting | null;
      appliedTo: string;
      serviceType: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ServiceSettingsByUpdatedAtQueryVariables = {
  entity?: EntityType | null;
  updatedAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelServiceSettingFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ServiceSettingsByUpdatedAtQuery = {
  serviceSettingsByUpdatedAt?: {
    __typename: "ModelServiceSettingConnection";
    items: Array<{
      __typename: "ServiceSetting";
      id: string;
      active: boolean;
      creditsCount: number;
      entity: EntityType;
      name: string;
      public?: PublicAdvertisementSetting | null;
      appliedTo: string;
      serviceType: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetAdvertisementSettingQueryVariables = {
  id: string;
};

export type GetAdvertisementSettingQuery = {
  getAdvertisementSetting?: {
    __typename: "AdvertisementSetting";
    id: string;
    active: boolean;
    creditsCount: number;
    description?: string | null;
    entity: EntityType;
    internalName: string;
    name: string;
    numberOfDays: number;
    reactivationCreditsCount: number;
    order: number;
    appliedTo: string;
    public?: PublicAdvertisementSetting | null;
    advertisementTypeID: string;
    createdAt: string;
    updatedAt: string;
    advertisementType?: {
      __typename: "AdvertisementType";
      id: string;
      active: boolean;
      entity: EntityType;
      name: string;
      jobDescription?: string | null;
      order: number;
      approvalRequired: string;
      components?: string | null;
      createdAt: string;
      updatedAt: string;
      advertisements?: {
        __typename: "ModelAdvertisementSettingConnection";
        items: Array<{
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListAdvertisementSettingsQueryVariables = {
  filter?: ModelAdvertisementSettingFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListAdvertisementSettingsQuery = {
  listAdvertisementSettings?: {
    __typename: "ModelAdvertisementSettingConnection";
    items: Array<{
      __typename: "AdvertisementSetting";
      id: string;
      active: boolean;
      creditsCount: number;
      description?: string | null;
      entity: EntityType;
      internalName: string;
      name: string;
      numberOfDays: number;
      reactivationCreditsCount: number;
      order: number;
      appliedTo: string;
      public?: PublicAdvertisementSetting | null;
      advertisementTypeID: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AdvertisementSettingsByCreatedAtQueryVariables = {
  entity?: EntityType | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelAdvertisementSettingFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AdvertisementSettingsByCreatedAtQuery = {
  advertisementSettingsByCreatedAt?: {
    __typename: "ModelAdvertisementSettingConnection";
    items: Array<{
      __typename: "AdvertisementSetting";
      id: string;
      active: boolean;
      creditsCount: number;
      description?: string | null;
      entity: EntityType;
      internalName: string;
      name: string;
      numberOfDays: number;
      reactivationCreditsCount: number;
      order: number;
      appliedTo: string;
      public?: PublicAdvertisementSetting | null;
      advertisementTypeID: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AdvertisementSettingsByUpdatedAtQueryVariables = {
  entity?: EntityType | null;
  updatedAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelAdvertisementSettingFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AdvertisementSettingsByUpdatedAtQuery = {
  advertisementSettingsByUpdatedAt?: {
    __typename: "ModelAdvertisementSettingConnection";
    items: Array<{
      __typename: "AdvertisementSetting";
      id: string;
      active: boolean;
      creditsCount: number;
      description?: string | null;
      entity: EntityType;
      internalName: string;
      name: string;
      numberOfDays: number;
      reactivationCreditsCount: number;
      order: number;
      appliedTo: string;
      public?: PublicAdvertisementSetting | null;
      advertisementTypeID: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AdvertisementSettingsByPublicQueryVariables = {
  public?: PublicAdvertisementSetting | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelAdvertisementSettingFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AdvertisementSettingsByPublicQuery = {
  advertisementSettingsByPublic?: {
    __typename: "ModelAdvertisementSettingConnection";
    items: Array<{
      __typename: "AdvertisementSetting";
      id: string;
      active: boolean;
      creditsCount: number;
      description?: string | null;
      entity: EntityType;
      internalName: string;
      name: string;
      numberOfDays: number;
      reactivationCreditsCount: number;
      order: number;
      appliedTo: string;
      public?: PublicAdvertisementSetting | null;
      advertisementTypeID: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AdvertisementSettingsByOrderQueryVariables = {
  entity?: EntityType | null;
  order?: ModelIntKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelAdvertisementSettingFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AdvertisementSettingsByOrderQuery = {
  advertisementSettingsByOrder?: {
    __typename: "ModelAdvertisementSettingConnection";
    items: Array<{
      __typename: "AdvertisementSetting";
      id: string;
      active: boolean;
      creditsCount: number;
      description?: string | null;
      entity: EntityType;
      internalName: string;
      name: string;
      numberOfDays: number;
      reactivationCreditsCount: number;
      order: number;
      appliedTo: string;
      public?: PublicAdvertisementSetting | null;
      advertisementTypeID: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetWorkerTypeQueryVariables = {
  id: string;
};

export type GetWorkerTypeQuery = {
  getWorkerType?: {
    __typename: "WorkerType";
    id: string;
    name: string;
    country: Country;
    level: number;
    order: number;
    createdAt: string;
    updatedAt: string;
    childEdges?: {
      __typename: "ModelWorkerTypeEdgeConnection";
      items: Array<{
        __typename: "WorkerTypeEdge";
        id: string;
        parentNodeId: string;
        childNodeId: string;
        country: Country;
        createdAt: string;
        updatedAt: string;
        parentNode?: {
          __typename: "WorkerType";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelWorkerTypeEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        childNode?: {
          __typename: "WorkerType";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelWorkerTypeEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type ListWorkerTypesQueryVariables = {
  filter?: ModelWorkerTypeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListWorkerTypesQuery = {
  listWorkerTypes?: {
    __typename: "ModelWorkerTypeConnection";
    items: Array<{
      __typename: "WorkerType";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelWorkerTypeEdgeConnection";
        items: Array<{
          __typename: "WorkerTypeEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type WorkerTypesByCountryAndLevelQueryVariables = {
  country?: Country | null;
  level?: ModelIntKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelWorkerTypeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type WorkerTypesByCountryAndLevelQuery = {
  workerTypesByCountryAndLevel?: {
    __typename: "ModelWorkerTypeConnection";
    items: Array<{
      __typename: "WorkerType";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelWorkerTypeEdgeConnection";
        items: Array<{
          __typename: "WorkerTypeEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type WorkerTypesByCountryQueryVariables = {
  country?: Country | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelWorkerTypeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type WorkerTypesByCountryQuery = {
  workerTypesByCountry?: {
    __typename: "ModelWorkerTypeConnection";
    items: Array<{
      __typename: "WorkerType";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelWorkerTypeEdgeConnection";
        items: Array<{
          __typename: "WorkerTypeEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type WorkerTypesByLevelQueryVariables = {
  level?: number | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelWorkerTypeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type WorkerTypesByLevelQuery = {
  workerTypesByLevel?: {
    __typename: "ModelWorkerTypeConnection";
    items: Array<{
      __typename: "WorkerType";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelWorkerTypeEdgeConnection";
        items: Array<{
          __typename: "WorkerTypeEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetWorkerTypeEdgeQueryVariables = {
  id: string;
};

export type GetWorkerTypeEdgeQuery = {
  getWorkerTypeEdge?: {
    __typename: "WorkerTypeEdge";
    id: string;
    parentNodeId: string;
    childNodeId: string;
    country: Country;
    createdAt: string;
    updatedAt: string;
    parentNode?: {
      __typename: "WorkerType";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelWorkerTypeEdgeConnection";
        items: Array<{
          __typename: "WorkerTypeEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    childNode?: {
      __typename: "WorkerType";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelWorkerTypeEdgeConnection";
        items: Array<{
          __typename: "WorkerTypeEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListWorkerTypeEdgesQueryVariables = {
  filter?: ModelWorkerTypeEdgeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListWorkerTypeEdgesQuery = {
  listWorkerTypeEdges?: {
    __typename: "ModelWorkerTypeEdgeConnection";
    items: Array<{
      __typename: "WorkerTypeEdge";
      id: string;
      parentNodeId: string;
      childNodeId: string;
      country: Country;
      createdAt: string;
      updatedAt: string;
      parentNode?: {
        __typename: "WorkerType";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelWorkerTypeEdgeConnection";
          items: Array<{
            __typename: "WorkerTypeEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      childNode?: {
        __typename: "WorkerType";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelWorkerTypeEdgeConnection";
          items: Array<{
            __typename: "WorkerTypeEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type WorkerTypesEdgesByParentNodeIdQueryVariables = {
  parentNodeId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelWorkerTypeEdgeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type WorkerTypesEdgesByParentNodeIdQuery = {
  workerTypesEdgesByParentNodeId?: {
    __typename: "ModelWorkerTypeEdgeConnection";
    items: Array<{
      __typename: "WorkerTypeEdge";
      id: string;
      parentNodeId: string;
      childNodeId: string;
      country: Country;
      createdAt: string;
      updatedAt: string;
      parentNode?: {
        __typename: "WorkerType";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelWorkerTypeEdgeConnection";
          items: Array<{
            __typename: "WorkerTypeEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      childNode?: {
        __typename: "WorkerType";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelWorkerTypeEdgeConnection";
          items: Array<{
            __typename: "WorkerTypeEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type WorkerTypeEdgesByChildNodeIdQueryVariables = {
  childNodeId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelWorkerTypeEdgeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type WorkerTypeEdgesByChildNodeIdQuery = {
  workerTypeEdgesByChildNodeId?: {
    __typename: "ModelWorkerTypeEdgeConnection";
    items: Array<{
      __typename: "WorkerTypeEdge";
      id: string;
      parentNodeId: string;
      childNodeId: string;
      country: Country;
      createdAt: string;
      updatedAt: string;
      parentNode?: {
        __typename: "WorkerType";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelWorkerTypeEdgeConnection";
          items: Array<{
            __typename: "WorkerTypeEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      childNode?: {
        __typename: "WorkerType";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelWorkerTypeEdgeConnection";
          items: Array<{
            __typename: "WorkerTypeEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type WorkerTypeEdgesByCountryQueryVariables = {
  country?: Country | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelWorkerTypeEdgeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type WorkerTypeEdgesByCountryQuery = {
  workerTypeEdgesByCountry?: {
    __typename: "ModelWorkerTypeEdgeConnection";
    items: Array<{
      __typename: "WorkerTypeEdge";
      id: string;
      parentNodeId: string;
      childNodeId: string;
      country: Country;
      createdAt: string;
      updatedAt: string;
      parentNode?: {
        __typename: "WorkerType";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelWorkerTypeEdgeConnection";
          items: Array<{
            __typename: "WorkerTypeEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      childNode?: {
        __typename: "WorkerType";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelWorkerTypeEdgeConnection";
          items: Array<{
            __typename: "WorkerTypeEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSuitableForQueryVariables = {
  id: string;
};

export type GetSuitableForQuery = {
  getSuitableFor?: {
    __typename: "SuitableFor";
    id: string;
    name: string;
    country: Country;
    level: number;
    order: number;
    createdAt: string;
    updatedAt: string;
    childEdges?: {
      __typename: "ModelSuitableForEdgeConnection";
      items: Array<{
        __typename: "SuitableForEdge";
        id: string;
        parentNodeId: string;
        childNodeId: string;
        country: Country;
        createdAt: string;
        updatedAt: string;
        parentNode?: {
          __typename: "SuitableFor";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelSuitableForEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        childNode?: {
          __typename: "SuitableFor";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelSuitableForEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type ListSuitableForsQueryVariables = {
  filter?: ModelSuitableForFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSuitableForsQuery = {
  listSuitableFors?: {
    __typename: "ModelSuitableForConnection";
    items: Array<{
      __typename: "SuitableFor";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelSuitableForEdgeConnection";
        items: Array<{
          __typename: "SuitableForEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SuitableForByCountryAndLevelQueryVariables = {
  country?: Country | null;
  level?: ModelIntKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSuitableForFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SuitableForByCountryAndLevelQuery = {
  suitableForByCountryAndLevel?: {
    __typename: "ModelSuitableForConnection";
    items: Array<{
      __typename: "SuitableFor";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelSuitableForEdgeConnection";
        items: Array<{
          __typename: "SuitableForEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SuitableForByCountryQueryVariables = {
  country?: Country | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSuitableForFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SuitableForByCountryQuery = {
  suitableForByCountry?: {
    __typename: "ModelSuitableForConnection";
    items: Array<{
      __typename: "SuitableFor";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelSuitableForEdgeConnection";
        items: Array<{
          __typename: "SuitableForEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SuitableForByLevelQueryVariables = {
  level?: number | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSuitableForFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SuitableForByLevelQuery = {
  suitableForByLevel?: {
    __typename: "ModelSuitableForConnection";
    items: Array<{
      __typename: "SuitableFor";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelSuitableForEdgeConnection";
        items: Array<{
          __typename: "SuitableForEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSuitableForEdgeQueryVariables = {
  id: string;
};

export type GetSuitableForEdgeQuery = {
  getSuitableForEdge?: {
    __typename: "SuitableForEdge";
    id: string;
    parentNodeId: string;
    childNodeId: string;
    country: Country;
    createdAt: string;
    updatedAt: string;
    parentNode?: {
      __typename: "SuitableFor";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelSuitableForEdgeConnection";
        items: Array<{
          __typename: "SuitableForEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    childNode?: {
      __typename: "SuitableFor";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelSuitableForEdgeConnection";
        items: Array<{
          __typename: "SuitableForEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListSuitableForEdgesQueryVariables = {
  filter?: ModelSuitableForEdgeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSuitableForEdgesQuery = {
  listSuitableForEdges?: {
    __typename: "ModelSuitableForEdgeConnection";
    items: Array<{
      __typename: "SuitableForEdge";
      id: string;
      parentNodeId: string;
      childNodeId: string;
      country: Country;
      createdAt: string;
      updatedAt: string;
      parentNode?: {
        __typename: "SuitableFor";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelSuitableForEdgeConnection";
          items: Array<{
            __typename: "SuitableForEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      childNode?: {
        __typename: "SuitableFor";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelSuitableForEdgeConnection";
          items: Array<{
            __typename: "SuitableForEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SuitableForEdgesByParentNodeIdQueryVariables = {
  parentNodeId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSuitableForEdgeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SuitableForEdgesByParentNodeIdQuery = {
  suitableForEdgesByParentNodeId?: {
    __typename: "ModelSuitableForEdgeConnection";
    items: Array<{
      __typename: "SuitableForEdge";
      id: string;
      parentNodeId: string;
      childNodeId: string;
      country: Country;
      createdAt: string;
      updatedAt: string;
      parentNode?: {
        __typename: "SuitableFor";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelSuitableForEdgeConnection";
          items: Array<{
            __typename: "SuitableForEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      childNode?: {
        __typename: "SuitableFor";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelSuitableForEdgeConnection";
          items: Array<{
            __typename: "SuitableForEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SuitableForEdgesByChildNodeIdQueryVariables = {
  childNodeId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSuitableForEdgeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SuitableForEdgesByChildNodeIdQuery = {
  suitableForEdgesByChildNodeId?: {
    __typename: "ModelSuitableForEdgeConnection";
    items: Array<{
      __typename: "SuitableForEdge";
      id: string;
      parentNodeId: string;
      childNodeId: string;
      country: Country;
      createdAt: string;
      updatedAt: string;
      parentNode?: {
        __typename: "SuitableFor";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelSuitableForEdgeConnection";
          items: Array<{
            __typename: "SuitableForEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      childNode?: {
        __typename: "SuitableFor";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelSuitableForEdgeConnection";
          items: Array<{
            __typename: "SuitableForEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SuitableForEdgesByCountryQueryVariables = {
  country?: Country | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSuitableForEdgeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SuitableForEdgesByCountryQuery = {
  suitableForEdgesByCountry?: {
    __typename: "ModelSuitableForEdgeConnection";
    items: Array<{
      __typename: "SuitableForEdge";
      id: string;
      parentNodeId: string;
      childNodeId: string;
      country: Country;
      createdAt: string;
      updatedAt: string;
      parentNode?: {
        __typename: "SuitableFor";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelSuitableForEdgeConnection";
          items: Array<{
            __typename: "SuitableForEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      childNode?: {
        __typename: "SuitableFor";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelSuitableForEdgeConnection";
          items: Array<{
            __typename: "SuitableForEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetEmployerQueryVariables = {
  id: string;
};

export type GetEmployerQuery = {
  getEmployer?: {
    __typename: "Employer";
    id: string;
    name: string;
    country: Country;
    level: number;
    order: number;
    createdAt: string;
    updatedAt: string;
    childEdges?: {
      __typename: "ModelEmployerEdgeConnection";
      items: Array<{
        __typename: "EmployerEdge";
        id: string;
        parentNodeId: string;
        childNodeId: string;
        country: Country;
        createdAt: string;
        updatedAt: string;
        parentNode?: {
          __typename: "Employer";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelEmployerEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        childNode?: {
          __typename: "Employer";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelEmployerEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type ListEmployersQueryVariables = {
  filter?: ModelEmployerFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListEmployersQuery = {
  listEmployers?: {
    __typename: "ModelEmployerConnection";
    items: Array<{
      __typename: "Employer";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelEmployerEdgeConnection";
        items: Array<{
          __typename: "EmployerEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type EmployersByCountryAndLevelQueryVariables = {
  country?: Country | null;
  level?: ModelIntKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelEmployerFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type EmployersByCountryAndLevelQuery = {
  employersByCountryAndLevel?: {
    __typename: "ModelEmployerConnection";
    items: Array<{
      __typename: "Employer";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelEmployerEdgeConnection";
        items: Array<{
          __typename: "EmployerEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type EmployersByCountryQueryVariables = {
  country?: Country | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelEmployerFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type EmployersByCountryQuery = {
  employersByCountry?: {
    __typename: "ModelEmployerConnection";
    items: Array<{
      __typename: "Employer";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelEmployerEdgeConnection";
        items: Array<{
          __typename: "EmployerEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type EmployersByLevelQueryVariables = {
  level?: number | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelEmployerFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type EmployersByLevelQuery = {
  employersByLevel?: {
    __typename: "ModelEmployerConnection";
    items: Array<{
      __typename: "Employer";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelEmployerEdgeConnection";
        items: Array<{
          __typename: "EmployerEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetEmployerEdgeQueryVariables = {
  id: string;
};

export type GetEmployerEdgeQuery = {
  getEmployerEdge?: {
    __typename: "EmployerEdge";
    id: string;
    parentNodeId: string;
    childNodeId: string;
    country: Country;
    createdAt: string;
    updatedAt: string;
    parentNode?: {
      __typename: "Employer";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelEmployerEdgeConnection";
        items: Array<{
          __typename: "EmployerEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    childNode?: {
      __typename: "Employer";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelEmployerEdgeConnection";
        items: Array<{
          __typename: "EmployerEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListEmployerEdgesQueryVariables = {
  filter?: ModelEmployerEdgeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListEmployerEdgesQuery = {
  listEmployerEdges?: {
    __typename: "ModelEmployerEdgeConnection";
    items: Array<{
      __typename: "EmployerEdge";
      id: string;
      parentNodeId: string;
      childNodeId: string;
      country: Country;
      createdAt: string;
      updatedAt: string;
      parentNode?: {
        __typename: "Employer";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelEmployerEdgeConnection";
          items: Array<{
            __typename: "EmployerEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      childNode?: {
        __typename: "Employer";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelEmployerEdgeConnection";
          items: Array<{
            __typename: "EmployerEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type EmployerEdgesByParentNodeIdQueryVariables = {
  parentNodeId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelEmployerEdgeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type EmployerEdgesByParentNodeIdQuery = {
  employerEdgesByParentNodeId?: {
    __typename: "ModelEmployerEdgeConnection";
    items: Array<{
      __typename: "EmployerEdge";
      id: string;
      parentNodeId: string;
      childNodeId: string;
      country: Country;
      createdAt: string;
      updatedAt: string;
      parentNode?: {
        __typename: "Employer";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelEmployerEdgeConnection";
          items: Array<{
            __typename: "EmployerEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      childNode?: {
        __typename: "Employer";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelEmployerEdgeConnection";
          items: Array<{
            __typename: "EmployerEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type EmployerEdgesByChildNodeIdQueryVariables = {
  childNodeId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelEmployerEdgeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type EmployerEdgesByChildNodeIdQuery = {
  employerEdgesByChildNodeId?: {
    __typename: "ModelEmployerEdgeConnection";
    items: Array<{
      __typename: "EmployerEdge";
      id: string;
      parentNodeId: string;
      childNodeId: string;
      country: Country;
      createdAt: string;
      updatedAt: string;
      parentNode?: {
        __typename: "Employer";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelEmployerEdgeConnection";
          items: Array<{
            __typename: "EmployerEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      childNode?: {
        __typename: "Employer";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelEmployerEdgeConnection";
          items: Array<{
            __typename: "EmployerEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type EmployerEdgesByCountryQueryVariables = {
  country?: Country | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelEmployerEdgeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type EmployerEdgesByCountryQuery = {
  employerEdgesByCountry?: {
    __typename: "ModelEmployerEdgeConnection";
    items: Array<{
      __typename: "EmployerEdge";
      id: string;
      parentNodeId: string;
      childNodeId: string;
      country: Country;
      createdAt: string;
      updatedAt: string;
      parentNode?: {
        __typename: "Employer";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelEmployerEdgeConnection";
          items: Array<{
            __typename: "EmployerEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      childNode?: {
        __typename: "Employer";
        id: string;
        name: string;
        country: Country;
        level: number;
        order: number;
        createdAt: string;
        updatedAt: string;
        childEdges?: {
          __typename: "ModelEmployerEdgeConnection";
          items: Array<{
            __typename: "EmployerEdge";
            id: string;
            parentNodeId: string;
            childNodeId: string;
            country: Country;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetProfileQueryVariables = {
  id: string;
};

export type GetProfileQuery = {
  getProfile?: {
    __typename: "Profile";
    id: string;
    credits?: number | null;
    sub?: string | null;
    email: string;
    entity?: EntityType | null;
    entityState?: string | null;
    profileImage?: string | null;
    firstDegree?: string | null;
    forename?: string | null;
    surname?: string | null;
    secondDegree?: string | null;
    aboutMe?: string | null;
    educations?: string | null;
    workExperiences?: string | null;
    publications?: string | null;
    conferences?: string | null;
    certificates?: string | null;
    languages?: string | null;
    otherInformation?: string | null;
    qualifications?: string | null;
    name?: string | null;
    crn?: string | null;
    vatRegNumber?: string | null;
    street?: string | null;
    buildingIdentificationNumber?: string | null;
    municipality?: string | null;
    postCode?: string | null;
    language?: string | null;
    phone?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    messages?: {
      __typename: "ModelMessageInboxConnection";
      items: Array<{
        __typename: "MessageInbox";
        id: string;
        name: string;
        email: string;
        phoneNumber?: string | null;
        message: string;
        file?: string | null;
        state?: MessageState | null;
        createdAt: string;
        updatedAt: string;
        profileID: string;
        advertisementID: string;
        profile?: {
          __typename: "Profile";
          id: string;
          credits?: number | null;
          sub?: string | null;
          email: string;
          entity?: EntityType | null;
          entityState?: string | null;
          profileImage?: string | null;
          firstDegree?: string | null;
          forename?: string | null;
          surname?: string | null;
          secondDegree?: string | null;
          aboutMe?: string | null;
          educations?: string | null;
          workExperiences?: string | null;
          publications?: string | null;
          conferences?: string | null;
          certificates?: string | null;
          languages?: string | null;
          otherInformation?: string | null;
          qualifications?: string | null;
          name?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          phone?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelMessageInboxConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        advertisement?: {
          __typename: "Advertisement";
          id: string;
          friendlyId?: number | null;
          entity: EntityType;
          active?: Active | null;
          state?: string | null;
          stateDescription?: string | null;
          paymentState?: string | null;
          jobPosition: string;
          jobShortDescription: string;
          jobDescription?: string | null;
          place: string;
          salaryFrom?: number | null;
          salaryTo?: number | null;
          salarySpecifiedInText?: boolean | null;
          reactivationCount?: number | null;
          currentReactivationCount?: number | null;
          automaticReactivation?: boolean | null;
          leadingPosition?: boolean | null;
          residentialPlace?: boolean | null;
          typeOfCooperation?: string | null;
          jobType?: string | null;
          financialConditions?: string | null;
          typeOfInternship?: string | null;
          contactFirstDegree?: string | null;
          contactForename?: string | null;
          contactSurname?: string | null;
          contactSecondDegree?: string | null;
          contactEmail?: string | null;
          contactPhoneNumber?: string | null;
          hideContactInformation?: boolean | null;
          workType?: string | null;
          suitableFor?: string | null;
          workTypeStringified?: string | null;
          suitableForStringified?: string | null;
          employmentTypesStringified?: string | null;
          employmentTypes?: string | null;
          paymentType?: PaymentType | null;
          advertisementSettingID: string;
          advertisementTypeID?: string | null;
          companyID?: string | null;
          profileID?: string | null;
          expirationDate: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          advertisementSetting?: {
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
          analytics?: {
            __typename: "ModelAdvertisementAnalyticConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type ListProfilesQueryVariables = {
  filter?: ModelProfileFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListProfilesQuery = {
  listProfiles?: {
    __typename: "ModelProfileConnection";
    items: Array<{
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ProfileBySubQueryVariables = {
  sub?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelProfileFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ProfileBySubQuery = {
  profileBySub?: {
    __typename: "ModelProfileConnection";
    items: Array<{
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SearchProfilesQueryVariables = {
  filter?: SearchableProfileFilterInput | null;
  sort?: SearchableProfileSortInput | null;
  limit?: number | null;
  nextToken?: string | null;
  from?: number | null;
};

export type SearchProfilesQuery = {
  searchProfiles?: {
    __typename: "SearchableProfileConnection";
    items: Array<{
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    total?: number | null;
  } | null;
};

export type GetMessageInboxQueryVariables = {
  id: string;
};

export type GetMessageInboxQuery = {
  getMessageInbox?: {
    __typename: "MessageInbox";
    id: string;
    name: string;
    email: string;
    phoneNumber?: string | null;
    message: string;
    file?: string | null;
    state?: MessageState | null;
    createdAt: string;
    updatedAt: string;
    profileID: string;
    advertisementID: string;
    profile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    advertisement?: {
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListMessageInboxsQueryVariables = {
  filter?: ModelMessageInboxFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListMessageInboxsQuery = {
  listMessageInboxs?: {
    __typename: "ModelMessageInboxConnection";
    items: Array<{
      __typename: "MessageInbox";
      id: string;
      name: string;
      email: string;
      phoneNumber?: string | null;
      message: string;
      file?: string | null;
      state?: MessageState | null;
      createdAt: string;
      updatedAt: string;
      profileID: string;
      advertisementID: string;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisement?: {
        __typename: "Advertisement";
        id: string;
        friendlyId?: number | null;
        entity: EntityType;
        active?: Active | null;
        state?: string | null;
        stateDescription?: string | null;
        paymentState?: string | null;
        jobPosition: string;
        jobShortDescription: string;
        jobDescription?: string | null;
        place: string;
        salaryFrom?: number | null;
        salaryTo?: number | null;
        salarySpecifiedInText?: boolean | null;
        reactivationCount?: number | null;
        currentReactivationCount?: number | null;
        automaticReactivation?: boolean | null;
        leadingPosition?: boolean | null;
        residentialPlace?: boolean | null;
        typeOfCooperation?: string | null;
        jobType?: string | null;
        financialConditions?: string | null;
        typeOfInternship?: string | null;
        contactFirstDegree?: string | null;
        contactForename?: string | null;
        contactSurname?: string | null;
        contactSecondDegree?: string | null;
        contactEmail?: string | null;
        contactPhoneNumber?: string | null;
        hideContactInformation?: boolean | null;
        workType?: string | null;
        suitableFor?: string | null;
        workTypeStringified?: string | null;
        suitableForStringified?: string | null;
        employmentTypesStringified?: string | null;
        employmentTypes?: string | null;
        paymentType?: PaymentType | null;
        advertisementSettingID: string;
        advertisementTypeID?: string | null;
        companyID?: string | null;
        profileID?: string | null;
        expirationDate: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        advertisementSetting?: {
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null;
        profile?: {
          __typename: "Profile";
          id: string;
          credits?: number | null;
          sub?: string | null;
          email: string;
          entity?: EntityType | null;
          entityState?: string | null;
          profileImage?: string | null;
          firstDegree?: string | null;
          forename?: string | null;
          surname?: string | null;
          secondDegree?: string | null;
          aboutMe?: string | null;
          educations?: string | null;
          workExperiences?: string | null;
          publications?: string | null;
          conferences?: string | null;
          certificates?: string | null;
          languages?: string | null;
          otherInformation?: string | null;
          qualifications?: string | null;
          name?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          phone?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelMessageInboxConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        company?: {
          __typename: "Company";
          id: string;
          entity: EntityType;
          entityState?: string | null;
          credits?: number | null;
          name: string;
          logo?: string | null;
          forename?: string | null;
          surname?: string | null;
          address?: string | null;
          phone?: string | null;
          email?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          employerTaxonomy?: string | null;
          profileImage?: string | null;
          qualification?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelCompanyMessageInboxConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelCompanyUserConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        owner?: string | null;
        analytics?: {
          __typename: "ModelAdvertisementAnalyticConnection";
          items: Array<{
            __typename: "AdvertisementAnalytic";
            id: string;
            name?: string | null;
            info?: string | null;
            advertisementID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type MessagesByProfileQueryVariables = {
  profileID?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelMessageInboxFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type MessagesByProfileQuery = {
  messagesByProfile?: {
    __typename: "ModelMessageInboxConnection";
    items: Array<{
      __typename: "MessageInbox";
      id: string;
      name: string;
      email: string;
      phoneNumber?: string | null;
      message: string;
      file?: string | null;
      state?: MessageState | null;
      createdAt: string;
      updatedAt: string;
      profileID: string;
      advertisementID: string;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisement?: {
        __typename: "Advertisement";
        id: string;
        friendlyId?: number | null;
        entity: EntityType;
        active?: Active | null;
        state?: string | null;
        stateDescription?: string | null;
        paymentState?: string | null;
        jobPosition: string;
        jobShortDescription: string;
        jobDescription?: string | null;
        place: string;
        salaryFrom?: number | null;
        salaryTo?: number | null;
        salarySpecifiedInText?: boolean | null;
        reactivationCount?: number | null;
        currentReactivationCount?: number | null;
        automaticReactivation?: boolean | null;
        leadingPosition?: boolean | null;
        residentialPlace?: boolean | null;
        typeOfCooperation?: string | null;
        jobType?: string | null;
        financialConditions?: string | null;
        typeOfInternship?: string | null;
        contactFirstDegree?: string | null;
        contactForename?: string | null;
        contactSurname?: string | null;
        contactSecondDegree?: string | null;
        contactEmail?: string | null;
        contactPhoneNumber?: string | null;
        hideContactInformation?: boolean | null;
        workType?: string | null;
        suitableFor?: string | null;
        workTypeStringified?: string | null;
        suitableForStringified?: string | null;
        employmentTypesStringified?: string | null;
        employmentTypes?: string | null;
        paymentType?: PaymentType | null;
        advertisementSettingID: string;
        advertisementTypeID?: string | null;
        companyID?: string | null;
        profileID?: string | null;
        expirationDate: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        advertisementSetting?: {
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null;
        profile?: {
          __typename: "Profile";
          id: string;
          credits?: number | null;
          sub?: string | null;
          email: string;
          entity?: EntityType | null;
          entityState?: string | null;
          profileImage?: string | null;
          firstDegree?: string | null;
          forename?: string | null;
          surname?: string | null;
          secondDegree?: string | null;
          aboutMe?: string | null;
          educations?: string | null;
          workExperiences?: string | null;
          publications?: string | null;
          conferences?: string | null;
          certificates?: string | null;
          languages?: string | null;
          otherInformation?: string | null;
          qualifications?: string | null;
          name?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          phone?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelMessageInboxConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        company?: {
          __typename: "Company";
          id: string;
          entity: EntityType;
          entityState?: string | null;
          credits?: number | null;
          name: string;
          logo?: string | null;
          forename?: string | null;
          surname?: string | null;
          address?: string | null;
          phone?: string | null;
          email?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          employerTaxonomy?: string | null;
          profileImage?: string | null;
          qualification?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelCompanyMessageInboxConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelCompanyUserConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        owner?: string | null;
        analytics?: {
          __typename: "ModelAdvertisementAnalyticConnection";
          items: Array<{
            __typename: "AdvertisementAnalytic";
            id: string;
            name?: string | null;
            info?: string | null;
            advertisementID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SearchMessageInboxsQueryVariables = {
  filter?: SearchableMessageInboxFilterInput | null;
  sort?: SearchableMessageInboxSortInput | null;
  limit?: number | null;
  nextToken?: string | null;
  from?: number | null;
};

export type SearchMessageInboxsQuery = {
  searchMessageInboxs?: {
    __typename: "SearchableMessageInboxConnection";
    items: Array<{
      __typename: "MessageInbox";
      id: string;
      name: string;
      email: string;
      phoneNumber?: string | null;
      message: string;
      file?: string | null;
      state?: MessageState | null;
      createdAt: string;
      updatedAt: string;
      profileID: string;
      advertisementID: string;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisement?: {
        __typename: "Advertisement";
        id: string;
        friendlyId?: number | null;
        entity: EntityType;
        active?: Active | null;
        state?: string | null;
        stateDescription?: string | null;
        paymentState?: string | null;
        jobPosition: string;
        jobShortDescription: string;
        jobDescription?: string | null;
        place: string;
        salaryFrom?: number | null;
        salaryTo?: number | null;
        salarySpecifiedInText?: boolean | null;
        reactivationCount?: number | null;
        currentReactivationCount?: number | null;
        automaticReactivation?: boolean | null;
        leadingPosition?: boolean | null;
        residentialPlace?: boolean | null;
        typeOfCooperation?: string | null;
        jobType?: string | null;
        financialConditions?: string | null;
        typeOfInternship?: string | null;
        contactFirstDegree?: string | null;
        contactForename?: string | null;
        contactSurname?: string | null;
        contactSecondDegree?: string | null;
        contactEmail?: string | null;
        contactPhoneNumber?: string | null;
        hideContactInformation?: boolean | null;
        workType?: string | null;
        suitableFor?: string | null;
        workTypeStringified?: string | null;
        suitableForStringified?: string | null;
        employmentTypesStringified?: string | null;
        employmentTypes?: string | null;
        paymentType?: PaymentType | null;
        advertisementSettingID: string;
        advertisementTypeID?: string | null;
        companyID?: string | null;
        profileID?: string | null;
        expirationDate: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        advertisementSetting?: {
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null;
        profile?: {
          __typename: "Profile";
          id: string;
          credits?: number | null;
          sub?: string | null;
          email: string;
          entity?: EntityType | null;
          entityState?: string | null;
          profileImage?: string | null;
          firstDegree?: string | null;
          forename?: string | null;
          surname?: string | null;
          secondDegree?: string | null;
          aboutMe?: string | null;
          educations?: string | null;
          workExperiences?: string | null;
          publications?: string | null;
          conferences?: string | null;
          certificates?: string | null;
          languages?: string | null;
          otherInformation?: string | null;
          qualifications?: string | null;
          name?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          phone?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelMessageInboxConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        company?: {
          __typename: "Company";
          id: string;
          entity: EntityType;
          entityState?: string | null;
          credits?: number | null;
          name: string;
          logo?: string | null;
          forename?: string | null;
          surname?: string | null;
          address?: string | null;
          phone?: string | null;
          email?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          employerTaxonomy?: string | null;
          profileImage?: string | null;
          qualification?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelCompanyMessageInboxConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelCompanyUserConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        owner?: string | null;
        analytics?: {
          __typename: "ModelAdvertisementAnalyticConnection";
          items: Array<{
            __typename: "AdvertisementAnalytic";
            id: string;
            name?: string | null;
            info?: string | null;
            advertisementID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    total?: number | null;
  } | null;
};

export type GetCompanyQueryVariables = {
  id: string;
};

export type GetCompanyQuery = {
  getCompany?: {
    __typename: "Company";
    id: string;
    entity: EntityType;
    entityState?: string | null;
    credits?: number | null;
    name: string;
    logo?: string | null;
    forename?: string | null;
    surname?: string | null;
    address?: string | null;
    phone?: string | null;
    email?: string | null;
    crn?: string | null;
    vatRegNumber?: string | null;
    street?: string | null;
    buildingIdentificationNumber?: string | null;
    municipality?: string | null;
    postCode?: string | null;
    language?: string | null;
    employerTaxonomy?: string | null;
    profileImage?: string | null;
    qualification?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    messages?: {
      __typename: "ModelCompanyMessageInboxConnection";
      items: Array<{
        __typename: "CompanyMessageInbox";
        id: string;
        name: string;
        email: string;
        state?: MessageState | null;
        phoneNumber?: string | null;
        message: string;
        file?: string | null;
        createdAt: string;
        updatedAt: string;
        companyID: string;
        advertisementID: string;
        company?: {
          __typename: "Company";
          id: string;
          entity: EntityType;
          entityState?: string | null;
          credits?: number | null;
          name: string;
          logo?: string | null;
          forename?: string | null;
          surname?: string | null;
          address?: string | null;
          phone?: string | null;
          email?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          employerTaxonomy?: string | null;
          profileImage?: string | null;
          qualification?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelCompanyMessageInboxConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelCompanyUserConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        advertisement?: {
          __typename: "Advertisement";
          id: string;
          friendlyId?: number | null;
          entity: EntityType;
          active?: Active | null;
          state?: string | null;
          stateDescription?: string | null;
          paymentState?: string | null;
          jobPosition: string;
          jobShortDescription: string;
          jobDescription?: string | null;
          place: string;
          salaryFrom?: number | null;
          salaryTo?: number | null;
          salarySpecifiedInText?: boolean | null;
          reactivationCount?: number | null;
          currentReactivationCount?: number | null;
          automaticReactivation?: boolean | null;
          leadingPosition?: boolean | null;
          residentialPlace?: boolean | null;
          typeOfCooperation?: string | null;
          jobType?: string | null;
          financialConditions?: string | null;
          typeOfInternship?: string | null;
          contactFirstDegree?: string | null;
          contactForename?: string | null;
          contactSurname?: string | null;
          contactSecondDegree?: string | null;
          contactEmail?: string | null;
          contactPhoneNumber?: string | null;
          hideContactInformation?: boolean | null;
          workType?: string | null;
          suitableFor?: string | null;
          workTypeStringified?: string | null;
          suitableForStringified?: string | null;
          employmentTypesStringified?: string | null;
          employmentTypes?: string | null;
          paymentType?: PaymentType | null;
          advertisementSettingID: string;
          advertisementTypeID?: string | null;
          companyID?: string | null;
          profileID?: string | null;
          expirationDate: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          advertisementSetting?: {
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
          analytics?: {
            __typename: "ModelAdvertisementAnalyticConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelCompanyUserConnection";
      items: Array<{
        __typename: "CompanyUser";
        id: string;
        entity: EntityType;
        name?: string | null;
        email: string;
        role: CompanyUserRole;
        createdAt: string;
        updatedAt: string;
        companyID: string;
        company?: {
          __typename: "Company";
          id: string;
          entity: EntityType;
          entityState?: string | null;
          credits?: number | null;
          name: string;
          logo?: string | null;
          forename?: string | null;
          surname?: string | null;
          address?: string | null;
          phone?: string | null;
          email?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          employerTaxonomy?: string | null;
          profileImage?: string | null;
          qualification?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelCompanyMessageInboxConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelCompanyUserConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type ListCompaniesQueryVariables = {
  filter?: ModelCompanyFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListCompaniesQuery = {
  listCompanies?: {
    __typename: "ModelCompanyConnection";
    items: Array<{
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CompaniesByStateQueryVariables = {
  entityState?: string | null;
  name?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCompanyFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CompaniesByStateQuery = {
  companiesByState?: {
    __typename: "ModelCompanyConnection";
    items: Array<{
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CompaniesByNameQueryVariables = {
  entity?: EntityType | null;
  name?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCompanyFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CompaniesByNameQuery = {
  companiesByName?: {
    __typename: "ModelCompanyConnection";
    items: Array<{
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CompaniesByCreatedAtQueryVariables = {
  entity?: EntityType | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCompanyFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CompaniesByCreatedAtQuery = {
  companiesByCreatedAt?: {
    __typename: "ModelCompanyConnection";
    items: Array<{
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CompaniesByUpdatedAtQueryVariables = {
  entity?: EntityType | null;
  updatedAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCompanyFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CompaniesByUpdatedAtQuery = {
  companiesByUpdatedAt?: {
    __typename: "ModelCompanyConnection";
    items: Array<{
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SearchCompanysQueryVariables = {
  filter?: SearchableCompanyFilterInput | null;
  sort?: SearchableCompanySortInput | null;
  limit?: number | null;
  nextToken?: string | null;
  from?: number | null;
};

export type SearchCompanysQuery = {
  searchCompanys?: {
    __typename: "SearchableCompanyConnection";
    items: Array<{
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    total?: number | null;
  } | null;
};

export type GetCompanyMessageInboxQueryVariables = {
  id: string;
};

export type GetCompanyMessageInboxQuery = {
  getCompanyMessageInbox?: {
    __typename: "CompanyMessageInbox";
    id: string;
    name: string;
    email: string;
    state?: MessageState | null;
    phoneNumber?: string | null;
    message: string;
    file?: string | null;
    createdAt: string;
    updatedAt: string;
    companyID: string;
    advertisementID: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    advertisement?: {
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListCompanyMessageInboxsQueryVariables = {
  filter?: ModelCompanyMessageInboxFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListCompanyMessageInboxsQuery = {
  listCompanyMessageInboxs?: {
    __typename: "ModelCompanyMessageInboxConnection";
    items: Array<{
      __typename: "CompanyMessageInbox";
      id: string;
      name: string;
      email: string;
      state?: MessageState | null;
      phoneNumber?: string | null;
      message: string;
      file?: string | null;
      createdAt: string;
      updatedAt: string;
      companyID: string;
      advertisementID: string;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisement?: {
        __typename: "Advertisement";
        id: string;
        friendlyId?: number | null;
        entity: EntityType;
        active?: Active | null;
        state?: string | null;
        stateDescription?: string | null;
        paymentState?: string | null;
        jobPosition: string;
        jobShortDescription: string;
        jobDescription?: string | null;
        place: string;
        salaryFrom?: number | null;
        salaryTo?: number | null;
        salarySpecifiedInText?: boolean | null;
        reactivationCount?: number | null;
        currentReactivationCount?: number | null;
        automaticReactivation?: boolean | null;
        leadingPosition?: boolean | null;
        residentialPlace?: boolean | null;
        typeOfCooperation?: string | null;
        jobType?: string | null;
        financialConditions?: string | null;
        typeOfInternship?: string | null;
        contactFirstDegree?: string | null;
        contactForename?: string | null;
        contactSurname?: string | null;
        contactSecondDegree?: string | null;
        contactEmail?: string | null;
        contactPhoneNumber?: string | null;
        hideContactInformation?: boolean | null;
        workType?: string | null;
        suitableFor?: string | null;
        workTypeStringified?: string | null;
        suitableForStringified?: string | null;
        employmentTypesStringified?: string | null;
        employmentTypes?: string | null;
        paymentType?: PaymentType | null;
        advertisementSettingID: string;
        advertisementTypeID?: string | null;
        companyID?: string | null;
        profileID?: string | null;
        expirationDate: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        advertisementSetting?: {
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null;
        profile?: {
          __typename: "Profile";
          id: string;
          credits?: number | null;
          sub?: string | null;
          email: string;
          entity?: EntityType | null;
          entityState?: string | null;
          profileImage?: string | null;
          firstDegree?: string | null;
          forename?: string | null;
          surname?: string | null;
          secondDegree?: string | null;
          aboutMe?: string | null;
          educations?: string | null;
          workExperiences?: string | null;
          publications?: string | null;
          conferences?: string | null;
          certificates?: string | null;
          languages?: string | null;
          otherInformation?: string | null;
          qualifications?: string | null;
          name?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          phone?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelMessageInboxConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        company?: {
          __typename: "Company";
          id: string;
          entity: EntityType;
          entityState?: string | null;
          credits?: number | null;
          name: string;
          logo?: string | null;
          forename?: string | null;
          surname?: string | null;
          address?: string | null;
          phone?: string | null;
          email?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          employerTaxonomy?: string | null;
          profileImage?: string | null;
          qualification?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelCompanyMessageInboxConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelCompanyUserConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        owner?: string | null;
        analytics?: {
          __typename: "ModelAdvertisementAnalyticConnection";
          items: Array<{
            __typename: "AdvertisementAnalytic";
            id: string;
            name?: string | null;
            info?: string | null;
            advertisementID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type MessagesByCompanyIDQueryVariables = {
  companyID?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCompanyMessageInboxFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type MessagesByCompanyIDQuery = {
  messagesByCompanyID?: {
    __typename: "ModelCompanyMessageInboxConnection";
    items: Array<{
      __typename: "CompanyMessageInbox";
      id: string;
      name: string;
      email: string;
      state?: MessageState | null;
      phoneNumber?: string | null;
      message: string;
      file?: string | null;
      createdAt: string;
      updatedAt: string;
      companyID: string;
      advertisementID: string;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisement?: {
        __typename: "Advertisement";
        id: string;
        friendlyId?: number | null;
        entity: EntityType;
        active?: Active | null;
        state?: string | null;
        stateDescription?: string | null;
        paymentState?: string | null;
        jobPosition: string;
        jobShortDescription: string;
        jobDescription?: string | null;
        place: string;
        salaryFrom?: number | null;
        salaryTo?: number | null;
        salarySpecifiedInText?: boolean | null;
        reactivationCount?: number | null;
        currentReactivationCount?: number | null;
        automaticReactivation?: boolean | null;
        leadingPosition?: boolean | null;
        residentialPlace?: boolean | null;
        typeOfCooperation?: string | null;
        jobType?: string | null;
        financialConditions?: string | null;
        typeOfInternship?: string | null;
        contactFirstDegree?: string | null;
        contactForename?: string | null;
        contactSurname?: string | null;
        contactSecondDegree?: string | null;
        contactEmail?: string | null;
        contactPhoneNumber?: string | null;
        hideContactInformation?: boolean | null;
        workType?: string | null;
        suitableFor?: string | null;
        workTypeStringified?: string | null;
        suitableForStringified?: string | null;
        employmentTypesStringified?: string | null;
        employmentTypes?: string | null;
        paymentType?: PaymentType | null;
        advertisementSettingID: string;
        advertisementTypeID?: string | null;
        companyID?: string | null;
        profileID?: string | null;
        expirationDate: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        advertisementSetting?: {
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null;
        profile?: {
          __typename: "Profile";
          id: string;
          credits?: number | null;
          sub?: string | null;
          email: string;
          entity?: EntityType | null;
          entityState?: string | null;
          profileImage?: string | null;
          firstDegree?: string | null;
          forename?: string | null;
          surname?: string | null;
          secondDegree?: string | null;
          aboutMe?: string | null;
          educations?: string | null;
          workExperiences?: string | null;
          publications?: string | null;
          conferences?: string | null;
          certificates?: string | null;
          languages?: string | null;
          otherInformation?: string | null;
          qualifications?: string | null;
          name?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          phone?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelMessageInboxConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        company?: {
          __typename: "Company";
          id: string;
          entity: EntityType;
          entityState?: string | null;
          credits?: number | null;
          name: string;
          logo?: string | null;
          forename?: string | null;
          surname?: string | null;
          address?: string | null;
          phone?: string | null;
          email?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          employerTaxonomy?: string | null;
          profileImage?: string | null;
          qualification?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelCompanyMessageInboxConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelCompanyUserConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        owner?: string | null;
        analytics?: {
          __typename: "ModelAdvertisementAnalyticConnection";
          items: Array<{
            __typename: "AdvertisementAnalytic";
            id: string;
            name?: string | null;
            info?: string | null;
            advertisementID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SearchCompanyMessageInboxsQueryVariables = {
  filter?: SearchableCompanyMessageInboxFilterInput | null;
  sort?: SearchableCompanyMessageInboxSortInput | null;
  limit?: number | null;
  nextToken?: string | null;
  from?: number | null;
};

export type SearchCompanyMessageInboxsQuery = {
  searchCompanyMessageInboxs?: {
    __typename: "SearchableCompanyMessageInboxConnection";
    items: Array<{
      __typename: "CompanyMessageInbox";
      id: string;
      name: string;
      email: string;
      state?: MessageState | null;
      phoneNumber?: string | null;
      message: string;
      file?: string | null;
      createdAt: string;
      updatedAt: string;
      companyID: string;
      advertisementID: string;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisement?: {
        __typename: "Advertisement";
        id: string;
        friendlyId?: number | null;
        entity: EntityType;
        active?: Active | null;
        state?: string | null;
        stateDescription?: string | null;
        paymentState?: string | null;
        jobPosition: string;
        jobShortDescription: string;
        jobDescription?: string | null;
        place: string;
        salaryFrom?: number | null;
        salaryTo?: number | null;
        salarySpecifiedInText?: boolean | null;
        reactivationCount?: number | null;
        currentReactivationCount?: number | null;
        automaticReactivation?: boolean | null;
        leadingPosition?: boolean | null;
        residentialPlace?: boolean | null;
        typeOfCooperation?: string | null;
        jobType?: string | null;
        financialConditions?: string | null;
        typeOfInternship?: string | null;
        contactFirstDegree?: string | null;
        contactForename?: string | null;
        contactSurname?: string | null;
        contactSecondDegree?: string | null;
        contactEmail?: string | null;
        contactPhoneNumber?: string | null;
        hideContactInformation?: boolean | null;
        workType?: string | null;
        suitableFor?: string | null;
        workTypeStringified?: string | null;
        suitableForStringified?: string | null;
        employmentTypesStringified?: string | null;
        employmentTypes?: string | null;
        paymentType?: PaymentType | null;
        advertisementSettingID: string;
        advertisementTypeID?: string | null;
        companyID?: string | null;
        profileID?: string | null;
        expirationDate: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        advertisementSetting?: {
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null;
        profile?: {
          __typename: "Profile";
          id: string;
          credits?: number | null;
          sub?: string | null;
          email: string;
          entity?: EntityType | null;
          entityState?: string | null;
          profileImage?: string | null;
          firstDegree?: string | null;
          forename?: string | null;
          surname?: string | null;
          secondDegree?: string | null;
          aboutMe?: string | null;
          educations?: string | null;
          workExperiences?: string | null;
          publications?: string | null;
          conferences?: string | null;
          certificates?: string | null;
          languages?: string | null;
          otherInformation?: string | null;
          qualifications?: string | null;
          name?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          phone?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelMessageInboxConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        company?: {
          __typename: "Company";
          id: string;
          entity: EntityType;
          entityState?: string | null;
          credits?: number | null;
          name: string;
          logo?: string | null;
          forename?: string | null;
          surname?: string | null;
          address?: string | null;
          phone?: string | null;
          email?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          employerTaxonomy?: string | null;
          profileImage?: string | null;
          qualification?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelCompanyMessageInboxConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelCompanyUserConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        owner?: string | null;
        analytics?: {
          __typename: "ModelAdvertisementAnalyticConnection";
          items: Array<{
            __typename: "AdvertisementAnalytic";
            id: string;
            name?: string | null;
            info?: string | null;
            advertisementID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    total?: number | null;
  } | null;
};

export type GetCompanyUserQueryVariables = {
  id: string;
};

export type GetCompanyUserQuery = {
  getCompanyUser?: {
    __typename: "CompanyUser";
    id: string;
    entity: EntityType;
    name?: string | null;
    email: string;
    role: CompanyUserRole;
    createdAt: string;
    updatedAt: string;
    companyID: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    owner?: string | null;
  } | null;
};

export type ListCompanyUsersQueryVariables = {
  filter?: ModelCompanyUserFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListCompanyUsersQuery = {
  listCompanyUsers?: {
    __typename: "ModelCompanyUserConnection";
    items: Array<{
      __typename: "CompanyUser";
      id: string;
      entity: EntityType;
      name?: string | null;
      email: string;
      role: CompanyUserRole;
      createdAt: string;
      updatedAt: string;
      companyID: string;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UsersByCompanyIDQueryVariables = {
  companyID?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCompanyUserFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type UsersByCompanyIDQuery = {
  usersByCompanyID?: {
    __typename: "ModelCompanyUserConnection";
    items: Array<{
      __typename: "CompanyUser";
      id: string;
      entity: EntityType;
      name?: string | null;
      email: string;
      role: CompanyUserRole;
      createdAt: string;
      updatedAt: string;
      companyID: string;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CompanyUsersByEmailQueryVariables = {
  email?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCompanyUserFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CompanyUsersByEmailQuery = {
  companyUsersByEmail?: {
    __typename: "ModelCompanyUserConnection";
    items: Array<{
      __typename: "CompanyUser";
      id: string;
      entity: EntityType;
      name?: string | null;
      email: string;
      role: CompanyUserRole;
      createdAt: string;
      updatedAt: string;
      companyID: string;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CompanyUserByCreatedAtQueryVariables = {
  entity?: EntityType | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCompanyUserFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CompanyUserByCreatedAtQuery = {
  companyUserByCreatedAt?: {
    __typename: "ModelCompanyUserConnection";
    items: Array<{
      __typename: "CompanyUser";
      id: string;
      entity: EntityType;
      name?: string | null;
      email: string;
      role: CompanyUserRole;
      createdAt: string;
      updatedAt: string;
      companyID: string;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CompanyUserByUpdatedAtQueryVariables = {
  entity?: EntityType | null;
  updatedAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCompanyUserFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CompanyUserByUpdatedAtQuery = {
  companyUserByUpdatedAt?: {
    __typename: "ModelCompanyUserConnection";
    items: Array<{
      __typename: "CompanyUser";
      id: string;
      entity: EntityType;
      name?: string | null;
      email: string;
      role: CompanyUserRole;
      createdAt: string;
      updatedAt: string;
      companyID: string;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SearchCompanyUsersQueryVariables = {
  filter?: SearchableCompanyUserFilterInput | null;
  sort?: SearchableCompanyUserSortInput | null;
  limit?: number | null;
  nextToken?: string | null;
  from?: number | null;
};

export type SearchCompanyUsersQuery = {
  searchCompanyUsers?: {
    __typename: "SearchableCompanyUserConnection";
    items: Array<{
      __typename: "CompanyUser";
      id: string;
      entity: EntityType;
      name?: string | null;
      email: string;
      role: CompanyUserRole;
      createdAt: string;
      updatedAt: string;
      companyID: string;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
    total?: number | null;
  } | null;
};

export type GetAdvertisementQueryVariables = {
  id: string;
};

export type GetAdvertisementQuery = {
  getAdvertisement?: {
    __typename: "Advertisement";
    id: string;
    friendlyId?: number | null;
    entity: EntityType;
    active?: Active | null;
    state?: string | null;
    stateDescription?: string | null;
    paymentState?: string | null;
    jobPosition: string;
    jobShortDescription: string;
    jobDescription?: string | null;
    place: string;
    salaryFrom?: number | null;
    salaryTo?: number | null;
    salarySpecifiedInText?: boolean | null;
    reactivationCount?: number | null;
    currentReactivationCount?: number | null;
    automaticReactivation?: boolean | null;
    leadingPosition?: boolean | null;
    residentialPlace?: boolean | null;
    typeOfCooperation?: string | null;
    jobType?: string | null;
    financialConditions?: string | null;
    typeOfInternship?: string | null;
    contactFirstDegree?: string | null;
    contactForename?: string | null;
    contactSurname?: string | null;
    contactSecondDegree?: string | null;
    contactEmail?: string | null;
    contactPhoneNumber?: string | null;
    hideContactInformation?: boolean | null;
    workType?: string | null;
    suitableFor?: string | null;
    workTypeStringified?: string | null;
    suitableForStringified?: string | null;
    employmentTypesStringified?: string | null;
    employmentTypes?: string | null;
    paymentType?: PaymentType | null;
    advertisementSettingID: string;
    advertisementTypeID?: string | null;
    companyID?: string | null;
    profileID?: string | null;
    expirationDate: string;
    createdAt: string;
    updatedAt: string;
    advertisementType?: {
      __typename: "AdvertisementType";
      id: string;
      active: boolean;
      entity: EntityType;
      name: string;
      jobDescription?: string | null;
      order: number;
      approvalRequired: string;
      components?: string | null;
      createdAt: string;
      updatedAt: string;
      advertisements?: {
        __typename: "ModelAdvertisementSettingConnection";
        items: Array<{
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    advertisementSetting?: {
      __typename: "AdvertisementSetting";
      id: string;
      active: boolean;
      creditsCount: number;
      description?: string | null;
      entity: EntityType;
      internalName: string;
      name: string;
      numberOfDays: number;
      reactivationCreditsCount: number;
      order: number;
      appliedTo: string;
      public?: PublicAdvertisementSetting | null;
      advertisementTypeID: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null;
    profile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    owner?: string | null;
    analytics?: {
      __typename: "ModelAdvertisementAnalyticConnection";
      items: Array<{
        __typename: "AdvertisementAnalytic";
        id: string;
        name?: string | null;
        info?: string | null;
        advertisementID: string;
        createdAt: string;
        updatedAt: string;
        advertisement?: {
          __typename: "Advertisement";
          id: string;
          friendlyId?: number | null;
          entity: EntityType;
          active?: Active | null;
          state?: string | null;
          stateDescription?: string | null;
          paymentState?: string | null;
          jobPosition: string;
          jobShortDescription: string;
          jobDescription?: string | null;
          place: string;
          salaryFrom?: number | null;
          salaryTo?: number | null;
          salarySpecifiedInText?: boolean | null;
          reactivationCount?: number | null;
          currentReactivationCount?: number | null;
          automaticReactivation?: boolean | null;
          leadingPosition?: boolean | null;
          residentialPlace?: boolean | null;
          typeOfCooperation?: string | null;
          jobType?: string | null;
          financialConditions?: string | null;
          typeOfInternship?: string | null;
          contactFirstDegree?: string | null;
          contactForename?: string | null;
          contactSurname?: string | null;
          contactSecondDegree?: string | null;
          contactEmail?: string | null;
          contactPhoneNumber?: string | null;
          hideContactInformation?: boolean | null;
          workType?: string | null;
          suitableFor?: string | null;
          workTypeStringified?: string | null;
          suitableForStringified?: string | null;
          employmentTypesStringified?: string | null;
          employmentTypes?: string | null;
          paymentType?: PaymentType | null;
          advertisementSettingID: string;
          advertisementTypeID?: string | null;
          companyID?: string | null;
          profileID?: string | null;
          expirationDate: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          advertisementSetting?: {
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
          analytics?: {
            __typename: "ModelAdvertisementAnalyticConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type ListAdvertisementsQueryVariables = {
  filter?: ModelAdvertisementFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListAdvertisementsQuery = {
  listAdvertisements?: {
    __typename: "ModelAdvertisementConnection";
    items: Array<{
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AdvertisementsByCompanyQueryVariables = {
  companyID?: string | null;
  updatedAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelAdvertisementFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AdvertisementsByCompanyQuery = {
  advertisementsByCompany?: {
    __typename: "ModelAdvertisementConnection";
    items: Array<{
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AdvertisementsByProfileQueryVariables = {
  profileID?: string | null;
  updatedAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelAdvertisementFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AdvertisementsByProfileQuery = {
  advertisementsByProfile?: {
    __typename: "ModelAdvertisementConnection";
    items: Array<{
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AdvertisementsByCreatedAtQueryVariables = {
  entity?: EntityType | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelAdvertisementFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AdvertisementsByCreatedAtQuery = {
  advertisementsByCreatedAt?: {
    __typename: "ModelAdvertisementConnection";
    items: Array<{
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AdvertisementsByUpdatedAtQueryVariables = {
  entity?: EntityType | null;
  updatedAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelAdvertisementFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AdvertisementsByUpdatedAtQuery = {
  advertisementsByUpdatedAt?: {
    __typename: "ModelAdvertisementConnection";
    items: Array<{
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AdvertisementsByStateQueryVariables = {
  state?: string | null;
  updatedAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelAdvertisementFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AdvertisementsByStateQuery = {
  advertisementsByState?: {
    __typename: "ModelAdvertisementConnection";
    items: Array<{
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AdvertisementsByPaymentStateQueryVariables = {
  paymentState?: string | null;
  updatedAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelAdvertisementFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AdvertisementsByPaymentStateQuery = {
  advertisementsByPaymentState?: {
    __typename: "ModelAdvertisementConnection";
    items: Array<{
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SearchAdvertisementsQueryVariables = {
  filter?: SearchableAdvertisementFilterInput | null;
  sort?: SearchableAdvertisementSortInput | null;
  limit?: number | null;
  nextToken?: string | null;
  from?: number | null;
};

export type SearchAdvertisementsQuery = {
  searchAdvertisements?: {
    __typename: "SearchableAdvertisementConnection";
    items: Array<{
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    total?: number | null;
  } | null;
};

export type GetAdvertisementAnalyticQueryVariables = {
  id: string;
};

export type GetAdvertisementAnalyticQuery = {
  getAdvertisementAnalytic?: {
    __typename: "AdvertisementAnalytic";
    id: string;
    name?: string | null;
    info?: string | null;
    advertisementID: string;
    createdAt: string;
    updatedAt: string;
    advertisement?: {
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListAdvertisementAnalyticsQueryVariables = {
  filter?: ModelAdvertisementAnalyticFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListAdvertisementAnalyticsQuery = {
  listAdvertisementAnalytics?: {
    __typename: "ModelAdvertisementAnalyticConnection";
    items: Array<{
      __typename: "AdvertisementAnalytic";
      id: string;
      name?: string | null;
      info?: string | null;
      advertisementID: string;
      createdAt: string;
      updatedAt: string;
      advertisement?: {
        __typename: "Advertisement";
        id: string;
        friendlyId?: number | null;
        entity: EntityType;
        active?: Active | null;
        state?: string | null;
        stateDescription?: string | null;
        paymentState?: string | null;
        jobPosition: string;
        jobShortDescription: string;
        jobDescription?: string | null;
        place: string;
        salaryFrom?: number | null;
        salaryTo?: number | null;
        salarySpecifiedInText?: boolean | null;
        reactivationCount?: number | null;
        currentReactivationCount?: number | null;
        automaticReactivation?: boolean | null;
        leadingPosition?: boolean | null;
        residentialPlace?: boolean | null;
        typeOfCooperation?: string | null;
        jobType?: string | null;
        financialConditions?: string | null;
        typeOfInternship?: string | null;
        contactFirstDegree?: string | null;
        contactForename?: string | null;
        contactSurname?: string | null;
        contactSecondDegree?: string | null;
        contactEmail?: string | null;
        contactPhoneNumber?: string | null;
        hideContactInformation?: boolean | null;
        workType?: string | null;
        suitableFor?: string | null;
        workTypeStringified?: string | null;
        suitableForStringified?: string | null;
        employmentTypesStringified?: string | null;
        employmentTypes?: string | null;
        paymentType?: PaymentType | null;
        advertisementSettingID: string;
        advertisementTypeID?: string | null;
        companyID?: string | null;
        profileID?: string | null;
        expirationDate: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        advertisementSetting?: {
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null;
        profile?: {
          __typename: "Profile";
          id: string;
          credits?: number | null;
          sub?: string | null;
          email: string;
          entity?: EntityType | null;
          entityState?: string | null;
          profileImage?: string | null;
          firstDegree?: string | null;
          forename?: string | null;
          surname?: string | null;
          secondDegree?: string | null;
          aboutMe?: string | null;
          educations?: string | null;
          workExperiences?: string | null;
          publications?: string | null;
          conferences?: string | null;
          certificates?: string | null;
          languages?: string | null;
          otherInformation?: string | null;
          qualifications?: string | null;
          name?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          phone?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelMessageInboxConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        company?: {
          __typename: "Company";
          id: string;
          entity: EntityType;
          entityState?: string | null;
          credits?: number | null;
          name: string;
          logo?: string | null;
          forename?: string | null;
          surname?: string | null;
          address?: string | null;
          phone?: string | null;
          email?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          employerTaxonomy?: string | null;
          profileImage?: string | null;
          qualification?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelCompanyMessageInboxConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelCompanyUserConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        owner?: string | null;
        analytics?: {
          __typename: "ModelAdvertisementAnalyticConnection";
          items: Array<{
            __typename: "AdvertisementAnalytic";
            id: string;
            name?: string | null;
            info?: string | null;
            advertisementID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AdvertisementAnalyticsByAdvertisementIdQueryVariables = {
  advertisementID?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelAdvertisementAnalyticFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AdvertisementAnalyticsByAdvertisementIdQuery = {
  advertisementAnalyticsByAdvertisementId?: {
    __typename: "ModelAdvertisementAnalyticConnection";
    items: Array<{
      __typename: "AdvertisementAnalytic";
      id: string;
      name?: string | null;
      info?: string | null;
      advertisementID: string;
      createdAt: string;
      updatedAt: string;
      advertisement?: {
        __typename: "Advertisement";
        id: string;
        friendlyId?: number | null;
        entity: EntityType;
        active?: Active | null;
        state?: string | null;
        stateDescription?: string | null;
        paymentState?: string | null;
        jobPosition: string;
        jobShortDescription: string;
        jobDescription?: string | null;
        place: string;
        salaryFrom?: number | null;
        salaryTo?: number | null;
        salarySpecifiedInText?: boolean | null;
        reactivationCount?: number | null;
        currentReactivationCount?: number | null;
        automaticReactivation?: boolean | null;
        leadingPosition?: boolean | null;
        residentialPlace?: boolean | null;
        typeOfCooperation?: string | null;
        jobType?: string | null;
        financialConditions?: string | null;
        typeOfInternship?: string | null;
        contactFirstDegree?: string | null;
        contactForename?: string | null;
        contactSurname?: string | null;
        contactSecondDegree?: string | null;
        contactEmail?: string | null;
        contactPhoneNumber?: string | null;
        hideContactInformation?: boolean | null;
        workType?: string | null;
        suitableFor?: string | null;
        workTypeStringified?: string | null;
        suitableForStringified?: string | null;
        employmentTypesStringified?: string | null;
        employmentTypes?: string | null;
        paymentType?: PaymentType | null;
        advertisementSettingID: string;
        advertisementTypeID?: string | null;
        companyID?: string | null;
        profileID?: string | null;
        expirationDate: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        advertisementSetting?: {
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null;
        profile?: {
          __typename: "Profile";
          id: string;
          credits?: number | null;
          sub?: string | null;
          email: string;
          entity?: EntityType | null;
          entityState?: string | null;
          profileImage?: string | null;
          firstDegree?: string | null;
          forename?: string | null;
          surname?: string | null;
          secondDegree?: string | null;
          aboutMe?: string | null;
          educations?: string | null;
          workExperiences?: string | null;
          publications?: string | null;
          conferences?: string | null;
          certificates?: string | null;
          languages?: string | null;
          otherInformation?: string | null;
          qualifications?: string | null;
          name?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          phone?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelMessageInboxConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        company?: {
          __typename: "Company";
          id: string;
          entity: EntityType;
          entityState?: string | null;
          credits?: number | null;
          name: string;
          logo?: string | null;
          forename?: string | null;
          surname?: string | null;
          address?: string | null;
          phone?: string | null;
          email?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          employerTaxonomy?: string | null;
          profileImage?: string | null;
          qualification?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelCompanyMessageInboxConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelCompanyUserConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        owner?: string | null;
        analytics?: {
          __typename: "ModelAdvertisementAnalyticConnection";
          items: Array<{
            __typename: "AdvertisementAnalytic";
            id: string;
            name?: string | null;
            info?: string | null;
            advertisementID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetShowCaseQueryVariables = {
  companyID: string;
};

export type GetShowCaseQuery = {
  getShowCase?: {
    __typename: "ShowCase";
    id: string;
    title: string;
    text: string;
    file?: string | null;
    createdAt: string;
    updatedAt: string;
    companyID: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListShowCasesQueryVariables = {
  companyID?: string | null;
  filter?: ModelShowCaseFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: ModelSortDirection | null;
};

export type ListShowCasesQuery = {
  listShowCases?: {
    __typename: "ModelShowCaseConnection";
    items: Array<{
      __typename: "ShowCase";
      id: string;
      title: string;
      text: string;
      file?: string | null;
      createdAt: string;
      updatedAt: string;
      companyID: string;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetTransactionQueryVariables = {
  id: string;
};

export type GetTransactionQuery = {
  getTransaction?: {
    __typename: "Transaction";
    id: string;
    amount: number;
    currency: string;
    description?: string | null;
    paymentIntent?: string | null;
    companyID?: string | null;
    profileID?: string | null;
    initiatedBy?: string | null;
    createdAt: string;
    updatedAt: string;
    profile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    initiatedByProfile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListTransactionsQueryVariables = {
  filter?: ModelTransactionFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListTransactionsQuery = {
  listTransactions?: {
    __typename: "ModelTransactionConnection";
    items: Array<{
      __typename: "Transaction";
      id: string;
      amount: number;
      currency: string;
      description?: string | null;
      paymentIntent?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      initiatedBy?: string | null;
      createdAt: string;
      updatedAt: string;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      initiatedByProfile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type TransactionsByCompanyQueryVariables = {
  companyID?: string | null;
  updatedAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelTransactionFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type TransactionsByCompanyQuery = {
  transactionsByCompany?: {
    __typename: "ModelTransactionConnection";
    items: Array<{
      __typename: "Transaction";
      id: string;
      amount: number;
      currency: string;
      description?: string | null;
      paymentIntent?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      initiatedBy?: string | null;
      createdAt: string;
      updatedAt: string;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      initiatedByProfile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type TransactionsByProfileQueryVariables = {
  profileID?: string | null;
  updatedAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelTransactionFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type TransactionsByProfileQuery = {
  transactionsByProfile?: {
    __typename: "ModelTransactionConnection";
    items: Array<{
      __typename: "Transaction";
      id: string;
      amount: number;
      currency: string;
      description?: string | null;
      paymentIntent?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      initiatedBy?: string | null;
      createdAt: string;
      updatedAt: string;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      initiatedByProfile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetCarouselQueryVariables = {
  id: string;
};

export type GetCarouselQuery = {
  getCarousel?: {
    __typename: "Carousel";
    id: string;
    active: boolean;
    entity: EntityType;
    image?: string | null;
    title: string;
    type?: CarouselType | null;
    companyID?: string | null;
    createdAt: string;
    updatedAt: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListCarouselsQueryVariables = {
  filter?: ModelCarouselFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListCarouselsQuery = {
  listCarousels?: {
    __typename: "ModelCarouselConnection";
    items: Array<{
      __typename: "Carousel";
      id: string;
      active: boolean;
      entity: EntityType;
      image?: string | null;
      title: string;
      type?: CarouselType | null;
      companyID?: string | null;
      createdAt: string;
      updatedAt: string;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CarouselsByCreatedAtQueryVariables = {
  entity?: EntityType | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCarouselFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CarouselsByCreatedAtQuery = {
  carouselsByCreatedAt?: {
    __typename: "ModelCarouselConnection";
    items: Array<{
      __typename: "Carousel";
      id: string;
      active: boolean;
      entity: EntityType;
      image?: string | null;
      title: string;
      type?: CarouselType | null;
      companyID?: string | null;
      createdAt: string;
      updatedAt: string;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CarouselsByUpdatedAtQueryVariables = {
  entity?: EntityType | null;
  updatedAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCarouselFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CarouselsByUpdatedAtQuery = {
  carouselsByUpdatedAt?: {
    __typename: "ModelCarouselConnection";
    items: Array<{
      __typename: "Carousel";
      id: string;
      active: boolean;
      entity: EntityType;
      image?: string | null;
      title: string;
      type?: CarouselType | null;
      companyID?: string | null;
      createdAt: string;
      updatedAt: string;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CarouselsByTypeQueryVariables = {
  type?: CarouselType | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCarouselFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CarouselsByTypeQuery = {
  carouselsByType?: {
    __typename: "ModelCarouselConnection";
    items: Array<{
      __typename: "Carousel";
      id: string;
      active: boolean;
      entity: EntityType;
      image?: string | null;
      title: string;
      type?: CarouselType | null;
      companyID?: string | null;
      createdAt: string;
      updatedAt: string;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnCreateEmailNewsletterSubscription = {
  onCreateEmailNewsletter?: {
    __typename: "EmailNewsletter";
    id: string;
    email?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateEmailNewsletterSubscription = {
  onUpdateEmailNewsletter?: {
    __typename: "EmailNewsletter";
    id: string;
    email?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteEmailNewsletterSubscription = {
  onDeleteEmailNewsletter?: {
    __typename: "EmailNewsletter";
    id: string;
    email?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateArticleCategorySubscription = {
  onCreateArticleCategory?: {
    __typename: "ArticleCategory";
    id: string;
    entity: EntityType;
    name: string;
    slug: string;
    order?: number | null;
    createdAt: string;
    updatedAt: string;
    articles?: {
      __typename: "ModelArticleConnection";
      items: Array<{
        __typename: "Article";
        id: string;
        active?: boolean | null;
        content?: string | null;
        description?: string | null;
        entity: EntityType;
        image?: string | null;
        title: string;
        slug: string;
        createdBy?: string | null;
        hashtags?: string | null;
        type?: string | null;
        order?: number | null;
        articleCategoryID: string;
        createdAt: string;
        updatedAt: string;
        category?: {
          __typename: "ArticleCategory";
          id: string;
          entity: EntityType;
          name: string;
          slug: string;
          order?: number | null;
          createdAt: string;
          updatedAt: string;
          articles?: {
            __typename: "ModelArticleConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnUpdateArticleCategorySubscription = {
  onUpdateArticleCategory?: {
    __typename: "ArticleCategory";
    id: string;
    entity: EntityType;
    name: string;
    slug: string;
    order?: number | null;
    createdAt: string;
    updatedAt: string;
    articles?: {
      __typename: "ModelArticleConnection";
      items: Array<{
        __typename: "Article";
        id: string;
        active?: boolean | null;
        content?: string | null;
        description?: string | null;
        entity: EntityType;
        image?: string | null;
        title: string;
        slug: string;
        createdBy?: string | null;
        hashtags?: string | null;
        type?: string | null;
        order?: number | null;
        articleCategoryID: string;
        createdAt: string;
        updatedAt: string;
        category?: {
          __typename: "ArticleCategory";
          id: string;
          entity: EntityType;
          name: string;
          slug: string;
          order?: number | null;
          createdAt: string;
          updatedAt: string;
          articles?: {
            __typename: "ModelArticleConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnDeleteArticleCategorySubscription = {
  onDeleteArticleCategory?: {
    __typename: "ArticleCategory";
    id: string;
    entity: EntityType;
    name: string;
    slug: string;
    order?: number | null;
    createdAt: string;
    updatedAt: string;
    articles?: {
      __typename: "ModelArticleConnection";
      items: Array<{
        __typename: "Article";
        id: string;
        active?: boolean | null;
        content?: string | null;
        description?: string | null;
        entity: EntityType;
        image?: string | null;
        title: string;
        slug: string;
        createdBy?: string | null;
        hashtags?: string | null;
        type?: string | null;
        order?: number | null;
        articleCategoryID: string;
        createdAt: string;
        updatedAt: string;
        category?: {
          __typename: "ArticleCategory";
          id: string;
          entity: EntityType;
          name: string;
          slug: string;
          order?: number | null;
          createdAt: string;
          updatedAt: string;
          articles?: {
            __typename: "ModelArticleConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnCreateArticleSubscription = {
  onCreateArticle?: {
    __typename: "Article";
    id: string;
    active?: boolean | null;
    content?: string | null;
    description?: string | null;
    entity: EntityType;
    image?: string | null;
    title: string;
    slug: string;
    createdBy?: string | null;
    hashtags?: string | null;
    type?: string | null;
    order?: number | null;
    articleCategoryID: string;
    createdAt: string;
    updatedAt: string;
    category?: {
      __typename: "ArticleCategory";
      id: string;
      entity: EntityType;
      name: string;
      slug: string;
      order?: number | null;
      createdAt: string;
      updatedAt: string;
      articles?: {
        __typename: "ModelArticleConnection";
        items: Array<{
          __typename: "Article";
          id: string;
          active?: boolean | null;
          content?: string | null;
          description?: string | null;
          entity: EntityType;
          image?: string | null;
          title: string;
          slug: string;
          createdBy?: string | null;
          hashtags?: string | null;
          type?: string | null;
          order?: number | null;
          articleCategoryID: string;
          createdAt: string;
          updatedAt: string;
          category?: {
            __typename: "ArticleCategory";
            id: string;
            entity: EntityType;
            name: string;
            slug: string;
            order?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateArticleSubscription = {
  onUpdateArticle?: {
    __typename: "Article";
    id: string;
    active?: boolean | null;
    content?: string | null;
    description?: string | null;
    entity: EntityType;
    image?: string | null;
    title: string;
    slug: string;
    createdBy?: string | null;
    hashtags?: string | null;
    type?: string | null;
    order?: number | null;
    articleCategoryID: string;
    createdAt: string;
    updatedAt: string;
    category?: {
      __typename: "ArticleCategory";
      id: string;
      entity: EntityType;
      name: string;
      slug: string;
      order?: number | null;
      createdAt: string;
      updatedAt: string;
      articles?: {
        __typename: "ModelArticleConnection";
        items: Array<{
          __typename: "Article";
          id: string;
          active?: boolean | null;
          content?: string | null;
          description?: string | null;
          entity: EntityType;
          image?: string | null;
          title: string;
          slug: string;
          createdBy?: string | null;
          hashtags?: string | null;
          type?: string | null;
          order?: number | null;
          articleCategoryID: string;
          createdAt: string;
          updatedAt: string;
          category?: {
            __typename: "ArticleCategory";
            id: string;
            entity: EntityType;
            name: string;
            slug: string;
            order?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteArticleSubscription = {
  onDeleteArticle?: {
    __typename: "Article";
    id: string;
    active?: boolean | null;
    content?: string | null;
    description?: string | null;
    entity: EntityType;
    image?: string | null;
    title: string;
    slug: string;
    createdBy?: string | null;
    hashtags?: string | null;
    type?: string | null;
    order?: number | null;
    articleCategoryID: string;
    createdAt: string;
    updatedAt: string;
    category?: {
      __typename: "ArticleCategory";
      id: string;
      entity: EntityType;
      name: string;
      slug: string;
      order?: number | null;
      createdAt: string;
      updatedAt: string;
      articles?: {
        __typename: "ModelArticleConnection";
        items: Array<{
          __typename: "Article";
          id: string;
          active?: boolean | null;
          content?: string | null;
          description?: string | null;
          entity: EntityType;
          image?: string | null;
          title: string;
          slug: string;
          createdBy?: string | null;
          hashtags?: string | null;
          type?: string | null;
          order?: number | null;
          articleCategoryID: string;
          createdAt: string;
          updatedAt: string;
          category?: {
            __typename: "ArticleCategory";
            id: string;
            entity: EntityType;
            name: string;
            slug: string;
            order?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateAdvertisementTypeSubscription = {
  onCreateAdvertisementType?: {
    __typename: "AdvertisementType";
    id: string;
    active: boolean;
    entity: EntityType;
    name: string;
    jobDescription?: string | null;
    order: number;
    approvalRequired: string;
    components?: string | null;
    createdAt: string;
    updatedAt: string;
    advertisements?: {
      __typename: "ModelAdvertisementSettingConnection";
      items: Array<{
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnUpdateAdvertisementTypeSubscription = {
  onUpdateAdvertisementType?: {
    __typename: "AdvertisementType";
    id: string;
    active: boolean;
    entity: EntityType;
    name: string;
    jobDescription?: string | null;
    order: number;
    approvalRequired: string;
    components?: string | null;
    createdAt: string;
    updatedAt: string;
    advertisements?: {
      __typename: "ModelAdvertisementSettingConnection";
      items: Array<{
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnDeleteAdvertisementTypeSubscription = {
  onDeleteAdvertisementType?: {
    __typename: "AdvertisementType";
    id: string;
    active: boolean;
    entity: EntityType;
    name: string;
    jobDescription?: string | null;
    order: number;
    approvalRequired: string;
    components?: string | null;
    createdAt: string;
    updatedAt: string;
    advertisements?: {
      __typename: "ModelAdvertisementSettingConnection";
      items: Array<{
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnCreateServiceSettingSubscription = {
  onCreateServiceSetting?: {
    __typename: "ServiceSetting";
    id: string;
    active: boolean;
    creditsCount: number;
    entity: EntityType;
    name: string;
    public?: PublicAdvertisementSetting | null;
    appliedTo: string;
    serviceType: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateServiceSettingSubscription = {
  onUpdateServiceSetting?: {
    __typename: "ServiceSetting";
    id: string;
    active: boolean;
    creditsCount: number;
    entity: EntityType;
    name: string;
    public?: PublicAdvertisementSetting | null;
    appliedTo: string;
    serviceType: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteServiceSettingSubscription = {
  onDeleteServiceSetting?: {
    __typename: "ServiceSetting";
    id: string;
    active: boolean;
    creditsCount: number;
    entity: EntityType;
    name: string;
    public?: PublicAdvertisementSetting | null;
    appliedTo: string;
    serviceType: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateAdvertisementSettingSubscription = {
  onCreateAdvertisementSetting?: {
    __typename: "AdvertisementSetting";
    id: string;
    active: boolean;
    creditsCount: number;
    description?: string | null;
    entity: EntityType;
    internalName: string;
    name: string;
    numberOfDays: number;
    reactivationCreditsCount: number;
    order: number;
    appliedTo: string;
    public?: PublicAdvertisementSetting | null;
    advertisementTypeID: string;
    createdAt: string;
    updatedAt: string;
    advertisementType?: {
      __typename: "AdvertisementType";
      id: string;
      active: boolean;
      entity: EntityType;
      name: string;
      jobDescription?: string | null;
      order: number;
      approvalRequired: string;
      components?: string | null;
      createdAt: string;
      updatedAt: string;
      advertisements?: {
        __typename: "ModelAdvertisementSettingConnection";
        items: Array<{
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateAdvertisementSettingSubscription = {
  onUpdateAdvertisementSetting?: {
    __typename: "AdvertisementSetting";
    id: string;
    active: boolean;
    creditsCount: number;
    description?: string | null;
    entity: EntityType;
    internalName: string;
    name: string;
    numberOfDays: number;
    reactivationCreditsCount: number;
    order: number;
    appliedTo: string;
    public?: PublicAdvertisementSetting | null;
    advertisementTypeID: string;
    createdAt: string;
    updatedAt: string;
    advertisementType?: {
      __typename: "AdvertisementType";
      id: string;
      active: boolean;
      entity: EntityType;
      name: string;
      jobDescription?: string | null;
      order: number;
      approvalRequired: string;
      components?: string | null;
      createdAt: string;
      updatedAt: string;
      advertisements?: {
        __typename: "ModelAdvertisementSettingConnection";
        items: Array<{
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteAdvertisementSettingSubscription = {
  onDeleteAdvertisementSetting?: {
    __typename: "AdvertisementSetting";
    id: string;
    active: boolean;
    creditsCount: number;
    description?: string | null;
    entity: EntityType;
    internalName: string;
    name: string;
    numberOfDays: number;
    reactivationCreditsCount: number;
    order: number;
    appliedTo: string;
    public?: PublicAdvertisementSetting | null;
    advertisementTypeID: string;
    createdAt: string;
    updatedAt: string;
    advertisementType?: {
      __typename: "AdvertisementType";
      id: string;
      active: boolean;
      entity: EntityType;
      name: string;
      jobDescription?: string | null;
      order: number;
      approvalRequired: string;
      components?: string | null;
      createdAt: string;
      updatedAt: string;
      advertisements?: {
        __typename: "ModelAdvertisementSettingConnection";
        items: Array<{
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateWorkerTypeSubscription = {
  onCreateWorkerType?: {
    __typename: "WorkerType";
    id: string;
    name: string;
    country: Country;
    level: number;
    order: number;
    createdAt: string;
    updatedAt: string;
    childEdges?: {
      __typename: "ModelWorkerTypeEdgeConnection";
      items: Array<{
        __typename: "WorkerTypeEdge";
        id: string;
        parentNodeId: string;
        childNodeId: string;
        country: Country;
        createdAt: string;
        updatedAt: string;
        parentNode?: {
          __typename: "WorkerType";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelWorkerTypeEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        childNode?: {
          __typename: "WorkerType";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelWorkerTypeEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnUpdateWorkerTypeSubscription = {
  onUpdateWorkerType?: {
    __typename: "WorkerType";
    id: string;
    name: string;
    country: Country;
    level: number;
    order: number;
    createdAt: string;
    updatedAt: string;
    childEdges?: {
      __typename: "ModelWorkerTypeEdgeConnection";
      items: Array<{
        __typename: "WorkerTypeEdge";
        id: string;
        parentNodeId: string;
        childNodeId: string;
        country: Country;
        createdAt: string;
        updatedAt: string;
        parentNode?: {
          __typename: "WorkerType";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelWorkerTypeEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        childNode?: {
          __typename: "WorkerType";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelWorkerTypeEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnDeleteWorkerTypeSubscription = {
  onDeleteWorkerType?: {
    __typename: "WorkerType";
    id: string;
    name: string;
    country: Country;
    level: number;
    order: number;
    createdAt: string;
    updatedAt: string;
    childEdges?: {
      __typename: "ModelWorkerTypeEdgeConnection";
      items: Array<{
        __typename: "WorkerTypeEdge";
        id: string;
        parentNodeId: string;
        childNodeId: string;
        country: Country;
        createdAt: string;
        updatedAt: string;
        parentNode?: {
          __typename: "WorkerType";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelWorkerTypeEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        childNode?: {
          __typename: "WorkerType";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelWorkerTypeEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnCreateWorkerTypeEdgeSubscription = {
  onCreateWorkerTypeEdge?: {
    __typename: "WorkerTypeEdge";
    id: string;
    parentNodeId: string;
    childNodeId: string;
    country: Country;
    createdAt: string;
    updatedAt: string;
    parentNode?: {
      __typename: "WorkerType";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelWorkerTypeEdgeConnection";
        items: Array<{
          __typename: "WorkerTypeEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    childNode?: {
      __typename: "WorkerType";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelWorkerTypeEdgeConnection";
        items: Array<{
          __typename: "WorkerTypeEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateWorkerTypeEdgeSubscription = {
  onUpdateWorkerTypeEdge?: {
    __typename: "WorkerTypeEdge";
    id: string;
    parentNodeId: string;
    childNodeId: string;
    country: Country;
    createdAt: string;
    updatedAt: string;
    parentNode?: {
      __typename: "WorkerType";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelWorkerTypeEdgeConnection";
        items: Array<{
          __typename: "WorkerTypeEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    childNode?: {
      __typename: "WorkerType";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelWorkerTypeEdgeConnection";
        items: Array<{
          __typename: "WorkerTypeEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteWorkerTypeEdgeSubscription = {
  onDeleteWorkerTypeEdge?: {
    __typename: "WorkerTypeEdge";
    id: string;
    parentNodeId: string;
    childNodeId: string;
    country: Country;
    createdAt: string;
    updatedAt: string;
    parentNode?: {
      __typename: "WorkerType";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelWorkerTypeEdgeConnection";
        items: Array<{
          __typename: "WorkerTypeEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    childNode?: {
      __typename: "WorkerType";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelWorkerTypeEdgeConnection";
        items: Array<{
          __typename: "WorkerTypeEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "WorkerType";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateSuitableForSubscription = {
  onCreateSuitableFor?: {
    __typename: "SuitableFor";
    id: string;
    name: string;
    country: Country;
    level: number;
    order: number;
    createdAt: string;
    updatedAt: string;
    childEdges?: {
      __typename: "ModelSuitableForEdgeConnection";
      items: Array<{
        __typename: "SuitableForEdge";
        id: string;
        parentNodeId: string;
        childNodeId: string;
        country: Country;
        createdAt: string;
        updatedAt: string;
        parentNode?: {
          __typename: "SuitableFor";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelSuitableForEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        childNode?: {
          __typename: "SuitableFor";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelSuitableForEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnUpdateSuitableForSubscription = {
  onUpdateSuitableFor?: {
    __typename: "SuitableFor";
    id: string;
    name: string;
    country: Country;
    level: number;
    order: number;
    createdAt: string;
    updatedAt: string;
    childEdges?: {
      __typename: "ModelSuitableForEdgeConnection";
      items: Array<{
        __typename: "SuitableForEdge";
        id: string;
        parentNodeId: string;
        childNodeId: string;
        country: Country;
        createdAt: string;
        updatedAt: string;
        parentNode?: {
          __typename: "SuitableFor";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelSuitableForEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        childNode?: {
          __typename: "SuitableFor";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelSuitableForEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnDeleteSuitableForSubscription = {
  onDeleteSuitableFor?: {
    __typename: "SuitableFor";
    id: string;
    name: string;
    country: Country;
    level: number;
    order: number;
    createdAt: string;
    updatedAt: string;
    childEdges?: {
      __typename: "ModelSuitableForEdgeConnection";
      items: Array<{
        __typename: "SuitableForEdge";
        id: string;
        parentNodeId: string;
        childNodeId: string;
        country: Country;
        createdAt: string;
        updatedAt: string;
        parentNode?: {
          __typename: "SuitableFor";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelSuitableForEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        childNode?: {
          __typename: "SuitableFor";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelSuitableForEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnCreateSuitableForEdgeSubscription = {
  onCreateSuitableForEdge?: {
    __typename: "SuitableForEdge";
    id: string;
    parentNodeId: string;
    childNodeId: string;
    country: Country;
    createdAt: string;
    updatedAt: string;
    parentNode?: {
      __typename: "SuitableFor";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelSuitableForEdgeConnection";
        items: Array<{
          __typename: "SuitableForEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    childNode?: {
      __typename: "SuitableFor";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelSuitableForEdgeConnection";
        items: Array<{
          __typename: "SuitableForEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateSuitableForEdgeSubscription = {
  onUpdateSuitableForEdge?: {
    __typename: "SuitableForEdge";
    id: string;
    parentNodeId: string;
    childNodeId: string;
    country: Country;
    createdAt: string;
    updatedAt: string;
    parentNode?: {
      __typename: "SuitableFor";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelSuitableForEdgeConnection";
        items: Array<{
          __typename: "SuitableForEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    childNode?: {
      __typename: "SuitableFor";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelSuitableForEdgeConnection";
        items: Array<{
          __typename: "SuitableForEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteSuitableForEdgeSubscription = {
  onDeleteSuitableForEdge?: {
    __typename: "SuitableForEdge";
    id: string;
    parentNodeId: string;
    childNodeId: string;
    country: Country;
    createdAt: string;
    updatedAt: string;
    parentNode?: {
      __typename: "SuitableFor";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelSuitableForEdgeConnection";
        items: Array<{
          __typename: "SuitableForEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    childNode?: {
      __typename: "SuitableFor";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelSuitableForEdgeConnection";
        items: Array<{
          __typename: "SuitableForEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "SuitableFor";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateEmployerSubscription = {
  onCreateEmployer?: {
    __typename: "Employer";
    id: string;
    name: string;
    country: Country;
    level: number;
    order: number;
    createdAt: string;
    updatedAt: string;
    childEdges?: {
      __typename: "ModelEmployerEdgeConnection";
      items: Array<{
        __typename: "EmployerEdge";
        id: string;
        parentNodeId: string;
        childNodeId: string;
        country: Country;
        createdAt: string;
        updatedAt: string;
        parentNode?: {
          __typename: "Employer";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelEmployerEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        childNode?: {
          __typename: "Employer";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelEmployerEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnUpdateEmployerSubscription = {
  onUpdateEmployer?: {
    __typename: "Employer";
    id: string;
    name: string;
    country: Country;
    level: number;
    order: number;
    createdAt: string;
    updatedAt: string;
    childEdges?: {
      __typename: "ModelEmployerEdgeConnection";
      items: Array<{
        __typename: "EmployerEdge";
        id: string;
        parentNodeId: string;
        childNodeId: string;
        country: Country;
        createdAt: string;
        updatedAt: string;
        parentNode?: {
          __typename: "Employer";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelEmployerEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        childNode?: {
          __typename: "Employer";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelEmployerEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnDeleteEmployerSubscription = {
  onDeleteEmployer?: {
    __typename: "Employer";
    id: string;
    name: string;
    country: Country;
    level: number;
    order: number;
    createdAt: string;
    updatedAt: string;
    childEdges?: {
      __typename: "ModelEmployerEdgeConnection";
      items: Array<{
        __typename: "EmployerEdge";
        id: string;
        parentNodeId: string;
        childNodeId: string;
        country: Country;
        createdAt: string;
        updatedAt: string;
        parentNode?: {
          __typename: "Employer";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelEmployerEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        childNode?: {
          __typename: "Employer";
          id: string;
          name: string;
          country: Country;
          level: number;
          order: number;
          createdAt: string;
          updatedAt: string;
          childEdges?: {
            __typename: "ModelEmployerEdgeConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnCreateEmployerEdgeSubscription = {
  onCreateEmployerEdge?: {
    __typename: "EmployerEdge";
    id: string;
    parentNodeId: string;
    childNodeId: string;
    country: Country;
    createdAt: string;
    updatedAt: string;
    parentNode?: {
      __typename: "Employer";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelEmployerEdgeConnection";
        items: Array<{
          __typename: "EmployerEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    childNode?: {
      __typename: "Employer";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelEmployerEdgeConnection";
        items: Array<{
          __typename: "EmployerEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateEmployerEdgeSubscription = {
  onUpdateEmployerEdge?: {
    __typename: "EmployerEdge";
    id: string;
    parentNodeId: string;
    childNodeId: string;
    country: Country;
    createdAt: string;
    updatedAt: string;
    parentNode?: {
      __typename: "Employer";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelEmployerEdgeConnection";
        items: Array<{
          __typename: "EmployerEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    childNode?: {
      __typename: "Employer";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelEmployerEdgeConnection";
        items: Array<{
          __typename: "EmployerEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteEmployerEdgeSubscription = {
  onDeleteEmployerEdge?: {
    __typename: "EmployerEdge";
    id: string;
    parentNodeId: string;
    childNodeId: string;
    country: Country;
    createdAt: string;
    updatedAt: string;
    parentNode?: {
      __typename: "Employer";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelEmployerEdgeConnection";
        items: Array<{
          __typename: "EmployerEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    childNode?: {
      __typename: "Employer";
      id: string;
      name: string;
      country: Country;
      level: number;
      order: number;
      createdAt: string;
      updatedAt: string;
      childEdges?: {
        __typename: "ModelEmployerEdgeConnection";
        items: Array<{
          __typename: "EmployerEdge";
          id: string;
          parentNodeId: string;
          childNodeId: string;
          country: Country;
          createdAt: string;
          updatedAt: string;
          parentNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
          childNode?: {
            __typename: "Employer";
            id: string;
            name: string;
            country: Country;
            level: number;
            order: number;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateProfileSubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateProfileSubscription = {
  onCreateProfile?: {
    __typename: "Profile";
    id: string;
    credits?: number | null;
    sub?: string | null;
    email: string;
    entity?: EntityType | null;
    entityState?: string | null;
    profileImage?: string | null;
    firstDegree?: string | null;
    forename?: string | null;
    surname?: string | null;
    secondDegree?: string | null;
    aboutMe?: string | null;
    educations?: string | null;
    workExperiences?: string | null;
    publications?: string | null;
    conferences?: string | null;
    certificates?: string | null;
    languages?: string | null;
    otherInformation?: string | null;
    qualifications?: string | null;
    name?: string | null;
    crn?: string | null;
    vatRegNumber?: string | null;
    street?: string | null;
    buildingIdentificationNumber?: string | null;
    municipality?: string | null;
    postCode?: string | null;
    language?: string | null;
    phone?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    messages?: {
      __typename: "ModelMessageInboxConnection";
      items: Array<{
        __typename: "MessageInbox";
        id: string;
        name: string;
        email: string;
        phoneNumber?: string | null;
        message: string;
        file?: string | null;
        state?: MessageState | null;
        createdAt: string;
        updatedAt: string;
        profileID: string;
        advertisementID: string;
        profile?: {
          __typename: "Profile";
          id: string;
          credits?: number | null;
          sub?: string | null;
          email: string;
          entity?: EntityType | null;
          entityState?: string | null;
          profileImage?: string | null;
          firstDegree?: string | null;
          forename?: string | null;
          surname?: string | null;
          secondDegree?: string | null;
          aboutMe?: string | null;
          educations?: string | null;
          workExperiences?: string | null;
          publications?: string | null;
          conferences?: string | null;
          certificates?: string | null;
          languages?: string | null;
          otherInformation?: string | null;
          qualifications?: string | null;
          name?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          phone?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelMessageInboxConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        advertisement?: {
          __typename: "Advertisement";
          id: string;
          friendlyId?: number | null;
          entity: EntityType;
          active?: Active | null;
          state?: string | null;
          stateDescription?: string | null;
          paymentState?: string | null;
          jobPosition: string;
          jobShortDescription: string;
          jobDescription?: string | null;
          place: string;
          salaryFrom?: number | null;
          salaryTo?: number | null;
          salarySpecifiedInText?: boolean | null;
          reactivationCount?: number | null;
          currentReactivationCount?: number | null;
          automaticReactivation?: boolean | null;
          leadingPosition?: boolean | null;
          residentialPlace?: boolean | null;
          typeOfCooperation?: string | null;
          jobType?: string | null;
          financialConditions?: string | null;
          typeOfInternship?: string | null;
          contactFirstDegree?: string | null;
          contactForename?: string | null;
          contactSurname?: string | null;
          contactSecondDegree?: string | null;
          contactEmail?: string | null;
          contactPhoneNumber?: string | null;
          hideContactInformation?: boolean | null;
          workType?: string | null;
          suitableFor?: string | null;
          workTypeStringified?: string | null;
          suitableForStringified?: string | null;
          employmentTypesStringified?: string | null;
          employmentTypes?: string | null;
          paymentType?: PaymentType | null;
          advertisementSettingID: string;
          advertisementTypeID?: string | null;
          companyID?: string | null;
          profileID?: string | null;
          expirationDate: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          advertisementSetting?: {
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
          analytics?: {
            __typename: "ModelAdvertisementAnalyticConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnUpdateProfileSubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateProfileSubscription = {
  onUpdateProfile?: {
    __typename: "Profile";
    id: string;
    credits?: number | null;
    sub?: string | null;
    email: string;
    entity?: EntityType | null;
    entityState?: string | null;
    profileImage?: string | null;
    firstDegree?: string | null;
    forename?: string | null;
    surname?: string | null;
    secondDegree?: string | null;
    aboutMe?: string | null;
    educations?: string | null;
    workExperiences?: string | null;
    publications?: string | null;
    conferences?: string | null;
    certificates?: string | null;
    languages?: string | null;
    otherInformation?: string | null;
    qualifications?: string | null;
    name?: string | null;
    crn?: string | null;
    vatRegNumber?: string | null;
    street?: string | null;
    buildingIdentificationNumber?: string | null;
    municipality?: string | null;
    postCode?: string | null;
    language?: string | null;
    phone?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    messages?: {
      __typename: "ModelMessageInboxConnection";
      items: Array<{
        __typename: "MessageInbox";
        id: string;
        name: string;
        email: string;
        phoneNumber?: string | null;
        message: string;
        file?: string | null;
        state?: MessageState | null;
        createdAt: string;
        updatedAt: string;
        profileID: string;
        advertisementID: string;
        profile?: {
          __typename: "Profile";
          id: string;
          credits?: number | null;
          sub?: string | null;
          email: string;
          entity?: EntityType | null;
          entityState?: string | null;
          profileImage?: string | null;
          firstDegree?: string | null;
          forename?: string | null;
          surname?: string | null;
          secondDegree?: string | null;
          aboutMe?: string | null;
          educations?: string | null;
          workExperiences?: string | null;
          publications?: string | null;
          conferences?: string | null;
          certificates?: string | null;
          languages?: string | null;
          otherInformation?: string | null;
          qualifications?: string | null;
          name?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          phone?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelMessageInboxConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        advertisement?: {
          __typename: "Advertisement";
          id: string;
          friendlyId?: number | null;
          entity: EntityType;
          active?: Active | null;
          state?: string | null;
          stateDescription?: string | null;
          paymentState?: string | null;
          jobPosition: string;
          jobShortDescription: string;
          jobDescription?: string | null;
          place: string;
          salaryFrom?: number | null;
          salaryTo?: number | null;
          salarySpecifiedInText?: boolean | null;
          reactivationCount?: number | null;
          currentReactivationCount?: number | null;
          automaticReactivation?: boolean | null;
          leadingPosition?: boolean | null;
          residentialPlace?: boolean | null;
          typeOfCooperation?: string | null;
          jobType?: string | null;
          financialConditions?: string | null;
          typeOfInternship?: string | null;
          contactFirstDegree?: string | null;
          contactForename?: string | null;
          contactSurname?: string | null;
          contactSecondDegree?: string | null;
          contactEmail?: string | null;
          contactPhoneNumber?: string | null;
          hideContactInformation?: boolean | null;
          workType?: string | null;
          suitableFor?: string | null;
          workTypeStringified?: string | null;
          suitableForStringified?: string | null;
          employmentTypesStringified?: string | null;
          employmentTypes?: string | null;
          paymentType?: PaymentType | null;
          advertisementSettingID: string;
          advertisementTypeID?: string | null;
          companyID?: string | null;
          profileID?: string | null;
          expirationDate: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          advertisementSetting?: {
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
          analytics?: {
            __typename: "ModelAdvertisementAnalyticConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnDeleteProfileSubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteProfileSubscription = {
  onDeleteProfile?: {
    __typename: "Profile";
    id: string;
    credits?: number | null;
    sub?: string | null;
    email: string;
    entity?: EntityType | null;
    entityState?: string | null;
    profileImage?: string | null;
    firstDegree?: string | null;
    forename?: string | null;
    surname?: string | null;
    secondDegree?: string | null;
    aboutMe?: string | null;
    educations?: string | null;
    workExperiences?: string | null;
    publications?: string | null;
    conferences?: string | null;
    certificates?: string | null;
    languages?: string | null;
    otherInformation?: string | null;
    qualifications?: string | null;
    name?: string | null;
    crn?: string | null;
    vatRegNumber?: string | null;
    street?: string | null;
    buildingIdentificationNumber?: string | null;
    municipality?: string | null;
    postCode?: string | null;
    language?: string | null;
    phone?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    messages?: {
      __typename: "ModelMessageInboxConnection";
      items: Array<{
        __typename: "MessageInbox";
        id: string;
        name: string;
        email: string;
        phoneNumber?: string | null;
        message: string;
        file?: string | null;
        state?: MessageState | null;
        createdAt: string;
        updatedAt: string;
        profileID: string;
        advertisementID: string;
        profile?: {
          __typename: "Profile";
          id: string;
          credits?: number | null;
          sub?: string | null;
          email: string;
          entity?: EntityType | null;
          entityState?: string | null;
          profileImage?: string | null;
          firstDegree?: string | null;
          forename?: string | null;
          surname?: string | null;
          secondDegree?: string | null;
          aboutMe?: string | null;
          educations?: string | null;
          workExperiences?: string | null;
          publications?: string | null;
          conferences?: string | null;
          certificates?: string | null;
          languages?: string | null;
          otherInformation?: string | null;
          qualifications?: string | null;
          name?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          phone?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelMessageInboxConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        advertisement?: {
          __typename: "Advertisement";
          id: string;
          friendlyId?: number | null;
          entity: EntityType;
          active?: Active | null;
          state?: string | null;
          stateDescription?: string | null;
          paymentState?: string | null;
          jobPosition: string;
          jobShortDescription: string;
          jobDescription?: string | null;
          place: string;
          salaryFrom?: number | null;
          salaryTo?: number | null;
          salarySpecifiedInText?: boolean | null;
          reactivationCount?: number | null;
          currentReactivationCount?: number | null;
          automaticReactivation?: boolean | null;
          leadingPosition?: boolean | null;
          residentialPlace?: boolean | null;
          typeOfCooperation?: string | null;
          jobType?: string | null;
          financialConditions?: string | null;
          typeOfInternship?: string | null;
          contactFirstDegree?: string | null;
          contactForename?: string | null;
          contactSurname?: string | null;
          contactSecondDegree?: string | null;
          contactEmail?: string | null;
          contactPhoneNumber?: string | null;
          hideContactInformation?: boolean | null;
          workType?: string | null;
          suitableFor?: string | null;
          workTypeStringified?: string | null;
          suitableForStringified?: string | null;
          employmentTypesStringified?: string | null;
          employmentTypes?: string | null;
          paymentType?: PaymentType | null;
          advertisementSettingID: string;
          advertisementTypeID?: string | null;
          companyID?: string | null;
          profileID?: string | null;
          expirationDate: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          advertisementSetting?: {
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
          analytics?: {
            __typename: "ModelAdvertisementAnalyticConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnCreateMessageInboxSubscription = {
  onCreateMessageInbox?: {
    __typename: "MessageInbox";
    id: string;
    name: string;
    email: string;
    phoneNumber?: string | null;
    message: string;
    file?: string | null;
    state?: MessageState | null;
    createdAt: string;
    updatedAt: string;
    profileID: string;
    advertisementID: string;
    profile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    advertisement?: {
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateMessageInboxSubscription = {
  onUpdateMessageInbox?: {
    __typename: "MessageInbox";
    id: string;
    name: string;
    email: string;
    phoneNumber?: string | null;
    message: string;
    file?: string | null;
    state?: MessageState | null;
    createdAt: string;
    updatedAt: string;
    profileID: string;
    advertisementID: string;
    profile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    advertisement?: {
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteMessageInboxSubscription = {
  onDeleteMessageInbox?: {
    __typename: "MessageInbox";
    id: string;
    name: string;
    email: string;
    phoneNumber?: string | null;
    message: string;
    file?: string | null;
    state?: MessageState | null;
    createdAt: string;
    updatedAt: string;
    profileID: string;
    advertisementID: string;
    profile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    advertisement?: {
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateCompanySubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateCompanySubscription = {
  onCreateCompany?: {
    __typename: "Company";
    id: string;
    entity: EntityType;
    entityState?: string | null;
    credits?: number | null;
    name: string;
    logo?: string | null;
    forename?: string | null;
    surname?: string | null;
    address?: string | null;
    phone?: string | null;
    email?: string | null;
    crn?: string | null;
    vatRegNumber?: string | null;
    street?: string | null;
    buildingIdentificationNumber?: string | null;
    municipality?: string | null;
    postCode?: string | null;
    language?: string | null;
    employerTaxonomy?: string | null;
    profileImage?: string | null;
    qualification?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    messages?: {
      __typename: "ModelCompanyMessageInboxConnection";
      items: Array<{
        __typename: "CompanyMessageInbox";
        id: string;
        name: string;
        email: string;
        state?: MessageState | null;
        phoneNumber?: string | null;
        message: string;
        file?: string | null;
        createdAt: string;
        updatedAt: string;
        companyID: string;
        advertisementID: string;
        company?: {
          __typename: "Company";
          id: string;
          entity: EntityType;
          entityState?: string | null;
          credits?: number | null;
          name: string;
          logo?: string | null;
          forename?: string | null;
          surname?: string | null;
          address?: string | null;
          phone?: string | null;
          email?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          employerTaxonomy?: string | null;
          profileImage?: string | null;
          qualification?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelCompanyMessageInboxConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelCompanyUserConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        advertisement?: {
          __typename: "Advertisement";
          id: string;
          friendlyId?: number | null;
          entity: EntityType;
          active?: Active | null;
          state?: string | null;
          stateDescription?: string | null;
          paymentState?: string | null;
          jobPosition: string;
          jobShortDescription: string;
          jobDescription?: string | null;
          place: string;
          salaryFrom?: number | null;
          salaryTo?: number | null;
          salarySpecifiedInText?: boolean | null;
          reactivationCount?: number | null;
          currentReactivationCount?: number | null;
          automaticReactivation?: boolean | null;
          leadingPosition?: boolean | null;
          residentialPlace?: boolean | null;
          typeOfCooperation?: string | null;
          jobType?: string | null;
          financialConditions?: string | null;
          typeOfInternship?: string | null;
          contactFirstDegree?: string | null;
          contactForename?: string | null;
          contactSurname?: string | null;
          contactSecondDegree?: string | null;
          contactEmail?: string | null;
          contactPhoneNumber?: string | null;
          hideContactInformation?: boolean | null;
          workType?: string | null;
          suitableFor?: string | null;
          workTypeStringified?: string | null;
          suitableForStringified?: string | null;
          employmentTypesStringified?: string | null;
          employmentTypes?: string | null;
          paymentType?: PaymentType | null;
          advertisementSettingID: string;
          advertisementTypeID?: string | null;
          companyID?: string | null;
          profileID?: string | null;
          expirationDate: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          advertisementSetting?: {
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
          analytics?: {
            __typename: "ModelAdvertisementAnalyticConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelCompanyUserConnection";
      items: Array<{
        __typename: "CompanyUser";
        id: string;
        entity: EntityType;
        name?: string | null;
        email: string;
        role: CompanyUserRole;
        createdAt: string;
        updatedAt: string;
        companyID: string;
        company?: {
          __typename: "Company";
          id: string;
          entity: EntityType;
          entityState?: string | null;
          credits?: number | null;
          name: string;
          logo?: string | null;
          forename?: string | null;
          surname?: string | null;
          address?: string | null;
          phone?: string | null;
          email?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          employerTaxonomy?: string | null;
          profileImage?: string | null;
          qualification?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelCompanyMessageInboxConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelCompanyUserConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnUpdateCompanySubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateCompanySubscription = {
  onUpdateCompany?: {
    __typename: "Company";
    id: string;
    entity: EntityType;
    entityState?: string | null;
    credits?: number | null;
    name: string;
    logo?: string | null;
    forename?: string | null;
    surname?: string | null;
    address?: string | null;
    phone?: string | null;
    email?: string | null;
    crn?: string | null;
    vatRegNumber?: string | null;
    street?: string | null;
    buildingIdentificationNumber?: string | null;
    municipality?: string | null;
    postCode?: string | null;
    language?: string | null;
    employerTaxonomy?: string | null;
    profileImage?: string | null;
    qualification?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    messages?: {
      __typename: "ModelCompanyMessageInboxConnection";
      items: Array<{
        __typename: "CompanyMessageInbox";
        id: string;
        name: string;
        email: string;
        state?: MessageState | null;
        phoneNumber?: string | null;
        message: string;
        file?: string | null;
        createdAt: string;
        updatedAt: string;
        companyID: string;
        advertisementID: string;
        company?: {
          __typename: "Company";
          id: string;
          entity: EntityType;
          entityState?: string | null;
          credits?: number | null;
          name: string;
          logo?: string | null;
          forename?: string | null;
          surname?: string | null;
          address?: string | null;
          phone?: string | null;
          email?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          employerTaxonomy?: string | null;
          profileImage?: string | null;
          qualification?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelCompanyMessageInboxConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelCompanyUserConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        advertisement?: {
          __typename: "Advertisement";
          id: string;
          friendlyId?: number | null;
          entity: EntityType;
          active?: Active | null;
          state?: string | null;
          stateDescription?: string | null;
          paymentState?: string | null;
          jobPosition: string;
          jobShortDescription: string;
          jobDescription?: string | null;
          place: string;
          salaryFrom?: number | null;
          salaryTo?: number | null;
          salarySpecifiedInText?: boolean | null;
          reactivationCount?: number | null;
          currentReactivationCount?: number | null;
          automaticReactivation?: boolean | null;
          leadingPosition?: boolean | null;
          residentialPlace?: boolean | null;
          typeOfCooperation?: string | null;
          jobType?: string | null;
          financialConditions?: string | null;
          typeOfInternship?: string | null;
          contactFirstDegree?: string | null;
          contactForename?: string | null;
          contactSurname?: string | null;
          contactSecondDegree?: string | null;
          contactEmail?: string | null;
          contactPhoneNumber?: string | null;
          hideContactInformation?: boolean | null;
          workType?: string | null;
          suitableFor?: string | null;
          workTypeStringified?: string | null;
          suitableForStringified?: string | null;
          employmentTypesStringified?: string | null;
          employmentTypes?: string | null;
          paymentType?: PaymentType | null;
          advertisementSettingID: string;
          advertisementTypeID?: string | null;
          companyID?: string | null;
          profileID?: string | null;
          expirationDate: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          advertisementSetting?: {
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
          analytics?: {
            __typename: "ModelAdvertisementAnalyticConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelCompanyUserConnection";
      items: Array<{
        __typename: "CompanyUser";
        id: string;
        entity: EntityType;
        name?: string | null;
        email: string;
        role: CompanyUserRole;
        createdAt: string;
        updatedAt: string;
        companyID: string;
        company?: {
          __typename: "Company";
          id: string;
          entity: EntityType;
          entityState?: string | null;
          credits?: number | null;
          name: string;
          logo?: string | null;
          forename?: string | null;
          surname?: string | null;
          address?: string | null;
          phone?: string | null;
          email?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          employerTaxonomy?: string | null;
          profileImage?: string | null;
          qualification?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelCompanyMessageInboxConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelCompanyUserConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnDeleteCompanySubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteCompanySubscription = {
  onDeleteCompany?: {
    __typename: "Company";
    id: string;
    entity: EntityType;
    entityState?: string | null;
    credits?: number | null;
    name: string;
    logo?: string | null;
    forename?: string | null;
    surname?: string | null;
    address?: string | null;
    phone?: string | null;
    email?: string | null;
    crn?: string | null;
    vatRegNumber?: string | null;
    street?: string | null;
    buildingIdentificationNumber?: string | null;
    municipality?: string | null;
    postCode?: string | null;
    language?: string | null;
    employerTaxonomy?: string | null;
    profileImage?: string | null;
    qualification?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    messages?: {
      __typename: "ModelCompanyMessageInboxConnection";
      items: Array<{
        __typename: "CompanyMessageInbox";
        id: string;
        name: string;
        email: string;
        state?: MessageState | null;
        phoneNumber?: string | null;
        message: string;
        file?: string | null;
        createdAt: string;
        updatedAt: string;
        companyID: string;
        advertisementID: string;
        company?: {
          __typename: "Company";
          id: string;
          entity: EntityType;
          entityState?: string | null;
          credits?: number | null;
          name: string;
          logo?: string | null;
          forename?: string | null;
          surname?: string | null;
          address?: string | null;
          phone?: string | null;
          email?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          employerTaxonomy?: string | null;
          profileImage?: string | null;
          qualification?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelCompanyMessageInboxConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelCompanyUserConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        advertisement?: {
          __typename: "Advertisement";
          id: string;
          friendlyId?: number | null;
          entity: EntityType;
          active?: Active | null;
          state?: string | null;
          stateDescription?: string | null;
          paymentState?: string | null;
          jobPosition: string;
          jobShortDescription: string;
          jobDescription?: string | null;
          place: string;
          salaryFrom?: number | null;
          salaryTo?: number | null;
          salarySpecifiedInText?: boolean | null;
          reactivationCount?: number | null;
          currentReactivationCount?: number | null;
          automaticReactivation?: boolean | null;
          leadingPosition?: boolean | null;
          residentialPlace?: boolean | null;
          typeOfCooperation?: string | null;
          jobType?: string | null;
          financialConditions?: string | null;
          typeOfInternship?: string | null;
          contactFirstDegree?: string | null;
          contactForename?: string | null;
          contactSurname?: string | null;
          contactSecondDegree?: string | null;
          contactEmail?: string | null;
          contactPhoneNumber?: string | null;
          hideContactInformation?: boolean | null;
          workType?: string | null;
          suitableFor?: string | null;
          workTypeStringified?: string | null;
          suitableForStringified?: string | null;
          employmentTypesStringified?: string | null;
          employmentTypes?: string | null;
          paymentType?: PaymentType | null;
          advertisementSettingID: string;
          advertisementTypeID?: string | null;
          companyID?: string | null;
          profileID?: string | null;
          expirationDate: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          advertisementSetting?: {
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
          analytics?: {
            __typename: "ModelAdvertisementAnalyticConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelCompanyUserConnection";
      items: Array<{
        __typename: "CompanyUser";
        id: string;
        entity: EntityType;
        name?: string | null;
        email: string;
        role: CompanyUserRole;
        createdAt: string;
        updatedAt: string;
        companyID: string;
        company?: {
          __typename: "Company";
          id: string;
          entity: EntityType;
          entityState?: string | null;
          credits?: number | null;
          name: string;
          logo?: string | null;
          forename?: string | null;
          surname?: string | null;
          address?: string | null;
          phone?: string | null;
          email?: string | null;
          crn?: string | null;
          vatRegNumber?: string | null;
          street?: string | null;
          buildingIdentificationNumber?: string | null;
          municipality?: string | null;
          postCode?: string | null;
          language?: string | null;
          employerTaxonomy?: string | null;
          profileImage?: string | null;
          qualification?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
          messages?: {
            __typename: "ModelCompanyMessageInboxConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelCompanyUserConnection";
            nextToken?: string | null;
          } | null;
        } | null;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnCreateCompanyMessageInboxSubscription = {
  onCreateCompanyMessageInbox?: {
    __typename: "CompanyMessageInbox";
    id: string;
    name: string;
    email: string;
    state?: MessageState | null;
    phoneNumber?: string | null;
    message: string;
    file?: string | null;
    createdAt: string;
    updatedAt: string;
    companyID: string;
    advertisementID: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    advertisement?: {
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateCompanyMessageInboxSubscription = {
  onUpdateCompanyMessageInbox?: {
    __typename: "CompanyMessageInbox";
    id: string;
    name: string;
    email: string;
    state?: MessageState | null;
    phoneNumber?: string | null;
    message: string;
    file?: string | null;
    createdAt: string;
    updatedAt: string;
    companyID: string;
    advertisementID: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    advertisement?: {
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteCompanyMessageInboxSubscription = {
  onDeleteCompanyMessageInbox?: {
    __typename: "CompanyMessageInbox";
    id: string;
    name: string;
    email: string;
    state?: MessageState | null;
    phoneNumber?: string | null;
    message: string;
    file?: string | null;
    createdAt: string;
    updatedAt: string;
    companyID: string;
    advertisementID: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    advertisement?: {
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateCompanyUserSubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateCompanyUserSubscription = {
  onCreateCompanyUser?: {
    __typename: "CompanyUser";
    id: string;
    entity: EntityType;
    name?: string | null;
    email: string;
    role: CompanyUserRole;
    createdAt: string;
    updatedAt: string;
    companyID: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    owner?: string | null;
  } | null;
};

export type OnUpdateCompanyUserSubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateCompanyUserSubscription = {
  onUpdateCompanyUser?: {
    __typename: "CompanyUser";
    id: string;
    entity: EntityType;
    name?: string | null;
    email: string;
    role: CompanyUserRole;
    createdAt: string;
    updatedAt: string;
    companyID: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    owner?: string | null;
  } | null;
};

export type OnDeleteCompanyUserSubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteCompanyUserSubscription = {
  onDeleteCompanyUser?: {
    __typename: "CompanyUser";
    id: string;
    entity: EntityType;
    name?: string | null;
    email: string;
    role: CompanyUserRole;
    createdAt: string;
    updatedAt: string;
    companyID: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    owner?: string | null;
  } | null;
};

export type OnCreateAdvertisementSubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateAdvertisementSubscription = {
  onCreateAdvertisement?: {
    __typename: "Advertisement";
    id: string;
    friendlyId?: number | null;
    entity: EntityType;
    active?: Active | null;
    state?: string | null;
    stateDescription?: string | null;
    paymentState?: string | null;
    jobPosition: string;
    jobShortDescription: string;
    jobDescription?: string | null;
    place: string;
    salaryFrom?: number | null;
    salaryTo?: number | null;
    salarySpecifiedInText?: boolean | null;
    reactivationCount?: number | null;
    currentReactivationCount?: number | null;
    automaticReactivation?: boolean | null;
    leadingPosition?: boolean | null;
    residentialPlace?: boolean | null;
    typeOfCooperation?: string | null;
    jobType?: string | null;
    financialConditions?: string | null;
    typeOfInternship?: string | null;
    contactFirstDegree?: string | null;
    contactForename?: string | null;
    contactSurname?: string | null;
    contactSecondDegree?: string | null;
    contactEmail?: string | null;
    contactPhoneNumber?: string | null;
    hideContactInformation?: boolean | null;
    workType?: string | null;
    suitableFor?: string | null;
    workTypeStringified?: string | null;
    suitableForStringified?: string | null;
    employmentTypesStringified?: string | null;
    employmentTypes?: string | null;
    paymentType?: PaymentType | null;
    advertisementSettingID: string;
    advertisementTypeID?: string | null;
    companyID?: string | null;
    profileID?: string | null;
    expirationDate: string;
    createdAt: string;
    updatedAt: string;
    advertisementType?: {
      __typename: "AdvertisementType";
      id: string;
      active: boolean;
      entity: EntityType;
      name: string;
      jobDescription?: string | null;
      order: number;
      approvalRequired: string;
      components?: string | null;
      createdAt: string;
      updatedAt: string;
      advertisements?: {
        __typename: "ModelAdvertisementSettingConnection";
        items: Array<{
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    advertisementSetting?: {
      __typename: "AdvertisementSetting";
      id: string;
      active: boolean;
      creditsCount: number;
      description?: string | null;
      entity: EntityType;
      internalName: string;
      name: string;
      numberOfDays: number;
      reactivationCreditsCount: number;
      order: number;
      appliedTo: string;
      public?: PublicAdvertisementSetting | null;
      advertisementTypeID: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null;
    profile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    owner?: string | null;
    analytics?: {
      __typename: "ModelAdvertisementAnalyticConnection";
      items: Array<{
        __typename: "AdvertisementAnalytic";
        id: string;
        name?: string | null;
        info?: string | null;
        advertisementID: string;
        createdAt: string;
        updatedAt: string;
        advertisement?: {
          __typename: "Advertisement";
          id: string;
          friendlyId?: number | null;
          entity: EntityType;
          active?: Active | null;
          state?: string | null;
          stateDescription?: string | null;
          paymentState?: string | null;
          jobPosition: string;
          jobShortDescription: string;
          jobDescription?: string | null;
          place: string;
          salaryFrom?: number | null;
          salaryTo?: number | null;
          salarySpecifiedInText?: boolean | null;
          reactivationCount?: number | null;
          currentReactivationCount?: number | null;
          automaticReactivation?: boolean | null;
          leadingPosition?: boolean | null;
          residentialPlace?: boolean | null;
          typeOfCooperation?: string | null;
          jobType?: string | null;
          financialConditions?: string | null;
          typeOfInternship?: string | null;
          contactFirstDegree?: string | null;
          contactForename?: string | null;
          contactSurname?: string | null;
          contactSecondDegree?: string | null;
          contactEmail?: string | null;
          contactPhoneNumber?: string | null;
          hideContactInformation?: boolean | null;
          workType?: string | null;
          suitableFor?: string | null;
          workTypeStringified?: string | null;
          suitableForStringified?: string | null;
          employmentTypesStringified?: string | null;
          employmentTypes?: string | null;
          paymentType?: PaymentType | null;
          advertisementSettingID: string;
          advertisementTypeID?: string | null;
          companyID?: string | null;
          profileID?: string | null;
          expirationDate: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          advertisementSetting?: {
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
          analytics?: {
            __typename: "ModelAdvertisementAnalyticConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnUpdateAdvertisementSubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateAdvertisementSubscription = {
  onUpdateAdvertisement?: {
    __typename: "Advertisement";
    id: string;
    friendlyId?: number | null;
    entity: EntityType;
    active?: Active | null;
    state?: string | null;
    stateDescription?: string | null;
    paymentState?: string | null;
    jobPosition: string;
    jobShortDescription: string;
    jobDescription?: string | null;
    place: string;
    salaryFrom?: number | null;
    salaryTo?: number | null;
    salarySpecifiedInText?: boolean | null;
    reactivationCount?: number | null;
    currentReactivationCount?: number | null;
    automaticReactivation?: boolean | null;
    leadingPosition?: boolean | null;
    residentialPlace?: boolean | null;
    typeOfCooperation?: string | null;
    jobType?: string | null;
    financialConditions?: string | null;
    typeOfInternship?: string | null;
    contactFirstDegree?: string | null;
    contactForename?: string | null;
    contactSurname?: string | null;
    contactSecondDegree?: string | null;
    contactEmail?: string | null;
    contactPhoneNumber?: string | null;
    hideContactInformation?: boolean | null;
    workType?: string | null;
    suitableFor?: string | null;
    workTypeStringified?: string | null;
    suitableForStringified?: string | null;
    employmentTypesStringified?: string | null;
    employmentTypes?: string | null;
    paymentType?: PaymentType | null;
    advertisementSettingID: string;
    advertisementTypeID?: string | null;
    companyID?: string | null;
    profileID?: string | null;
    expirationDate: string;
    createdAt: string;
    updatedAt: string;
    advertisementType?: {
      __typename: "AdvertisementType";
      id: string;
      active: boolean;
      entity: EntityType;
      name: string;
      jobDescription?: string | null;
      order: number;
      approvalRequired: string;
      components?: string | null;
      createdAt: string;
      updatedAt: string;
      advertisements?: {
        __typename: "ModelAdvertisementSettingConnection";
        items: Array<{
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    advertisementSetting?: {
      __typename: "AdvertisementSetting";
      id: string;
      active: boolean;
      creditsCount: number;
      description?: string | null;
      entity: EntityType;
      internalName: string;
      name: string;
      numberOfDays: number;
      reactivationCreditsCount: number;
      order: number;
      appliedTo: string;
      public?: PublicAdvertisementSetting | null;
      advertisementTypeID: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null;
    profile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    owner?: string | null;
    analytics?: {
      __typename: "ModelAdvertisementAnalyticConnection";
      items: Array<{
        __typename: "AdvertisementAnalytic";
        id: string;
        name?: string | null;
        info?: string | null;
        advertisementID: string;
        createdAt: string;
        updatedAt: string;
        advertisement?: {
          __typename: "Advertisement";
          id: string;
          friendlyId?: number | null;
          entity: EntityType;
          active?: Active | null;
          state?: string | null;
          stateDescription?: string | null;
          paymentState?: string | null;
          jobPosition: string;
          jobShortDescription: string;
          jobDescription?: string | null;
          place: string;
          salaryFrom?: number | null;
          salaryTo?: number | null;
          salarySpecifiedInText?: boolean | null;
          reactivationCount?: number | null;
          currentReactivationCount?: number | null;
          automaticReactivation?: boolean | null;
          leadingPosition?: boolean | null;
          residentialPlace?: boolean | null;
          typeOfCooperation?: string | null;
          jobType?: string | null;
          financialConditions?: string | null;
          typeOfInternship?: string | null;
          contactFirstDegree?: string | null;
          contactForename?: string | null;
          contactSurname?: string | null;
          contactSecondDegree?: string | null;
          contactEmail?: string | null;
          contactPhoneNumber?: string | null;
          hideContactInformation?: boolean | null;
          workType?: string | null;
          suitableFor?: string | null;
          workTypeStringified?: string | null;
          suitableForStringified?: string | null;
          employmentTypesStringified?: string | null;
          employmentTypes?: string | null;
          paymentType?: PaymentType | null;
          advertisementSettingID: string;
          advertisementTypeID?: string | null;
          companyID?: string | null;
          profileID?: string | null;
          expirationDate: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          advertisementSetting?: {
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
          analytics?: {
            __typename: "ModelAdvertisementAnalyticConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnDeleteAdvertisementSubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteAdvertisementSubscription = {
  onDeleteAdvertisement?: {
    __typename: "Advertisement";
    id: string;
    friendlyId?: number | null;
    entity: EntityType;
    active?: Active | null;
    state?: string | null;
    stateDescription?: string | null;
    paymentState?: string | null;
    jobPosition: string;
    jobShortDescription: string;
    jobDescription?: string | null;
    place: string;
    salaryFrom?: number | null;
    salaryTo?: number | null;
    salarySpecifiedInText?: boolean | null;
    reactivationCount?: number | null;
    currentReactivationCount?: number | null;
    automaticReactivation?: boolean | null;
    leadingPosition?: boolean | null;
    residentialPlace?: boolean | null;
    typeOfCooperation?: string | null;
    jobType?: string | null;
    financialConditions?: string | null;
    typeOfInternship?: string | null;
    contactFirstDegree?: string | null;
    contactForename?: string | null;
    contactSurname?: string | null;
    contactSecondDegree?: string | null;
    contactEmail?: string | null;
    contactPhoneNumber?: string | null;
    hideContactInformation?: boolean | null;
    workType?: string | null;
    suitableFor?: string | null;
    workTypeStringified?: string | null;
    suitableForStringified?: string | null;
    employmentTypesStringified?: string | null;
    employmentTypes?: string | null;
    paymentType?: PaymentType | null;
    advertisementSettingID: string;
    advertisementTypeID?: string | null;
    companyID?: string | null;
    profileID?: string | null;
    expirationDate: string;
    createdAt: string;
    updatedAt: string;
    advertisementType?: {
      __typename: "AdvertisementType";
      id: string;
      active: boolean;
      entity: EntityType;
      name: string;
      jobDescription?: string | null;
      order: number;
      approvalRequired: string;
      components?: string | null;
      createdAt: string;
      updatedAt: string;
      advertisements?: {
        __typename: "ModelAdvertisementSettingConnection";
        items: Array<{
          __typename: "AdvertisementSetting";
          id: string;
          active: boolean;
          creditsCount: number;
          description?: string | null;
          entity: EntityType;
          internalName: string;
          name: string;
          numberOfDays: number;
          reactivationCreditsCount: number;
          order: number;
          appliedTo: string;
          public?: PublicAdvertisementSetting | null;
          advertisementTypeID: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    advertisementSetting?: {
      __typename: "AdvertisementSetting";
      id: string;
      active: boolean;
      creditsCount: number;
      description?: string | null;
      entity: EntityType;
      internalName: string;
      name: string;
      numberOfDays: number;
      reactivationCreditsCount: number;
      order: number;
      appliedTo: string;
      public?: PublicAdvertisementSetting | null;
      advertisementTypeID: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null;
    profile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    owner?: string | null;
    analytics?: {
      __typename: "ModelAdvertisementAnalyticConnection";
      items: Array<{
        __typename: "AdvertisementAnalytic";
        id: string;
        name?: string | null;
        info?: string | null;
        advertisementID: string;
        createdAt: string;
        updatedAt: string;
        advertisement?: {
          __typename: "Advertisement";
          id: string;
          friendlyId?: number | null;
          entity: EntityType;
          active?: Active | null;
          state?: string | null;
          stateDescription?: string | null;
          paymentState?: string | null;
          jobPosition: string;
          jobShortDescription: string;
          jobDescription?: string | null;
          place: string;
          salaryFrom?: number | null;
          salaryTo?: number | null;
          salarySpecifiedInText?: boolean | null;
          reactivationCount?: number | null;
          currentReactivationCount?: number | null;
          automaticReactivation?: boolean | null;
          leadingPosition?: boolean | null;
          residentialPlace?: boolean | null;
          typeOfCooperation?: string | null;
          jobType?: string | null;
          financialConditions?: string | null;
          typeOfInternship?: string | null;
          contactFirstDegree?: string | null;
          contactForename?: string | null;
          contactSurname?: string | null;
          contactSecondDegree?: string | null;
          contactEmail?: string | null;
          contactPhoneNumber?: string | null;
          hideContactInformation?: boolean | null;
          workType?: string | null;
          suitableFor?: string | null;
          workTypeStringified?: string | null;
          suitableForStringified?: string | null;
          employmentTypesStringified?: string | null;
          employmentTypes?: string | null;
          paymentType?: PaymentType | null;
          advertisementSettingID: string;
          advertisementTypeID?: string | null;
          companyID?: string | null;
          profileID?: string | null;
          expirationDate: string;
          createdAt: string;
          updatedAt: string;
          advertisementType?: {
            __typename: "AdvertisementType";
            id: string;
            active: boolean;
            entity: EntityType;
            name: string;
            jobDescription?: string | null;
            order: number;
            approvalRequired: string;
            components?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          advertisementSetting?: {
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
          analytics?: {
            __typename: "ModelAdvertisementAnalyticConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnCreateAdvertisementAnalyticSubscription = {
  onCreateAdvertisementAnalytic?: {
    __typename: "AdvertisementAnalytic";
    id: string;
    name?: string | null;
    info?: string | null;
    advertisementID: string;
    createdAt: string;
    updatedAt: string;
    advertisement?: {
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateAdvertisementAnalyticSubscription = {
  onUpdateAdvertisementAnalytic?: {
    __typename: "AdvertisementAnalytic";
    id: string;
    name?: string | null;
    info?: string | null;
    advertisementID: string;
    createdAt: string;
    updatedAt: string;
    advertisement?: {
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteAdvertisementAnalyticSubscription = {
  onDeleteAdvertisementAnalytic?: {
    __typename: "AdvertisementAnalytic";
    id: string;
    name?: string | null;
    info?: string | null;
    advertisementID: string;
    createdAt: string;
    updatedAt: string;
    advertisement?: {
      __typename: "Advertisement";
      id: string;
      friendlyId?: number | null;
      entity: EntityType;
      active?: Active | null;
      state?: string | null;
      stateDescription?: string | null;
      paymentState?: string | null;
      jobPosition: string;
      jobShortDescription: string;
      jobDescription?: string | null;
      place: string;
      salaryFrom?: number | null;
      salaryTo?: number | null;
      salarySpecifiedInText?: boolean | null;
      reactivationCount?: number | null;
      currentReactivationCount?: number | null;
      automaticReactivation?: boolean | null;
      leadingPosition?: boolean | null;
      residentialPlace?: boolean | null;
      typeOfCooperation?: string | null;
      jobType?: string | null;
      financialConditions?: string | null;
      typeOfInternship?: string | null;
      contactFirstDegree?: string | null;
      contactForename?: string | null;
      contactSurname?: string | null;
      contactSecondDegree?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      hideContactInformation?: boolean | null;
      workType?: string | null;
      suitableFor?: string | null;
      workTypeStringified?: string | null;
      suitableForStringified?: string | null;
      employmentTypesStringified?: string | null;
      employmentTypes?: string | null;
      paymentType?: PaymentType | null;
      advertisementSettingID: string;
      advertisementTypeID?: string | null;
      companyID?: string | null;
      profileID?: string | null;
      expirationDate: string;
      createdAt: string;
      updatedAt: string;
      advertisementType?: {
        __typename: "AdvertisementType";
        id: string;
        active: boolean;
        entity: EntityType;
        name: string;
        jobDescription?: string | null;
        order: number;
        approvalRequired: string;
        components?: string | null;
        createdAt: string;
        updatedAt: string;
        advertisements?: {
          __typename: "ModelAdvertisementSettingConnection";
          items: Array<{
            __typename: "AdvertisementSetting";
            id: string;
            active: boolean;
            creditsCount: number;
            description?: string | null;
            entity: EntityType;
            internalName: string;
            name: string;
            numberOfDays: number;
            reactivationCreditsCount: number;
            order: number;
            appliedTo: string;
            public?: PublicAdvertisementSetting | null;
            advertisementTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      advertisementSetting?: {
        __typename: "AdvertisementSetting";
        id: string;
        active: boolean;
        creditsCount: number;
        description?: string | null;
        entity: EntityType;
        internalName: string;
        name: string;
        numberOfDays: number;
        reactivationCreditsCount: number;
        order: number;
        appliedTo: string;
        public?: PublicAdvertisementSetting | null;
        advertisementTypeID: string;
        createdAt: string;
        updatedAt: string;
        advertisementType?: {
          __typename: "AdvertisementType";
          id: string;
          active: boolean;
          entity: EntityType;
          name: string;
          jobDescription?: string | null;
          order: number;
          approvalRequired: string;
          components?: string | null;
          createdAt: string;
          updatedAt: string;
          advertisements?: {
            __typename: "ModelAdvertisementSettingConnection";
            nextToken?: string | null;
          } | null;
        } | null;
      } | null;
      profile?: {
        __typename: "Profile";
        id: string;
        credits?: number | null;
        sub?: string | null;
        email: string;
        entity?: EntityType | null;
        entityState?: string | null;
        profileImage?: string | null;
        firstDegree?: string | null;
        forename?: string | null;
        surname?: string | null;
        secondDegree?: string | null;
        aboutMe?: string | null;
        educations?: string | null;
        workExperiences?: string | null;
        publications?: string | null;
        conferences?: string | null;
        certificates?: string | null;
        languages?: string | null;
        otherInformation?: string | null;
        qualifications?: string | null;
        name?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        phone?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelMessageInboxConnection";
          items: Array<{
            __typename: "MessageInbox";
            id: string;
            name: string;
            email: string;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            state?: MessageState | null;
            createdAt: string;
            updatedAt: string;
            profileID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      company?: {
        __typename: "Company";
        id: string;
        entity: EntityType;
        entityState?: string | null;
        credits?: number | null;
        name: string;
        logo?: string | null;
        forename?: string | null;
        surname?: string | null;
        address?: string | null;
        phone?: string | null;
        email?: string | null;
        crn?: string | null;
        vatRegNumber?: string | null;
        street?: string | null;
        buildingIdentificationNumber?: string | null;
        municipality?: string | null;
        postCode?: string | null;
        language?: string | null;
        employerTaxonomy?: string | null;
        profileImage?: string | null;
        qualification?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        messages?: {
          __typename: "ModelCompanyMessageInboxConnection";
          items: Array<{
            __typename: "CompanyMessageInbox";
            id: string;
            name: string;
            email: string;
            state?: MessageState | null;
            phoneNumber?: string | null;
            message: string;
            file?: string | null;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            advertisementID: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelCompanyUserConnection";
          items: Array<{
            __typename: "CompanyUser";
            id: string;
            entity: EntityType;
            name?: string | null;
            email: string;
            role: CompanyUserRole;
            createdAt: string;
            updatedAt: string;
            companyID: string;
            owner?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      analytics?: {
        __typename: "ModelAdvertisementAnalyticConnection";
        items: Array<{
          __typename: "AdvertisementAnalytic";
          id: string;
          name?: string | null;
          info?: string | null;
          advertisementID: string;
          createdAt: string;
          updatedAt: string;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateShowCaseSubscription = {
  onCreateShowCase?: {
    __typename: "ShowCase";
    id: string;
    title: string;
    text: string;
    file?: string | null;
    createdAt: string;
    updatedAt: string;
    companyID: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateShowCaseSubscription = {
  onUpdateShowCase?: {
    __typename: "ShowCase";
    id: string;
    title: string;
    text: string;
    file?: string | null;
    createdAt: string;
    updatedAt: string;
    companyID: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteShowCaseSubscription = {
  onDeleteShowCase?: {
    __typename: "ShowCase";
    id: string;
    title: string;
    text: string;
    file?: string | null;
    createdAt: string;
    updatedAt: string;
    companyID: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateTransactionSubscription = {
  onCreateTransaction?: {
    __typename: "Transaction";
    id: string;
    amount: number;
    currency: string;
    description?: string | null;
    paymentIntent?: string | null;
    companyID?: string | null;
    profileID?: string | null;
    initiatedBy?: string | null;
    createdAt: string;
    updatedAt: string;
    profile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    initiatedByProfile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateTransactionSubscription = {
  onUpdateTransaction?: {
    __typename: "Transaction";
    id: string;
    amount: number;
    currency: string;
    description?: string | null;
    paymentIntent?: string | null;
    companyID?: string | null;
    profileID?: string | null;
    initiatedBy?: string | null;
    createdAt: string;
    updatedAt: string;
    profile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    initiatedByProfile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteTransactionSubscription = {
  onDeleteTransaction?: {
    __typename: "Transaction";
    id: string;
    amount: number;
    currency: string;
    description?: string | null;
    paymentIntent?: string | null;
    companyID?: string | null;
    profileID?: string | null;
    initiatedBy?: string | null;
    createdAt: string;
    updatedAt: string;
    profile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    initiatedByProfile?: {
      __typename: "Profile";
      id: string;
      credits?: number | null;
      sub?: string | null;
      email: string;
      entity?: EntityType | null;
      entityState?: string | null;
      profileImage?: string | null;
      firstDegree?: string | null;
      forename?: string | null;
      surname?: string | null;
      secondDegree?: string | null;
      aboutMe?: string | null;
      educations?: string | null;
      workExperiences?: string | null;
      publications?: string | null;
      conferences?: string | null;
      certificates?: string | null;
      languages?: string | null;
      otherInformation?: string | null;
      qualifications?: string | null;
      name?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      phone?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelMessageInboxConnection";
        items: Array<{
          __typename: "MessageInbox";
          id: string;
          name: string;
          email: string;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          state?: MessageState | null;
          createdAt: string;
          updatedAt: string;
          profileID: string;
          advertisementID: string;
          profile?: {
            __typename: "Profile";
            id: string;
            credits?: number | null;
            sub?: string | null;
            email: string;
            entity?: EntityType | null;
            entityState?: string | null;
            profileImage?: string | null;
            firstDegree?: string | null;
            forename?: string | null;
            surname?: string | null;
            secondDegree?: string | null;
            aboutMe?: string | null;
            educations?: string | null;
            workExperiences?: string | null;
            publications?: string | null;
            conferences?: string | null;
            certificates?: string | null;
            languages?: string | null;
            otherInformation?: string | null;
            qualifications?: string | null;
            name?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            phone?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateCarouselSubscription = {
  onCreateCarousel?: {
    __typename: "Carousel";
    id: string;
    active: boolean;
    entity: EntityType;
    image?: string | null;
    title: string;
    type?: CarouselType | null;
    companyID?: string | null;
    createdAt: string;
    updatedAt: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateCarouselSubscription = {
  onUpdateCarousel?: {
    __typename: "Carousel";
    id: string;
    active: boolean;
    entity: EntityType;
    image?: string | null;
    title: string;
    type?: CarouselType | null;
    companyID?: string | null;
    createdAt: string;
    updatedAt: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteCarouselSubscription = {
  onDeleteCarousel?: {
    __typename: "Carousel";
    id: string;
    active: boolean;
    entity: EntityType;
    image?: string | null;
    title: string;
    type?: CarouselType | null;
    companyID?: string | null;
    createdAt: string;
    updatedAt: string;
    company?: {
      __typename: "Company";
      id: string;
      entity: EntityType;
      entityState?: string | null;
      credits?: number | null;
      name: string;
      logo?: string | null;
      forename?: string | null;
      surname?: string | null;
      address?: string | null;
      phone?: string | null;
      email?: string | null;
      crn?: string | null;
      vatRegNumber?: string | null;
      street?: string | null;
      buildingIdentificationNumber?: string | null;
      municipality?: string | null;
      postCode?: string | null;
      language?: string | null;
      employerTaxonomy?: string | null;
      profileImage?: string | null;
      qualification?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      messages?: {
        __typename: "ModelCompanyMessageInboxConnection";
        items: Array<{
          __typename: "CompanyMessageInbox";
          id: string;
          name: string;
          email: string;
          state?: MessageState | null;
          phoneNumber?: string | null;
          message: string;
          file?: string | null;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          advertisementID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          advertisement?: {
            __typename: "Advertisement";
            id: string;
            friendlyId?: number | null;
            entity: EntityType;
            active?: Active | null;
            state?: string | null;
            stateDescription?: string | null;
            paymentState?: string | null;
            jobPosition: string;
            jobShortDescription: string;
            jobDescription?: string | null;
            place: string;
            salaryFrom?: number | null;
            salaryTo?: number | null;
            salarySpecifiedInText?: boolean | null;
            reactivationCount?: number | null;
            currentReactivationCount?: number | null;
            automaticReactivation?: boolean | null;
            leadingPosition?: boolean | null;
            residentialPlace?: boolean | null;
            typeOfCooperation?: string | null;
            jobType?: string | null;
            financialConditions?: string | null;
            typeOfInternship?: string | null;
            contactFirstDegree?: string | null;
            contactForename?: string | null;
            contactSurname?: string | null;
            contactSecondDegree?: string | null;
            contactEmail?: string | null;
            contactPhoneNumber?: string | null;
            hideContactInformation?: boolean | null;
            workType?: string | null;
            suitableFor?: string | null;
            workTypeStringified?: string | null;
            suitableForStringified?: string | null;
            employmentTypesStringified?: string | null;
            employmentTypes?: string | null;
            paymentType?: PaymentType | null;
            advertisementSettingID: string;
            advertisementTypeID?: string | null;
            companyID?: string | null;
            profileID?: string | null;
            expirationDate: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelCompanyUserConnection";
        items: Array<{
          __typename: "CompanyUser";
          id: string;
          entity: EntityType;
          name?: string | null;
          email: string;
          role: CompanyUserRole;
          createdAt: string;
          updatedAt: string;
          companyID: string;
          company?: {
            __typename: "Company";
            id: string;
            entity: EntityType;
            entityState?: string | null;
            credits?: number | null;
            name: string;
            logo?: string | null;
            forename?: string | null;
            surname?: string | null;
            address?: string | null;
            phone?: string | null;
            email?: string | null;
            crn?: string | null;
            vatRegNumber?: string | null;
            street?: string | null;
            buildingIdentificationNumber?: string | null;
            municipality?: string | null;
            postCode?: string | null;
            language?: string | null;
            employerTaxonomy?: string | null;
            profileImage?: string | null;
            qualification?: string | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
          } | null;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};
