import React from "react";
import { useTranslation } from "react-i18next";
import {
  Advertisement,
  SearchAdvertisementsQuery,
  UpdateAdvertisementInput,
  UpdateAdvertisementMutation,
} from "../../../../../API";
import { ActiveAdvertisement } from "./ActiveAdvertisement";
import { DateFormat } from "../../../../../components/Moment";
import { MedTable } from "../../../../../components/Table";
import { CreatedBy } from "./CreatedBy";
import { useMutation } from "@oasa/amplify-query";
import {
  deleteAdvertisement,
  updateAdvertisement,
} from "../../../../../graphql/mutations";
import { AdvertisementState } from "./_types";
import { formatAdvertisementValue } from "../_services";
import { RemoveButton } from "src/components/Form/Button/Icons/Remove";
import _ from "lodash";
import { EditButton } from "src/components/Form/Button/Icons/Edit";
import { EditExpiration } from "src/components/Form/Button/Icons/EditExpiration";
import { RowStart } from "src/constants/globalStyles";
import { IsActive } from "./IsActive";
import { createRoute } from "../../../../../utils/createRoute";
import { Routes } from "../../../../../routes";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import { Pagination } from "../../../../../components/Table/Pagination";

export const AdvertisementsTable = ({
  data,
  loading,
  refetch,
  totalPages,
  page,
  setPage,
  nextTokens,
}: {
  data: SearchAdvertisementsQuery | null;
  refetch: any;
  loading: boolean;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  totalPages: number;
  page: number;
  nextTokens?: any;
}) => {
  const { t } = useTranslation(["common", "advertisements"]);

  const [update] = useMutation<
    UpdateAdvertisementInput,
    UpdateAdvertisementMutation
  >(updateAdvertisement);

  const [deleteFromDatabase] = useMutation<
    UpdateAdvertisementInput,
    UpdateAdvertisementMutation
  >(deleteAdvertisement);

  const removeItemFromDatabase = async (item: Advertisement) => {
    const result = await deleteFromDatabase({
      input: {
        id: item?.id,
      },
    });
    _.delay(() => {
      refetch();
    }, 2000);
  };

  const removeItem = async (item: Advertisement) => {
    const result = await update({
      input: {
        ...formatAdvertisementValue(item),
        state: AdvertisementState.DELETED,
      },
    });
    _.delay(() => {
      refetch();
    }, 2000);
  };

  return (
    <>
      <MedTable
        emptyTableText={t("advertisements:emptyTable")}
        loading={loading}
        columns={[
          {
            title: t("common:table.id"),
            dataIndex: "state",
            width: "380px",
            render: (text: AdvertisementState, record: any) => (
              <ActiveAdvertisement state={text} id={record?.id} />
            ),
          },
          {
            title: t("common:table.name"),
            dataIndex: "jobPosition",
            render(text: string, record: Advertisement) {
              return <span>{text}</span>;
            },
          },
          {
            title: t("common:table.createdBy"),
            width: "250px",
            render: (text: any, record: Advertisement) => (
              <CreatedBy advertisement={record} />
            ),
          },
          {
            title: t("common:table.createdAt"),
            dataIndex: "createdAt",
            width: "150px",
            render(text: string, record: Advertisement) {
              return <DateFormat date={text} format={"DD. MM. YYYY"} />;
            },
          },
          {
            title: t("common:table.place"),
            dataIndex: "place",
            render(text: string, record: Advertisement) {
              return <span>{text}</span>;
            },
          },
          {
            title: t("common:table.state"),
            dataIndex: "state",
            width: "110px",
            render(text: any, record: Advertisement) {
              return <span>{t(`common:awsJson.${text}`)}</span>;
            },
          },
          {
            title: t("common:table.status"),
            dataIndex: "active",
            width: "110px",
            render(text: any, record: Advertisement) {
              return (
                <IsActive
                  expirationDate={record?.expirationDate}
                  paymentState={record?.paymentState}
                  state={record?.state}
                />
              );
            },
          },
          {
            title: "",
            titleHide: true,
            key: "action",
            render: (_text: any, record: any) => (
              <RowStart>
                <EditExpiration id={record?.id} refetch={refetch} />
                <EditButton
                  route={createRoute(Routes.EDIT_ADVERTISEMENT, [record?.id])}
                />
                <RemoveButton
                  title={t("advertisements:removeFromDB.title")}
                  description={t("advertisements:removeFromDB.description")}
                  okText={t("advertisements:removeFromDB.ok")}
                  cancelText={t("advertisements:removeFromDB.cancel")}
                  handleClick={() => removeItemFromDatabase(record)}
                />
              </RowStart>
            ),
          },
        ]}
        dataSource={data?.searchAdvertisements?.items || []}
      />
      <Pagination
        totalPages={totalPages}
        page={page}
        setPage={setPage}
        nextTokens={nextTokens}
      />
    </>
  );
};
