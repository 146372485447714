import React, { useEffect } from "react";
import { ConfigProvider, DatePicker } from "antd";
import csCZ from "antd/lib/locale/cs_CZ";
import locale from "antd/es/date-picker/locale/cs_CZ";
import "moment/locale/cs";
import { Moment } from "moment";
import { FormikProps } from "formik";
import moment from "moment";
import { useState } from "react";

export const CalendarInput = ({
  formik,
  value,
  name,
}: {
  formik: FormikProps<any>;
  value?: string;
  name: string;
}) => {
  const [date, setDate] = useState<any | null>(undefined);
  const dateFormat = "DD. MM. YYYY";

  const handleChange = (selected: Moment | null, dateString: string) => {
    setDate(selected);
    formik.setFieldValue(name, selected?.toISOString());
  };

  useEffect(() => {
    setDate(moment(formik.values.expirationDate));
  }, [formik.values.expirationDate]);

  if (!date) return <></>;

  return (
    <DatePicker
      defaultValue={date ? date : moment()}
      format={dateFormat}
      bordered={false}
      onChange={handleChange}
    />
  );
};
