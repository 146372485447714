import { Title } from "optimify-mediportal-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { ChangePassword } from "./components/ChangePassword";
import { StyledTabs, Wrap } from "../../../../constants/globalStyles";
import { Tabs } from "antd";

const { TabPane } = Tabs;

export const ProfileSettings = () => {
  const { t } = useTranslation(["common", "profile"]);

  return (
    <Wrap>
      <Title>{t("common:menu.profile")}</Title>
      <StyledTabs tabPosition={"left"}>
        <TabPane tab={t("profile:changePassword")} key="1">
          <ChangePassword />
        </TabPane>
      </StyledTabs>
    </Wrap>
  );
};
