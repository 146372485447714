import { Checkbox } from "antd";
import React from "react";
import styled from "styled-components";
import { ErrorMessage } from "./ErrorMessage";

export const CheckboxGroup = ({
  allTitle,
  title,
  all,
  options,
  formik,
  name,
  singleChoice,
  customTrigger,
}: {
  all?: boolean;
  allTitle?: string;
  title?: string;
  singleChoice?: boolean;
  name: string;
  formik: any;
  options: { value: string | number | boolean; label: string }[];
  customTrigger?: () => void;
}) => {
  const [indeterminate, setIndeterminate] = React.useState(true);
  const [checkAll, setCheckAll] = React.useState(false);

  const onChange = (list: any) => {
    if (singleChoice) {
      if (list && list[0]) formik.setFieldValue(name, list[0]);
      customTrigger && customTrigger();
      return;
    }
    formik.setFieldValue(name, list);
    setIndeterminate(!!list.length && list.length < options.length);
    setCheckAll(list.length === options.length);
  };

  const onCheckAllChange = (e: any) => {
    formik.setFieldValue(
      name,
      e.target.checked ? options?.map((e: any) => e?.value) : []
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <Container>
      {title && <Title>{title}</Title>}
      {all && !singleChoice && (
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          {allTitle || "Všechny"}
        </Checkbox>
      )}
      <Checkbox.Group
        defaultValue={formik && name && formik.values[name]}
        name={name}
        options={options}
        value={formik && name && formik.values[name]}
        onChange={onChange}
        style={{ display: "flex", flexDirection: "column" }}
      />
      <ErrorMessage formik={formik} name={name} />
    </Container>
  );
};

const Title = styled.div`
  font-size: 13px;
  color: #555555;

  padding-bottom: 3px;
  padding-left: 1px;
`;

const Container = styled.div`
  margin-bottom: 13px;

  .ant-checkbox-wrapper {
    color: #2f2f2f;
    font-size: 13px;
  }
`;
