import React from "react";
import { Center } from "optimify-mediportal-components";
import styled from "styled-components";
import { Spin, Space } from "antd";

export const LoadingLayout = ({
  children,
  loading,
}: {
  children: React.ReactNode;
  loading?: boolean;
}) => {
  if (loading) {
    return (
      <CenterContainer>
        <Spin size="large" />
      </CenterContainer>
    );
  }

  return <>{children}</>;
};

const CenterContainer = styled(Center)`
  height: calc(100vh - 400px);
`;
