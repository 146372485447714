import React, { useEffect, useState } from "react";
import { Dropdown, Menu as AntdMenu } from "antd";
import {
  faCogs,
  faPalette,
  faQuestionCircle,
  faSignOutAlt,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { faNewspaper } from "@fortawesome/free-regular-svg-icons";
import Logo from "./images/medijob.svg";
import { Routes } from "../../routes";
import { Link, useHistory, useLocation } from "react-router-dom";
import { User } from "./components/User";
import { useTranslation } from "react-i18next";
import { faAd } from "@fortawesome/free-solid-svg-icons/faAd";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { Auth } from "aws-amplify";
import { createRoute, routeTo } from "../../utils/createRoute";
import {
  Container,
  ContentWrap,
  FooterWrap,
  LogoWrap,
  MainContainer,
  SignOut,
} from "./_styles";
import Header from "./components/Header";
import Menu, { MenuItem } from "./components/Menu";

interface Layout {
  children: React.ReactNode;
}

export const AdminLayout = ({ children }: Layout) => {
  let history = useHistory();
  const [user, setUser] = useState<any>(null);
  const [isAdmin, setIsAdmin] = useState<any>(null);
  const [isPublicist, setIsPublicist] = useState<any>(null);
  const [isEditor, setIsEditor] = useState<any>(null);

  useEffect(() => {
    let interval: any = null;
    checkAuth();
    interval = setInterval(() => {
      checkAuth();
    }, 2000);
    return () => {
      interval && clearInterval(interval);
    };
  }, []);

  const checkAuth = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const groups =
        user.signInUserSession.accessToken.payload["cognito:groups"];
      setIsAdmin(groups.includes("SuperAdmins") || groups.includes("Admins"));
      setIsPublicist(groups.includes("Publicists"));
      setIsEditor(groups.includes("Editors"));
      setUser(user?.attributes);
    } catch (e) {
      history.push(Routes.HOME);
    }
  };

  const { t } = useTranslation(["common"]);

  const location = useLocation();
  const navigate = (route: Routes) => {
    routeTo(history, route);
  };

  const signOut = async () => {
    try {
      await Auth.signOut({ global: true });
      history.push(Routes.HOME);
      history.go(0);
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  return (
    <Container>
      <Menu
        header={
          <LogoWrap>
            <img src={Logo} alt="logo" />
          </LogoWrap>
        }
        items={[
          {
            icon: faAd,
            text: t("common:menu.advertisements"),
            onClick: () => navigate(Routes.ADVERTISEMENTS),
            active:
              location.pathname.includes(createRoute(Routes.ADVERTISEMENTS)) ||
              location.pathname === Routes.PORTAL,
            show: isAdmin || isEditor,
          },
          {
            icon: faUsers,
            text: t("common:menu.users"),
            onClick: () => navigate(Routes.USERS),
            active: location.pathname.includes(createRoute(Routes.USERS)),
            show: isAdmin,
          },
          {
            icon: faUserTie,
            text: t("common:menu.taxonomy"),
            onClick: () => navigate(Routes.TAXONOMY),
            active: location.pathname.includes(createRoute(Routes.TAXONOMY)),
            show: isAdmin,
          },
          {
            icon: faNewspaper,
            text: t("common:menu.articles"),
            onClick: () => navigate(Routes.ARTICLES),
            active: location.pathname.includes(createRoute(Routes.ARTICLES)),
            show: isAdmin || isPublicist,
          },
          {
            icon: faPalette,
            text: t("common:menu.carousel"),
            onClick: () => navigate(Routes.CAROUSELS),
            active: location.pathname.includes(createRoute(Routes.CAROUSELS)),
            show: isAdmin,
          },
          {
            icon: faCogs,
            text: t("common:menu.settings"),
            onClick: () => navigate(Routes.SETTINGS),
            active: location.pathname.includes(createRoute(Routes.SETTINGS)),
            show: isAdmin,
          },
        ]}
        footer={
          <FooterWrap>
            <MenuItem
              icon={faQuestionCircle}
              text={t("common:menu.help")}
              onClick={() => {}}
              show={true}
            />
          </FooterWrap>
        }
      />
      <MainContainer>
        <Header
          portals={[
            {
              name: "MediJob",
              onClick: () => {},
              active: true,
            },
          ]}
          content={
            <>
              <Dropdown
                placement={"bottomRight"}
                overlay={
                  <AntdMenu>
                    <AntdMenu.Item key="0">
                      <Link to={Routes.PROFILE_SETTINGS}>Profil</Link>
                    </AntdMenu.Item>
                    <AntdMenu.Item key="1">
                      <span onClick={() => signOut()}> Odhlásit se</span>
                    </AntdMenu.Item>
                  </AntdMenu>
                }
                trigger={["click"]}
              >
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <User user={user?.email?.charAt(0)?.toUpperCase() || "U"} />
                </a>
              </Dropdown>

              <SignOut icon={faSignOutAlt} onClick={() => signOut()} />
            </>
          }
        />
        <ContentWrap>{children}</ContentWrap>
      </MainContainer>
    </Container>
  );
};
