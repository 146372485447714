export const carouselsByCreatedAt = /* GraphQL */ `
  query CarouselsByCreatedAt(
    $entity: EntityType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCarouselFilterInput
    $limit: Int
    $nextToken: String
  ) {
    carouselsByCreatedAt(
      entity: $entity
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        createdAt
        updatedAt
        companyID
        company {
          id
          name
        }
      }
      nextToken
    }
  }
`;
