import { AdvertisementsTable } from "./components/AdvertisementsTable";
import { Title } from "optimify-mediportal-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { Filter } from "./components/Filter";
import { useFormik } from "formik";
import { useJobSearch } from "./components/useJobSearch";
import { Wrap } from "../../../../constants/globalStyles";
import { SpaceBetweenWrap, TitleMediaWrapper } from "./_styles";
import { FormData } from "./components/_types";

export const Advertisements = () => {
  const { t } = useTranslation(["common", "advertisements"]);

  const formik = useFormik<FormData>({
    initialValues: {
      job: "",
      companyID: "",
      state: "",
      profileID: "",
      status: "",
      dateFrom: "",
      dateTo: "",
    },
    onSubmit: async (values: FormData) => {},
  });

  const { data, loading, refetch, totalPages, page, setPage, nextTokens } =
    useJobSearch(formik.values);

  return (
    <Wrap>
      <SpaceBetweenWrap>
        <Title>{t("advertisements:searchAdvertisement")}</Title>
        <TitleMediaWrapper>
          <Title>{t("advertisements:createdAt")}</Title>
        </TitleMediaWrapper>
      </SpaceBetweenWrap>
      <Filter formik={formik} />
      <AdvertisementsTable
        data={data}
        loading={loading}
        refetch={refetch}
        totalPages={totalPages}
        page={page}
        setPage={setPage}
        nextTokens={nextTokens}
      />
    </Wrap>
  );
};
