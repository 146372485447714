/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:4a8ad84e-7598-42f9-a46f-e0e8604ddda9",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_ujbJr4p4n",
    "aws_user_pools_web_client_id": "3sh5jqj1abqrko8phue33n5ppe",
    "oauth": {
        "domain": "medijob-prod.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://medijob-react-nextjs-web.vercel.app/",
        "redirectSignOut": "http://localhost:3000/,https://medijob-react-nextjs-web.vercel.app/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://g5ohjzhw45emhikrct52wt76ga.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-d5kxhh6qqbdr5bienxisunhhaa",
    "aws_cloud_logic_custom": [
        {
            "name": "common",
            "endpoint": "https://9n46dd08jg.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "payment",
            "endpoint": "https://hdl96zm07c.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "transaction",
            "endpoint": "https://5ggco0h1a4.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "credits",
            "endpoint": "https://tr32icskod.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "medijobccdf662006db473f822b5c27a121208392554-prod",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
