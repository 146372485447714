import React, { useState } from "react";
import styled from "styled-components";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { Row } from "../../constants/globalStyles";

const Search = ({
  placeholder,
  name,
  formik,
}: {
  placeholder?: string;
  name: string;
  formik: FormikProps<any>;
}) => {
  const [state, setState] = useState("");
  const { t } = useTranslation(["form"]);
  return (
    <Wrap>
      <Input
        type="text"
        name={name}
        value={state || ""}
        onChange={(e) => {
          setState(e.target.value || "");
        }}
        placeholder={placeholder || t("form:placeholder.search")}
        unselect={formik.values[name] || ""}
      />
      <Button type={"button"} onClick={() => formik.setFieldValue(name, state)}>
        <FontAwesomeIcon icon={faSearch} />
      </Button>
      {formik.values[name] && (
        <UnSelect
          onClick={() => {
            formik.setFieldValue(name, undefined);
            setState("");
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </UnSelect>
      )}
    </Wrap>
  );
};

export default Search;

const UnSelect = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.primary};
  margin-left: 10px;
  margin-right: 10px;
`;

const Wrap = styled(Row)`
  position: relative;
  margin-right: 8px;
  margin-top: 8px;
`;

const Button = styled.button`
  width: 32px;

  margin-left: -34px;

  background-color: transparent;
  border: none;
  border-radius: 14px;

  cursor: pointer;
  transition: all 0.3s ease-out;

  svg {
    color: darkgray;
  }
`;

const Input = styled.input<{ unselect?: boolean }>`
  box-sizing: border-box;

  width: ${(props) => (props.unselect ? "180px" : "208px")};

  padding: 6px 40px 5px 16px;

  background-color: ${(props) => props.theme.gray};
  border: none;
  border-radius: 14px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.05);
  font-size: 13px;

  &:focus {
    outline: none;
  }

  /* clears the 'X' from Internet Explorer */

  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the 'X' from Chrome */

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
`;

const Autocomplete = styled.div`
  position: absolute;

  top: 6px;
  right: 40px;
  bottom: 5px;
  left: 16px;

  box-sizing: border-box;

  width: 208px;

  color: ${(props) => props.theme.darkerGray};
  font-size: 13px;

  overflow: hidden;
  pointer-events: none;
`;
