import React from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  FilterAlignCenter,
  FilterContainer,
  FilterRow,
  FilterSelect,
} from "../_styles";
import moment, { Moment } from "moment";
import { FormikProps } from "formik";
import { FormData } from "./_types";
import Search from "src/components/Form/Search";
import CalendarRangeInput from "src/components/Form/CalendarRangeInput";
import { CompanyFilter } from "./Filter/CompanyFilter";
import { PersonalFilter } from "./Filter/PersonalFilter";

export const Filter = ({ formik }: { formik: FormikProps<FormData> }) => {
  const { t } = useTranslation(["common", "advertisements"]);

  return (
    <FilterRow onSubmit={formik.handleSubmit}>
      <FilterContainer>
        <FilterAlignCenter>
          <Search formik={formik} name={"job"} />
          <Container>
            <FilterSelect
              formik={formik}
              options={[
                { value: "ACTIVE", label: t("common:awsJson.ACTIVE") },
                { value: "INACTIVE", label: t("common:awsJson.INACTIVE") },
              ]}
              name={"status"}
              label={t("common:table.status")}
              unselectable
            />
          </Container>
          <CompanyFilter formik={formik} />
          <PersonalFilter formik={formik} />
        </FilterAlignCenter>
        <FilterAlignCenter>
          <Container>
            <CalendarRangeInput
              formik={formik}
              onChange={(
                dates: [Moment, Moment],
                dateStrings: [string, string],
                info: {
                  range: string;
                }
              ) => {
                formik.setFieldValue("dateFrom", dateStrings[0]);
                formik.setFieldValue("dateTo", dateStrings[1]);
              }}
              value={[moment(), moment()]}
            />
          </Container>
        </FilterAlignCenter>
      </FilterContainer>
    </FilterRow>
  );
};
