import { Row, SpaceBetween } from "src/constants/globalStyles";
import styled from "styled-components";
import Select from "./components/Filter/Select";

export const FilterRow = styled.form`
  padding: 0 5px 12px 5px;
`;

export const SpaceBetweenWrap = styled(SpaceBetween)`
  padding: 0 5px;
`;

export const FilterSelect = styled(Select)`
  margin-top: 8px;
`;

export const FilterAlignCenter = styled(Row)`
  align-items: center;
  flex-wrap: wrap;
`;

export const FilterContainer = styled(Row)`
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

export const Container = styled(Row)`
  margin-top: 8px;
`;

export const TitleMediaWrapper = styled.span`
  display: none;

  ${({ theme }) => theme.largeDesktop`
       display: block;
   `}
`;
