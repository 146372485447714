import Moment from "react-moment";

export const DateFormat = ({
  format,
  date,
}: {
  format?: string;
  date?: string;
}) => {
  return <Moment format={format || "DD. MM. YYYY HH:mm"}>{date}</Moment>;
};
