import { PublicAdvertisementSetting } from "src/API";
import { useTranslation } from "react-i18next";
import Tooltip from "antd/lib/tooltip";

export const AppliedTo = ({
  appliedTo,
  isPublic,
}: {
  appliedTo: any;
  isPublic?: PublicAdvertisementSetting | null | undefined;
}) => {
  const { t } = useTranslation(["common"]);

  if (isPublic === PublicAdvertisementSetting.PUBLIC)
    return <span>{t("common:checkbox.public")}</span>;

  return (
    <span>
      <Tooltip title="prompt text">{t("common:checkbox.private")}</Tooltip>
    </span>
  );
};
