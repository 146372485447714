import { Switch as AntdSwitch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useEffect } from "react";

export const Switch = ({ name, formik }: { name: string; formik: any }) => {
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    if (!name) return;
    setChecked(formik.values[name]);
  }, [formik.values]);

  const handleChange = (checked: boolean) => {
    formik.setFieldValue(name, checked);
  };

  return (
    <AntdSwitch
      onChange={handleChange}
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      checked={checked}
    />
  );
};
