import styled from "styled-components";
import { Center, End } from "optimify-mediportal-components";
import { useFormik } from "formik";
import { Auth, Hub } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../../routes";
import * as Yup from "yup";
import { routeTo } from "../../../../utils/createRoute";
import { SubmitButton } from "src/components/Form/Button/Submit";
import Logo from "../../../../components/Layout/images/medijob.svg";
import { Column } from "../../../../constants/globalStyles";
import AdminInput from "src/components/Form/AdminInput";

export enum Groups {
  SUPER_ADMIN = "SuperAdmins",
  ADMIN = "Admins",
  PUBLICIST = "Publicists",
  EDITOR = "Editors",
}

export const SignIn = () => {
  const { t, i18n } = useTranslation(["signIn", "form"]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [error, setError] = useState<any>(null);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .min(5, t("form:validation.toShort", { count: 5 }))
        .max(50, t("form:validation.toLong", { count: 50 }))
        .required(t("form:validation.required")),
      username: Yup.string()
        .email(t("form:validation.invalidEmail"))
        .required(t("form:validation.required")),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      await signIn(values);
      setLoading(false);
    },
  });

  useEffect(() => {
    Hub.listen("auth", async ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          redirectHandler(data);
          break;
        case "signOut":
          history.push(Routes.HOME);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          history.push(Routes.HOME);
          console.log("Sign in failure", data);
          break;
      }
    });

    let interval: any = null;
    checkAuth();
    interval = setInterval(() => {
      checkAuth();
    }, 2000);
    return () => {
      interval && clearInterval(interval);
    };
  }, []);

  const checkAuth = async () => {
    try {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          redirectHandler(user);
        })
        .catch(() => {
          history.push(Routes.HOME);
        });
    } catch (e) {
      history.push(Routes.HOME);
    }
  };

  const redirectHandler = (user: any) => {
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];

    if (!groups) return;

    if (groups.includes("SuperAdmins") || groups.includes("Admins")) {
      history.push(Routes.PORTAL);
    } else if (groups.includes("Publicists")) {
      routeTo(history, Routes.ARTICLES);
    } else if (groups.includes("Editors")) {
      history.push(Routes.PORTAL);
    }
  };

  async function signIn(values: { username: string; password: string }) {
    try {
      const user = await Auth.signIn(values.username, values.password);

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        await user.completeNewPasswordChallenge(values.password);
        await signIn(values);
        return;
      }
      redirectHandler(user);
      setError(null);
    } catch (error) {
      console.log("error signing in", error);
      setError(error);
    }
  }

  return (
    <CenterContainer>
      <Container>
        <Image src={Logo} alt="logo" />
        <Wrap>
          <form onSubmit={formik.handleSubmit}>
            <Title>{t("signIn:title")}</Title>
            {error && error?.code && (
              <ErrorMessage>
                {t(`form:errorMessage.${error?.code}`)}
              </ErrorMessage>
            )}
            <AdminInput
              type="text"
              title={t("signIn:username")}
              name="username"
              formik={formik}
              required
            />
            <AdminInput
              type="password"
              title={t("signIn:password")}
              name="password"
              formik={formik}
              required
            />
            <End>
              <SubmitButton type="submit" variant="primary" loading={loading}>
                {t("signIn:signIn")}
              </SubmitButton>
            </End>
          </form>
        </Wrap>
      </Container>
    </CenterContainer>
  );
};

const CenterContainer = styled(Center)`
  width: 100%;
  height: 100vh;
  background-color: #ececec;
`;

const Wrap = styled.div`
  width: 25rem;
  padding: 35px 30px 50px;
  border-radius: 4px;
  border-top-width: 4px;
  border-top-style: solid;
  border-top-color: ${(props) => props.theme.background.blue};
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(227, 233, 243) 0px 2px 4px 0px;
`;

const Container = styled(Column)`
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 20px;
  padding-bottom: 10px;
`;

export const ErrorMessage = styled.div`
  padding: 9px 14px;
  border-radius: 3px;
  color: white;
  background-color: #e70202;
  margin-bottom: 15px;
  overflow: hidden;
`;

const Image = styled.img`
  margin-bottom: 20px;
`;
