import React, { InputHTMLAttributes, useEffect, useState } from "react";
import styled from "styled-components";
import { FormikProps } from "formik";

interface Input extends InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  formik?: any;
}

const AdminInput = ({
  formik,
  name,
  title,
  type,
  required,
}: {
  title: string;
  formik: FormikProps<any>;
  name: string;
  type: string;
  required?: boolean;
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (e: any) => {
    setValue(e.target.value);
    formik.setFieldValue(name, e.target.value);
  };

  useEffect(() => {
    setValue(formik.values[name]);
  }, [formik.values[name]]);

  return (
    <Column>
      {title && <Title>{title}</Title>}
      <AdminInputComponent
        name={name}
        type={type}
        onChange={handleChange}
        value={value}
      />
      {name &&
      formik &&
      formik.errors &&
      formik.errors[name] &&
      formik.touched &&
      formik.touched[name] ? (
        <ErrorMessage>{formik.errors[name]}</ErrorMessage>
      ) : null}
    </Column>
  );
};

export default AdminInput;

const Title = styled.div`
  font-size: 13px;
  color: #555555;

  padding-bottom: 3px;
  padding-left: 1px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;

  padding-bottom: 13px;
`;
const AdminInputComponent = styled.input`
  box-sizing: border-box;

  width: 100%;

  padding: 9px 16px 10px;

  border: 1px solid #c3c3c3;
  border-radius: 7px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.05);
  color: black;

  outline: none;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #c8c8c8;
    font-weight: 300;
  }

  :-ms-input-placeholder {
    color: #c8c8c8;
    font-weight: 300;
  }

  :focus {
    border: 1px solid #19d2b1;
  }
`;

const ErrorMessage = styled.span`
  color: red;
  margin-top: 3px;
  margin-left: 4px;
  font-size: 11px;
`;
