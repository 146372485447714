import React from "react";
import { Row } from "optimify-mediportal-components";
import { useTranslation } from "react-i18next";
import { Routes } from "../../../../../routes";
import {
  ArticlesByCreatedAtQuery,
  DeleteArticleInput,
  DeleteArticleMutation,
  EntityType,
  ModelSortDirection,
} from "../../../../../API";
import { articlesByCreatedAt } from "../graphql/queries";
import { deleteArticle } from "../../../../../graphql/mutations";
import { createRoute } from "../../../../../utils/createRoute";
import { DateFormat } from "../../../../../components/Moment";
import { MedTable } from "src/components/Table";
import { EditButton } from "src/components/Form/Button/Icons/Edit";
import { RemoveButton } from "../../../../../components/Form/Button/Icons/Remove";
import { Pagination } from "../../../../../components/Table/Pagination";
import { useMutation, usePaginatedQuery } from "@oasa/amplify-query";

export const ArticlesTable = () => {
  const { t } = useTranslation(["common", "articles"]);

  const { loading, data, totalPages, page, setPage, refetch, nextTokens } =
    usePaginatedQuery<ArticlesByCreatedAtQuery>(articlesByCreatedAt, {
      entity: EntityType.ARTICLE,
      sortDirection: ModelSortDirection.DESC,
      limit: 10,
      name: "articlesByCreatedAt",
    });

  const [remove] = useMutation<DeleteArticleInput, DeleteArticleMutation>(
    deleteArticle
  );

  const removeItem = async (slug: string) => {
    await remove({ input: { slug: slug } });
    refetch();
  };
  console.log(nextTokens);
  return (
    <>
      <MedTable
        emptyTableText={t("articles:emptyTable")}
        dataSource={data?.articlesByCreatedAt?.items || []}
        loading={loading}
        columns={[
          {
            title: t("common:table.articleTitles"),
            render: (text: any, record: any) => <span>{record?.title}</span>,
          },
          {
            title: t("common:table.description"),
            render: (text: any, record: any) => (
              <span>{record?.description}</span>
            ),
          },
          {
            title: t("common:table.status"),
            render: (text: any, record: any) => (
              <span>
                {t(
                  record?.active
                    ? "common:table.active"
                    : "common:table.inactive"
                )}
              </span>
            ),
          },
          {
            title: t("common:table.category"),
            render: (text: any, record: any) => (
              <span>{record?.category?.name}</span>
            ),
          },
          {
            title: t("common:table.createdAt"),
            render: (text: any, record: any) => (
              <span>
                <DateFormat date={record?.createdAt} />
              </span>
            ),
          },
          {
            title: t("common:table.updatedAt"),
            render: (text: any, record: any) => (
              <span>
                <DateFormat date={record?.updatedAt} />
              </span>
            ),
          },
          {
            title: "",
            titleHide: true,
            key: "action",
            render: (_text: any, record: any) => (
              <Row>
                <EditButton
                  route={createRoute(Routes.CREATE_ARTICLE, [record?.slug])}
                />
                <RemoveButton
                  title={t("articles:removeArticle.title")}
                  description={t("articles:removeArticle.description")}
                  okText={t("articles:removeArticle.ok")}
                  cancelText={t("articles:removeArticle.cancel")}
                  handleClick={() => removeItem(record?.slug)}
                />
              </Row>
            ),
          },
        ]}
      />
      <Pagination
        totalPages={totalPages}
        page={page}
        setPage={setPage}
        nextTokens={nextTokens}
      />
    </>
  );
};
