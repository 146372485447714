import _ from "lodash";
import { Advertisement } from "src/API";

export const mapTaxonomy = (
  listSuitableForByCountryData: any,
  advertisement?: Advertisement | null
) => {
  if (!advertisement) return { suitableFor: [] };

  const suitableFor = advertisement?.suitableFor
    ? JSON.parse(advertisement?.suitableFor)
    : [];

  let tempSuitableFor: any = [];

  for (const key in suitableFor) {
    if (suitableFor[key]) {
      const mapped = suitableFor[key]?.map((e: string) =>
        listSuitableForByCountryData?.find((l: any) => l?.id === e)
      );
      tempSuitableFor = [...tempSuitableFor, ...mapped];
    }
  }
  tempSuitableFor = _.uniqBy(tempSuitableFor, (e: any) => e?.id);
  tempSuitableFor = _.groupBy(tempSuitableFor, (e: any) => e?.level);

  return { suitableFor: tempSuitableFor };
};
