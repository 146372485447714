import {
  createSuitableForEdge,
  createWorkerType,
  createWorkerTypeEdge,
  deleteWorkerTypeEdge,
  updateWorkerType,
} from "../../../../../graphql/mutations";
import { message } from "antd";
import { routeTo } from "../../../../../utils/createRoute";
import { Routes } from "../../../../../routes";
import { TaxonomyPage } from "../index";
import { mutation } from "../../../../../utils/useQuery";

/**
 * Create advertisement
 * @param values
 * @param mutation
 * @param id
 * @param history
 */
export const handleSubmit = async (
  values: any,
  workerType: any,
  history: any,
  id?: string,
  parentId?: string
) => {
  try {
    const { children, ...data } = values;

    if (id) {
      await mutation(updateWorkerType, {
        input: data,
      });
    } else {
      const result: any = await mutation(createWorkerType, {
        input: data,
      });
      parentId &&
        (await mutation(createWorkerTypeEdge, {
          input: {
            parentNodeId: parentId,
            country: values.country,
            childNodeId: result?.createWorkerType?.id,
          },
        }));
    }

    const currentEdges = workerType?.childEdges?.items || [];
    for (const e of currentEdges) {
      if (children && !children?.includes(e.childNodeId)) {
        await mutation(deleteWorkerTypeEdge, { input: { id: e?.id } });
      }
    }

    if (children) {
      for (const childrenIterator of children) {
        if (
          !currentEdges?.find((e: any) => e?.childNodeId === childrenIterator)
        ) {
          await mutation(createWorkerTypeEdge, {
            input: {
              parentNodeId: data.id,
              country: values.country,
              childNodeId: childrenIterator,
            },
          });
        }
      }
    }

    message.success(`Taxonomie byla úspěšně uložena.`);

    routeTo(history, Routes.TAXONOMY, [TaxonomyPage.WorkerType]);
  } catch (e) {
    message.error(`Exception: ${e}`);
  }
};
