import { Company, UpdateProfileInput } from "../../../../API";

export const formatCompany = (values: Company) => {
  return {
    id: values?.id,
    name: values?.name,
    profileImage: values?.profileImage,
    entity: values?.entity,
    logo: values?.logo,
    forename: values?.forename,
    surname: values?.surname,
    address: values?.address,
    phone: values?.phone,
    email: values?.email,
    crn: values?.crn,
    vatRegNumber: values?.vatRegNumber,
    street: values?.street,
    buildingIdentificationNumber: values?.buildingIdentificationNumber,
    municipality: values?.municipality,
    postCode: values?.postCode,
    language: values?.language,
    employerTaxonomy: values?.employerTaxonomy,
    qualification: values?.qualification,
    createdAt: values?.createdAt,
    updatedAt: values?.updatedAt,
    credits: values?.credits,
  };
};

export const formatProfile = (values: UpdateProfileInput) => {
  return {
    id: values?.id,
    credits: values?.credits,
    email: values?.email,
    entity: values?.entity,
    entityState: values?.entityState,
    profileImage: values?.profileImage,
    firstDegree: values?.firstDegree,
    forename: values?.forename,
    surname: values?.surname,
    secondDegree: values?.secondDegree,
    aboutMe: values?.aboutMe,
    educations: values?.educations,
    workExperiences: values?.workExperiences,
    publications: values?.publications,
    conferences: values?.conferences,
    certificates: values?.certificates,
    languages: values?.languages,
    otherInformation: values?.otherInformation,
    qualifications: values?.qualifications,
    createdAt: values?.createdAt,
    updatedAt: values?.updatedAt,
  };
};
