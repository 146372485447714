import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Center, Row, RowStart } from "src/constants/globalStyles";

export const ContentWrap = styled.div`
  width: 100%;
`;

export const LogoWrap = styled(Center)`
  margin-bottom: 1em;

  img {
    height: 50px;
  }
`;

export const FooterWrap = styled.ul`
  margin: 0;
  padding: 0;
  border-top: 1px solid #e8e8e8;
  list-style: none;
`;

export const SignOut = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

export const Container = styled(RowStart)`
  align-items: flex-start;
`;

export const MainContainer = styled.main`
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;

  margin-left: 200px;

  ${({ theme }) => theme.desktop`
       margin-left: 250px;
   `}

  ${({ theme }) => theme.largeDesktop`
       margin-left: 300px;
   `}
`;
