import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Icon } from "../_styles";
import { ConfirmAlert } from "src/components/ConfirmAlert";

export const RemoveButton = ({
  title,
  description,
  okText,
  cancelText,
  handleClick,
  color,
  small,
  icon,
}: {
  title: string;
  description: string;
  okText: string;
  cancelText: string;
  handleClick: () => void;
  color?: string;
  small?: boolean;
  icon?: IconDefinition;
}) => {
  return (
    <ConfirmAlert
      title={title}
      description={description}
      okText={okText}
      cancelText={cancelText}
      handleOk={() => handleClick()}
    >
      <Icon small={small}>
        <FontAwesomeIcon
          icon={icon ? icon : faTrash}
          color={color || "#1321b1"}
        />
      </Icon>
    </ConfirmAlert>
  );
};
