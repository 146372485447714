import React from "react";
import { AdminInput, Card, Col, Row } from "optimify-mediportal-components";
import { Routes } from "../../../../../routes";
import { EditEntityLayout } from "../../../../../components/Layout/EditEntityLayout";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQuery } from "../../../../../utils/useQuery";
import {
  createArticleCategory,
  updateArticleCategory,
} from "../../../../../graphql/mutations";
import { useHistory, useParams } from "react-router-dom";
import { getArticleCategory } from "../../../../../graphql/queries";
import { EntityType, GetArticleCategoryQuery } from "../../../../../API";
import { Form } from "../../../../../constants/globalStyles";
import { routeTo } from "../../../../../utils/createRoute";
import { ArticlePages } from "../index";
import { message } from "antd";
import { FooterButtons } from "../../../../../components/Form/FooterButtons";

export const CreateCategory = () => {
  const { t } = useTranslation(["common", "articles"]);
  const { slug } = useParams<{ slug?: string }>();
  const history = useHistory();
  const { mutation, data, mutationLoading } = useQuery<GetArticleCategoryQuery>(
    getArticleCategory,
    {
      slug: slug,
    }
  );

  const formik = useFormik({
    initialValues: slug
      ? {
          id: data?.getArticleCategory?.id,
          name: data?.getArticleCategory?.name,
          entity: data?.getArticleCategory?.entity,
          order: data?.getArticleCategory?.order,
          slug: data?.getArticleCategory?.slug,
        }
      : {
          name: "",
          slug: "",
          order: 1,
          entity: EntityType.ARTICLE_CATEGORY,
        },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(2, t("form:validation.toShort", { count: 2 }))
        .max(50, t("form:validation.toLong", { count: 50 }))
        .required(t("form:validation.required")),
      slug: Yup.string()
        .min(1, t("form:validation.toShort", { count: 1 }))
        .max(100, t("form:validation.toLong", { count: 100 }))
        .required(t("form:validation.required")),
    }),
    onSubmit: async (values) => {
      await mutation(slug ? updateArticleCategory : createArticleCategory, {
        input: values,
      });
      message.success(`Kategorie byla úspěšně uložena.`);
      message.info(
        `Uložené změny budou na portálu viditelné po první revalidaci. Může trvat několik minut.`
      );
      routeTo(history, Routes.ARTICLES, [ArticlePages.CategoriesTable]);
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <EditEntityLayout
        title={t(slug ? "articles:editCategory" : "articles:createCategory")}
        footer={
          <FooterButtons
            formik={formik}
            onClick={() =>
              routeTo(history, Routes.ARTICLES, [ArticlePages.CategoriesTable])
            }
            loading={mutationLoading}
          />
        }
      >
        <Row>
          <Col>
            <Card padding>
              <AdminInput
                type="text"
                title={t("common:table.name")}
                name="name"
                formik={formik}
              />
              <AdminInput
                type="text"
                title={t("common:input.slug")}
                name="slug"
                formik={formik}
              />
            </Card>
          </Col>
        </Row>
      </EditEntityLayout>
    </Form>
  );
};
