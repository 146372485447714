import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { Avatar, Image } from "antd";
import { UserOutlined } from "@ant-design/icons";

import React from "react";

export const User = ({ user }: { user: string }) => {
  return (
    <div>
      <Avatar style={{ color: "#fff", backgroundColor: "#1321b1" }}>
        {user}
      </Avatar>
    </div>
  );
};
