import React from "react";
import { Row } from "optimify-mediportal-components";
import { useTranslation } from "react-i18next";
import { Routes } from "../../../../../routes";
import {
  AdvertisementType,
  AdvertisementTypesByOrderQuery,
  DeleteAdvertisementInput,
  DeleteAdvertisementMutation,
  EntityType,
  ModelSortDirection,
} from "../../../../../API";
import { advertisementTypesByOrder } from "../graphql/queires";
import { deleteAdvertisementType } from "../../../../../graphql/mutations";
import { createRoute } from "../../../../../utils/createRoute";
import { Parsed } from "../../../../../components/Table/DataFormat";
import { DateFormat } from "../../../../../components/Moment";
import { MedTable } from "src/components/Table";
import { EditButton } from "src/components/Form/Button/Icons/Edit";
import { RemoveButton } from "../../../../../components/Form/Button/Icons/Remove";
import { useMutation, usePaginatedQuery } from "@oasa/amplify-query";
import { Pagination } from "../../../../../components/Table/Pagination";
import { ApprovalRequired } from "../../../../../types/_types";

export const AdTypeSettingsTable = () => {
  const { t } = useTranslation(["common", "settings"]);

  const { loading, data, totalPages, page, setPage, refetch, nextTokens } =
    usePaginatedQuery<AdvertisementTypesByOrderQuery>(
      advertisementTypesByOrder,
      {
        entity: EntityType.ADVERTISEMENT_TYPE,
        sortDirection: ModelSortDirection.ASC,
        limit: 10,
        name: "advertisementTypesByCreatedAt",
      }
    );

  const [remove] = useMutation<
    DeleteAdvertisementInput,
    DeleteAdvertisementMutation
  >(deleteAdvertisementType);

  const removeItem = async (id?: string) => {
    if (id) {
      await remove({ input: { id: id } });
      refetch();
    }
  };

  return (
    <>
      <MedTable
        emptyTableText={t("settings:empty.adType")}
        dataSource={data?.advertisementTypesByOrder?.items || []}
        loading={loading}
        columns={[
          {
            title: t("common:table.name"),
            render: (text: any, record: AdvertisementType) => (
              <span>{record.name}</span>
            ),
          },
          {
            title: t("common:table.status"),
            render: (text: any, record: any) => (
              <span>
                {t(
                  record?.active
                    ? "common:table.active"
                    : "common:table.inactive"
                )}
              </span>
            ),
          },
          {
            title: t("common:table.approvalRequired"),
            render: (text: any, record: AdvertisementType) => (
              <span>
                {t(
                  record?.approvalRequired === ApprovalRequired.YES
                    ? "common:checkbox.yes"
                    : "common:checkbox.no"
                )}
              </span>
            ),
          },
          {
            title: t("common:table.createdAt"),
            render: (text: any, record: AdvertisementType) => (
              <span>
                <DateFormat date={record?.createdAt} />
              </span>
            ),
          },
          {
            title: t("common:table.updatedAt"),
            render: (text: any, record: AdvertisementType) => (
              <span>
                <DateFormat date={record?.updatedAt} />
              </span>
            ),
          },
          {
            title: "",
            titleHide: true,
            key: "action",
            render: (_text: any, record: AdvertisementType) => (
              <Row>
                <EditButton
                  route={createRoute(Routes.CREATE_ADVERTISEMENT_SETTING_TYPE, [
                    record?.id,
                  ])}
                />
                <RemoveButton
                  title={t("settings:removeAdvertisementType.title")}
                  description={t(
                    "settings:removeAdvertisementType.description"
                  )}
                  okText={t("settings:removeAdvertisementType.ok")}
                  cancelText={t("settings:removeAdvertisementType.cancel")}
                  handleClick={() => removeItem(record?.id)}
                />
              </Row>
            ),
          },
        ]}
      />
      <Pagination
        totalPages={totalPages}
        page={page}
        setPage={setPage}
        nextTokens={nextTokens}
      />
    </>
  );
};
