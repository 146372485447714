import React, { ReactNode } from "react";
import styled from "styled-components";
import { ButtonStyles, ChildrenWrapper } from "./_styles";

type ButtonVariant = "primary" | "outline" | "plain";

interface ButtonProps {
  variant: ButtonVariant;
  children: ReactNode;
  small?: boolean;
  disable?: boolean;
  tab?: boolean;
  tabStart?: boolean;
  tabEnd?: boolean;
}

export const LinkButton = (props: ButtonProps) => {
  return (
    <StyledLink
      variant={props.variant}
      small={props.small}
      disabled={props.disable}
      tab={props.tab}
      tabStart={props.tabStart}
      tabEnd={props.tabEnd}
    >
      <ChildrenWrapper>{props.children}</ChildrenWrapper>
    </StyledLink>
  );
};

const StyledLink = styled.span<{
  variant: ButtonVariant;
  small?: boolean;
  disabled?: boolean;
  tab?: boolean;
  tabStart?: boolean;
  tabEnd?: boolean;
}>`
  ${(props) => ButtonStyles(props)}

  ${(props) => props.disabled && "pointer-events: none;"}
`;
