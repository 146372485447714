/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEmailNewsletter = /* GraphQL */ `
  query GetEmailNewsletter($id: ID!) {
    getEmailNewsletter(id: $id) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const listEmailNewsletters = /* GraphQL */ `
  query ListEmailNewsletters(
    $filter: ModelEmailNewsletterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmailNewsletters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getArticleCategory = /* GraphQL */ `
  query GetArticleCategory($slug: String!) {
    getArticleCategory(slug: $slug) {
      id
      entity
      name
      slug
      order
      createdAt
      updatedAt
      articles {
        items {
          id
          active
          content
          description
          entity
          image
          title
          slug
          createdBy
          hashtags
          type
          order
          articleCategoryID
          createdAt
          updatedAt
          category {
            id
            entity
            name
            slug
            order
            createdAt
            updatedAt
            articles {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listArticleCategories = /* GraphQL */ `
  query ListArticleCategories(
    $slug: String
    $filter: ModelArticleCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listArticleCategories(
      slug: $slug
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        entity
        name
        slug
        order
        createdAt
        updatedAt
        articles {
          items {
            id
            active
            content
            description
            entity
            image
            title
            slug
            createdBy
            hashtags
            type
            order
            articleCategoryID
            createdAt
            updatedAt
            category {
              id
              entity
              name
              slug
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const articleCategoriesByOrder = /* GraphQL */ `
  query ArticleCategoriesByOrder(
    $entity: EntityType
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticleCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articleCategoriesByOrder(
      entity: $entity
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entity
        name
        slug
        order
        createdAt
        updatedAt
        articles {
          items {
            id
            active
            content
            description
            entity
            image
            title
            slug
            createdBy
            hashtags
            type
            order
            articleCategoryID
            createdAt
            updatedAt
            category {
              id
              entity
              name
              slug
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const articleCategoriesByCreatedAt = /* GraphQL */ `
  query ArticleCategoriesByCreatedAt(
    $entity: EntityType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticleCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articleCategoriesByCreatedAt(
      entity: $entity
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entity
        name
        slug
        order
        createdAt
        updatedAt
        articles {
          items {
            id
            active
            content
            description
            entity
            image
            title
            slug
            createdBy
            hashtags
            type
            order
            articleCategoryID
            createdAt
            updatedAt
            category {
              id
              entity
              name
              slug
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const articleCategoriesByUpdatedAt = /* GraphQL */ `
  query ArticleCategoriesByUpdatedAt(
    $entity: EntityType
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticleCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articleCategoriesByUpdatedAt(
      entity: $entity
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entity
        name
        slug
        order
        createdAt
        updatedAt
        articles {
          items {
            id
            active
            content
            description
            entity
            image
            title
            slug
            createdBy
            hashtags
            type
            order
            articleCategoryID
            createdAt
            updatedAt
            category {
              id
              entity
              name
              slug
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchArticleCategorys = /* GraphQL */ `
  query SearchArticleCategorys(
    $filter: SearchableArticleCategoryFilterInput
    $sort: SearchableArticleCategorySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchArticleCategorys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        entity
        name
        slug
        order
        createdAt
        updatedAt
        articles {
          items {
            id
            active
            content
            description
            entity
            image
            title
            slug
            createdBy
            hashtags
            type
            order
            articleCategoryID
            createdAt
            updatedAt
            category {
              id
              entity
              name
              slug
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getArticle = /* GraphQL */ `
  query GetArticle($slug: String!) {
    getArticle(slug: $slug) {
      id
      active
      content
      description
      entity
      image
      title
      slug
      createdBy
      hashtags
      type
      order
      articleCategoryID
      createdAt
      updatedAt
      category {
        id
        entity
        name
        slug
        order
        createdAt
        updatedAt
        articles {
          items {
            id
            active
            content
            description
            entity
            image
            title
            slug
            createdBy
            hashtags
            type
            order
            articleCategoryID
            createdAt
            updatedAt
            category {
              id
              entity
              name
              slug
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listArticles = /* GraphQL */ `
  query ListArticles(
    $slug: String
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listArticles(
      slug: $slug
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        active
        content
        description
        entity
        image
        title
        slug
        createdBy
        hashtags
        type
        order
        articleCategoryID
        createdAt
        updatedAt
        category {
          id
          entity
          name
          slug
          order
          createdAt
          updatedAt
          articles {
            items {
              id
              active
              content
              description
              entity
              image
              title
              slug
              createdBy
              hashtags
              type
              order
              articleCategoryID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const articlesByCreatedAt = /* GraphQL */ `
  query ArticlesByCreatedAt(
    $entity: EntityType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlesByCreatedAt(
      entity: $entity
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        content
        description
        entity
        image
        title
        slug
        createdBy
        hashtags
        type
        order
        articleCategoryID
        createdAt
        updatedAt
        category {
          id
          entity
          name
          slug
          order
          createdAt
          updatedAt
          articles {
            items {
              id
              active
              content
              description
              entity
              image
              title
              slug
              createdBy
              hashtags
              type
              order
              articleCategoryID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const articlesByUpdatedAt = /* GraphQL */ `
  query ArticlesByUpdatedAt(
    $entity: EntityType
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlesByUpdatedAt(
      entity: $entity
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        content
        description
        entity
        image
        title
        slug
        createdBy
        hashtags
        type
        order
        articleCategoryID
        createdAt
        updatedAt
        category {
          id
          entity
          name
          slug
          order
          createdAt
          updatedAt
          articles {
            items {
              id
              active
              content
              description
              entity
              image
              title
              slug
              createdBy
              hashtags
              type
              order
              articleCategoryID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const articlesByTypeByCreatedAt = /* GraphQL */ `
  query ArticlesByTypeByCreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlesByTypeByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        content
        description
        entity
        image
        title
        slug
        createdBy
        hashtags
        type
        order
        articleCategoryID
        createdAt
        updatedAt
        category {
          id
          entity
          name
          slug
          order
          createdAt
          updatedAt
          articles {
            items {
              id
              active
              content
              description
              entity
              image
              title
              slug
              createdBy
              hashtags
              type
              order
              articleCategoryID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const articlesByTypeByUpdatedAt = /* GraphQL */ `
  query ArticlesByTypeByUpdatedAt(
    $type: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlesByTypeByUpdatedAt(
      type: $type
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        content
        description
        entity
        image
        title
        slug
        createdBy
        hashtags
        type
        order
        articleCategoryID
        createdAt
        updatedAt
        category {
          id
          entity
          name
          slug
          order
          createdAt
          updatedAt
          articles {
            items {
              id
              active
              content
              description
              entity
              image
              title
              slug
              createdBy
              hashtags
              type
              order
              articleCategoryID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const articlesByType = /* GraphQL */ `
  query ArticlesByType(
    $type: String
    $sortDirection: ModelSortDirection
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlesByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        content
        description
        entity
        image
        title
        slug
        createdBy
        hashtags
        type
        order
        articleCategoryID
        createdAt
        updatedAt
        category {
          id
          entity
          name
          slug
          order
          createdAt
          updatedAt
          articles {
            items {
              id
              active
              content
              description
              entity
              image
              title
              slug
              createdBy
              hashtags
              type
              order
              articleCategoryID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const articlesByCategorySlug = /* GraphQL */ `
  query ArticlesByCategorySlug(
    $articleCategoryID: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlesByCategorySlug(
      articleCategoryID: $articleCategoryID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        content
        description
        entity
        image
        title
        slug
        createdBy
        hashtags
        type
        order
        articleCategoryID
        createdAt
        updatedAt
        category {
          id
          entity
          name
          slug
          order
          createdAt
          updatedAt
          articles {
            items {
              id
              active
              content
              description
              entity
              image
              title
              slug
              createdBy
              hashtags
              type
              order
              articleCategoryID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const searchArticles = /* GraphQL */ `
  query SearchArticles(
    $filter: SearchableArticleFilterInput
    $sort: SearchableArticleSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchArticles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        active
        content
        description
        entity
        image
        title
        slug
        createdBy
        hashtags
        type
        order
        articleCategoryID
        createdAt
        updatedAt
        category {
          id
          entity
          name
          slug
          order
          createdAt
          updatedAt
          articles {
            items {
              id
              active
              content
              description
              entity
              image
              title
              slug
              createdBy
              hashtags
              type
              order
              articleCategoryID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
      total
    }
  }
`;
export const getAdvertisementType = /* GraphQL */ `
  query GetAdvertisementType($id: ID!) {
    getAdvertisementType(id: $id) {
      id
      active
      entity
      name
      jobDescription
      order
      approvalRequired
      components
      createdAt
      updatedAt
      advertisements {
        items {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listAdvertisementTypes = /* GraphQL */ `
  query ListAdvertisementTypes(
    $filter: ModelAdvertisementTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdvertisementTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        entity
        name
        jobDescription
        order
        approvalRequired
        components
        createdAt
        updatedAt
        advertisements {
          items {
            id
            active
            creditsCount
            description
            entity
            internalName
            name
            numberOfDays
            reactivationCreditsCount
            order
            appliedTo
            public
            advertisementTypeID
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const advertisementTypesByCreatedAt = /* GraphQL */ `
  query AdvertisementTypesByCreatedAt(
    $entity: EntityType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdvertisementTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advertisementTypesByCreatedAt(
      entity: $entity
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        entity
        name
        jobDescription
        order
        approvalRequired
        components
        createdAt
        updatedAt
        advertisements {
          items {
            id
            active
            creditsCount
            description
            entity
            internalName
            name
            numberOfDays
            reactivationCreditsCount
            order
            appliedTo
            public
            advertisementTypeID
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const advertisementTypesByUpdatedAt = /* GraphQL */ `
  query AdvertisementTypesByUpdatedAt(
    $entity: EntityType
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdvertisementTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advertisementTypesByUpdatedAt(
      entity: $entity
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        entity
        name
        jobDescription
        order
        approvalRequired
        components
        createdAt
        updatedAt
        advertisements {
          items {
            id
            active
            creditsCount
            description
            entity
            internalName
            name
            numberOfDays
            reactivationCreditsCount
            order
            appliedTo
            public
            advertisementTypeID
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const advertisementTypesByOrder = /* GraphQL */ `
  query AdvertisementTypesByOrder(
    $entity: EntityType
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdvertisementTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advertisementTypesByOrder(
      entity: $entity
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        entity
        name
        jobDescription
        order
        approvalRequired
        components
        createdAt
        updatedAt
        advertisements {
          items {
            id
            active
            creditsCount
            description
            entity
            internalName
            name
            numberOfDays
            reactivationCreditsCount
            order
            appliedTo
            public
            advertisementTypeID
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getServiceSetting = /* GraphQL */ `
  query GetServiceSetting($id: ID!) {
    getServiceSetting(id: $id) {
      id
      active
      creditsCount
      entity
      name
      public
      appliedTo
      serviceType
      createdAt
      updatedAt
    }
  }
`;
export const listServiceSettings = /* GraphQL */ `
  query ListServiceSettings(
    $filter: ModelServiceSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        active
        creditsCount
        entity
        name
        public
        appliedTo
        serviceType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const serviceByName = /* GraphQL */ `
  query ServiceByName(
    $name: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelServiceSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    serviceByName(
      name: $name
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        creditsCount
        entity
        name
        public
        appliedTo
        serviceType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const serviceSettingsByCreatedAt = /* GraphQL */ `
  query ServiceSettingsByCreatedAt(
    $entity: EntityType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelServiceSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    serviceSettingsByCreatedAt(
      entity: $entity
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        creditsCount
        entity
        name
        public
        appliedTo
        serviceType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const serviceSettingsByUpdatedAt = /* GraphQL */ `
  query ServiceSettingsByUpdatedAt(
    $entity: EntityType
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelServiceSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    serviceSettingsByUpdatedAt(
      entity: $entity
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        creditsCount
        entity
        name
        public
        appliedTo
        serviceType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAdvertisementSetting = /* GraphQL */ `
  query GetAdvertisementSetting($id: ID!) {
    getAdvertisementSetting(id: $id) {
      id
      active
      creditsCount
      description
      entity
      internalName
      name
      numberOfDays
      reactivationCreditsCount
      order
      appliedTo
      public
      advertisementTypeID
      createdAt
      updatedAt
      advertisementType {
        id
        active
        entity
        name
        jobDescription
        order
        approvalRequired
        components
        createdAt
        updatedAt
        advertisements {
          items {
            id
            active
            creditsCount
            description
            entity
            internalName
            name
            numberOfDays
            reactivationCreditsCount
            order
            appliedTo
            public
            advertisementTypeID
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listAdvertisementSettings = /* GraphQL */ `
  query ListAdvertisementSettings(
    $filter: ModelAdvertisementSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdvertisementSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        creditsCount
        description
        entity
        internalName
        name
        numberOfDays
        reactivationCreditsCount
        order
        appliedTo
        public
        advertisementTypeID
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const advertisementSettingsByCreatedAt = /* GraphQL */ `
  query AdvertisementSettingsByCreatedAt(
    $entity: EntityType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdvertisementSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advertisementSettingsByCreatedAt(
      entity: $entity
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        creditsCount
        description
        entity
        internalName
        name
        numberOfDays
        reactivationCreditsCount
        order
        appliedTo
        public
        advertisementTypeID
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const advertisementSettingsByUpdatedAt = /* GraphQL */ `
  query AdvertisementSettingsByUpdatedAt(
    $entity: EntityType
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdvertisementSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advertisementSettingsByUpdatedAt(
      entity: $entity
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        creditsCount
        description
        entity
        internalName
        name
        numberOfDays
        reactivationCreditsCount
        order
        appliedTo
        public
        advertisementTypeID
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const advertisementSettingsByPublic = /* GraphQL */ `
  query AdvertisementSettingsByPublic(
    $public: PublicAdvertisementSetting
    $sortDirection: ModelSortDirection
    $filter: ModelAdvertisementSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advertisementSettingsByPublic(
      public: $public
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        creditsCount
        description
        entity
        internalName
        name
        numberOfDays
        reactivationCreditsCount
        order
        appliedTo
        public
        advertisementTypeID
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const advertisementSettingsByOrder = /* GraphQL */ `
  query AdvertisementSettingsByOrder(
    $entity: EntityType
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdvertisementSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advertisementSettingsByOrder(
      entity: $entity
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        creditsCount
        description
        entity
        internalName
        name
        numberOfDays
        reactivationCreditsCount
        order
        appliedTo
        public
        advertisementTypeID
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getWorkerType = /* GraphQL */ `
  query GetWorkerType($id: ID!) {
    getWorkerType(id: $id) {
      id
      name
      country
      level
      order
      createdAt
      updatedAt
      childEdges {
        items {
          id
          parentNodeId
          childNodeId
          country
          createdAt
          updatedAt
          parentNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
          childNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listWorkerTypes = /* GraphQL */ `
  query ListWorkerTypes(
    $filter: ModelWorkerTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkerTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const workerTypesByCountryAndLevel = /* GraphQL */ `
  query WorkerTypesByCountryAndLevel(
    $country: Country
    $level: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkerTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workerTypesByCountryAndLevel(
      country: $country
      level: $level
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const workerTypesByCountry = /* GraphQL */ `
  query WorkerTypesByCountry(
    $country: Country
    $sortDirection: ModelSortDirection
    $filter: ModelWorkerTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workerTypesByCountry(
      country: $country
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const workerTypesByLevel = /* GraphQL */ `
  query WorkerTypesByLevel(
    $level: Int
    $sortDirection: ModelSortDirection
    $filter: ModelWorkerTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workerTypesByLevel(
      level: $level
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getWorkerTypeEdge = /* GraphQL */ `
  query GetWorkerTypeEdge($id: ID!) {
    getWorkerTypeEdge(id: $id) {
      id
      parentNodeId
      childNodeId
      country
      createdAt
      updatedAt
      parentNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      childNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listWorkerTypeEdges = /* GraphQL */ `
  query ListWorkerTypeEdges(
    $filter: ModelWorkerTypeEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkerTypeEdges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parentNodeId
        childNodeId
        country
        createdAt
        updatedAt
        parentNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        childNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const workerTypesEdgesByParentNodeId = /* GraphQL */ `
  query WorkerTypesEdgesByParentNodeId(
    $parentNodeId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelWorkerTypeEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workerTypesEdgesByParentNodeId(
      parentNodeId: $parentNodeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentNodeId
        childNodeId
        country
        createdAt
        updatedAt
        parentNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        childNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const workerTypeEdgesByChildNodeId = /* GraphQL */ `
  query WorkerTypeEdgesByChildNodeId(
    $childNodeId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelWorkerTypeEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workerTypeEdgesByChildNodeId(
      childNodeId: $childNodeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentNodeId
        childNodeId
        country
        createdAt
        updatedAt
        parentNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        childNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const workerTypeEdgesByCountry = /* GraphQL */ `
  query WorkerTypeEdgesByCountry(
    $country: Country
    $sortDirection: ModelSortDirection
    $filter: ModelWorkerTypeEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workerTypeEdgesByCountry(
      country: $country
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentNodeId
        childNodeId
        country
        createdAt
        updatedAt
        parentNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        childNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getSuitableFor = /* GraphQL */ `
  query GetSuitableFor($id: ID!) {
    getSuitableFor(id: $id) {
      id
      name
      country
      level
      order
      createdAt
      updatedAt
      childEdges {
        items {
          id
          parentNodeId
          childNodeId
          country
          createdAt
          updatedAt
          parentNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
          childNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listSuitableFors = /* GraphQL */ `
  query ListSuitableFors(
    $filter: ModelSuitableForFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuitableFors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const suitableForByCountryAndLevel = /* GraphQL */ `
  query SuitableForByCountryAndLevel(
    $country: Country
    $level: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSuitableForFilterInput
    $limit: Int
    $nextToken: String
  ) {
    suitableForByCountryAndLevel(
      country: $country
      level: $level
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const suitableForByCountry = /* GraphQL */ `
  query SuitableForByCountry(
    $country: Country
    $sortDirection: ModelSortDirection
    $filter: ModelSuitableForFilterInput
    $limit: Int
    $nextToken: String
  ) {
    suitableForByCountry(
      country: $country
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const suitableForByLevel = /* GraphQL */ `
  query SuitableForByLevel(
    $level: Int
    $sortDirection: ModelSortDirection
    $filter: ModelSuitableForFilterInput
    $limit: Int
    $nextToken: String
  ) {
    suitableForByLevel(
      level: $level
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSuitableForEdge = /* GraphQL */ `
  query GetSuitableForEdge($id: ID!) {
    getSuitableForEdge(id: $id) {
      id
      parentNodeId
      childNodeId
      country
      createdAt
      updatedAt
      parentNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      childNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listSuitableForEdges = /* GraphQL */ `
  query ListSuitableForEdges(
    $filter: ModelSuitableForEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuitableForEdges(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentNodeId
        childNodeId
        country
        createdAt
        updatedAt
        parentNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        childNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const suitableForEdgesByParentNodeId = /* GraphQL */ `
  query SuitableForEdgesByParentNodeId(
    $parentNodeId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSuitableForEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    suitableForEdgesByParentNodeId(
      parentNodeId: $parentNodeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentNodeId
        childNodeId
        country
        createdAt
        updatedAt
        parentNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        childNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const suitableForEdgesByChildNodeId = /* GraphQL */ `
  query SuitableForEdgesByChildNodeId(
    $childNodeId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSuitableForEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    suitableForEdgesByChildNodeId(
      childNodeId: $childNodeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentNodeId
        childNodeId
        country
        createdAt
        updatedAt
        parentNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        childNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const suitableForEdgesByCountry = /* GraphQL */ `
  query SuitableForEdgesByCountry(
    $country: Country
    $sortDirection: ModelSortDirection
    $filter: ModelSuitableForEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    suitableForEdgesByCountry(
      country: $country
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentNodeId
        childNodeId
        country
        createdAt
        updatedAt
        parentNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        childNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getEmployer = /* GraphQL */ `
  query GetEmployer($id: ID!) {
    getEmployer(id: $id) {
      id
      name
      country
      level
      order
      createdAt
      updatedAt
      childEdges {
        items {
          id
          parentNodeId
          childNodeId
          country
          createdAt
          updatedAt
          parentNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
          childNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listEmployers = /* GraphQL */ `
  query ListEmployers(
    $filter: ModelEmployerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const employersByCountryAndLevel = /* GraphQL */ `
  query EmployersByCountryAndLevel(
    $country: Country
    $level: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employersByCountryAndLevel(
      country: $country
      level: $level
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const employersByCountry = /* GraphQL */ `
  query EmployersByCountry(
    $country: Country
    $sortDirection: ModelSortDirection
    $filter: ModelEmployerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employersByCountry(
      country: $country
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const employersByLevel = /* GraphQL */ `
  query EmployersByLevel(
    $level: Int
    $sortDirection: ModelSortDirection
    $filter: ModelEmployerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employersByLevel(
      level: $level
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getEmployerEdge = /* GraphQL */ `
  query GetEmployerEdge($id: ID!) {
    getEmployerEdge(id: $id) {
      id
      parentNodeId
      childNodeId
      country
      createdAt
      updatedAt
      parentNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      childNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listEmployerEdges = /* GraphQL */ `
  query ListEmployerEdges(
    $filter: ModelEmployerEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployerEdges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parentNodeId
        childNodeId
        country
        createdAt
        updatedAt
        parentNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        childNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const employerEdgesByParentNodeId = /* GraphQL */ `
  query EmployerEdgesByParentNodeId(
    $parentNodeId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEmployerEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employerEdgesByParentNodeId(
      parentNodeId: $parentNodeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentNodeId
        childNodeId
        country
        createdAt
        updatedAt
        parentNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        childNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const employerEdgesByChildNodeId = /* GraphQL */ `
  query EmployerEdgesByChildNodeId(
    $childNodeId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEmployerEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employerEdgesByChildNodeId(
      childNodeId: $childNodeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentNodeId
        childNodeId
        country
        createdAt
        updatedAt
        parentNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        childNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const employerEdgesByCountry = /* GraphQL */ `
  query EmployerEdgesByCountry(
    $country: Country
    $sortDirection: ModelSortDirection
    $filter: ModelEmployerEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employerEdgesByCountry(
      country: $country
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentNodeId
        childNodeId
        country
        createdAt
        updatedAt
        parentNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        childNode {
          id
          name
          country
          level
          order
          createdAt
          updatedAt
          childEdges {
            items {
              id
              parentNodeId
              childNodeId
              country
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      credits
      sub
      email
      entity
      entityState
      profileImage
      firstDegree
      forename
      surname
      secondDegree
      aboutMe
      educations
      workExperiences
      publications
      conferences
      certificates
      languages
      otherInformation
      qualifications
      name
      crn
      vatRegNumber
      street
      buildingIdentificationNumber
      municipality
      postCode
      language
      phone
      createdAt
      updatedAt
      owner
      messages {
        items {
          id
          name
          email
          phoneNumber
          message
          file
          state
          createdAt
          updatedAt
          profileID
          advertisementID
          profile {
            id
            credits
            sub
            email
            entity
            entityState
            profileImage
            firstDegree
            forename
            surname
            secondDegree
            aboutMe
            educations
            workExperiences
            publications
            conferences
            certificates
            languages
            otherInformation
            qualifications
            name
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            phone
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
          }
          advertisement {
            id
            friendlyId
            entity
            active
            state
            stateDescription
            paymentState
            jobPosition
            jobShortDescription
            jobDescription
            place
            salaryFrom
            salaryTo
            salarySpecifiedInText
            reactivationCount
            currentReactivationCount
            automaticReactivation
            leadingPosition
            residentialPlace
            typeOfCooperation
            jobType
            financialConditions
            typeOfInternship
            contactFirstDegree
            contactForename
            contactSurname
            contactSecondDegree
            contactEmail
            contactPhoneNumber
            hideContactInformation
            workType
            suitableFor
            workTypeStringified
            suitableForStringified
            employmentTypesStringified
            employmentTypes
            paymentType
            advertisementSettingID
            advertisementTypeID
            companyID
            profileID
            expirationDate
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
            advertisementSetting {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
            analytics {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        credits
        sub
        email
        entity
        entityState
        profileImage
        firstDegree
        forename
        surname
        secondDegree
        aboutMe
        educations
        workExperiences
        publications
        conferences
        certificates
        languages
        otherInformation
        qualifications
        name
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        phone
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            phoneNumber
            message
            file
            state
            createdAt
            updatedAt
            profileID
            advertisementID
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const profileBySub = /* GraphQL */ `
  query ProfileBySub(
    $sub: String
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profileBySub(
      sub: $sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        credits
        sub
        email
        entity
        entityState
        profileImage
        firstDegree
        forename
        surname
        secondDegree
        aboutMe
        educations
        workExperiences
        publications
        conferences
        certificates
        languages
        otherInformation
        qualifications
        name
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        phone
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            phoneNumber
            message
            file
            state
            createdAt
            updatedAt
            profileID
            advertisementID
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchProfiles = /* GraphQL */ `
  query SearchProfiles(
    $filter: SearchableProfileFilterInput
    $sort: SearchableProfileSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProfiles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        credits
        sub
        email
        entity
        entityState
        profileImage
        firstDegree
        forename
        surname
        secondDegree
        aboutMe
        educations
        workExperiences
        publications
        conferences
        certificates
        languages
        otherInformation
        qualifications
        name
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        phone
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            phoneNumber
            message
            file
            state
            createdAt
            updatedAt
            profileID
            advertisementID
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getMessageInbox = /* GraphQL */ `
  query GetMessageInbox($id: ID!) {
    getMessageInbox(id: $id) {
      id
      name
      email
      phoneNumber
      message
      file
      state
      createdAt
      updatedAt
      profileID
      advertisementID
      profile {
        id
        credits
        sub
        email
        entity
        entityState
        profileImage
        firstDegree
        forename
        surname
        secondDegree
        aboutMe
        educations
        workExperiences
        publications
        conferences
        certificates
        languages
        otherInformation
        qualifications
        name
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        phone
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            phoneNumber
            message
            file
            state
            createdAt
            updatedAt
            profileID
            advertisementID
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      advertisement {
        id
        friendlyId
        entity
        active
        state
        stateDescription
        paymentState
        jobPosition
        jobShortDescription
        jobDescription
        place
        salaryFrom
        salaryTo
        salarySpecifiedInText
        reactivationCount
        currentReactivationCount
        automaticReactivation
        leadingPosition
        residentialPlace
        typeOfCooperation
        jobType
        financialConditions
        typeOfInternship
        contactFirstDegree
        contactForename
        contactSurname
        contactSecondDegree
        contactEmail
        contactPhoneNumber
        hideContactInformation
        workType
        suitableFor
        workTypeStringified
        suitableForStringified
        employmentTypesStringified
        employmentTypes
        paymentType
        advertisementSettingID
        advertisementTypeID
        companyID
        profileID
        expirationDate
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        advertisementSetting {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
        analytics {
          items {
            id
            name
            info
            advertisementID
            createdAt
            updatedAt
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listMessageInboxs = /* GraphQL */ `
  query ListMessageInboxs(
    $filter: ModelMessageInboxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageInboxs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        phoneNumber
        message
        file
        state
        createdAt
        updatedAt
        profileID
        advertisementID
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        advertisement {
          id
          friendlyId
          entity
          active
          state
          stateDescription
          paymentState
          jobPosition
          jobShortDescription
          jobDescription
          place
          salaryFrom
          salaryTo
          salarySpecifiedInText
          reactivationCount
          currentReactivationCount
          automaticReactivation
          leadingPosition
          residentialPlace
          typeOfCooperation
          jobType
          financialConditions
          typeOfInternship
          contactFirstDegree
          contactForename
          contactSurname
          contactSecondDegree
          contactEmail
          contactPhoneNumber
          hideContactInformation
          workType
          suitableFor
          workTypeStringified
          suitableForStringified
          employmentTypesStringified
          employmentTypes
          paymentType
          advertisementSettingID
          advertisementTypeID
          companyID
          profileID
          expirationDate
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
          advertisementSetting {
            id
            active
            creditsCount
            description
            entity
            internalName
            name
            numberOfDays
            reactivationCreditsCount
            order
            appliedTo
            public
            advertisementTypeID
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
          }
          profile {
            id
            credits
            sub
            email
            entity
            entityState
            profileImage
            firstDegree
            forename
            surname
            secondDegree
            aboutMe
            educations
            workExperiences
            publications
            conferences
            certificates
            languages
            otherInformation
            qualifications
            name
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            phone
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
          }
          company {
            id
            entity
            entityState
            credits
            name
            logo
            forename
            surname
            address
            phone
            email
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            employerTaxonomy
            profileImage
            qualification
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
            users {
              nextToken
            }
          }
          owner
          analytics {
            items {
              id
              name
              info
              advertisementID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const messagesByProfile = /* GraphQL */ `
  query MessagesByProfile(
    $profileID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageInboxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByProfile(
      profileID: $profileID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        phoneNumber
        message
        file
        state
        createdAt
        updatedAt
        profileID
        advertisementID
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        advertisement {
          id
          friendlyId
          entity
          active
          state
          stateDescription
          paymentState
          jobPosition
          jobShortDescription
          jobDescription
          place
          salaryFrom
          salaryTo
          salarySpecifiedInText
          reactivationCount
          currentReactivationCount
          automaticReactivation
          leadingPosition
          residentialPlace
          typeOfCooperation
          jobType
          financialConditions
          typeOfInternship
          contactFirstDegree
          contactForename
          contactSurname
          contactSecondDegree
          contactEmail
          contactPhoneNumber
          hideContactInformation
          workType
          suitableFor
          workTypeStringified
          suitableForStringified
          employmentTypesStringified
          employmentTypes
          paymentType
          advertisementSettingID
          advertisementTypeID
          companyID
          profileID
          expirationDate
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
          advertisementSetting {
            id
            active
            creditsCount
            description
            entity
            internalName
            name
            numberOfDays
            reactivationCreditsCount
            order
            appliedTo
            public
            advertisementTypeID
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
          }
          profile {
            id
            credits
            sub
            email
            entity
            entityState
            profileImage
            firstDegree
            forename
            surname
            secondDegree
            aboutMe
            educations
            workExperiences
            publications
            conferences
            certificates
            languages
            otherInformation
            qualifications
            name
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            phone
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
          }
          company {
            id
            entity
            entityState
            credits
            name
            logo
            forename
            surname
            address
            phone
            email
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            employerTaxonomy
            profileImage
            qualification
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
            users {
              nextToken
            }
          }
          owner
          analytics {
            items {
              id
              name
              info
              advertisementID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const searchMessageInboxs = /* GraphQL */ `
  query SearchMessageInboxs(
    $filter: SearchableMessageInboxFilterInput
    $sort: SearchableMessageInboxSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchMessageInboxs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        email
        phoneNumber
        message
        file
        state
        createdAt
        updatedAt
        profileID
        advertisementID
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        advertisement {
          id
          friendlyId
          entity
          active
          state
          stateDescription
          paymentState
          jobPosition
          jobShortDescription
          jobDescription
          place
          salaryFrom
          salaryTo
          salarySpecifiedInText
          reactivationCount
          currentReactivationCount
          automaticReactivation
          leadingPosition
          residentialPlace
          typeOfCooperation
          jobType
          financialConditions
          typeOfInternship
          contactFirstDegree
          contactForename
          contactSurname
          contactSecondDegree
          contactEmail
          contactPhoneNumber
          hideContactInformation
          workType
          suitableFor
          workTypeStringified
          suitableForStringified
          employmentTypesStringified
          employmentTypes
          paymentType
          advertisementSettingID
          advertisementTypeID
          companyID
          profileID
          expirationDate
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
          advertisementSetting {
            id
            active
            creditsCount
            description
            entity
            internalName
            name
            numberOfDays
            reactivationCreditsCount
            order
            appliedTo
            public
            advertisementTypeID
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
          }
          profile {
            id
            credits
            sub
            email
            entity
            entityState
            profileImage
            firstDegree
            forename
            surname
            secondDegree
            aboutMe
            educations
            workExperiences
            publications
            conferences
            certificates
            languages
            otherInformation
            qualifications
            name
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            phone
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
          }
          company {
            id
            entity
            entityState
            credits
            name
            logo
            forename
            surname
            address
            phone
            email
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            employerTaxonomy
            profileImage
            qualification
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
            users {
              nextToken
            }
          }
          owner
          analytics {
            items {
              id
              name
              info
              advertisementID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
      total
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      entity
      entityState
      credits
      name
      logo
      forename
      surname
      address
      phone
      email
      crn
      vatRegNumber
      street
      buildingIdentificationNumber
      municipality
      postCode
      language
      employerTaxonomy
      profileImage
      qualification
      createdAt
      updatedAt
      owner
      messages {
        items {
          id
          name
          email
          state
          phoneNumber
          message
          file
          createdAt
          updatedAt
          companyID
          advertisementID
          company {
            id
            entity
            entityState
            credits
            name
            logo
            forename
            surname
            address
            phone
            email
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            employerTaxonomy
            profileImage
            qualification
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
            users {
              nextToken
            }
          }
          advertisement {
            id
            friendlyId
            entity
            active
            state
            stateDescription
            paymentState
            jobPosition
            jobShortDescription
            jobDescription
            place
            salaryFrom
            salaryTo
            salarySpecifiedInText
            reactivationCount
            currentReactivationCount
            automaticReactivation
            leadingPosition
            residentialPlace
            typeOfCooperation
            jobType
            financialConditions
            typeOfInternship
            contactFirstDegree
            contactForename
            contactSurname
            contactSecondDegree
            contactEmail
            contactPhoneNumber
            hideContactInformation
            workType
            suitableFor
            workTypeStringified
            suitableForStringified
            employmentTypesStringified
            employmentTypes
            paymentType
            advertisementSettingID
            advertisementTypeID
            companyID
            profileID
            expirationDate
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
            advertisementSetting {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
            analytics {
              nextToken
            }
          }
        }
        nextToken
      }
      users {
        items {
          id
          entity
          name
          email
          role
          createdAt
          updatedAt
          companyID
          company {
            id
            entity
            entityState
            credits
            name
            logo
            forename
            surname
            address
            phone
            email
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            employerTaxonomy
            profileImage
            qualification
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
            users {
              nextToken
            }
          }
          owner
        }
        nextToken
      }
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const companiesByState = /* GraphQL */ `
  query CompaniesByState(
    $entityState: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companiesByState(
      entityState: $entityState
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const companiesByName = /* GraphQL */ `
  query CompaniesByName(
    $entity: EntityType
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companiesByName(
      entity: $entity
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const companiesByCreatedAt = /* GraphQL */ `
  query CompaniesByCreatedAt(
    $entity: EntityType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companiesByCreatedAt(
      entity: $entity
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const companiesByUpdatedAt = /* GraphQL */ `
  query CompaniesByUpdatedAt(
    $entity: EntityType
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companiesByUpdatedAt(
      entity: $entity
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchCompanys = /* GraphQL */ `
  query SearchCompanys(
    $filter: SearchableCompanyFilterInput
    $sort: SearchableCompanySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCompanys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getCompanyMessageInbox = /* GraphQL */ `
  query GetCompanyMessageInbox($id: ID!) {
    getCompanyMessageInbox(id: $id) {
      id
      name
      email
      state
      phoneNumber
      message
      file
      createdAt
      updatedAt
      companyID
      advertisementID
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      advertisement {
        id
        friendlyId
        entity
        active
        state
        stateDescription
        paymentState
        jobPosition
        jobShortDescription
        jobDescription
        place
        salaryFrom
        salaryTo
        salarySpecifiedInText
        reactivationCount
        currentReactivationCount
        automaticReactivation
        leadingPosition
        residentialPlace
        typeOfCooperation
        jobType
        financialConditions
        typeOfInternship
        contactFirstDegree
        contactForename
        contactSurname
        contactSecondDegree
        contactEmail
        contactPhoneNumber
        hideContactInformation
        workType
        suitableFor
        workTypeStringified
        suitableForStringified
        employmentTypesStringified
        employmentTypes
        paymentType
        advertisementSettingID
        advertisementTypeID
        companyID
        profileID
        expirationDate
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        advertisementSetting {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
        analytics {
          items {
            id
            name
            info
            advertisementID
            createdAt
            updatedAt
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listCompanyMessageInboxs = /* GraphQL */ `
  query ListCompanyMessageInboxs(
    $filter: ModelCompanyMessageInboxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyMessageInboxs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        state
        phoneNumber
        message
        file
        createdAt
        updatedAt
        companyID
        advertisementID
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        advertisement {
          id
          friendlyId
          entity
          active
          state
          stateDescription
          paymentState
          jobPosition
          jobShortDescription
          jobDescription
          place
          salaryFrom
          salaryTo
          salarySpecifiedInText
          reactivationCount
          currentReactivationCount
          automaticReactivation
          leadingPosition
          residentialPlace
          typeOfCooperation
          jobType
          financialConditions
          typeOfInternship
          contactFirstDegree
          contactForename
          contactSurname
          contactSecondDegree
          contactEmail
          contactPhoneNumber
          hideContactInformation
          workType
          suitableFor
          workTypeStringified
          suitableForStringified
          employmentTypesStringified
          employmentTypes
          paymentType
          advertisementSettingID
          advertisementTypeID
          companyID
          profileID
          expirationDate
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
          advertisementSetting {
            id
            active
            creditsCount
            description
            entity
            internalName
            name
            numberOfDays
            reactivationCreditsCount
            order
            appliedTo
            public
            advertisementTypeID
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
          }
          profile {
            id
            credits
            sub
            email
            entity
            entityState
            profileImage
            firstDegree
            forename
            surname
            secondDegree
            aboutMe
            educations
            workExperiences
            publications
            conferences
            certificates
            languages
            otherInformation
            qualifications
            name
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            phone
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
          }
          company {
            id
            entity
            entityState
            credits
            name
            logo
            forename
            surname
            address
            phone
            email
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            employerTaxonomy
            profileImage
            qualification
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
            users {
              nextToken
            }
          }
          owner
          analytics {
            items {
              id
              name
              info
              advertisementID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const messagesByCompanyID = /* GraphQL */ `
  query MessagesByCompanyID(
    $companyID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyMessageInboxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByCompanyID(
      companyID: $companyID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        state
        phoneNumber
        message
        file
        createdAt
        updatedAt
        companyID
        advertisementID
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        advertisement {
          id
          friendlyId
          entity
          active
          state
          stateDescription
          paymentState
          jobPosition
          jobShortDescription
          jobDescription
          place
          salaryFrom
          salaryTo
          salarySpecifiedInText
          reactivationCount
          currentReactivationCount
          automaticReactivation
          leadingPosition
          residentialPlace
          typeOfCooperation
          jobType
          financialConditions
          typeOfInternship
          contactFirstDegree
          contactForename
          contactSurname
          contactSecondDegree
          contactEmail
          contactPhoneNumber
          hideContactInformation
          workType
          suitableFor
          workTypeStringified
          suitableForStringified
          employmentTypesStringified
          employmentTypes
          paymentType
          advertisementSettingID
          advertisementTypeID
          companyID
          profileID
          expirationDate
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
          advertisementSetting {
            id
            active
            creditsCount
            description
            entity
            internalName
            name
            numberOfDays
            reactivationCreditsCount
            order
            appliedTo
            public
            advertisementTypeID
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
          }
          profile {
            id
            credits
            sub
            email
            entity
            entityState
            profileImage
            firstDegree
            forename
            surname
            secondDegree
            aboutMe
            educations
            workExperiences
            publications
            conferences
            certificates
            languages
            otherInformation
            qualifications
            name
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            phone
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
          }
          company {
            id
            entity
            entityState
            credits
            name
            logo
            forename
            surname
            address
            phone
            email
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            employerTaxonomy
            profileImage
            qualification
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
            users {
              nextToken
            }
          }
          owner
          analytics {
            items {
              id
              name
              info
              advertisementID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const searchCompanyMessageInboxs = /* GraphQL */ `
  query SearchCompanyMessageInboxs(
    $filter: SearchableCompanyMessageInboxFilterInput
    $sort: SearchableCompanyMessageInboxSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCompanyMessageInboxs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        email
        state
        phoneNumber
        message
        file
        createdAt
        updatedAt
        companyID
        advertisementID
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        advertisement {
          id
          friendlyId
          entity
          active
          state
          stateDescription
          paymentState
          jobPosition
          jobShortDescription
          jobDescription
          place
          salaryFrom
          salaryTo
          salarySpecifiedInText
          reactivationCount
          currentReactivationCount
          automaticReactivation
          leadingPosition
          residentialPlace
          typeOfCooperation
          jobType
          financialConditions
          typeOfInternship
          contactFirstDegree
          contactForename
          contactSurname
          contactSecondDegree
          contactEmail
          contactPhoneNumber
          hideContactInformation
          workType
          suitableFor
          workTypeStringified
          suitableForStringified
          employmentTypesStringified
          employmentTypes
          paymentType
          advertisementSettingID
          advertisementTypeID
          companyID
          profileID
          expirationDate
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
          advertisementSetting {
            id
            active
            creditsCount
            description
            entity
            internalName
            name
            numberOfDays
            reactivationCreditsCount
            order
            appliedTo
            public
            advertisementTypeID
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
          }
          profile {
            id
            credits
            sub
            email
            entity
            entityState
            profileImage
            firstDegree
            forename
            surname
            secondDegree
            aboutMe
            educations
            workExperiences
            publications
            conferences
            certificates
            languages
            otherInformation
            qualifications
            name
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            phone
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
          }
          company {
            id
            entity
            entityState
            credits
            name
            logo
            forename
            surname
            address
            phone
            email
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            employerTaxonomy
            profileImage
            qualification
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
            users {
              nextToken
            }
          }
          owner
          analytics {
            items {
              id
              name
              info
              advertisementID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
      total
    }
  }
`;
export const getCompanyUser = /* GraphQL */ `
  query GetCompanyUser($id: ID!) {
    getCompanyUser(id: $id) {
      id
      entity
      name
      email
      role
      createdAt
      updatedAt
      companyID
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const listCompanyUsers = /* GraphQL */ `
  query ListCompanyUsers(
    $filter: ModelCompanyUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        entity
        name
        email
        role
        createdAt
        updatedAt
        companyID
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
      }
      nextToken
    }
  }
`;
export const usersByCompanyID = /* GraphQL */ `
  query UsersByCompanyID(
    $companyID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByCompanyID(
      companyID: $companyID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entity
        name
        email
        role
        createdAt
        updatedAt
        companyID
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
      }
      nextToken
    }
  }
`;
export const companyUsersByEmail = /* GraphQL */ `
  query CompanyUsersByEmail(
    $email: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyUsersByEmail(
      email: $email
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entity
        name
        email
        role
        createdAt
        updatedAt
        companyID
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
      }
      nextToken
    }
  }
`;
export const companyUserByCreatedAt = /* GraphQL */ `
  query CompanyUserByCreatedAt(
    $entity: EntityType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyUserByCreatedAt(
      entity: $entity
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entity
        name
        email
        role
        createdAt
        updatedAt
        companyID
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
      }
      nextToken
    }
  }
`;
export const companyUserByUpdatedAt = /* GraphQL */ `
  query CompanyUserByUpdatedAt(
    $entity: EntityType
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyUserByUpdatedAt(
      entity: $entity
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entity
        name
        email
        role
        createdAt
        updatedAt
        companyID
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
      }
      nextToken
    }
  }
`;
export const searchCompanyUsers = /* GraphQL */ `
  query SearchCompanyUsers(
    $filter: SearchableCompanyUserFilterInput
    $sort: SearchableCompanyUserSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCompanyUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        entity
        name
        email
        role
        createdAt
        updatedAt
        companyID
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
      }
      nextToken
      total
    }
  }
`;
export const getAdvertisement = /* GraphQL */ `
  query GetAdvertisement($id: ID!) {
    getAdvertisement(id: $id) {
      id
      friendlyId
      entity
      active
      state
      stateDescription
      paymentState
      jobPosition
      jobShortDescription
      jobDescription
      place
      salaryFrom
      salaryTo
      salarySpecifiedInText
      reactivationCount
      currentReactivationCount
      automaticReactivation
      leadingPosition
      residentialPlace
      typeOfCooperation
      jobType
      financialConditions
      typeOfInternship
      contactFirstDegree
      contactForename
      contactSurname
      contactSecondDegree
      contactEmail
      contactPhoneNumber
      hideContactInformation
      workType
      suitableFor
      workTypeStringified
      suitableForStringified
      employmentTypesStringified
      employmentTypes
      paymentType
      advertisementSettingID
      advertisementTypeID
      companyID
      profileID
      expirationDate
      createdAt
      updatedAt
      advertisementType {
        id
        active
        entity
        name
        jobDescription
        order
        approvalRequired
        components
        createdAt
        updatedAt
        advertisements {
          items {
            id
            active
            creditsCount
            description
            entity
            internalName
            name
            numberOfDays
            reactivationCreditsCount
            order
            appliedTo
            public
            advertisementTypeID
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      advertisementSetting {
        id
        active
        creditsCount
        description
        entity
        internalName
        name
        numberOfDays
        reactivationCreditsCount
        order
        appliedTo
        public
        advertisementTypeID
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      profile {
        id
        credits
        sub
        email
        entity
        entityState
        profileImage
        firstDegree
        forename
        surname
        secondDegree
        aboutMe
        educations
        workExperiences
        publications
        conferences
        certificates
        languages
        otherInformation
        qualifications
        name
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        phone
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            phoneNumber
            message
            file
            state
            createdAt
            updatedAt
            profileID
            advertisementID
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      owner
      analytics {
        items {
          id
          name
          info
          advertisementID
          createdAt
          updatedAt
          advertisement {
            id
            friendlyId
            entity
            active
            state
            stateDescription
            paymentState
            jobPosition
            jobShortDescription
            jobDescription
            place
            salaryFrom
            salaryTo
            salarySpecifiedInText
            reactivationCount
            currentReactivationCount
            automaticReactivation
            leadingPosition
            residentialPlace
            typeOfCooperation
            jobType
            financialConditions
            typeOfInternship
            contactFirstDegree
            contactForename
            contactSurname
            contactSecondDegree
            contactEmail
            contactPhoneNumber
            hideContactInformation
            workType
            suitableFor
            workTypeStringified
            suitableForStringified
            employmentTypesStringified
            employmentTypes
            paymentType
            advertisementSettingID
            advertisementTypeID
            companyID
            profileID
            expirationDate
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
            advertisementSetting {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
            analytics {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listAdvertisements = /* GraphQL */ `
  query ListAdvertisements(
    $filter: ModelAdvertisementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdvertisements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        friendlyId
        entity
        active
        state
        stateDescription
        paymentState
        jobPosition
        jobShortDescription
        jobDescription
        place
        salaryFrom
        salaryTo
        salarySpecifiedInText
        reactivationCount
        currentReactivationCount
        automaticReactivation
        leadingPosition
        residentialPlace
        typeOfCooperation
        jobType
        financialConditions
        typeOfInternship
        contactFirstDegree
        contactForename
        contactSurname
        contactSecondDegree
        contactEmail
        contactPhoneNumber
        hideContactInformation
        workType
        suitableFor
        workTypeStringified
        suitableForStringified
        employmentTypesStringified
        employmentTypes
        paymentType
        advertisementSettingID
        advertisementTypeID
        companyID
        profileID
        expirationDate
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        advertisementSetting {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
        analytics {
          items {
            id
            name
            info
            advertisementID
            createdAt
            updatedAt
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const advertisementsByCompany = /* GraphQL */ `
  query AdvertisementsByCompany(
    $companyID: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdvertisementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advertisementsByCompany(
      companyID: $companyID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        friendlyId
        entity
        active
        state
        stateDescription
        paymentState
        jobPosition
        jobShortDescription
        jobDescription
        place
        salaryFrom
        salaryTo
        salarySpecifiedInText
        reactivationCount
        currentReactivationCount
        automaticReactivation
        leadingPosition
        residentialPlace
        typeOfCooperation
        jobType
        financialConditions
        typeOfInternship
        contactFirstDegree
        contactForename
        contactSurname
        contactSecondDegree
        contactEmail
        contactPhoneNumber
        hideContactInformation
        workType
        suitableFor
        workTypeStringified
        suitableForStringified
        employmentTypesStringified
        employmentTypes
        paymentType
        advertisementSettingID
        advertisementTypeID
        companyID
        profileID
        expirationDate
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        advertisementSetting {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
        analytics {
          items {
            id
            name
            info
            advertisementID
            createdAt
            updatedAt
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const advertisementsByProfile = /* GraphQL */ `
  query AdvertisementsByProfile(
    $profileID: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdvertisementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advertisementsByProfile(
      profileID: $profileID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        friendlyId
        entity
        active
        state
        stateDescription
        paymentState
        jobPosition
        jobShortDescription
        jobDescription
        place
        salaryFrom
        salaryTo
        salarySpecifiedInText
        reactivationCount
        currentReactivationCount
        automaticReactivation
        leadingPosition
        residentialPlace
        typeOfCooperation
        jobType
        financialConditions
        typeOfInternship
        contactFirstDegree
        contactForename
        contactSurname
        contactSecondDegree
        contactEmail
        contactPhoneNumber
        hideContactInformation
        workType
        suitableFor
        workTypeStringified
        suitableForStringified
        employmentTypesStringified
        employmentTypes
        paymentType
        advertisementSettingID
        advertisementTypeID
        companyID
        profileID
        expirationDate
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        advertisementSetting {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
        analytics {
          items {
            id
            name
            info
            advertisementID
            createdAt
            updatedAt
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const advertisementsByCreatedAt = /* GraphQL */ `
  query AdvertisementsByCreatedAt(
    $entity: EntityType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdvertisementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advertisementsByCreatedAt(
      entity: $entity
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        friendlyId
        entity
        active
        state
        stateDescription
        paymentState
        jobPosition
        jobShortDescription
        jobDescription
        place
        salaryFrom
        salaryTo
        salarySpecifiedInText
        reactivationCount
        currentReactivationCount
        automaticReactivation
        leadingPosition
        residentialPlace
        typeOfCooperation
        jobType
        financialConditions
        typeOfInternship
        contactFirstDegree
        contactForename
        contactSurname
        contactSecondDegree
        contactEmail
        contactPhoneNumber
        hideContactInformation
        workType
        suitableFor
        workTypeStringified
        suitableForStringified
        employmentTypesStringified
        employmentTypes
        paymentType
        advertisementSettingID
        advertisementTypeID
        companyID
        profileID
        expirationDate
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        advertisementSetting {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
        analytics {
          items {
            id
            name
            info
            advertisementID
            createdAt
            updatedAt
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const advertisementsByUpdatedAt = /* GraphQL */ `
  query AdvertisementsByUpdatedAt(
    $entity: EntityType
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdvertisementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advertisementsByUpdatedAt(
      entity: $entity
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        friendlyId
        entity
        active
        state
        stateDescription
        paymentState
        jobPosition
        jobShortDescription
        jobDescription
        place
        salaryFrom
        salaryTo
        salarySpecifiedInText
        reactivationCount
        currentReactivationCount
        automaticReactivation
        leadingPosition
        residentialPlace
        typeOfCooperation
        jobType
        financialConditions
        typeOfInternship
        contactFirstDegree
        contactForename
        contactSurname
        contactSecondDegree
        contactEmail
        contactPhoneNumber
        hideContactInformation
        workType
        suitableFor
        workTypeStringified
        suitableForStringified
        employmentTypesStringified
        employmentTypes
        paymentType
        advertisementSettingID
        advertisementTypeID
        companyID
        profileID
        expirationDate
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        advertisementSetting {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
        analytics {
          items {
            id
            name
            info
            advertisementID
            createdAt
            updatedAt
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const advertisementsByState = /* GraphQL */ `
  query AdvertisementsByState(
    $state: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdvertisementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advertisementsByState(
      state: $state
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        friendlyId
        entity
        active
        state
        stateDescription
        paymentState
        jobPosition
        jobShortDescription
        jobDescription
        place
        salaryFrom
        salaryTo
        salarySpecifiedInText
        reactivationCount
        currentReactivationCount
        automaticReactivation
        leadingPosition
        residentialPlace
        typeOfCooperation
        jobType
        financialConditions
        typeOfInternship
        contactFirstDegree
        contactForename
        contactSurname
        contactSecondDegree
        contactEmail
        contactPhoneNumber
        hideContactInformation
        workType
        suitableFor
        workTypeStringified
        suitableForStringified
        employmentTypesStringified
        employmentTypes
        paymentType
        advertisementSettingID
        advertisementTypeID
        companyID
        profileID
        expirationDate
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        advertisementSetting {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
        analytics {
          items {
            id
            name
            info
            advertisementID
            createdAt
            updatedAt
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const advertisementsByPaymentState = /* GraphQL */ `
  query AdvertisementsByPaymentState(
    $paymentState: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdvertisementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advertisementsByPaymentState(
      paymentState: $paymentState
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        friendlyId
        entity
        active
        state
        stateDescription
        paymentState
        jobPosition
        jobShortDescription
        jobDescription
        place
        salaryFrom
        salaryTo
        salarySpecifiedInText
        reactivationCount
        currentReactivationCount
        automaticReactivation
        leadingPosition
        residentialPlace
        typeOfCooperation
        jobType
        financialConditions
        typeOfInternship
        contactFirstDegree
        contactForename
        contactSurname
        contactSecondDegree
        contactEmail
        contactPhoneNumber
        hideContactInformation
        workType
        suitableFor
        workTypeStringified
        suitableForStringified
        employmentTypesStringified
        employmentTypes
        paymentType
        advertisementSettingID
        advertisementTypeID
        companyID
        profileID
        expirationDate
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        advertisementSetting {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
        analytics {
          items {
            id
            name
            info
            advertisementID
            createdAt
            updatedAt
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchAdvertisements = /* GraphQL */ `
  query SearchAdvertisements(
    $filter: SearchableAdvertisementFilterInput
    $sort: SearchableAdvertisementSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchAdvertisements(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        friendlyId
        entity
        active
        state
        stateDescription
        paymentState
        jobPosition
        jobShortDescription
        jobDescription
        place
        salaryFrom
        salaryTo
        salarySpecifiedInText
        reactivationCount
        currentReactivationCount
        automaticReactivation
        leadingPosition
        residentialPlace
        typeOfCooperation
        jobType
        financialConditions
        typeOfInternship
        contactFirstDegree
        contactForename
        contactSurname
        contactSecondDegree
        contactEmail
        contactPhoneNumber
        hideContactInformation
        workType
        suitableFor
        workTypeStringified
        suitableForStringified
        employmentTypesStringified
        employmentTypes
        paymentType
        advertisementSettingID
        advertisementTypeID
        companyID
        profileID
        expirationDate
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        advertisementSetting {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
        analytics {
          items {
            id
            name
            info
            advertisementID
            createdAt
            updatedAt
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getAdvertisementAnalytic = /* GraphQL */ `
  query GetAdvertisementAnalytic($id: ID!) {
    getAdvertisementAnalytic(id: $id) {
      id
      name
      info
      advertisementID
      createdAt
      updatedAt
      advertisement {
        id
        friendlyId
        entity
        active
        state
        stateDescription
        paymentState
        jobPosition
        jobShortDescription
        jobDescription
        place
        salaryFrom
        salaryTo
        salarySpecifiedInText
        reactivationCount
        currentReactivationCount
        automaticReactivation
        leadingPosition
        residentialPlace
        typeOfCooperation
        jobType
        financialConditions
        typeOfInternship
        contactFirstDegree
        contactForename
        contactSurname
        contactSecondDegree
        contactEmail
        contactPhoneNumber
        hideContactInformation
        workType
        suitableFor
        workTypeStringified
        suitableForStringified
        employmentTypesStringified
        employmentTypes
        paymentType
        advertisementSettingID
        advertisementTypeID
        companyID
        profileID
        expirationDate
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        advertisementSetting {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
        analytics {
          items {
            id
            name
            info
            advertisementID
            createdAt
            updatedAt
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listAdvertisementAnalytics = /* GraphQL */ `
  query ListAdvertisementAnalytics(
    $filter: ModelAdvertisementAnalyticFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdvertisementAnalytics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        info
        advertisementID
        createdAt
        updatedAt
        advertisement {
          id
          friendlyId
          entity
          active
          state
          stateDescription
          paymentState
          jobPosition
          jobShortDescription
          jobDescription
          place
          salaryFrom
          salaryTo
          salarySpecifiedInText
          reactivationCount
          currentReactivationCount
          automaticReactivation
          leadingPosition
          residentialPlace
          typeOfCooperation
          jobType
          financialConditions
          typeOfInternship
          contactFirstDegree
          contactForename
          contactSurname
          contactSecondDegree
          contactEmail
          contactPhoneNumber
          hideContactInformation
          workType
          suitableFor
          workTypeStringified
          suitableForStringified
          employmentTypesStringified
          employmentTypes
          paymentType
          advertisementSettingID
          advertisementTypeID
          companyID
          profileID
          expirationDate
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
          advertisementSetting {
            id
            active
            creditsCount
            description
            entity
            internalName
            name
            numberOfDays
            reactivationCreditsCount
            order
            appliedTo
            public
            advertisementTypeID
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
          }
          profile {
            id
            credits
            sub
            email
            entity
            entityState
            profileImage
            firstDegree
            forename
            surname
            secondDegree
            aboutMe
            educations
            workExperiences
            publications
            conferences
            certificates
            languages
            otherInformation
            qualifications
            name
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            phone
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
          }
          company {
            id
            entity
            entityState
            credits
            name
            logo
            forename
            surname
            address
            phone
            email
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            employerTaxonomy
            profileImage
            qualification
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
            users {
              nextToken
            }
          }
          owner
          analytics {
            items {
              id
              name
              info
              advertisementID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const advertisementAnalyticsByAdvertisementId = /* GraphQL */ `
  query AdvertisementAnalyticsByAdvertisementId(
    $advertisementID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAdvertisementAnalyticFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advertisementAnalyticsByAdvertisementId(
      advertisementID: $advertisementID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        info
        advertisementID
        createdAt
        updatedAt
        advertisement {
          id
          friendlyId
          entity
          active
          state
          stateDescription
          paymentState
          jobPosition
          jobShortDescription
          jobDescription
          place
          salaryFrom
          salaryTo
          salarySpecifiedInText
          reactivationCount
          currentReactivationCount
          automaticReactivation
          leadingPosition
          residentialPlace
          typeOfCooperation
          jobType
          financialConditions
          typeOfInternship
          contactFirstDegree
          contactForename
          contactSurname
          contactSecondDegree
          contactEmail
          contactPhoneNumber
          hideContactInformation
          workType
          suitableFor
          workTypeStringified
          suitableForStringified
          employmentTypesStringified
          employmentTypes
          paymentType
          advertisementSettingID
          advertisementTypeID
          companyID
          profileID
          expirationDate
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
          advertisementSetting {
            id
            active
            creditsCount
            description
            entity
            internalName
            name
            numberOfDays
            reactivationCreditsCount
            order
            appliedTo
            public
            advertisementTypeID
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
          }
          profile {
            id
            credits
            sub
            email
            entity
            entityState
            profileImage
            firstDegree
            forename
            surname
            secondDegree
            aboutMe
            educations
            workExperiences
            publications
            conferences
            certificates
            languages
            otherInformation
            qualifications
            name
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            phone
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
          }
          company {
            id
            entity
            entityState
            credits
            name
            logo
            forename
            surname
            address
            phone
            email
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            employerTaxonomy
            profileImage
            qualification
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
            users {
              nextToken
            }
          }
          owner
          analytics {
            items {
              id
              name
              info
              advertisementID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getShowCase = /* GraphQL */ `
  query GetShowCase($companyID: ID!) {
    getShowCase(companyID: $companyID) {
      id
      title
      text
      file
      createdAt
      updatedAt
      companyID
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
    }
  }
`;
export const listShowCases = /* GraphQL */ `
  query ListShowCases(
    $companyID: ID
    $filter: ModelShowCaseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listShowCases(
      companyID: $companyID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        text
        file
        createdAt
        updatedAt
        companyID
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      amount
      currency
      description
      paymentIntent
      companyID
      profileID
      initiatedBy
      createdAt
      updatedAt
      profile {
        id
        credits
        sub
        email
        entity
        entityState
        profileImage
        firstDegree
        forename
        surname
        secondDegree
        aboutMe
        educations
        workExperiences
        publications
        conferences
        certificates
        languages
        otherInformation
        qualifications
        name
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        phone
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            phoneNumber
            message
            file
            state
            createdAt
            updatedAt
            profileID
            advertisementID
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      initiatedByProfile {
        id
        credits
        sub
        email
        entity
        entityState
        profileImage
        firstDegree
        forename
        surname
        secondDegree
        aboutMe
        educations
        workExperiences
        publications
        conferences
        certificates
        languages
        otherInformation
        qualifications
        name
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        phone
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            phoneNumber
            message
            file
            state
            createdAt
            updatedAt
            profileID
            advertisementID
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amount
        currency
        description
        paymentIntent
        companyID
        profileID
        initiatedBy
        createdAt
        updatedAt
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        initiatedByProfile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const transactionsByCompany = /* GraphQL */ `
  query TransactionsByCompany(
    $companyID: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByCompany(
      companyID: $companyID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount
        currency
        description
        paymentIntent
        companyID
        profileID
        initiatedBy
        createdAt
        updatedAt
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        initiatedByProfile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const transactionsByProfile = /* GraphQL */ `
  query TransactionsByProfile(
    $profileID: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByProfile(
      profileID: $profileID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount
        currency
        description
        paymentIntent
        companyID
        profileID
        initiatedBy
        createdAt
        updatedAt
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        initiatedByProfile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getCarousel = /* GraphQL */ `
  query GetCarousel($id: ID!) {
    getCarousel(id: $id) {
      id
      active
      entity
      image
      title
      type
      companyID
      createdAt
      updatedAt
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
    }
  }
`;
export const listCarousels = /* GraphQL */ `
  query ListCarousels(
    $filter: ModelCarouselFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarousels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        active
        entity
        image
        title
        type
        companyID
        createdAt
        updatedAt
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const carouselsByCreatedAt = /* GraphQL */ `
  query CarouselsByCreatedAt(
    $entity: EntityType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCarouselFilterInput
    $limit: Int
    $nextToken: String
  ) {
    carouselsByCreatedAt(
      entity: $entity
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        entity
        image
        title
        type
        companyID
        createdAt
        updatedAt
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const carouselsByUpdatedAt = /* GraphQL */ `
  query CarouselsByUpdatedAt(
    $entity: EntityType
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCarouselFilterInput
    $limit: Int
    $nextToken: String
  ) {
    carouselsByUpdatedAt(
      entity: $entity
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        entity
        image
        title
        type
        companyID
        createdAt
        updatedAt
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const carouselsByType = /* GraphQL */ `
  query CarouselsByType(
    $type: CarouselType
    $sortDirection: ModelSortDirection
    $filter: ModelCarouselFilterInput
    $limit: Int
    $nextToken: String
  ) {
    carouselsByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        entity
        image
        title
        type
        companyID
        createdAt
        updatedAt
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
