import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SpaceBetween } from "optimify-mediportal-components";
import { Column, RowEnd } from "src/constants/globalStyles";

export const Icon = styled(FontAwesomeIcon)`
  margin-right: 4px;
`;

export const ButtonContainer = styled(RowEnd)``;

export const SendContainer = styled(Column)`
  margin-left: 1em;
  justify-content: flex-end;
`;

export const Container = styled(SpaceBetween)`
  width: 100%;
  margin-top: 10px;
  align-items: start;
  color: ${(props) => props.theme.text.blue};
`;

export const MainContact = styled(Column)`
  background-color: ${(props) => props.theme.background.green};
  padding: 2em;
  min-width: 150px;
  max-width: 550px;
  border-radius: 12px;
`;

export const Name = styled.span`
  margin-bottom: 0.5em;
  font-size: 1.1em;
  font-weight: bold;
`;

export const Description = styled.span`
  font-size: 0.9em;
`;

export const Form = styled.form``;

export const ColumnContainer = styled(Column)`
  margin-top: 15px;
`;
