import React from "react";
import { BaseRoutes } from "./routes";
import { theme } from "./constants/theme";
import { ThemeProvider } from "styled-components";
import media from "./constants/media";

const App = () => {
  return (
    <ThemeProvider theme={{ ...media, ...theme }}>
      <BaseRoutes />
    </ThemeProvider>
  );
};

export default App;
