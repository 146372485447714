import React, { MouseEventHandler, ReactNode } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type MenuItemType =
  | {
      text: string;
      icon?: IconProp;
      href: string;
      onClick?: undefined;
      active?: boolean;
      show: boolean;
    }
  | {
      text: string;
      icon?: IconProp;
      href?: undefined;
      onClick: MouseEventHandler<HTMLAnchorElement>;
      active?: boolean;
      show: boolean;
    };

const Menu = ({
  items,
  header,
  footer,
}: {
  header?: ReactNode;
  items: Array<MenuItemType>;
  footer?: ReactNode;
}) => {
  return (
    <Wrap>
      {header && <HeaderWrap>{header}</HeaderWrap>}
      <ItemsWrap>
        {items
          ?.filter((item: MenuItemType) => item.show)
          ?.map((item, k) => (
            <MenuItem key={k} {...item} />
          ))}
      </ItemsWrap>
      {footer && <FooterWrap>{footer}</FooterWrap>}
    </Wrap>
  );
};

export const MenuItem = (props: MenuItemType) => {
  return (
    <ItemWrap>
      {props.href ? (
        <a href={props.href}>
          <ItemLink active={props.active}>
            {props.icon && <FontAwesomeIcon icon={props.icon} />}
            <span>{props.text}</span>
          </ItemLink>
        </a>
      ) : (
        <ItemLink onClick={props.onClick} active={props.active}>
          {props.icon && <FontAwesomeIcon icon={props.icon} />}
          <span>{props.text}</span>
        </ItemLink>
      )}
    </ItemWrap>
  );
};

export default Menu;

const Wrap = styled.nav`
  box-sizing: border-box;
  position: fixed;

  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  width: 200px;
  height: 100vh;

  border-right: 1px solid ${(props) => props.theme.border.darkBlue};
  background-color: ${(props) => props.theme.background.blue};
  color: ${(props) => props.theme.text.white};
  box-shadow: 4px 0px 16px rgba(16, 30, 115, 0.08);

  ${({ theme }) => theme.desktop`
       width: 250px;
   `}

  ${({ theme }) => theme.largeDesktop`
      width: 300px;
   `}
`;

const HeaderWrap = styled.div`
  box-sizing: border-box;
  background-color: #ececec;
`;

const ItemsWrap = styled.ul`
  box-sizing: border-box;

  flex-grow: 1;

  margin: 0;
  padding: 0;

  list-style: none;

  border-top: 1px solid ${(props) => props.theme.border.gray}; ;
`;

const MenuHeader = styled.div`
  margin: 0 24px 8px;

  color: ${(props) => props.theme.darkerGray};
  font-size: 14px;
`;

const FooterWrap = styled.div`
  box-sizing: border-box;
`;

const ItemWrap = styled.li`
  box-sizing: border-box;

  width: 100%;
  height: 48px;

  a:hover {
    color: inherit;
  }
`;

const ItemLink = styled.a<{ active?: boolean }>`
  box-sizing: border-box;

  display: flex;
  align-items: center;

  width: 100%;
  height: 100%;

  padding: 8px 24px 8px 56px;

  background-color: ${(props) =>
    props.active ? props.theme.primaryLight : "transparent"};
  color: ${(props) => props.theme.darkGray};
  font-size: 14px;
  font-weight: 600;

  cursor: pointer;
  transition: all 0.3s ease-out;

  > * {
    display: inline-block;
  }

  svg {
    width: 16px !important;
    height: 16px;

    margin: 0 16px -2px -32px;

    color: ${(props) => props.theme.background.whie};
  }

  &:hover {
    background-color: ${(props) => props.theme.primaryLightest};
  }
`;
