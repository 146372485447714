import { Link } from "react-router-dom";
import { Routes } from "../../routes";
import React from "react";
import { LinkButton } from "../Form/Button/Link";

export const LinkTo = ({ to, title }: { to: Routes; title: string }) => {
  return (
    <Link to={to}>
      <LinkButton variant="plain">{title}</LinkButton>
    </Link>
  );
};
