export const articlesByCreatedAt = /* GraphQL */ `
  query ArticlesByCreatedAt(
    $entity: EntityType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlesByCreatedAt(
      entity: $entity
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        description
        title
        type
        order
        createdAt
        slug
        updatedAt
        category {
          name
        }
      }
      nextToken
    }
  }
`;

export const articleCategoriesByCreatedAt = /* GraphQL */ `
  query ArticleCategoriesByCreatedAt(
    $entity: EntityType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticleCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articleCategoriesByCreatedAt(
      entity: $entity
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const articlesByCategorySlug = /* GraphQL */ `
  query ArticlesByCategorySlug(
    $articleCategoryID: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlesByCategorySlug(
      articleCategoryID: $articleCategoryID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
    }
  }
`;
