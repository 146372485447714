import {
  createServiceSetting,
  updateServiceSetting,
} from "../../../../../graphql/mutations";
import { message } from "antd";
import { routeTo } from "../../../../../utils/createRoute";
import { Routes } from "../../../../../routes";
import { CreateServiceSettingInput } from "../../../../../API";
import { SettingPage } from "../index";

/**
 * Create service setting
 * @param values
 * @param mutation
 * @param id
 * @param history
 */
export const handleSubmit = async (
  values: CreateServiceSettingInput,
  mutation: any,
  history: any,
  id?: string
) => {
  try {
    const formattedValues = {
      ...values,
      appliedTo: JSON.stringify(values.appliedTo),
      serviceType: JSON.stringify(values.serviceType),
    };

    await mutation(id ? updateServiceSetting : createServiceSetting, {
      input: formattedValues,
    });

    message.success(`Nastavení služby bylo úspěšně uloženo.`);

    routeTo(history, Routes.SETTINGS, [SettingPage.ServiceSettings]);
  } catch (e) {
    message.error(`Exception: ${e}`);
  }
};
