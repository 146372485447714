import React from "react";
import { SearchUsersWrapper } from "../../_styles";
import { useFormik } from "formik";
import { FormData } from "../../_types";
import Search from "src/components/Form/Search";
import { SearchUsers } from "./SearchUsers";

export const PrivatePerson = () => {
  const formik = useFormik<FormData>({
    initialValues: {
      name: undefined,
    },
    enableReinitialize: true,
    onSubmit: async (values: FormData) => {},
  });

  const mapValue = (value: string | undefined) => {
    if (!value || value.length === 0) {
      return undefined;
    }
    return value;
  };

  const value = formik.values?.name ? mapValue(formik.values?.name) : undefined;

  return (
    <>
      <SearchUsersWrapper>
        <Search formik={formik} name={"name"} />
      </SearchUsersWrapper>
      <SearchUsers value={value} />
    </>
  );
};
