export interface FormData {
  job: string | undefined;
  companyID: string | undefined;
  state: string | undefined;
  profileID: string | undefined;
  status: string | undefined;
  dateFrom: string | undefined;
  dateTo: string | undefined;
}

export enum AdvertisementState {
  TEMPLATE = "TEMPLATE",
  APPROVED = "APPROVED",
  DISAPPROVED = "DISAPPROVED",
  WAITING_FOR_APPROVAL = "WAITING_FOR_APPROVAL",
  DELETED = "DELETED",
}
