import React, { useEffect, useState } from "react";
import { AdminInput, Card, Col, Row } from "optimify-mediportal-components";
import { Routes } from "../../../../../routes";
import { EditEntityLayout } from "../../../../../components/Layout/EditEntityLayout";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "../../../../../utils/useQuery";
import {
  CreateArticleInput,
  EntityType,
  GetArticleQuery,
  ListArticleCategoriesQuery,
} from "../../../../../API";
import {
  getArticle,
  listArticleCategories,
} from "../../../../../graphql/queries";
import { DragAndDrop } from "../../../../../components/Form/File";
import { ContentEditor } from "../../../../../components/Form/Editor";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { FormSelect } from "../../../../../components/Form/Select";
import { Form } from "../../../../../constants/globalStyles";
import { routeTo } from "../../../../../utils/createRoute";
import { ArticlePages } from "../index";
import { Storage } from "aws-amplify";
import { handleSubmit } from "./_services";
import { FooterButtons } from "../../../../../components/Form/FooterButtons";
import { HashTagSelect } from "../components/HashTagSelect";
import { AdminTextArea } from "../../../../../components/Form/TextArea";
import { Switch } from "src/components/Form/Switch";
import { ArticleType } from "../../../../../types/_types";
import { CheckboxGroup } from "src/components/Form/CheckBox";

export const CreateArticle = () => {
  const { t } = useTranslation(["common", "articles"]);
  const { slug } = useParams<{ slug?: string }>();
  const history = useHistory();
  const [url, setUrl] = useState<string | null>(null);
  const { mutation, data, mutationLoading } = useQuery<GetArticleQuery>(
    getArticle,
    {
      slug: slug,
    }
  );
  const categories = useQuery<ListArticleCategoriesQuery>(
    listArticleCategories
  );

  const formik = useFormik<CreateArticleInput>({
    initialValues: data?.getArticle
      ? {
          id: data?.getArticle?.id,
          title: data?.getArticle?.title,
          description: data?.getArticle?.description,
          content: "",
          active: data?.getArticle?.active,
          articleCategoryID: data?.getArticle?.articleCategoryID,
          entity: data?.getArticle?.entity,
          type: data?.getArticle?.type,
          slug: data?.getArticle?.slug,
          hashtags: data?.getArticle?.hashtags
            ? JSON.parse(data?.getArticle?.hashtags)
            : [],
          createdBy: data?.getArticle?.createdBy,
          image: data?.getArticle?.image,
        }
      : {
          title: "",
          description: "",
          content: "",
          articleCategoryID: "",
          image: "",
          slug: "",
          createdBy: "",
          type: ArticleType.STANDARD,
          entity: EntityType.ARTICLE,
          active: true,
        },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .min(2, t("form:validation.toShort", { count: 2 }))
        .max(200, t("form:validation.toLong", { count: 200 }))
        .required(t("form:validation.required")),
      articleCategoryID: Yup.string().required(t("form:validation.required")),
      image: Yup.string()
        .test("hasImage", t("form:validation.imageRequired"), (value) => {
          if (!value) return false;
          return true;
        })
        .typeError(t("form:validation.imageRequired")),
    }),
    onSubmit: async (values: CreateArticleInput) => {
      await handleSubmit(values, mutation, history, slug);
    },
  });

  useEffect(() => {
    const initArticle = async () => {
      if (data?.getArticle?.content) {
        const content =
          data?.getArticle?.content && data?.getArticle?.content != ""
            ? EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  convertFromHTML(data?.getArticle?.content as any) as any
                )
              )
            : "";

        formik.setFieldValue("content", content);
      }
      if (data?.getArticle?.image) {
        const image = await Storage.get(data?.getArticle?.image);
        setUrl(image as string);
      }
    };

    initArticle();
  }, [data?.getArticle]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <EditEntityLayout
        title={t(slug ? "articles:editArticle" : "articles:createArticle")}
        footer={
          <FooterButtons
            onClick={() =>
              routeTo(history, Routes.ARTICLES, [ArticlePages.ArticlesTable])
            }
            loading={mutationLoading}
          />
        }
        titleRightSection={<Switch name={"active"} formik={formik} />}
      >
        <Row>
          <Col>
            <Card padding>
              <FormSelect
                loading={categories?.loading}
                title={t("common:input.articleCategory")}
                placeholder={t("common:input.articleCategory")}
                name="articleCategoryID"
                formik={formik}
                options={
                  categories?.data?.listArticleCategories?.items?.map(
                    (value, index) => {
                      return {
                        value: value?.slug,
                        title: value?.name,
                      };
                    }
                  ) || []
                }
                showSearch
              />
              <AdminInput
                type="text"
                title={t("common:input.articleTitle")}
                name="title"
                formik={formik}
              />
              <HashTagSelect
                title={t("common:input.hashtag")}
                name="hashtags"
                formik={formik}
              />
              <AdminTextArea
                title={t("common:input.articleDescription")}
                name="description"
                formik={formik}
              />
              <AdminInput
                type="text"
                title={t("common:input.createdBy")}
                name="createdBy"
                formik={formik}
              />
              <CheckboxGroup
                formik={formik}
                singleChoice
                name={"type"}
                title={t("articles:type.title")}
                options={[
                  {
                    value: ArticleType.STANDARD,
                    label: t("articles:type.standard"),
                  },
                  {
                    value: ArticleType.PAID,
                    label: t("articles:type.paid"),
                  },
                ]}
              />
              <DragAndDrop
                formik={formik}
                name={"image"}
                url={url}
                imageName={data?.getArticle?.image}
              />
            </Card>
          </Col>
          <Col>
            <Card padding fullHeight>
              <ContentEditor name="content" formik={formik} />
            </Card>
          </Col>
        </Row>
      </EditEntityLayout>
    </Form>
  );
};
