import styled from "styled-components";
import {
  EditButton,
  AddButton,
  RemoveButton,
  SpaceBetween,
} from "optimify-mediportal-components";
import { IconDefinition } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../../../routes";
import { routeTo } from "../../../../../utils/createRoute";
import { Country } from "../../../../../API";

export const Element = ({
  id,
  title,
  country,
  refetch,
  mutation,
  createRoute,
  editRoute,
  objectKey,
  index,
  level,
  deleteQuery,
}: {
  id: string;
  title: string;
  objectKey: string;
  index: number;
  level: number;
  country: Country;
  createRoute: Routes;
  editRoute: Routes;
  refetch: () => void;
  mutation: (query: string, variables?: any) => Promise<any>;
  deleteQuery: any;
}) => {
  const history = useHistory();

  const removeItem = async () => {
    await mutation(deleteQuery, { input: { id: id } });
    refetch();
  };
  const handleClick = () => {};

  return (
    <PositionedElement
      id={`${id}`}
      onClick={handleClick}
      top={200 * (index + 1)}
      left={110 + 350 * parseInt(objectKey)}
    >
      <Title>{title}</Title>
      <SpaceBetween>
        <EditButton
          handleClick={() => {
            routeTo(history, editRoute, [country, level, id]);
          }}
          color={"white"}
          small
        />
        <AddButton
          handleClick={() => {
            routeTo(history, createRoute, [country, level + 1, id]);
          }}
          color={"white"}
          small
        />
        <RemoveButton
          placement={"right"}
          handleClick={() => {
            removeItem();
          }}
          color={"white"}
          small
        />
      </SpaceBetween>
    </PositionedElement>
  );
};

const Icon = ({ icon }: { icon: IconDefinition }) => {
  return (
    <IconContainer>
      <FontAwesomeIcon icon={icon} />
    </IconContainer>
  );
};

const IconContainer = styled.div`
  padding: 4px;
  cursor: pointer;
  z-index: 99999999;
`;

const Item = styled.div`
  position: absolute;
  width: 120px;
  font-size: 10px;
  border-radius: 5%;
  z-index: 9999999;
  background-color: ${(props) => props.theme.primary};
  color: white;
  padding: 5px 10px;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg : {
    color: white;
  }
`;

const Title = styled.div``;

export const PositionedElement = styled(Item)<{ top: number; left: number }>`
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
`;
