import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  RouteProps,
  useHistory,
  useLocation,
} from "react-router-dom";
import { Routes } from ".";

interface Groups {
  groups: string[];
}

export const PrivateRoute = ({
  component: Component,
  groups,
  ...rest
}: RouteProps & Groups) => {
  const history = useHistory();
  const location = useLocation();
  const [signedIn, setSignedIn] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (!user) {
        }
        const signInUserSessionGroups =
          user.signInUserSession.accessToken.payload["cognito:groups"];

        if (
          groups.some((element) => signInUserSessionGroups.includes(element))
        ) {
          setSignedIn(true);
        } else {
          history.push(Routes.HOME);
          history.go(0);
        }
      } catch (e) {
        history.push(Routes.HOME);
        history.go(0);
        console.error(e);
      }
    })();
  }, [location.pathname]);

  return (
    <Route
      {...rest}
      render={(props) =>
        true ? (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Component {...props} />
        ) : (
          <Redirect to={Routes.HOME} />
        )
      }
    />
  );
};
