import { css } from "styled-components";

const sizes = {
  ultraLargeDesktop: 1840,
  largeDesktop: 1440,
  desktop: 1024,
  tablet: 640,
  mobile: 380,
};

export default Object.keys(sizes).reduce((acc: any, label: string) => {
  acc[label] = (args: any) => css`
    @media (min-width: ${(sizes as any)[label]}px) {
      ${css(args)};
    }
  `;
  return acc;
}, {});
