/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const removeAllUserData = /* GraphQL */ `
  mutation RemoveAllUserData(
    $profileID: String!
    $email: String!
    $owner: String!
  ) {
    removeAllUserData(profileID: $profileID, email: $email, owner: $owner)
  }
`;
export const removeAllCompanyData = /* GraphQL */ `
  mutation RemoveAllCompanyData($companyID: String!) {
    removeAllCompanyData(companyID: $companyID)
  }
`;
export const createEmailNewsletter = /* GraphQL */ `
  mutation CreateEmailNewsletter(
    $input: CreateEmailNewsletterInput!
    $condition: ModelEmailNewsletterConditionInput
  ) {
    createEmailNewsletter(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const updateEmailNewsletter = /* GraphQL */ `
  mutation UpdateEmailNewsletter(
    $input: UpdateEmailNewsletterInput!
    $condition: ModelEmailNewsletterConditionInput
  ) {
    updateEmailNewsletter(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmailNewsletter = /* GraphQL */ `
  mutation DeleteEmailNewsletter(
    $input: DeleteEmailNewsletterInput!
    $condition: ModelEmailNewsletterConditionInput
  ) {
    deleteEmailNewsletter(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const createArticleCategory = /* GraphQL */ `
  mutation CreateArticleCategory(
    $input: CreateArticleCategoryInput!
    $condition: ModelArticleCategoryConditionInput
  ) {
    createArticleCategory(input: $input, condition: $condition) {
      id
      entity
      name
      slug
      order
      createdAt
      updatedAt
      articles {
        items {
          id
          active
          content
          description
          entity
          image
          title
          slug
          createdBy
          hashtags
          type
          order
          articleCategoryID
          createdAt
          updatedAt
          category {
            id
            entity
            name
            slug
            order
            createdAt
            updatedAt
            articles {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateArticleCategory = /* GraphQL */ `
  mutation UpdateArticleCategory(
    $input: UpdateArticleCategoryInput!
    $condition: ModelArticleCategoryConditionInput
  ) {
    updateArticleCategory(input: $input, condition: $condition) {
      id
      entity
      name
      slug
      order
      createdAt
      updatedAt
      articles {
        items {
          id
          active
          content
          description
          entity
          image
          title
          slug
          createdBy
          hashtags
          type
          order
          articleCategoryID
          createdAt
          updatedAt
          category {
            id
            entity
            name
            slug
            order
            createdAt
            updatedAt
            articles {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteArticleCategory = /* GraphQL */ `
  mutation DeleteArticleCategory(
    $input: DeleteArticleCategoryInput!
    $condition: ModelArticleCategoryConditionInput
  ) {
    deleteArticleCategory(input: $input, condition: $condition) {
      id
      entity
      name
      slug
      order
      createdAt
      updatedAt
      articles {
        items {
          id
          active
          content
          description
          entity
          image
          title
          slug
          createdBy
          hashtags
          type
          order
          articleCategoryID
          createdAt
          updatedAt
          category {
            id
            entity
            name
            slug
            order
            createdAt
            updatedAt
            articles {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createArticle = /* GraphQL */ `
  mutation CreateArticle(
    $input: CreateArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    createArticle(input: $input, condition: $condition) {
      id
      active
      content
      description
      entity
      image
      title
      slug
      createdBy
      hashtags
      type
      order
      articleCategoryID
      createdAt
      updatedAt
      category {
        id
        entity
        name
        slug
        order
        createdAt
        updatedAt
        articles {
          items {
            id
            active
            content
            description
            entity
            image
            title
            slug
            createdBy
            hashtags
            type
            order
            articleCategoryID
            createdAt
            updatedAt
            category {
              id
              entity
              name
              slug
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateArticle = /* GraphQL */ `
  mutation UpdateArticle(
    $input: UpdateArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    updateArticle(input: $input, condition: $condition) {
      id
      active
      content
      description
      entity
      image
      title
      slug
      createdBy
      hashtags
      type
      order
      articleCategoryID
      createdAt
      updatedAt
      category {
        id
        entity
        name
        slug
        order
        createdAt
        updatedAt
        articles {
          items {
            id
            active
            content
            description
            entity
            image
            title
            slug
            createdBy
            hashtags
            type
            order
            articleCategoryID
            createdAt
            updatedAt
            category {
              id
              entity
              name
              slug
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteArticle = /* GraphQL */ `
  mutation DeleteArticle(
    $input: DeleteArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    deleteArticle(input: $input, condition: $condition) {
      id
      active
      content
      description
      entity
      image
      title
      slug
      createdBy
      hashtags
      type
      order
      articleCategoryID
      createdAt
      updatedAt
      category {
        id
        entity
        name
        slug
        order
        createdAt
        updatedAt
        articles {
          items {
            id
            active
            content
            description
            entity
            image
            title
            slug
            createdBy
            hashtags
            type
            order
            articleCategoryID
            createdAt
            updatedAt
            category {
              id
              entity
              name
              slug
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createAdvertisementType = /* GraphQL */ `
  mutation CreateAdvertisementType(
    $input: CreateAdvertisementTypeInput!
    $condition: ModelAdvertisementTypeConditionInput
  ) {
    createAdvertisementType(input: $input, condition: $condition) {
      id
      active
      entity
      name
      jobDescription
      order
      approvalRequired
      components
      createdAt
      updatedAt
      advertisements {
        items {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateAdvertisementType = /* GraphQL */ `
  mutation UpdateAdvertisementType(
    $input: UpdateAdvertisementTypeInput!
    $condition: ModelAdvertisementTypeConditionInput
  ) {
    updateAdvertisementType(input: $input, condition: $condition) {
      id
      active
      entity
      name
      jobDescription
      order
      approvalRequired
      components
      createdAt
      updatedAt
      advertisements {
        items {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteAdvertisementType = /* GraphQL */ `
  mutation DeleteAdvertisementType(
    $input: DeleteAdvertisementTypeInput!
    $condition: ModelAdvertisementTypeConditionInput
  ) {
    deleteAdvertisementType(input: $input, condition: $condition) {
      id
      active
      entity
      name
      jobDescription
      order
      approvalRequired
      components
      createdAt
      updatedAt
      advertisements {
        items {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createServiceSetting = /* GraphQL */ `
  mutation CreateServiceSetting(
    $input: CreateServiceSettingInput!
    $condition: ModelServiceSettingConditionInput
  ) {
    createServiceSetting(input: $input, condition: $condition) {
      id
      active
      creditsCount
      entity
      name
      public
      appliedTo
      serviceType
      createdAt
      updatedAt
    }
  }
`;
export const updateServiceSetting = /* GraphQL */ `
  mutation UpdateServiceSetting(
    $input: UpdateServiceSettingInput!
    $condition: ModelServiceSettingConditionInput
  ) {
    updateServiceSetting(input: $input, condition: $condition) {
      id
      active
      creditsCount
      entity
      name
      public
      appliedTo
      serviceType
      createdAt
      updatedAt
    }
  }
`;
export const deleteServiceSetting = /* GraphQL */ `
  mutation DeleteServiceSetting(
    $input: DeleteServiceSettingInput!
    $condition: ModelServiceSettingConditionInput
  ) {
    deleteServiceSetting(input: $input, condition: $condition) {
      id
      active
      creditsCount
      entity
      name
      public
      appliedTo
      serviceType
      createdAt
      updatedAt
    }
  }
`;
export const createAdvertisementSetting = /* GraphQL */ `
  mutation CreateAdvertisementSetting(
    $input: CreateAdvertisementSettingInput!
    $condition: ModelAdvertisementSettingConditionInput
  ) {
    createAdvertisementSetting(input: $input, condition: $condition) {
      id
      active
      creditsCount
      description
      entity
      internalName
      name
      numberOfDays
      reactivationCreditsCount
      order
      appliedTo
      public
      advertisementTypeID
      createdAt
      updatedAt
      advertisementType {
        id
        active
        entity
        name
        jobDescription
        order
        approvalRequired
        components
        createdAt
        updatedAt
        advertisements {
          items {
            id
            active
            creditsCount
            description
            entity
            internalName
            name
            numberOfDays
            reactivationCreditsCount
            order
            appliedTo
            public
            advertisementTypeID
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateAdvertisementSetting = /* GraphQL */ `
  mutation UpdateAdvertisementSetting(
    $input: UpdateAdvertisementSettingInput!
    $condition: ModelAdvertisementSettingConditionInput
  ) {
    updateAdvertisementSetting(input: $input, condition: $condition) {
      id
      active
      creditsCount
      description
      entity
      internalName
      name
      numberOfDays
      reactivationCreditsCount
      order
      appliedTo
      public
      advertisementTypeID
      createdAt
      updatedAt
      advertisementType {
        id
        active
        entity
        name
        jobDescription
        order
        approvalRequired
        components
        createdAt
        updatedAt
        advertisements {
          items {
            id
            active
            creditsCount
            description
            entity
            internalName
            name
            numberOfDays
            reactivationCreditsCount
            order
            appliedTo
            public
            advertisementTypeID
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteAdvertisementSetting = /* GraphQL */ `
  mutation DeleteAdvertisementSetting(
    $input: DeleteAdvertisementSettingInput!
    $condition: ModelAdvertisementSettingConditionInput
  ) {
    deleteAdvertisementSetting(input: $input, condition: $condition) {
      id
      active
      creditsCount
      description
      entity
      internalName
      name
      numberOfDays
      reactivationCreditsCount
      order
      appliedTo
      public
      advertisementTypeID
      createdAt
      updatedAt
      advertisementType {
        id
        active
        entity
        name
        jobDescription
        order
        approvalRequired
        components
        createdAt
        updatedAt
        advertisements {
          items {
            id
            active
            creditsCount
            description
            entity
            internalName
            name
            numberOfDays
            reactivationCreditsCount
            order
            appliedTo
            public
            advertisementTypeID
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createWorkerType = /* GraphQL */ `
  mutation CreateWorkerType(
    $input: CreateWorkerTypeInput!
    $condition: ModelWorkerTypeConditionInput
  ) {
    createWorkerType(input: $input, condition: $condition) {
      id
      name
      country
      level
      order
      createdAt
      updatedAt
      childEdges {
        items {
          id
          parentNodeId
          childNodeId
          country
          createdAt
          updatedAt
          parentNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
          childNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateWorkerType = /* GraphQL */ `
  mutation UpdateWorkerType(
    $input: UpdateWorkerTypeInput!
    $condition: ModelWorkerTypeConditionInput
  ) {
    updateWorkerType(input: $input, condition: $condition) {
      id
      name
      country
      level
      order
      createdAt
      updatedAt
      childEdges {
        items {
          id
          parentNodeId
          childNodeId
          country
          createdAt
          updatedAt
          parentNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
          childNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteWorkerType = /* GraphQL */ `
  mutation DeleteWorkerType(
    $input: DeleteWorkerTypeInput!
    $condition: ModelWorkerTypeConditionInput
  ) {
    deleteWorkerType(input: $input, condition: $condition) {
      id
      name
      country
      level
      order
      createdAt
      updatedAt
      childEdges {
        items {
          id
          parentNodeId
          childNodeId
          country
          createdAt
          updatedAt
          parentNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
          childNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createWorkerTypeEdge = /* GraphQL */ `
  mutation CreateWorkerTypeEdge(
    $input: CreateWorkerTypeEdgeInput!
    $condition: ModelWorkerTypeEdgeConditionInput
  ) {
    createWorkerTypeEdge(input: $input, condition: $condition) {
      id
      parentNodeId
      childNodeId
      country
      createdAt
      updatedAt
      parentNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      childNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateWorkerTypeEdge = /* GraphQL */ `
  mutation UpdateWorkerTypeEdge(
    $input: UpdateWorkerTypeEdgeInput!
    $condition: ModelWorkerTypeEdgeConditionInput
  ) {
    updateWorkerTypeEdge(input: $input, condition: $condition) {
      id
      parentNodeId
      childNodeId
      country
      createdAt
      updatedAt
      parentNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      childNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteWorkerTypeEdge = /* GraphQL */ `
  mutation DeleteWorkerTypeEdge(
    $input: DeleteWorkerTypeEdgeInput!
    $condition: ModelWorkerTypeEdgeConditionInput
  ) {
    deleteWorkerTypeEdge(input: $input, condition: $condition) {
      id
      parentNodeId
      childNodeId
      country
      createdAt
      updatedAt
      parentNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      childNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createSuitableFor = /* GraphQL */ `
  mutation CreateSuitableFor(
    $input: CreateSuitableForInput!
    $condition: ModelSuitableForConditionInput
  ) {
    createSuitableFor(input: $input, condition: $condition) {
      id
      name
      country
      level
      order
      createdAt
      updatedAt
      childEdges {
        items {
          id
          parentNodeId
          childNodeId
          country
          createdAt
          updatedAt
          parentNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
          childNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateSuitableFor = /* GraphQL */ `
  mutation UpdateSuitableFor(
    $input: UpdateSuitableForInput!
    $condition: ModelSuitableForConditionInput
  ) {
    updateSuitableFor(input: $input, condition: $condition) {
      id
      name
      country
      level
      order
      createdAt
      updatedAt
      childEdges {
        items {
          id
          parentNodeId
          childNodeId
          country
          createdAt
          updatedAt
          parentNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
          childNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteSuitableFor = /* GraphQL */ `
  mutation DeleteSuitableFor(
    $input: DeleteSuitableForInput!
    $condition: ModelSuitableForConditionInput
  ) {
    deleteSuitableFor(input: $input, condition: $condition) {
      id
      name
      country
      level
      order
      createdAt
      updatedAt
      childEdges {
        items {
          id
          parentNodeId
          childNodeId
          country
          createdAt
          updatedAt
          parentNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
          childNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createSuitableForEdge = /* GraphQL */ `
  mutation CreateSuitableForEdge(
    $input: CreateSuitableForEdgeInput!
    $condition: ModelSuitableForEdgeConditionInput
  ) {
    createSuitableForEdge(input: $input, condition: $condition) {
      id
      parentNodeId
      childNodeId
      country
      createdAt
      updatedAt
      parentNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      childNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateSuitableForEdge = /* GraphQL */ `
  mutation UpdateSuitableForEdge(
    $input: UpdateSuitableForEdgeInput!
    $condition: ModelSuitableForEdgeConditionInput
  ) {
    updateSuitableForEdge(input: $input, condition: $condition) {
      id
      parentNodeId
      childNodeId
      country
      createdAt
      updatedAt
      parentNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      childNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteSuitableForEdge = /* GraphQL */ `
  mutation DeleteSuitableForEdge(
    $input: DeleteSuitableForEdgeInput!
    $condition: ModelSuitableForEdgeConditionInput
  ) {
    deleteSuitableForEdge(input: $input, condition: $condition) {
      id
      parentNodeId
      childNodeId
      country
      createdAt
      updatedAt
      parentNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      childNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createEmployer = /* GraphQL */ `
  mutation CreateEmployer(
    $input: CreateEmployerInput!
    $condition: ModelEmployerConditionInput
  ) {
    createEmployer(input: $input, condition: $condition) {
      id
      name
      country
      level
      order
      createdAt
      updatedAt
      childEdges {
        items {
          id
          parentNodeId
          childNodeId
          country
          createdAt
          updatedAt
          parentNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
          childNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateEmployer = /* GraphQL */ `
  mutation UpdateEmployer(
    $input: UpdateEmployerInput!
    $condition: ModelEmployerConditionInput
  ) {
    updateEmployer(input: $input, condition: $condition) {
      id
      name
      country
      level
      order
      createdAt
      updatedAt
      childEdges {
        items {
          id
          parentNodeId
          childNodeId
          country
          createdAt
          updatedAt
          parentNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
          childNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteEmployer = /* GraphQL */ `
  mutation DeleteEmployer(
    $input: DeleteEmployerInput!
    $condition: ModelEmployerConditionInput
  ) {
    deleteEmployer(input: $input, condition: $condition) {
      id
      name
      country
      level
      order
      createdAt
      updatedAt
      childEdges {
        items {
          id
          parentNodeId
          childNodeId
          country
          createdAt
          updatedAt
          parentNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
          childNode {
            id
            name
            country
            level
            order
            createdAt
            updatedAt
            childEdges {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createEmployerEdge = /* GraphQL */ `
  mutation CreateEmployerEdge(
    $input: CreateEmployerEdgeInput!
    $condition: ModelEmployerEdgeConditionInput
  ) {
    createEmployerEdge(input: $input, condition: $condition) {
      id
      parentNodeId
      childNodeId
      country
      createdAt
      updatedAt
      parentNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      childNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateEmployerEdge = /* GraphQL */ `
  mutation UpdateEmployerEdge(
    $input: UpdateEmployerEdgeInput!
    $condition: ModelEmployerEdgeConditionInput
  ) {
    updateEmployerEdge(input: $input, condition: $condition) {
      id
      parentNodeId
      childNodeId
      country
      createdAt
      updatedAt
      parentNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      childNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteEmployerEdge = /* GraphQL */ `
  mutation DeleteEmployerEdge(
    $input: DeleteEmployerEdgeInput!
    $condition: ModelEmployerEdgeConditionInput
  ) {
    deleteEmployerEdge(input: $input, condition: $condition) {
      id
      parentNodeId
      childNodeId
      country
      createdAt
      updatedAt
      parentNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      childNode {
        id
        name
        country
        level
        order
        createdAt
        updatedAt
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
            country
            createdAt
            updatedAt
            parentNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
            childNode {
              id
              name
              country
              level
              order
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      credits
      sub
      email
      entity
      entityState
      profileImage
      firstDegree
      forename
      surname
      secondDegree
      aboutMe
      educations
      workExperiences
      publications
      conferences
      certificates
      languages
      otherInformation
      qualifications
      name
      crn
      vatRegNumber
      street
      buildingIdentificationNumber
      municipality
      postCode
      language
      phone
      createdAt
      updatedAt
      owner
      messages {
        items {
          id
          name
          email
          phoneNumber
          message
          file
          state
          createdAt
          updatedAt
          profileID
          advertisementID
          profile {
            id
            credits
            sub
            email
            entity
            entityState
            profileImage
            firstDegree
            forename
            surname
            secondDegree
            aboutMe
            educations
            workExperiences
            publications
            conferences
            certificates
            languages
            otherInformation
            qualifications
            name
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            phone
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
          }
          advertisement {
            id
            friendlyId
            entity
            active
            state
            stateDescription
            paymentState
            jobPosition
            jobShortDescription
            jobDescription
            place
            salaryFrom
            salaryTo
            salarySpecifiedInText
            reactivationCount
            currentReactivationCount
            automaticReactivation
            leadingPosition
            residentialPlace
            typeOfCooperation
            jobType
            financialConditions
            typeOfInternship
            contactFirstDegree
            contactForename
            contactSurname
            contactSecondDegree
            contactEmail
            contactPhoneNumber
            hideContactInformation
            workType
            suitableFor
            workTypeStringified
            suitableForStringified
            employmentTypesStringified
            employmentTypes
            paymentType
            advertisementSettingID
            advertisementTypeID
            companyID
            profileID
            expirationDate
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
            advertisementSetting {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
            analytics {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      credits
      sub
      email
      entity
      entityState
      profileImage
      firstDegree
      forename
      surname
      secondDegree
      aboutMe
      educations
      workExperiences
      publications
      conferences
      certificates
      languages
      otherInformation
      qualifications
      name
      crn
      vatRegNumber
      street
      buildingIdentificationNumber
      municipality
      postCode
      language
      phone
      createdAt
      updatedAt
      owner
      messages {
        items {
          id
          name
          email
          phoneNumber
          message
          file
          state
          createdAt
          updatedAt
          profileID
          advertisementID
          profile {
            id
            credits
            sub
            email
            entity
            entityState
            profileImage
            firstDegree
            forename
            surname
            secondDegree
            aboutMe
            educations
            workExperiences
            publications
            conferences
            certificates
            languages
            otherInformation
            qualifications
            name
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            phone
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
          }
          advertisement {
            id
            friendlyId
            entity
            active
            state
            stateDescription
            paymentState
            jobPosition
            jobShortDescription
            jobDescription
            place
            salaryFrom
            salaryTo
            salarySpecifiedInText
            reactivationCount
            currentReactivationCount
            automaticReactivation
            leadingPosition
            residentialPlace
            typeOfCooperation
            jobType
            financialConditions
            typeOfInternship
            contactFirstDegree
            contactForename
            contactSurname
            contactSecondDegree
            contactEmail
            contactPhoneNumber
            hideContactInformation
            workType
            suitableFor
            workTypeStringified
            suitableForStringified
            employmentTypesStringified
            employmentTypes
            paymentType
            advertisementSettingID
            advertisementTypeID
            companyID
            profileID
            expirationDate
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
            advertisementSetting {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
            analytics {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      credits
      sub
      email
      entity
      entityState
      profileImage
      firstDegree
      forename
      surname
      secondDegree
      aboutMe
      educations
      workExperiences
      publications
      conferences
      certificates
      languages
      otherInformation
      qualifications
      name
      crn
      vatRegNumber
      street
      buildingIdentificationNumber
      municipality
      postCode
      language
      phone
      createdAt
      updatedAt
      owner
      messages {
        items {
          id
          name
          email
          phoneNumber
          message
          file
          state
          createdAt
          updatedAt
          profileID
          advertisementID
          profile {
            id
            credits
            sub
            email
            entity
            entityState
            profileImage
            firstDegree
            forename
            surname
            secondDegree
            aboutMe
            educations
            workExperiences
            publications
            conferences
            certificates
            languages
            otherInformation
            qualifications
            name
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            phone
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
          }
          advertisement {
            id
            friendlyId
            entity
            active
            state
            stateDescription
            paymentState
            jobPosition
            jobShortDescription
            jobDescription
            place
            salaryFrom
            salaryTo
            salarySpecifiedInText
            reactivationCount
            currentReactivationCount
            automaticReactivation
            leadingPosition
            residentialPlace
            typeOfCooperation
            jobType
            financialConditions
            typeOfInternship
            contactFirstDegree
            contactForename
            contactSurname
            contactSecondDegree
            contactEmail
            contactPhoneNumber
            hideContactInformation
            workType
            suitableFor
            workTypeStringified
            suitableForStringified
            employmentTypesStringified
            employmentTypes
            paymentType
            advertisementSettingID
            advertisementTypeID
            companyID
            profileID
            expirationDate
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
            advertisementSetting {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
            analytics {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createMessageInbox = /* GraphQL */ `
  mutation CreateMessageInbox(
    $input: CreateMessageInboxInput!
    $condition: ModelMessageInboxConditionInput
  ) {
    createMessageInbox(input: $input, condition: $condition) {
      id
      name
      email
      phoneNumber
      message
      file
      state
      createdAt
      updatedAt
      profileID
      advertisementID
      profile {
        id
        credits
        sub
        email
        entity
        entityState
        profileImage
        firstDegree
        forename
        surname
        secondDegree
        aboutMe
        educations
        workExperiences
        publications
        conferences
        certificates
        languages
        otherInformation
        qualifications
        name
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        phone
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            phoneNumber
            message
            file
            state
            createdAt
            updatedAt
            profileID
            advertisementID
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      advertisement {
        id
        friendlyId
        entity
        active
        state
        stateDescription
        paymentState
        jobPosition
        jobShortDescription
        jobDescription
        place
        salaryFrom
        salaryTo
        salarySpecifiedInText
        reactivationCount
        currentReactivationCount
        automaticReactivation
        leadingPosition
        residentialPlace
        typeOfCooperation
        jobType
        financialConditions
        typeOfInternship
        contactFirstDegree
        contactForename
        contactSurname
        contactSecondDegree
        contactEmail
        contactPhoneNumber
        hideContactInformation
        workType
        suitableFor
        workTypeStringified
        suitableForStringified
        employmentTypesStringified
        employmentTypes
        paymentType
        advertisementSettingID
        advertisementTypeID
        companyID
        profileID
        expirationDate
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        advertisementSetting {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
        analytics {
          items {
            id
            name
            info
            advertisementID
            createdAt
            updatedAt
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateMessageInbox = /* GraphQL */ `
  mutation UpdateMessageInbox(
    $input: UpdateMessageInboxInput!
    $condition: ModelMessageInboxConditionInput
  ) {
    updateMessageInbox(input: $input, condition: $condition) {
      id
      name
      email
      phoneNumber
      message
      file
      state
      createdAt
      updatedAt
      profileID
      advertisementID
      profile {
        id
        credits
        sub
        email
        entity
        entityState
        profileImage
        firstDegree
        forename
        surname
        secondDegree
        aboutMe
        educations
        workExperiences
        publications
        conferences
        certificates
        languages
        otherInformation
        qualifications
        name
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        phone
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            phoneNumber
            message
            file
            state
            createdAt
            updatedAt
            profileID
            advertisementID
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      advertisement {
        id
        friendlyId
        entity
        active
        state
        stateDescription
        paymentState
        jobPosition
        jobShortDescription
        jobDescription
        place
        salaryFrom
        salaryTo
        salarySpecifiedInText
        reactivationCount
        currentReactivationCount
        automaticReactivation
        leadingPosition
        residentialPlace
        typeOfCooperation
        jobType
        financialConditions
        typeOfInternship
        contactFirstDegree
        contactForename
        contactSurname
        contactSecondDegree
        contactEmail
        contactPhoneNumber
        hideContactInformation
        workType
        suitableFor
        workTypeStringified
        suitableForStringified
        employmentTypesStringified
        employmentTypes
        paymentType
        advertisementSettingID
        advertisementTypeID
        companyID
        profileID
        expirationDate
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        advertisementSetting {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
        analytics {
          items {
            id
            name
            info
            advertisementID
            createdAt
            updatedAt
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteMessageInbox = /* GraphQL */ `
  mutation DeleteMessageInbox(
    $input: DeleteMessageInboxInput!
    $condition: ModelMessageInboxConditionInput
  ) {
    deleteMessageInbox(input: $input, condition: $condition) {
      id
      name
      email
      phoneNumber
      message
      file
      state
      createdAt
      updatedAt
      profileID
      advertisementID
      profile {
        id
        credits
        sub
        email
        entity
        entityState
        profileImage
        firstDegree
        forename
        surname
        secondDegree
        aboutMe
        educations
        workExperiences
        publications
        conferences
        certificates
        languages
        otherInformation
        qualifications
        name
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        phone
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            phoneNumber
            message
            file
            state
            createdAt
            updatedAt
            profileID
            advertisementID
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      advertisement {
        id
        friendlyId
        entity
        active
        state
        stateDescription
        paymentState
        jobPosition
        jobShortDescription
        jobDescription
        place
        salaryFrom
        salaryTo
        salarySpecifiedInText
        reactivationCount
        currentReactivationCount
        automaticReactivation
        leadingPosition
        residentialPlace
        typeOfCooperation
        jobType
        financialConditions
        typeOfInternship
        contactFirstDegree
        contactForename
        contactSurname
        contactSecondDegree
        contactEmail
        contactPhoneNumber
        hideContactInformation
        workType
        suitableFor
        workTypeStringified
        suitableForStringified
        employmentTypesStringified
        employmentTypes
        paymentType
        advertisementSettingID
        advertisementTypeID
        companyID
        profileID
        expirationDate
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        advertisementSetting {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
        analytics {
          items {
            id
            name
            info
            advertisementID
            createdAt
            updatedAt
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      entity
      entityState
      credits
      name
      logo
      forename
      surname
      address
      phone
      email
      crn
      vatRegNumber
      street
      buildingIdentificationNumber
      municipality
      postCode
      language
      employerTaxonomy
      profileImage
      qualification
      createdAt
      updatedAt
      owner
      messages {
        items {
          id
          name
          email
          state
          phoneNumber
          message
          file
          createdAt
          updatedAt
          companyID
          advertisementID
          company {
            id
            entity
            entityState
            credits
            name
            logo
            forename
            surname
            address
            phone
            email
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            employerTaxonomy
            profileImage
            qualification
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
            users {
              nextToken
            }
          }
          advertisement {
            id
            friendlyId
            entity
            active
            state
            stateDescription
            paymentState
            jobPosition
            jobShortDescription
            jobDescription
            place
            salaryFrom
            salaryTo
            salarySpecifiedInText
            reactivationCount
            currentReactivationCount
            automaticReactivation
            leadingPosition
            residentialPlace
            typeOfCooperation
            jobType
            financialConditions
            typeOfInternship
            contactFirstDegree
            contactForename
            contactSurname
            contactSecondDegree
            contactEmail
            contactPhoneNumber
            hideContactInformation
            workType
            suitableFor
            workTypeStringified
            suitableForStringified
            employmentTypesStringified
            employmentTypes
            paymentType
            advertisementSettingID
            advertisementTypeID
            companyID
            profileID
            expirationDate
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
            advertisementSetting {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
            analytics {
              nextToken
            }
          }
        }
        nextToken
      }
      users {
        items {
          id
          entity
          name
          email
          role
          createdAt
          updatedAt
          companyID
          company {
            id
            entity
            entityState
            credits
            name
            logo
            forename
            surname
            address
            phone
            email
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            employerTaxonomy
            profileImage
            qualification
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
            users {
              nextToken
            }
          }
          owner
        }
        nextToken
      }
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      entity
      entityState
      credits
      name
      logo
      forename
      surname
      address
      phone
      email
      crn
      vatRegNumber
      street
      buildingIdentificationNumber
      municipality
      postCode
      language
      employerTaxonomy
      profileImage
      qualification
      createdAt
      updatedAt
      owner
      messages {
        items {
          id
          name
          email
          state
          phoneNumber
          message
          file
          createdAt
          updatedAt
          companyID
          advertisementID
          company {
            id
            entity
            entityState
            credits
            name
            logo
            forename
            surname
            address
            phone
            email
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            employerTaxonomy
            profileImage
            qualification
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
            users {
              nextToken
            }
          }
          advertisement {
            id
            friendlyId
            entity
            active
            state
            stateDescription
            paymentState
            jobPosition
            jobShortDescription
            jobDescription
            place
            salaryFrom
            salaryTo
            salarySpecifiedInText
            reactivationCount
            currentReactivationCount
            automaticReactivation
            leadingPosition
            residentialPlace
            typeOfCooperation
            jobType
            financialConditions
            typeOfInternship
            contactFirstDegree
            contactForename
            contactSurname
            contactSecondDegree
            contactEmail
            contactPhoneNumber
            hideContactInformation
            workType
            suitableFor
            workTypeStringified
            suitableForStringified
            employmentTypesStringified
            employmentTypes
            paymentType
            advertisementSettingID
            advertisementTypeID
            companyID
            profileID
            expirationDate
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
            advertisementSetting {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
            analytics {
              nextToken
            }
          }
        }
        nextToken
      }
      users {
        items {
          id
          entity
          name
          email
          role
          createdAt
          updatedAt
          companyID
          company {
            id
            entity
            entityState
            credits
            name
            logo
            forename
            surname
            address
            phone
            email
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            employerTaxonomy
            profileImage
            qualification
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
            users {
              nextToken
            }
          }
          owner
        }
        nextToken
      }
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      entity
      entityState
      credits
      name
      logo
      forename
      surname
      address
      phone
      email
      crn
      vatRegNumber
      street
      buildingIdentificationNumber
      municipality
      postCode
      language
      employerTaxonomy
      profileImage
      qualification
      createdAt
      updatedAt
      owner
      messages {
        items {
          id
          name
          email
          state
          phoneNumber
          message
          file
          createdAt
          updatedAt
          companyID
          advertisementID
          company {
            id
            entity
            entityState
            credits
            name
            logo
            forename
            surname
            address
            phone
            email
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            employerTaxonomy
            profileImage
            qualification
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
            users {
              nextToken
            }
          }
          advertisement {
            id
            friendlyId
            entity
            active
            state
            stateDescription
            paymentState
            jobPosition
            jobShortDescription
            jobDescription
            place
            salaryFrom
            salaryTo
            salarySpecifiedInText
            reactivationCount
            currentReactivationCount
            automaticReactivation
            leadingPosition
            residentialPlace
            typeOfCooperation
            jobType
            financialConditions
            typeOfInternship
            contactFirstDegree
            contactForename
            contactSurname
            contactSecondDegree
            contactEmail
            contactPhoneNumber
            hideContactInformation
            workType
            suitableFor
            workTypeStringified
            suitableForStringified
            employmentTypesStringified
            employmentTypes
            paymentType
            advertisementSettingID
            advertisementTypeID
            companyID
            profileID
            expirationDate
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
            advertisementSetting {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
            analytics {
              nextToken
            }
          }
        }
        nextToken
      }
      users {
        items {
          id
          entity
          name
          email
          role
          createdAt
          updatedAt
          companyID
          company {
            id
            entity
            entityState
            credits
            name
            logo
            forename
            surname
            address
            phone
            email
            crn
            vatRegNumber
            street
            buildingIdentificationNumber
            municipality
            postCode
            language
            employerTaxonomy
            profileImage
            qualification
            createdAt
            updatedAt
            owner
            messages {
              nextToken
            }
            users {
              nextToken
            }
          }
          owner
        }
        nextToken
      }
    }
  }
`;
export const createCompanyMessageInbox = /* GraphQL */ `
  mutation CreateCompanyMessageInbox(
    $input: CreateCompanyMessageInboxInput!
    $condition: ModelCompanyMessageInboxConditionInput
  ) {
    createCompanyMessageInbox(input: $input, condition: $condition) {
      id
      name
      email
      state
      phoneNumber
      message
      file
      createdAt
      updatedAt
      companyID
      advertisementID
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      advertisement {
        id
        friendlyId
        entity
        active
        state
        stateDescription
        paymentState
        jobPosition
        jobShortDescription
        jobDescription
        place
        salaryFrom
        salaryTo
        salarySpecifiedInText
        reactivationCount
        currentReactivationCount
        automaticReactivation
        leadingPosition
        residentialPlace
        typeOfCooperation
        jobType
        financialConditions
        typeOfInternship
        contactFirstDegree
        contactForename
        contactSurname
        contactSecondDegree
        contactEmail
        contactPhoneNumber
        hideContactInformation
        workType
        suitableFor
        workTypeStringified
        suitableForStringified
        employmentTypesStringified
        employmentTypes
        paymentType
        advertisementSettingID
        advertisementTypeID
        companyID
        profileID
        expirationDate
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        advertisementSetting {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
        analytics {
          items {
            id
            name
            info
            advertisementID
            createdAt
            updatedAt
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateCompanyMessageInbox = /* GraphQL */ `
  mutation UpdateCompanyMessageInbox(
    $input: UpdateCompanyMessageInboxInput!
    $condition: ModelCompanyMessageInboxConditionInput
  ) {
    updateCompanyMessageInbox(input: $input, condition: $condition) {
      id
      name
      email
      state
      phoneNumber
      message
      file
      createdAt
      updatedAt
      companyID
      advertisementID
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      advertisement {
        id
        friendlyId
        entity
        active
        state
        stateDescription
        paymentState
        jobPosition
        jobShortDescription
        jobDescription
        place
        salaryFrom
        salaryTo
        salarySpecifiedInText
        reactivationCount
        currentReactivationCount
        automaticReactivation
        leadingPosition
        residentialPlace
        typeOfCooperation
        jobType
        financialConditions
        typeOfInternship
        contactFirstDegree
        contactForename
        contactSurname
        contactSecondDegree
        contactEmail
        contactPhoneNumber
        hideContactInformation
        workType
        suitableFor
        workTypeStringified
        suitableForStringified
        employmentTypesStringified
        employmentTypes
        paymentType
        advertisementSettingID
        advertisementTypeID
        companyID
        profileID
        expirationDate
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        advertisementSetting {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
        analytics {
          items {
            id
            name
            info
            advertisementID
            createdAt
            updatedAt
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteCompanyMessageInbox = /* GraphQL */ `
  mutation DeleteCompanyMessageInbox(
    $input: DeleteCompanyMessageInboxInput!
    $condition: ModelCompanyMessageInboxConditionInput
  ) {
    deleteCompanyMessageInbox(input: $input, condition: $condition) {
      id
      name
      email
      state
      phoneNumber
      message
      file
      createdAt
      updatedAt
      companyID
      advertisementID
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      advertisement {
        id
        friendlyId
        entity
        active
        state
        stateDescription
        paymentState
        jobPosition
        jobShortDescription
        jobDescription
        place
        salaryFrom
        salaryTo
        salarySpecifiedInText
        reactivationCount
        currentReactivationCount
        automaticReactivation
        leadingPosition
        residentialPlace
        typeOfCooperation
        jobType
        financialConditions
        typeOfInternship
        contactFirstDegree
        contactForename
        contactSurname
        contactSecondDegree
        contactEmail
        contactPhoneNumber
        hideContactInformation
        workType
        suitableFor
        workTypeStringified
        suitableForStringified
        employmentTypesStringified
        employmentTypes
        paymentType
        advertisementSettingID
        advertisementTypeID
        companyID
        profileID
        expirationDate
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        advertisementSetting {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
        analytics {
          items {
            id
            name
            info
            advertisementID
            createdAt
            updatedAt
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createCompanyUser = /* GraphQL */ `
  mutation CreateCompanyUser(
    $input: CreateCompanyUserInput!
    $condition: ModelCompanyUserConditionInput
  ) {
    createCompanyUser(input: $input, condition: $condition) {
      id
      entity
      name
      email
      role
      createdAt
      updatedAt
      companyID
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const updateCompanyUser = /* GraphQL */ `
  mutation UpdateCompanyUser(
    $input: UpdateCompanyUserInput!
    $condition: ModelCompanyUserConditionInput
  ) {
    updateCompanyUser(input: $input, condition: $condition) {
      id
      entity
      name
      email
      role
      createdAt
      updatedAt
      companyID
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const deleteCompanyUser = /* GraphQL */ `
  mutation DeleteCompanyUser(
    $input: DeleteCompanyUserInput!
    $condition: ModelCompanyUserConditionInput
  ) {
    deleteCompanyUser(input: $input, condition: $condition) {
      id
      entity
      name
      email
      role
      createdAt
      updatedAt
      companyID
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const createAdvertisement = /* GraphQL */ `
  mutation CreateAdvertisement(
    $input: CreateAdvertisementInput!
    $condition: ModelAdvertisementConditionInput
  ) {
    createAdvertisement(input: $input, condition: $condition) {
      id
      friendlyId
      entity
      active
      state
      stateDescription
      paymentState
      jobPosition
      jobShortDescription
      jobDescription
      place
      salaryFrom
      salaryTo
      salarySpecifiedInText
      reactivationCount
      currentReactivationCount
      automaticReactivation
      leadingPosition
      residentialPlace
      typeOfCooperation
      jobType
      financialConditions
      typeOfInternship
      contactFirstDegree
      contactForename
      contactSurname
      contactSecondDegree
      contactEmail
      contactPhoneNumber
      hideContactInformation
      workType
      suitableFor
      workTypeStringified
      suitableForStringified
      employmentTypesStringified
      employmentTypes
      paymentType
      advertisementSettingID
      advertisementTypeID
      companyID
      profileID
      expirationDate
      createdAt
      updatedAt
      advertisementType {
        id
        active
        entity
        name
        jobDescription
        order
        approvalRequired
        components
        createdAt
        updatedAt
        advertisements {
          items {
            id
            active
            creditsCount
            description
            entity
            internalName
            name
            numberOfDays
            reactivationCreditsCount
            order
            appliedTo
            public
            advertisementTypeID
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      advertisementSetting {
        id
        active
        creditsCount
        description
        entity
        internalName
        name
        numberOfDays
        reactivationCreditsCount
        order
        appliedTo
        public
        advertisementTypeID
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      profile {
        id
        credits
        sub
        email
        entity
        entityState
        profileImage
        firstDegree
        forename
        surname
        secondDegree
        aboutMe
        educations
        workExperiences
        publications
        conferences
        certificates
        languages
        otherInformation
        qualifications
        name
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        phone
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            phoneNumber
            message
            file
            state
            createdAt
            updatedAt
            profileID
            advertisementID
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      owner
      analytics {
        items {
          id
          name
          info
          advertisementID
          createdAt
          updatedAt
          advertisement {
            id
            friendlyId
            entity
            active
            state
            stateDescription
            paymentState
            jobPosition
            jobShortDescription
            jobDescription
            place
            salaryFrom
            salaryTo
            salarySpecifiedInText
            reactivationCount
            currentReactivationCount
            automaticReactivation
            leadingPosition
            residentialPlace
            typeOfCooperation
            jobType
            financialConditions
            typeOfInternship
            contactFirstDegree
            contactForename
            contactSurname
            contactSecondDegree
            contactEmail
            contactPhoneNumber
            hideContactInformation
            workType
            suitableFor
            workTypeStringified
            suitableForStringified
            employmentTypesStringified
            employmentTypes
            paymentType
            advertisementSettingID
            advertisementTypeID
            companyID
            profileID
            expirationDate
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
            advertisementSetting {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
            analytics {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateAdvertisement = /* GraphQL */ `
  mutation UpdateAdvertisement(
    $input: UpdateAdvertisementInput!
    $condition: ModelAdvertisementConditionInput
  ) {
    updateAdvertisement(input: $input, condition: $condition) {
      id
      friendlyId
      entity
      active
      state
      stateDescription
      paymentState
      jobPosition
      jobShortDescription
      jobDescription
      place
      salaryFrom
      salaryTo
      salarySpecifiedInText
      reactivationCount
      currentReactivationCount
      automaticReactivation
      leadingPosition
      residentialPlace
      typeOfCooperation
      jobType
      financialConditions
      typeOfInternship
      contactFirstDegree
      contactForename
      contactSurname
      contactSecondDegree
      contactEmail
      contactPhoneNumber
      hideContactInformation
      workType
      suitableFor
      workTypeStringified
      suitableForStringified
      employmentTypesStringified
      employmentTypes
      paymentType
      advertisementSettingID
      advertisementTypeID
      companyID
      profileID
      expirationDate
      createdAt
      updatedAt
      advertisementType {
        id
        active
        entity
        name
        jobDescription
        order
        approvalRequired
        components
        createdAt
        updatedAt
        advertisements {
          items {
            id
            active
            creditsCount
            description
            entity
            internalName
            name
            numberOfDays
            reactivationCreditsCount
            order
            appliedTo
            public
            advertisementTypeID
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      advertisementSetting {
        id
        active
        creditsCount
        description
        entity
        internalName
        name
        numberOfDays
        reactivationCreditsCount
        order
        appliedTo
        public
        advertisementTypeID
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      profile {
        id
        credits
        sub
        email
        entity
        entityState
        profileImage
        firstDegree
        forename
        surname
        secondDegree
        aboutMe
        educations
        workExperiences
        publications
        conferences
        certificates
        languages
        otherInformation
        qualifications
        name
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        phone
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            phoneNumber
            message
            file
            state
            createdAt
            updatedAt
            profileID
            advertisementID
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      owner
      analytics {
        items {
          id
          name
          info
          advertisementID
          createdAt
          updatedAt
          advertisement {
            id
            friendlyId
            entity
            active
            state
            stateDescription
            paymentState
            jobPosition
            jobShortDescription
            jobDescription
            place
            salaryFrom
            salaryTo
            salarySpecifiedInText
            reactivationCount
            currentReactivationCount
            automaticReactivation
            leadingPosition
            residentialPlace
            typeOfCooperation
            jobType
            financialConditions
            typeOfInternship
            contactFirstDegree
            contactForename
            contactSurname
            contactSecondDegree
            contactEmail
            contactPhoneNumber
            hideContactInformation
            workType
            suitableFor
            workTypeStringified
            suitableForStringified
            employmentTypesStringified
            employmentTypes
            paymentType
            advertisementSettingID
            advertisementTypeID
            companyID
            profileID
            expirationDate
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
            advertisementSetting {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
            analytics {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteAdvertisement = /* GraphQL */ `
  mutation DeleteAdvertisement(
    $input: DeleteAdvertisementInput!
    $condition: ModelAdvertisementConditionInput
  ) {
    deleteAdvertisement(input: $input, condition: $condition) {
      id
      friendlyId
      entity
      active
      state
      stateDescription
      paymentState
      jobPosition
      jobShortDescription
      jobDescription
      place
      salaryFrom
      salaryTo
      salarySpecifiedInText
      reactivationCount
      currentReactivationCount
      automaticReactivation
      leadingPosition
      residentialPlace
      typeOfCooperation
      jobType
      financialConditions
      typeOfInternship
      contactFirstDegree
      contactForename
      contactSurname
      contactSecondDegree
      contactEmail
      contactPhoneNumber
      hideContactInformation
      workType
      suitableFor
      workTypeStringified
      suitableForStringified
      employmentTypesStringified
      employmentTypes
      paymentType
      advertisementSettingID
      advertisementTypeID
      companyID
      profileID
      expirationDate
      createdAt
      updatedAt
      advertisementType {
        id
        active
        entity
        name
        jobDescription
        order
        approvalRequired
        components
        createdAt
        updatedAt
        advertisements {
          items {
            id
            active
            creditsCount
            description
            entity
            internalName
            name
            numberOfDays
            reactivationCreditsCount
            order
            appliedTo
            public
            advertisementTypeID
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      advertisementSetting {
        id
        active
        creditsCount
        description
        entity
        internalName
        name
        numberOfDays
        reactivationCreditsCount
        order
        appliedTo
        public
        advertisementTypeID
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      profile {
        id
        credits
        sub
        email
        entity
        entityState
        profileImage
        firstDegree
        forename
        surname
        secondDegree
        aboutMe
        educations
        workExperiences
        publications
        conferences
        certificates
        languages
        otherInformation
        qualifications
        name
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        phone
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            phoneNumber
            message
            file
            state
            createdAt
            updatedAt
            profileID
            advertisementID
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      owner
      analytics {
        items {
          id
          name
          info
          advertisementID
          createdAt
          updatedAt
          advertisement {
            id
            friendlyId
            entity
            active
            state
            stateDescription
            paymentState
            jobPosition
            jobShortDescription
            jobDescription
            place
            salaryFrom
            salaryTo
            salarySpecifiedInText
            reactivationCount
            currentReactivationCount
            automaticReactivation
            leadingPosition
            residentialPlace
            typeOfCooperation
            jobType
            financialConditions
            typeOfInternship
            contactFirstDegree
            contactForename
            contactSurname
            contactSecondDegree
            contactEmail
            contactPhoneNumber
            hideContactInformation
            workType
            suitableFor
            workTypeStringified
            suitableForStringified
            employmentTypesStringified
            employmentTypes
            paymentType
            advertisementSettingID
            advertisementTypeID
            companyID
            profileID
            expirationDate
            createdAt
            updatedAt
            advertisementType {
              id
              active
              entity
              name
              jobDescription
              order
              approvalRequired
              components
              createdAt
              updatedAt
            }
            advertisementSetting {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
            analytics {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createAdvertisementAnalytic = /* GraphQL */ `
  mutation CreateAdvertisementAnalytic(
    $input: CreateAdvertisementAnalyticInput!
    $condition: ModelAdvertisementAnalyticConditionInput
  ) {
    createAdvertisementAnalytic(input: $input, condition: $condition) {
      id
      name
      info
      advertisementID
      createdAt
      updatedAt
      advertisement {
        id
        friendlyId
        entity
        active
        state
        stateDescription
        paymentState
        jobPosition
        jobShortDescription
        jobDescription
        place
        salaryFrom
        salaryTo
        salarySpecifiedInText
        reactivationCount
        currentReactivationCount
        automaticReactivation
        leadingPosition
        residentialPlace
        typeOfCooperation
        jobType
        financialConditions
        typeOfInternship
        contactFirstDegree
        contactForename
        contactSurname
        contactSecondDegree
        contactEmail
        contactPhoneNumber
        hideContactInformation
        workType
        suitableFor
        workTypeStringified
        suitableForStringified
        employmentTypesStringified
        employmentTypes
        paymentType
        advertisementSettingID
        advertisementTypeID
        companyID
        profileID
        expirationDate
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        advertisementSetting {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
        analytics {
          items {
            id
            name
            info
            advertisementID
            createdAt
            updatedAt
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateAdvertisementAnalytic = /* GraphQL */ `
  mutation UpdateAdvertisementAnalytic(
    $input: UpdateAdvertisementAnalyticInput!
    $condition: ModelAdvertisementAnalyticConditionInput
  ) {
    updateAdvertisementAnalytic(input: $input, condition: $condition) {
      id
      name
      info
      advertisementID
      createdAt
      updatedAt
      advertisement {
        id
        friendlyId
        entity
        active
        state
        stateDescription
        paymentState
        jobPosition
        jobShortDescription
        jobDescription
        place
        salaryFrom
        salaryTo
        salarySpecifiedInText
        reactivationCount
        currentReactivationCount
        automaticReactivation
        leadingPosition
        residentialPlace
        typeOfCooperation
        jobType
        financialConditions
        typeOfInternship
        contactFirstDegree
        contactForename
        contactSurname
        contactSecondDegree
        contactEmail
        contactPhoneNumber
        hideContactInformation
        workType
        suitableFor
        workTypeStringified
        suitableForStringified
        employmentTypesStringified
        employmentTypes
        paymentType
        advertisementSettingID
        advertisementTypeID
        companyID
        profileID
        expirationDate
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        advertisementSetting {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
        analytics {
          items {
            id
            name
            info
            advertisementID
            createdAt
            updatedAt
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteAdvertisementAnalytic = /* GraphQL */ `
  mutation DeleteAdvertisementAnalytic(
    $input: DeleteAdvertisementAnalyticInput!
    $condition: ModelAdvertisementAnalyticConditionInput
  ) {
    deleteAdvertisementAnalytic(input: $input, condition: $condition) {
      id
      name
      info
      advertisementID
      createdAt
      updatedAt
      advertisement {
        id
        friendlyId
        entity
        active
        state
        stateDescription
        paymentState
        jobPosition
        jobShortDescription
        jobDescription
        place
        salaryFrom
        salaryTo
        salarySpecifiedInText
        reactivationCount
        currentReactivationCount
        automaticReactivation
        leadingPosition
        residentialPlace
        typeOfCooperation
        jobType
        financialConditions
        typeOfInternship
        contactFirstDegree
        contactForename
        contactSurname
        contactSecondDegree
        contactEmail
        contactPhoneNumber
        hideContactInformation
        workType
        suitableFor
        workTypeStringified
        suitableForStringified
        employmentTypesStringified
        employmentTypes
        paymentType
        advertisementSettingID
        advertisementTypeID
        companyID
        profileID
        expirationDate
        createdAt
        updatedAt
        advertisementType {
          id
          active
          entity
          name
          jobDescription
          order
          approvalRequired
          components
          createdAt
          updatedAt
          advertisements {
            items {
              id
              active
              creditsCount
              description
              entity
              internalName
              name
              numberOfDays
              reactivationCreditsCount
              order
              appliedTo
              public
              advertisementTypeID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        advertisementSetting {
          id
          active
          creditsCount
          description
          entity
          internalName
          name
          numberOfDays
          reactivationCreditsCount
          order
          appliedTo
          public
          advertisementTypeID
          createdAt
          updatedAt
          advertisementType {
            id
            active
            entity
            name
            jobDescription
            order
            approvalRequired
            components
            createdAt
            updatedAt
            advertisements {
              nextToken
            }
          }
        }
        profile {
          id
          credits
          sub
          email
          entity
          entityState
          profileImage
          firstDegree
          forename
          surname
          secondDegree
          aboutMe
          educations
          workExperiences
          publications
          conferences
          certificates
          languages
          otherInformation
          qualifications
          name
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          phone
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              phoneNumber
              message
              file
              state
              createdAt
              updatedAt
              profileID
              advertisementID
            }
            nextToken
          }
        }
        company {
          id
          entity
          entityState
          credits
          name
          logo
          forename
          surname
          address
          phone
          email
          crn
          vatRegNumber
          street
          buildingIdentificationNumber
          municipality
          postCode
          language
          employerTaxonomy
          profileImage
          qualification
          createdAt
          updatedAt
          owner
          messages {
            items {
              id
              name
              email
              state
              phoneNumber
              message
              file
              createdAt
              updatedAt
              companyID
              advertisementID
            }
            nextToken
          }
          users {
            items {
              id
              entity
              name
              email
              role
              createdAt
              updatedAt
              companyID
              owner
            }
            nextToken
          }
        }
        owner
        analytics {
          items {
            id
            name
            info
            advertisementID
            createdAt
            updatedAt
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createShowCase = /* GraphQL */ `
  mutation CreateShowCase(
    $input: CreateShowCaseInput!
    $condition: ModelShowCaseConditionInput
  ) {
    createShowCase(input: $input, condition: $condition) {
      id
      title
      text
      file
      createdAt
      updatedAt
      companyID
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
    }
  }
`;
export const updateShowCase = /* GraphQL */ `
  mutation UpdateShowCase(
    $input: UpdateShowCaseInput!
    $condition: ModelShowCaseConditionInput
  ) {
    updateShowCase(input: $input, condition: $condition) {
      id
      title
      text
      file
      createdAt
      updatedAt
      companyID
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteShowCase = /* GraphQL */ `
  mutation DeleteShowCase(
    $input: DeleteShowCaseInput!
    $condition: ModelShowCaseConditionInput
  ) {
    deleteShowCase(input: $input, condition: $condition) {
      id
      title
      text
      file
      createdAt
      updatedAt
      companyID
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      amount
      currency
      description
      paymentIntent
      companyID
      profileID
      initiatedBy
      createdAt
      updatedAt
      profile {
        id
        credits
        sub
        email
        entity
        entityState
        profileImage
        firstDegree
        forename
        surname
        secondDegree
        aboutMe
        educations
        workExperiences
        publications
        conferences
        certificates
        languages
        otherInformation
        qualifications
        name
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        phone
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            phoneNumber
            message
            file
            state
            createdAt
            updatedAt
            profileID
            advertisementID
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      initiatedByProfile {
        id
        credits
        sub
        email
        entity
        entityState
        profileImage
        firstDegree
        forename
        surname
        secondDegree
        aboutMe
        educations
        workExperiences
        publications
        conferences
        certificates
        languages
        otherInformation
        qualifications
        name
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        phone
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            phoneNumber
            message
            file
            state
            createdAt
            updatedAt
            profileID
            advertisementID
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      amount
      currency
      description
      paymentIntent
      companyID
      profileID
      initiatedBy
      createdAt
      updatedAt
      profile {
        id
        credits
        sub
        email
        entity
        entityState
        profileImage
        firstDegree
        forename
        surname
        secondDegree
        aboutMe
        educations
        workExperiences
        publications
        conferences
        certificates
        languages
        otherInformation
        qualifications
        name
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        phone
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            phoneNumber
            message
            file
            state
            createdAt
            updatedAt
            profileID
            advertisementID
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      initiatedByProfile {
        id
        credits
        sub
        email
        entity
        entityState
        profileImage
        firstDegree
        forename
        surname
        secondDegree
        aboutMe
        educations
        workExperiences
        publications
        conferences
        certificates
        languages
        otherInformation
        qualifications
        name
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        phone
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            phoneNumber
            message
            file
            state
            createdAt
            updatedAt
            profileID
            advertisementID
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      amount
      currency
      description
      paymentIntent
      companyID
      profileID
      initiatedBy
      createdAt
      updatedAt
      profile {
        id
        credits
        sub
        email
        entity
        entityState
        profileImage
        firstDegree
        forename
        surname
        secondDegree
        aboutMe
        educations
        workExperiences
        publications
        conferences
        certificates
        languages
        otherInformation
        qualifications
        name
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        phone
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            phoneNumber
            message
            file
            state
            createdAt
            updatedAt
            profileID
            advertisementID
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      initiatedByProfile {
        id
        credits
        sub
        email
        entity
        entityState
        profileImage
        firstDegree
        forename
        surname
        secondDegree
        aboutMe
        educations
        workExperiences
        publications
        conferences
        certificates
        languages
        otherInformation
        qualifications
        name
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        phone
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            phoneNumber
            message
            file
            state
            createdAt
            updatedAt
            profileID
            advertisementID
            profile {
              id
              credits
              sub
              email
              entity
              entityState
              profileImage
              firstDegree
              forename
              surname
              secondDegree
              aboutMe
              educations
              workExperiences
              publications
              conferences
              certificates
              languages
              otherInformation
              qualifications
              name
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              phone
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
    }
  }
`;
export const createCarousel = /* GraphQL */ `
  mutation CreateCarousel(
    $input: CreateCarouselInput!
    $condition: ModelCarouselConditionInput
  ) {
    createCarousel(input: $input, condition: $condition) {
      id
      active
      entity
      image
      title
      type
      companyID
      createdAt
      updatedAt
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
    }
  }
`;
export const updateCarousel = /* GraphQL */ `
  mutation UpdateCarousel(
    $input: UpdateCarouselInput!
    $condition: ModelCarouselConditionInput
  ) {
    updateCarousel(input: $input, condition: $condition) {
      id
      active
      entity
      image
      title
      type
      companyID
      createdAt
      updatedAt
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteCarousel = /* GraphQL */ `
  mutation DeleteCarousel(
    $input: DeleteCarouselInput!
    $condition: ModelCarouselConditionInput
  ) {
    deleteCarousel(input: $input, condition: $condition) {
      id
      active
      entity
      image
      title
      type
      companyID
      createdAt
      updatedAt
      company {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
        messages {
          items {
            id
            name
            email
            state
            phoneNumber
            message
            file
            createdAt
            updatedAt
            companyID
            advertisementID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            advertisement {
              id
              friendlyId
              entity
              active
              state
              stateDescription
              paymentState
              jobPosition
              jobShortDescription
              jobDescription
              place
              salaryFrom
              salaryTo
              salarySpecifiedInText
              reactivationCount
              currentReactivationCount
              automaticReactivation
              leadingPosition
              residentialPlace
              typeOfCooperation
              jobType
              financialConditions
              typeOfInternship
              contactFirstDegree
              contactForename
              contactSurname
              contactSecondDegree
              contactEmail
              contactPhoneNumber
              hideContactInformation
              workType
              suitableFor
              workTypeStringified
              suitableForStringified
              employmentTypesStringified
              employmentTypes
              paymentType
              advertisementSettingID
              advertisementTypeID
              companyID
              profileID
              expirationDate
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
        users {
          items {
            id
            entity
            name
            email
            role
            createdAt
            updatedAt
            companyID
            company {
              id
              entity
              entityState
              credits
              name
              logo
              forename
              surname
              address
              phone
              email
              crn
              vatRegNumber
              street
              buildingIdentificationNumber
              municipality
              postCode
              language
              employerTaxonomy
              profileImage
              qualification
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
    }
  }
`;
