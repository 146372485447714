import styled from "styled-components";
import React, { ReactNode } from "react";
import { Loading } from "./Loading";
import { Center } from "src/constants/globalStyles";

export type Row<T> = {
  title: any;
  titleHide?: boolean;
  dataIndex?: string;
  key?: string;
  render: (text: any, record: T) => ReactNode;
  width?: string;
};

const TableRow = <T extends unknown>({
  columns,
  key,
  data,
}: {
  columns: Row<T>[];
  key: number;
  data: any;
}) => {
  return (
    <tr key={key}>
      {columns?.map((e: Row<T>, keyColumn: number) => {
        const value = e.dataIndex ? data[e.dataIndex] : "";
        return <td key={keyColumn}>{e.render(value, data)}</td>;
      })}
    </tr>
  );
};

export const MedTable = <T extends unknown>({
  columns,
  dataSource,
  emptyTableText,
  loading,
}: {
  columns: Row<T>[];
  dataSource: any;
  emptyTableText?: string;
  loading?: boolean;
}) => {
  const linearBackground =
    "transparent linear-gradient(180deg, #ededed 0%, #fff 100%)";
  return (
    <Container>
      <MediJobTable background={linearBackground}>
        <thead>
          <tr>
            {columns?.map((e: Row<T>, key: number) => {
              return (
                <Th key={key} width={e?.width}>
                  <Header transparent={!!e?.titleHide}>
                    {e?.titleHide ? "XXX" : e?.title}
                  </Header>
                </Th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {!loading &&
            dataSource?.map((data: any, key: number) => {
              return <TableRow data={data} key={key} columns={columns} />;
            })}
        </tbody>
      </MediJobTable>
      <Loading loading={loading}>
        {!loading && dataSource?.length === 0 && emptyTableText && (
          <EmptyTable text={emptyTableText} />
        )}
      </Loading>
    </Container>
  );
};

export const EmptyTable = ({ text }: { text: string }) => {
  return (
    <Text>
      {text.split("\n").map((c, key) => {
        return <Paragraph key={key}> {c} </Paragraph>;
      })}
    </Text>
  );
};

const Text = styled.div`
  color: ${(props) => props.theme.text.blue};
  background-color: ${(props) => props.theme.background.white};
  padding: 20px 29px;
  font-size: 45px;
  font-family: NeueAlteGrotesk-Medium;
`;

const Paragraph = styled.p`
  margin: 0;
  line-height: 58px;
`;

const Header = styled.div<{ transparent: boolean }>`
  font-size: 13px;
  padding: 7px 10px;
  color: ${(props) =>
    props.transparent ? props.theme.background.blue : props.theme.text.white};
`;

const Container = styled.div`
  background-color: ${(props) => props.theme.background.white};
  position: relative;
  overflow: auto;
`;

const Th = styled.th<{ width?: string }>`
  width: ${(props) => (props.width ? props.width : "auto")};
  min-width: 70px;
`;

const MediJobTable = styled.table<{ background: string }>`
  border-collapse: collapse;
  overflow: auto;
  table-layout: fixed;
  width: 100%;
  min-width: 1550px;

  th {
    padding: 0;
    background: ${(props) => props.background};
    white-space: nowrap;
  }

  td {
    padding: 7px 0 7px 10px;
    border-bottom: 1px solid ${(props) => props.theme.border.blue};
    overflow: visible;
    vertical-align: top;
  }

  th > div {
    text-align: left;
    background-color: ${(props) => props.theme.background.blue};
  }

  tbody {
    background-color: ${(props) => props.theme.background.white};
  }

  thead:first-child {
    z-index: 9999999;
  }

  th:first-child > div {
    border-radius: 15px 0 0 15px;
    padding-left: 25px;
  }

  th:last-child > div {
    border-radius: 0 15px 15px 0;
  }

  th,
  td {
    font-family: NeueAlteGrotesk-Medium;
  }

  td {
    color: ${(props) => props.theme.background.blue};
  }
`;

export const Collapse = styled(Center)`
  position: absolute;
  top: 14px;
  left: 20px;
  margin: auto;

  cursor: pointer;
  width: 16px;
  height: 16px;
  border: 1px solid ${(props) => props.theme.border.blue};
  border-radius: 50%;
`;

export const ArrowContainer = styled.div`
  width: 8px;
  height: 5px;
  vertical-align: middle;
  position: relative;
`;

export const Relative = styled.div`
  position: relative;
`;
