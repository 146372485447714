import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import { ErrorMessage } from "./ErrorMessage";

const { Dragger } = Upload;

export const DragAndDrop = ({
  formik,
  name,
  url,
  imageName,
}: {
  formik: FormikProps<any>;
  name: string;
  url: string | null;
  imageName?: string | null;
}) => {
  const { t } = useTranslation(["common"]);
  const [fileList, setFileList] = useState<any>([]);

  const removeFileFromS3Bucket = async () => {
    formik.setFieldValue(name, null);
    setFileList([]);
    // if (imageName) await Storage.remove(imageName);
  };

  useEffect(() => {
    if (url) {
      setFileList([
        {
          uid: "-1",
          name: imageName,
          status: "done",
          url: url,
        },
      ]);
    }
  }, [url]);

  const option = {
    name: "file",
    listType: "picture-card" as any,
    multiple: false,
    maxCount: 1,
    beforeUpload: () => false,
    onRemove: (file: any) => {
      if (file.url === url && imageName) {
        removeFileFromS3Bucket();
      }
    },
    onChange(info: any) {
      formik.setFieldValue(name, info.file);
      setFileList(info.fileList);
    },
  };

  return (
    <Container>
      <DragFile {...option} fileList={fileList}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined style={{ color: "#00c197" }} />
        </p>
        <DragIngo>{t("common:input.files")}</DragIngo>
      </DragFile>
      <ErrorMessage formik={formik} name={name} />
    </Container>
  );
};

const Container = styled.div`
  margin-top: 8px;

  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: ${(props) => props.theme.primary};
  }

  .ant-upload.ant-upload-drag {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    background: #fafafa;
    border: 1px dashed ${(props) => props.theme.primary};
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
  }
`;

const DragFile = styled(Dragger)`
  margin-bottom: 1em;
`;

const DragIngo = styled.p`
  color: ${(props) => props.theme.text.label};
`;
