import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Icon } from "../_styles";
import { createRoute } from "../../../../utils/createRoute";
import { Routes } from "../../../../routes";
import { Link } from "react-router-dom";

export const EditButton = ({
  route,
  small,
  color,
  target,
}: {
  color?: string;
  small?: boolean;
  route: any;
  target?: string;
}) => {
  return (
    <Link to={route} target={target ? target : "_self"}>
      <Icon small={small}>
        <FontAwesomeIcon icon={faEdit} color={color || "#1321b1"} />
      </Icon>
    </Link>
  );
};
