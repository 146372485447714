import styled from "styled-components";

export const ErrorMessage = ({
  formik,
  name,
}: {
  formik: any;
  name: string;
}) => {
  if (
    name &&
    formik &&
    formik.errors &&
    formik.errors[name] &&
    formik.touched &&
    formik.touched[name]
  ) {
    return <Message>{formik.errors[name]}</Message>;
  }

  return <></>;
};

const Message = styled.span`
  color: red;
  margin-top: 3px;
  margin-left: 4px;
  font-size: 11px;
`;
