import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import { Icon } from "../../../../../components/Form/Button/_styles";
import { useMutation, useLazyQuery } from "@oasa/amplify-query";
import {
  GetProfileQuery,
  UpdateProfileInput,
  UpdateProfileMutation,
} from "../../../../../API";
import { getProfile } from "../graphql/queries";
import { useFormik } from "formik";
import { DialogForm } from "../../../../../components/DialogForm";
import { useTranslation } from "react-i18next";
import { updateProfile } from "../../../../../graphql/mutations";
import { message } from "antd";
import NumberInput from "src/components/Form/NumberInput";

export const PersonalCredits = ({ profileID }: { profileID: string }) => {
  const { t } = useTranslation(["profile"]);

  const [query, { data }] = useLazyQuery<GetProfileQuery>(getProfile);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    query({
      id: profileID,
    });
  };
  const [update] = useMutation<UpdateProfileInput, UpdateProfileMutation>(
    updateProfile
  );

  const formik = useFormik<any | any>({
    initialValues: {
      id: data?.getProfile?.id,
      credits: data?.getProfile?.credits,
    },
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      try {
        if (
          values.credits === null ||
          values.credits === undefined ||
          Number(values.credits) < 0
        ) {
          await message.warning(t("profile:credits.invalid"));
          return;
        }

        const response = await update({
          input: { id: values?.id, credits: parseInt(values.credits) },
        });
        formik.setValues({
          id: response?.updateProfile?.id,
          credits: response?.updateProfile?.credits,
        });
        await message.success(t("profile:credits.successMessage"));
      } catch (e) {
        await message.error(e);
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      id: data?.getProfile?.id,
      credits: data?.getProfile?.credits,
    });
  }, [data]);

  return (
    <DialogForm
      title={t("profile:credits.title")}
      okText={t("profile:credits.ok")}
      cancelText={t("profile:credits.cancel")}
      formik={formik}
      customCancel={() => getData()}
      form={
        <form onSubmit={formik.handleSubmit}>
          <NumberInput
            title={t("common:table.creditsCount")}
            name="credits"
            formik={formik}
          />
        </form>
      }
    >
      <Icon>
        <FontAwesomeIcon icon={faCoins} color={"#1321b1"} />
      </Icon>
    </DialogForm>
  );
};
