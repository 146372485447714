import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash";
import React from "react";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";

type UseQueryType<ResultType> = {
  loading: boolean;
  mutationLoading: boolean;
  error: any;
  data: ResultType;
  refetch: () => void;
  mutation: (query: string, variables?: any) => Promise<any>;
};

export const gqlOp = async <
  ResultType extends {},
  VariablesType extends {} = {}
>(
  query: string,
  variables?: VariablesType
) => {
  const { data } = (await API.graphql(graphqlOperation(query, variables))) as {
    data: ResultType;
  };
  return data;
};

export const mutation = async <
  ResultType extends {},
  VariablesType extends {} = {}
>(
  query: string,
  variables?: VariablesType
) => {
  return await gqlOp<ResultType, VariablesType>(query, variables);
};

export const useQuery = <ResultType extends {}, VariablesType extends {} = {}>(
  query?: string,
  variables?: VariablesType
): UseQueryType<ResultType> => {
  const [loading, setLoading] = React.useState(true);
  const [mutationLoading, setMutationLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [data, setData] = React.useState<any>([]);

  const mutation = async <ResultType extends {}, VariablesType extends {} = {}>(
    query: string,
    variables?: VariablesType
  ) => {
    try {
      await setMutationLoading(true);
      await gqlOp<ResultType, VariablesType>(query, variables);
      await setMutationLoading(false);
    } catch (e) {
      await setMutationLoading(false);
    }
  };

  const fetchQuery = async (
    query: string,
    variables?: any,
    concat?: boolean
  ) => {
    try {
      setLoading(true);

      const newData: any = await gqlOp<ResultType, VariablesType>(
        query,
        variables
      );
      if (variables && variables?.nextToken && concat) {
        const node = _.get(data, variables.name);
        setData({
          [variables.name]: {
            items: (node?.items || [])?.concat(newData[variables.name]?.items),
            nextToken: newData[variables.name]?.nextToken,
          },
        });
      } else {
        setData(newData);
      }
    } catch (error) {
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const refetch = () => {
    if (query) fetchQuery(query, variables, false);
  };

  useDeepCompareEffectNoCheck(() => {
    if (query) fetchQuery(query, variables, true);
  }, [query, variables]);

  return {
    loading,
    data,
    error,
    refetch,
    mutation,
    mutationLoading,
  };
};
