import { useQuery } from "@oasa/amplify-query";
import { useTranslation } from "react-i18next";
import { GetShowCaseQuery } from "src/API";
import { getShowCase } from "src/graphql/queries";

export const CompanyExtraServices = ({
  companyID,
}: {
  companyID?: string | null;
}) => {
  const { t } = useTranslation(["common"]);
  const { data, loading } = useQuery<GetShowCaseQuery>(getShowCase, {
    companyID: companyID,
  });

  if (loading) <></>;

  if (!data.getShowCase) return <span>{t("common:table:none")}</span>;

  return <span>{t("common:table:showcase")}</span>;
};
