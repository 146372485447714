import { UpdateAdvertisementInput } from "src/API";
import { Advertisement } from "src/API";

export const formatAdvertisementValue = (
  advertisement: Advertisement
): UpdateAdvertisementInput => {
  return {
    id: advertisement?.id,
    friendlyId: advertisement?.friendlyId,
    entity: advertisement?.entity,
    active: advertisement?.active,
    state: advertisement?.state,
    jobPosition: advertisement?.jobPosition,
    jobShortDescription: advertisement?.jobShortDescription,
    jobDescription: advertisement?.jobDescription,
    place: advertisement?.place,
    salaryFrom: advertisement?.salaryFrom,
    salaryTo: advertisement?.salaryTo,
    salarySpecifiedInText: advertisement?.salarySpecifiedInText,
    reactivationCount: advertisement?.reactivationCount,
    automaticReactivation: advertisement?.automaticReactivation,
    leadingPosition: advertisement?.leadingPosition,
    residentialPlace: advertisement?.residentialPlace,
    typeOfCooperation: advertisement?.typeOfCooperation,
    jobType: advertisement?.jobType,
    financialConditions: advertisement?.financialConditions,
    typeOfInternship: advertisement?.typeOfInternship,
    contactFirstDegree: advertisement?.contactFirstDegree,
    contactForename: advertisement?.contactForename,
    contactSurname: advertisement?.contactSurname,
    contactSecondDegree: advertisement?.contactSecondDegree,
    contactEmail: advertisement?.contactEmail,
    contactPhoneNumber: advertisement?.contactPhoneNumber,
    hideContactInformation: advertisement?.hideContactInformation,
    workType: advertisement?.workType,
    suitableFor: advertisement?.suitableFor,
    employmentTypes: advertisement?.employmentTypes,
    paymentType: advertisement?.paymentType,
    advertisementSettingID: advertisement?.advertisementSettingID,
    companyID: advertisement?.companyID,
    profileID: advertisement?.profileID,
    createdAt: advertisement?.createdAt,
    updatedAt: advertisement?.updatedAt,
    expirationDate: advertisement?.expirationDate,
    advertisementTypeID: advertisement?.advertisementTypeID,
  };
};
