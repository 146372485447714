import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import common from "./locales/cs/common.json";
import advertisements from "./locales/cs/advertisiments.json";
import users from "./locales/cs/users.json";
import carousel from "./locales/cs/carousel.json";
import settings from "./locales/cs/settings.json";
import articles from "./locales/cs/articles.json";
import taxonomy from "./locales/cs/taxonomy.json";
import signIn from "./locales/cs/signin.json";
import form from "./locales/cs/form.json";
import profile from "./locales/cs/profile.json";

const resources = {
  cs: {
    common: common,
    advertisements: advertisements,
    users: users,
    carousel: carousel,
    settings: settings,
    articles: articles,
    taxonomy: taxonomy,
    signIn: signIn,
    form: form,
    profile: profile,
  },
};

i18n.use(initReactI18next).init({
  resources,
  defaultNS: "common",
  lng: "cs",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
