import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { Routes } from "./enum";
import { SignIn } from "../modules/admin/portal/signIn";
import { Portal } from "../modules/admin/portal";

const BaseRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route path={Routes.PORTAL} component={Portal} />
        <Route path={Routes.HOME} component={SignIn} />
      </Switch>
    </Router>
  );
};

export { Routes, BaseRoutes };
