import { uuidv4 } from "../../../../../utils/guid";
import { getFileExtension } from "../../../../../utils/file";
import { Storage } from "aws-amplify";
import {
  createCarousel,
  updateCarousel,
} from "../../../../../graphql/mutations";
import { message } from "antd";
import { routeTo } from "../../../../../utils/createRoute";
import { Routes } from "../../../../../routes";
import { CreateCarouselInput } from "../../../../../API";

/**
 * Create carousel
 * @param values
 * @param mutation
 * @param id
 * @param history
 */
export const handleSubmit = async (
  values: CreateCarouselInput,
  mutation: any,
  history: any,
  id?: string
) => {
  let name = "";
  try {
    if (values.image) {
      name =
        typeof values.image === "string"
          ? values.image
          : `carousel/${Date.now()}-${uuidv4()}.${getFileExtension(
              (values.image as any)?.name
            )}`;

      if (typeof values.image !== "string") {
        await Storage.put(name, values.image, {
          contentType: "image/jpeg", // contentType is optional
        });
      }
    }

    const formattedValues = {
      ...values,
      image: name || "",
    };

    await mutation(id ? updateCarousel : createCarousel, {
      input: formattedValues,
    });

    message.success(`Carousel byl úspěšně uložen.`);

    routeTo(history, Routes.CAROUSELS);
  } catch (e) {
    message.error(`Exception: ${e}`);
  }
};
