import React from "react";
import { Row } from "optimify-mediportal-components";
import { useTranslation } from "react-i18next";
import { Routes } from "../../../../../routes";
import {
  ArticleCategoriesByCreatedAtQuery,
  ArticleCategory,
  ArticlesByCategorySlugQuery,
  DeleteArticleInput,
  DeleteArticleMutation,
  EntityType,
  ModelSortDirection,
} from "../../../../../API";
import { articleCategoriesByCreatedAt } from "../graphql/queries";
import { deleteArticleCategory } from "../../../../../graphql/mutations";
import { createRoute } from "../../../../../utils/createRoute";
import { DateFormat } from "../../../../../components/Moment";
import { MedTable } from "src/components/Table";
import { EditButton } from "../../../../../components/Form/Button/Icons/Edit";
import { RemoveButton } from "src/components/Form/Button/Icons/Remove";
import {
  graphqlOperation,
  useLazyQuery,
  useMutation,
  usePaginatedQuery,
} from "@oasa/amplify-query";
import { Pagination } from "../../../../../components/Table/Pagination";
import { message } from "antd";
import { articlesByCategorySlug } from "../../../../../graphql/queries";
import { GRAPHQL_AUTH_MODE } from "../../../../../types/_types";

export const CategoriesTable = () => {
  const { t } = useTranslation(["common", "advertisements", "articles"]);

  const { loading, data, totalPages, page, setPage, refetch, nextTokens } =
    usePaginatedQuery<ArticleCategoriesByCreatedAtQuery>(
      articleCategoriesByCreatedAt,
      {
        entity: EntityType.ARTICLE_CATEGORY,
        sortDirection: ModelSortDirection.DESC,
        limit: 10,
        name: "articleCategoriesByCreatedAt",
      }
    );

  const [remove] = useMutation<DeleteArticleInput, DeleteArticleMutation>(
    deleteArticleCategory
  );

  const removeItem = async (category: ArticleCategory) => {
    const response: ArticlesByCategorySlugQuery = await graphqlOperation(
      articlesByCategorySlug,
      {
        articleCategoryID: category.slug,
      }
    );
    if (
      response?.articlesByCategorySlug?.items &&
      response?.articlesByCategorySlug?.items?.length > 0
    ) {
      message.info(t("articles:notifications.categoryIsNotEmpty"));
      return;
    }

    await remove({ input: { slug: category?.slug } });
    refetch();
  };

  return (
    <>
      <MedTable
        emptyTableText={t("articles:emptyCategoriesTable")}
        dataSource={data?.articleCategoriesByCreatedAt?.items || []}
        loading={loading}
        columns={[
          {
            title: t("common:table.name"),
            render: (text: any, record: any) => <span>{record.name}</span>,
          },
          {
            title: t("common:table.slug"),
            render: (text: any, record: any) => <span>{record.slug}</span>,
          },
          {
            title: t("common:table.createdAt"),
            render: (text: any, record: any) => (
              <span>
                <DateFormat date={record?.createdAt} />
              </span>
            ),
          },
          {
            title: t("common:table.updatedAt"),
            render: (text: any, record: any) => (
              <span>
                <DateFormat date={record?.updatedAt} />
              </span>
            ),
          },
          {
            title: "",
            titleHide: true,
            key: "action",
            render: (_text: any, record: any) => (
              <Row>
                <EditButton
                  route={createRoute(Routes.CREATE_CATEGORY, [record?.slug])}
                />
                <RemoveButton
                  title={t("articles:removeCategory.title")}
                  description={t("articles:removeCategory.description")}
                  okText={t("articles:removeCategory.ok")}
                  cancelText={t("articles:removeCategory.cancel")}
                  handleClick={() => removeItem(record)}
                />
              </Row>
            ),
          },
        ]}
      />
      <Pagination
        totalPages={totalPages}
        page={page}
        setPage={setPage}
        nextTokens={nextTokens}
      />
    </>
  );
};
