import styled from "styled-components";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 64, color: "#19d2b1" }} spin />
);

export const Loading = ({
  loading,
  children,
}: {
  loading?: boolean;
  children: React.ReactNode;
}) => {
  const { t } = useTranslation(["common"]);
  if (loading) {
    return (
      <Relative>
        <CenterSpin>
          <Spin indicator={antIcon} size={"large"} />
          <Label>{t("common:loading")}</Label>
        </CenterSpin>
      </Relative>
    );
  }
  return <>{children}</>;
};

const CenterSpin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
`;

const Relative = styled.div`
  position: relative;
  width: 100%;
`;

const Label = styled.span`
  font-size: 12px;
  margin-top: 15px;
  color: ${(props) => props.theme.text.blue};
`;
