export const formatCurrency = (
  amount?: number | null,
  locale?: string,
  currency?: string
) => {
  if (!amount) return 0;
  return amount.toLocaleString(locale ? locale : "cs-CZ", {
    style: "currency",
    currency: currency ? currency : "CZK",
    minimumFractionDigits: 0,
  });
};
