import React, { useEffect } from "react";
import {
  Profile,
  RemoveAllUserDataMutation,
  RemoveAllUserDataMutationVariables,
  SearchableCompanySortableFields,
  SearchableSortDirection,
  SearchProfilesQuery,
} from "../../../../../../API";
import { searchProfiles } from "../../graphql/queries";
import { Table } from "./Table";
import _ from "lodash";
import { useLazyQuery, useMutation } from "@oasa/amplify-query";
import { removeAllUserData } from "../../../../../../graphql/mutations";
import { EntityState } from "../../../../../../types/_types";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { Pagination } from "src/components/Table/Pagination";

export const SearchUsers = ({ value }: { value?: string | null }) => {
  const { t } = useTranslation(["profile"]);
  const [page, setPage] = React.useState<number>(0);

  const [remove] = useMutation<
    RemoveAllUserDataMutationVariables,
    RemoveAllUserDataMutation
  >(removeAllUserData);

  const [search, { loading, data, totalPages, nextTokens }] =
    useLazyQuery<SearchProfilesQuery>(searchProfiles);
  useEffect(() => {
    refetch(0);
  }, [value]);

  const removeItem = async (item: Profile) => {
    message.info(t("profile:deletingAllUserData"), 10);
    await remove({
      profileID: item.id,
      email: item?.email,
      owner: item?.owner,
    });
    _.delay(() => {
      refetch(0);
    }, 4000);
  };

  const refetch = (currentPage: number) => {
    const token = currentPage ? nextTokens[currentPage] : undefined;
    search({
      filter: !!value
        ? {
            entityState: { eq: EntityState.ACTIVE },
            or: [
              { id: { matchPhrasePrefix: value } },
              { email: { matchPhrasePrefix: value } },
              { forename: { matchPhrasePrefix: value } },
              { surname: { matchPhrasePrefix: value } },
              { aboutMe: { matchPhrasePrefix: value } },
            ],
          }
        : undefined,
      sort: {
        field: SearchableCompanySortableFields.createdAt,
        direction: SearchableSortDirection.desc,
      },
      limit: 1000,
      name: "searchProfiles",
      nextToken: token,
      page: currentPage,
    });
  };

  return (
    <>
      <Table
        items={data?.searchProfiles?.items || []}
        loading={loading}
        removeItem={removeItem}
      />
      <Pagination
        totalPages={totalPages}
        page={page}
        setPage={setPage}
        nextTokens={nextTokens}
        customHandler={refetch}
      />
    </>
  );
};
