import { Advertisement } from "src/API";
import { CompanyContact } from "./CompanyContact";
import { PersonalContact } from "./PersonalContact";

export const Contact = ({ job }: { job?: Advertisement | null }) => {
  if (!!job?.companyID) {
    return <CompanyContact job={job} />;
  }

  return <PersonalContact job={job} />;
};
