import {
  createAdvertisementType,
  updateAdvertisementType,
} from "../../../../../graphql/mutations";
import { message } from "antd";
import { routeTo } from "../../../../../utils/createRoute";
import { Routes } from "../../../../../routes";
import { CreateAdvertisementTypeInput } from "../../../../../API";
import { SettingPage } from "../index";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";

/**
 * Create advertisement type
 * @param values
 * @param mutation
 * @param id
 * @param history
 */
export const handleSubmit = async (
  values: CreateAdvertisementTypeInput,
  mutation: any,
  history: any,
  id?: string
) => {
  try {
    const formattedValues = {
      ...values,
      components: JSON.stringify(values.components),
      jobDescription: values?.jobDescription
        ? draftToHtml(
            convertToRaw((values?.jobDescription as any)?.getCurrentContent())
          )
        : "",
    };

    await mutation(id ? updateAdvertisementType : createAdvertisementType, {
      input: formattedValues,
    });

    message.success(`Typ inzerátu byl úspěšně uložen.`);

    routeTo(history, Routes.SETTINGS, [SettingPage.AdvertisementsTypeSettings]);
  } catch (e) {
    message.error(`Exception: ${e}`);
  }
};
