export enum OfferSize {
  SMALL,
  MEDIUM,
  LARGE,
}

export enum PaymentState {
  FREE = "FREE",
  WAITING_FOR_PAYMENT = "WAITING_FOR_PAYMENT",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  PAYMENT_SUCCESSFUL = "PAYMENT_SUCCESSFUL",
  DELETED = "DELETED",
}

export enum GRAPHQL_AUTH_MODE {
  API_KEY = "API_KEY",
  AWS_IAM = "AWS_IAM",
  OPENID_CONNECT = "OPENID_CONNECT",
  AMAZON_COGNITO_USER_POOLS = "AMAZON_COGNITO_USER_POOLS",
  AWS_LAMBDA = "AWS_LAMBDA",
}

export enum ArticleType {
  STANDARD = "STANDARD",
  PAID = "PAID",
}

export enum ApprovalRequired {
  YES = "YES",
  NO = "NO",
}

export enum EntityState {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}
