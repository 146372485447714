import React, { useState } from "react";
import {
  AdminInput,
  Card,
  Col,
  CreditsButton,
  RemoveButton,
  Row,
  Search,
  Table,
} from "optimify-mediportal-components";
import { useTranslation } from "react-i18next";
import { Avatar, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "../../../../../utils/useQuery";
import { EntityType, GetArticleCategoryQuery } from "../../../../../API";
import { getArticleCategory } from "../../../../../graphql/queries";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  createArticleCategory,
  updateArticleCategory,
} from "../../../../../graphql/mutations";
import { routeTo } from "../../../../../utils/createRoute";
import { Routes } from "../../../../../routes";
import { ArticlePages } from "../../articles";
import { Form } from "../../../../../constants/globalStyles";
import { EditEntityLayout } from "../../../../../components/Layout/EditEntityLayout";
import { FooterButtons } from "../../../../../components/Form/FooterButtons";
import { Auth } from "aws-amplify";

export const ChangePassword = () => {
  const { t } = useTranslation(["common", "profile"]);
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      oldPassword: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string()
        .min(2, t("form:validation.toShort", { count: 2 }))
        .max(50, t("form:validation.toLong", { count: 50 }))
        .required(t("form:validation.required")),
      newPassword: Yup.string()
        .min(2, t("form:validation.toShort", { count: 2 }))
        .max(50, t("form:validation.toLong", { count: 50 }))
        .required(t("form:validation.required")),
    }),
    onSubmit: async (values) => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const response = await Auth.changePassword(
          user,
          values.oldPassword,
          values.newPassword
        );
      } catch (err) {
        message.error(err);
      } finally {
        message.success(`Heslo bylo úspěšně uloženo.`);
      }
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <EditEntityLayout
        hideBackButton
        title={t("profile:changePassword")}
        footer={
          <FooterButtons
            hideBackButton
            formik={formik}
            loading={formik.isSubmitting}
          />
        }
      >
        <Row>
          <Col>
            <Card padding>
              <AdminInput
                type="password"
                title={t("common:input.oldPassword")}
                name="oldPassword"
                formik={formik}
              />
              <AdminInput
                type="password"
                title={t("common:input.newPassword")}
                name="newPassword"
                formik={formik}
              />
            </Card>
          </Col>
        </Row>
      </EditEntityLayout>
    </Form>
  );
};
