import { Select, Tag } from "antd";
import { SelectValue } from "antd/lib/select";
import { FormikProps } from "formik";
import styled from "styled-components";
import { Option } from "antd/es/mentions";
import _ from "lodash";
import { ErrorMessage } from "./ErrorMessage";
import React from "react";
import { boolean } from "yup";

export const FormSelect = ({
  formik,
  name,
  title,
  options,
  placeholder,
  loading,
  showSearch,
}: {
  formik: FormikProps<any>;
  name: string;
  title: string;
  placeholder?: string;
  loading?: boolean;
  options: Array<{ value?: string; title?: string }>;
  showSearch?: boolean;
}) => {
  const tagRender = (props: any) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  const handleChange = (value: any) => {
    formik.setFieldValue(name, value);
  };

  return (
    <Container>
      {title && <Label>{title}</Label>}
      <StyledSelect
        showSearch={showSearch}
        placeholder={placeholder || ""}
        loading={loading}
        optionFilterProp="children"
        onChange={handleChange}
        value={
          formik.values && name && formik.values[name] != ""
            ? formik.values[name]
            : []
        }
        style={{ width: "100%" }}
      >
        {options?.map(
          (iterator: { value?: string; title?: string }, index: number) => {
            return (
              <Option value={iterator.value || ""} key={index.toString()}>
                {iterator.title}
              </Option>
            );
          }
        )}
      </StyledSelect>
      <ErrorMessage formik={formik} name={name} />
    </Container>
  );
};

const StyledSelect = styled(Select)``;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 13px;

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: ${(props) => props.theme.border.green};
    box-shadow: none;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #c3c3c3;
    border-radius: 7px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.05);
    padding: 4px 16px 6px 10px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 43px;
  }

  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 39px;
  }
`;

const Label = styled.span`
  font-size: 13px;
  color: #555555;
  padding-bottom: 3px;
  padding-left: 1px;
`;
