import { AdvertisementState, FormData } from "./_types";
import React, { useEffect, useState } from "react";
import {
  SearchableAdvertisementFilterInput,
  SearchableCompanySortableFields,
  SearchableSortDirection,
  SearchAdvertisementsQuery,
} from "src/API";
import { searchAdvertisements } from "./graphql/queries";
import _ from "lodash";
import { PaymentState } from "src/types/_types";
import { usePaginatedQuery } from "@oasa/amplify-query";

export interface JobSearchFilters {
  filters: FormData;
}

export interface JobSearchReturnType {
  data: SearchAdvertisementsQuery | null;
  loading: boolean;
  errors: any;
  refetch: any;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  totalPages: number;
  page: number;
  nextTokens: any;
}

const mapValue = (value: string | undefined) => {
  if (!value || value.length === 0) {
    return undefined;
  }
  return value;
};

const formatFilters = (values: FormData | undefined): FormData => {
  return {
    job: mapValue(values?.job),
    companyID: mapValue(values?.companyID),
    profileID: mapValue(values?.profileID),
    state: mapValue(values?.state),
    status: mapValue(values?.status),
    dateFrom: mapValue(values?.dateFrom),
    dateTo: mapValue(values?.dateTo),
  };
};

export function useJobSearch(filters?: FormData): JobSearchReturnType {
  const [filterState, setFilterState] = useState<FormData>({
    job: "",
    companyID: "",
    state: "",
    profileID: "",
    status: "",
    dateFrom: "",
    dateTo: "",
  });
  const [searchFilter, setSearchFilter] = useState<
    SearchableAdvertisementFilterInput | undefined
  >(undefined);

  const createFilterInput = (
    filters: FormData | undefined
  ): SearchableAdvertisementFilterInput | undefined => {
    if (!filters) {
      return undefined;
    }

    const { job, companyID, state, profileID, status, dateFrom, dateTo } =
      filters;

    const jobPosition = job
      ? [
          { place: { matchPhrasePrefix: job } },
          { id: { matchPhrasePrefix: job } },
          { jobPosition: { matchPhrasePrefix: job } },
          { jobShortDescription: { matchPhrasePrefix: job } },
          { jobDescription: { matchPhrasePrefix: job } },
        ]
      : undefined;
    const statusFilter =
      status === "ACTIVE"
        ? [
            { expirationDate: { gte: new Date().toISOString() } },
            { state: { eq: AdvertisementState.APPROVED } },
            { paymentState: { eq: PaymentState.PAYMENT_SUCCESSFUL } },
          ]
        : status === "INACTIVE"
        ? [
            {
              or: [
                { expirationDate: { lte: new Date().toISOString() } },
                { state: { ne: AdvertisementState.APPROVED } },
                { paymentState: { ne: PaymentState.PAYMENT_SUCCESSFUL } },
              ],
            },
          ]
        : undefined;

    return {
      and: statusFilter,
      companyID: companyID ? { eq: companyID } : undefined,
      profileID: profileID ? { eq: profileID } : undefined,
      createdAt: dateFrom && dateTo ? { range: [dateFrom, dateTo] } : undefined,
      or: jobPosition,
    };
  };
  const {
    data,
    loading,
    errors,
    refetch,
    totalPages,
    page,
    setPage,
    nextTokens,
    setNextTokens,
  } = usePaginatedQuery<SearchAdvertisementsQuery>(searchAdvertisements, {
    filter: searchFilter,
    sort: {
      field: SearchableCompanySortableFields.createdAt,
      direction: SearchableSortDirection.desc,
    },
    name: "searchAdvertisements",
    limit: 10,
  });

  useEffect(() => {
    if (!filters) return;

    if (
      filters &&
      Object.entries(filterState).toString() ===
        Object.entries(filters).toString()
    )
      return;

    setNextTokens([undefined]);
    setPage(0);
    setFilterState(filters);

    const filtrationValues = createFilterInput(formatFilters(filters));

    const filter = _.isEmpty(_.omitBy(filtrationValues, _.isNil))
      ? undefined
      : filtrationValues;

    setSearchFilter(filter);
  }, [filters]);
  console.log(totalPages);
  console.log(page);
  console.log(nextTokens);
  return {
    data,
    loading,
    errors,
    refetch,
    totalPages,
    page,
    setPage,
    nextTokens,
  };
}
