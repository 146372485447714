import { RowCenter } from "../../../constants/globalStyles";
import styled from "styled-components";

export const Wrapper = styled(RowCenter)`
  margin-top: 1em;

  font-size: 1.2em;
`;

export const Icon = styled.span<{ disabled?: boolean }>`
  padding: 5px 7px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: ${(props) => props.theme.primary};
`;

export const Page = styled.span<{ active: boolean }>`
  padding: 5px 7px;
  cursor: pointer;
  font-weight: ${(props) => (props.active ? "bold" : "400")};
  text-decoration: ${(props) => (props.active ? "underline" : "none")};
  color: ${(props) => props.theme.primary};

  &:hover {
    text-decoration: underline;
  }
`;
