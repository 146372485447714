import { Title } from "optimify-mediportal-components";
import { ArticlesTable } from "./components/ArticlesTable";
import { useTranslation } from "react-i18next";
import {
  LayoutHeader,
  StyledTabs,
  Wrap,
} from "../../../../constants/globalStyles";
import { Routes } from "../../../../routes";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Tabs } from "antd";
import { CategoriesTable } from "./components/CategoryTable";
import { routeTo } from "../../../../utils/createRoute";
import { Button } from "src/components/Form/Button/Button";

export enum ArticlePages {
  ArticlesTable = "articlesTable",
  CategoriesTable = "categoriesTable",
}
const { TabPane } = Tabs;
export const Articles = () => {
  const { t } = useTranslation(["articles"]);
  const { key } = useParams<{ key?: ArticlePages }>();
  const [page, setPage] = useState(ArticlePages.ArticlesTable);
  const history = useHistory();

  useEffect(() => {
    key && setPage(key);
  }, [key]);

  const callback = (key: string) => {
    routeTo(history, Routes.ARTICLES, [key]);
  };

  return (
    <Wrap>
      <LayoutHeader>
        <Title noMargin>{t("articles:title")} </Title>
        <Button
          type="button"
          variant="outline"
          onClick={() =>
            routeTo(
              history,
              page === ArticlePages.ArticlesTable
                ? Routes.CREATE_ARTICLE
                : Routes.CREATE_CATEGORY
            )
          }
        >
          {t(
            page === ArticlePages.ArticlesTable
              ? "articles:addArticle"
              : "articles:addCategory"
          )}
        </Button>
      </LayoutHeader>
      <StyledTabs activeKey={page} onChange={callback}>
        <TabPane tab={t("articles:articles")} key={ArticlePages.ArticlesTable}>
          <ArticlesTable />
        </TabPane>
        <TabPane
          tab={t("articles:categories")}
          key={ArticlePages.CategoriesTable}
        >
          <CategoriesTable />
        </TabPane>
      </StyledTabs>
    </Wrap>
  );
};
