import React, { useEffect } from "react";
import {
  AdminInput,
  Card,
  CheckboxGroup,
  Col,
  Row,
} from "optimify-mediportal-components";
import { Routes } from "../../../../../routes";
import { EditEntityLayout } from "../../../../../components/Layout/EditEntityLayout";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FooterButtons } from "../../../../../components/Form/FooterButtons";
import { routeTo } from "../../../../../utils/createRoute";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "../../../../../utils/useQuery";
import { handleSuitableFor } from "./_services";
import { Form } from "../../../../../constants/globalStyles";
import {
  getSuitableFor,
  getWorkerType,
  suitableForByCountryAndLevel,
  workerTypesByCountryAndLevel,
} from "../../../../../graphql/queries";
import {
  Country,
  CreateSuitableForInput,
  CreateWorkerTypeInput,
  GetSuitableForQuery,
  GetWorkerTypeQuery,
  SuitableFor,
  SuitableForByCountryAndLevelQuery,
  WorkerType,
  WorkerTypeEdge,
} from "../../../../../API";
import _ from "lodash";

export const CreateSuitableForTaxonomy = () => {
  const { t } = useTranslation(["common", "taxonomy"]);
  const { level, country, id, parentId } = useParams<{
    level: string;
    country: Country;
    id?: string;
    parentId?: string;
  }>();
  const history = useHistory();

  const listSuitableFor = useQuery<SuitableForByCountryAndLevelQuery>(
    suitableForByCountryAndLevel,
    {
      country: country,
      level: { eq: parseInt(level) + 1 },
    }
  );

  const { mutation, data, mutationLoading } = useQuery<GetSuitableForQuery>(
    getSuitableFor,
    {
      id: id,
    }
  );

  const formik = useFormik<CreateSuitableForInput & { children?: any }>({
    initialValues: data?.getSuitableFor
      ? {
          id: data?.getSuitableFor?.id,
          name: data?.getSuitableFor?.name,
          country: data?.getSuitableFor?.country,
          order: data?.getSuitableFor?.order,
          level: data?.getSuitableFor?.level,
          children: data?.getSuitableFor.childEdges?.items?.map((e: any) => {
            return e.childNodeId;
          }),
        }
      : {
          name: "",
          country: country,
          order: 1,
          level: parseInt(level),
        },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, t("form:validation.toShort", { count: 1 }))
        .max(100, t("form:validation.toLong", { count: 100 }))
        .required(t("form:validation.required")),
      order: Yup.number().required(t("form:validation.required")),
    }),
    onSubmit: async (values: CreateWorkerTypeInput) => {
      await handleSuitableFor(
        values,
        data?.getSuitableFor,
        mutation,
        history,
        id,
        parentId
      );
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <EditEntityLayout
        title={t(
          id
            ? "taxonomy:editSuitableForTaxonomy"
            : "taxonomy:createSuitableForTaxonomy",
          {
            level: level,
            country: country,
          }
        )}
        footer={
          <FooterButtons
            onClick={() => routeTo(history, Routes.TAXONOMY, [])}
            loading={mutationLoading}
          />
        }
      >
        <Row>
          <Col>
            <Card padding fullHeight>
              <AdminInput
                type="text"
                title={t("common:table.name")}
                name="name"
                formik={formik}
                required
              />
              <AdminInput
                type="text"
                title={t("common:table.order")}
                name="order"
                formik={formik}
                required
              />
            </Card>
          </Col>
          <Col>
            {Array.isArray(
              listSuitableFor?.data?.suitableForByCountryAndLevel?.items
            ) &&
            listSuitableFor?.data?.suitableForByCountryAndLevel?.items &&
            listSuitableFor?.data?.suitableForByCountryAndLevel?.items.length >
              0 ? (
              <Card padding>
                <CheckboxGroup
                  formik={formik}
                  name={"children"}
                  title={t("taxonomy:children")}
                  options={
                    _.orderBy(
                      listSuitableFor?.data?.suitableForByCountryAndLevel
                        ?.items,
                      (e) => e?.order
                    )?.map((value: SuitableFor | null, index: number) => {
                      return {
                        value: value?.id || "",
                        label: value?.name || "",
                      };
                    }) || []
                  }
                />
              </Card>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </EditEntityLayout>
    </Form>
  );
};
