import React, { useState } from "react";
import styled from "styled-components";
import ExampleTable from "./ExampleTable";
import {
  AdDescription,
  AdminButton,
  AdminInput,
  AdTag,
  AdTitle,
  Card,
  Col,
  P,
  Padding,
  Row,
  Search,
  Select,
  Title,
} from "optimify-mediportal-components";

export const Temp = () => {
  const [search, setSearch] = useState("");

  return (
    <>
      <Row>
        <Padding>
          <Search
            value={search}
            autocompleteValue="Kardiochirurg Praha"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onSearch={() => {}}
          />
        </Padding>
        <Padding>
          <Select
            defaultValue={{ value: "all", label: "Všechny inzeráty" }}
            options={[
              { value: "all", label: "Všechny inzeráty" },
              { value: "active", label: "Aktivní" },
              { value: "inactive", label: "Neaktivní" },
            ]}
          />
        </Padding>
      </Row>

      <Row>
        <Col>
          <Card padding>
            <Title>Taxonomie - typ korporace</Title>

            <AdminButton type="submit" variant="primary">
              Přihlásit se
            </AdminButton>
            <AdminButton type="button" variant="outline">
              Zrušit
            </AdminButton>
            <AdminButton type="link" href="#" variant="plain">
              Zpět
            </AdminButton>
          </Card>
        </Col>
        <Col>
          <Card padding>
            <AdTitle>Název</AdTitle>
            <AdDescription>Hrudní chirurg</AdDescription>
            <AdTitle>Náhledový popis</AdTitle>
            <P>
              Lorem ipsum dolor sit amet, consectetur adipiscing sed do eiusmod
              tempor incididunt labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
              ea commodo consequat. Duis aute irure dolor in reprehenderit in
              voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              Excepteur sint occaecat cupidatat non proident,
            </P>
            <Card corners>
              <Padding size={0.25}>
                <AdTitle>Zaměření</AdTitle>
                <AdTag>LZP</AdTag>
                <br />
                <AdTag>Lékař</AdTag>
                <br />
                <AdTag color="yellow" clickable>
                  Kmen 1
                </AdTag>
                <AdTag color="yellow" clickable>
                  Kmen 2
                </AdTag>
                <AdTag color="yellow" clickable off>
                  Kmen 3
                </AdTag>
                <br />
                <AdTag color="pink" doubleWidth>
                  Atestace
                </AdTag>
              </Padding>
            </Card>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <ExampleTable />
          </Card>
        </Col>
      </Row>
    </>
  );
};

const LogoWrap = styled.div`
  margin: 16px 0 32px;
`;

const FooterWrap = styled.ul`
  margin: 0;
  padding: 8px 0;
  border-top: 1px solid #e8e8e8;
  list-style: none;
`;
