import React from "react";
import { Row } from "optimify-mediportal-components";
import { useTranslation } from "react-i18next";
import { Routes } from "../../../../../routes";
import {
  Carousel,
  CarouselsByCreatedAtQuery,
  DeleteCarouselInput,
  DeleteCarouselMutation,
  EntityType,
  ModelSortDirection,
} from "../../../../../API";
import { carouselsByCreatedAt } from "../graphql/queries";
import { deleteCarousel } from "../../../../../graphql/mutations";
import { createRoute } from "../../../../../utils/createRoute";
import { DateFormat } from "../../../../../components/Moment";
import { MedTable } from "src/components/Table";
import { EditButton } from "src/components/Form/Button/Icons/Edit";
import { RemoveButton } from "src/components/Form/Button/Icons/Remove";
import { useMutation, usePaginatedQuery } from "@oasa/amplify-query";
import { Pagination } from "../../../../../components/Table/Pagination";
import { Owner } from "../../users/components/Owner";

export const CarouselsTable = () => {
  const { t } = useTranslation(["common", "advertisements", "carousel"]);

  const { loading, data, totalPages, page, setPage, refetch, nextTokens } =
    usePaginatedQuery<CarouselsByCreatedAtQuery>(carouselsByCreatedAt, {
      entity: EntityType.CAROUSEL,
      sortDirection: ModelSortDirection.DESC,
      limit: 10,
      name: "carouselsByCreatedAt",
    });

  const [remove] = useMutation<DeleteCarouselInput, DeleteCarouselMutation>(
    deleteCarousel
  );

  const removeItem = async (id?: string) => {
    if (id) {
      await remove({ input: { id: id } });
      refetch();
    }
  };

  return (
    <>
      <MedTable
        emptyTableText={t("carousel:emptyTable")}
        dataSource={data?.carouselsByCreatedAt?.items || []}
        loading={loading}
        columns={[
          {
            title: t("common:table.id"),
            render: (text: any, record: Carousel) => <span>{record.id}</span>,
          },
          {
            title: t("common:table.name"),
            render: (text: any, record: Carousel) => (
              <span>{record.company?.name}</span>
            ),
          },
          {
            title: t("common:table.email"),
            render: (text: any, record: Carousel) => (
              <Owner companyID={record?.companyID} />
            ),
          },
          {
            title: t("common:table.createdAt"),
            render: (text: any, record: Carousel) => (
              <span>
                <DateFormat date={record?.createdAt} />
              </span>
            ),
          },
          {
            title: t("common:table.updatedAt"),
            render: (text: any, record: any) => (
              <span>
                <DateFormat date={record?.updatedAt} />
              </span>
            ),
          },
          {
            title: "",
            titleHide: true,
            key: "action",
            render: (_text: any, record: Carousel) => (
              <Row>
                <EditButton
                  route={createRoute(Routes.CREATE_CAROUSEL, [record?.id])}
                />
                <RemoveButton
                  title={t("carousel:remove.title")}
                  description={t("carousel:remove.description")}
                  okText={t("carousel:remove.ok")}
                  cancelText={t("carousel:remove.cancel")}
                  handleClick={() => removeItem(record?.id)}
                />
              </Row>
            ),
          },
        ]}
      />
      <Pagination
        totalPages={totalPages}
        page={page}
        setPage={setPage}
        nextTokens={nextTokens}
      />
    </>
  );
};
