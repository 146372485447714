import {
  Advertisement,
  UpdateAdvertisementInput,
  UpdateAdvertisementMutation,
  UpdateCompanyInput,
} from "../../../../../../../API";
import { AdvertisementState } from "../../../components/_types";
import { Row, SpaceBetween } from "optimify-mediportal-components";
import { LinkTo } from "../../../../../../../components/Link";
import { Routes } from "../../../../../../../routes";
import { Button } from "../../../../../../../components/Form/Button/Button";
import React from "react";
import { formatAdvertisementValue } from "../../../_services";
import { useMutation } from "@oasa/amplify-query";
import { updateAdvertisement } from "../../../../../../../graphql/mutations";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import { DialogForm } from "../../../../../../../components/DialogForm";
import { useFormik } from "formik";
import { AdminTextArea } from "./TextArea";
import { SubmitButton } from "src/components/Form/Button/Submit";
import { RowEnd } from "../../../../../../../constants/globalStyles";
import styled from "styled-components";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

export const Approval = ({ job }: { job?: Advertisement | null }) => {
  const history = useHistory();
  const { t } = useTranslation([
    "form",
    "settings",
    "advertisements",
    "common",
  ]);

  const [update] = useMutation<
    UpdateAdvertisementInput,
    UpdateAdvertisementMutation
  >(updateAdvertisement);

  const formik = useFormik<UpdateCompanyInput | any>({
    initialValues: {
      stateDescription: "",
    },
    validationSchema: Yup.object().shape({
      stateDescription: Yup.string().required(t("form:validation.required")),
    }),
    enableReinitialize: true,
    onSubmit: async (values: UpdateCompanyInput) => {
      handleStateChange(AdvertisementState.DISAPPROVED);
    },
  });

  const handleStateChange = async (state: AdvertisementState) => {
    if (!job) return;

    try {
      await update({
        input: {
          id: job?.id,
          stateDescription: formik.values.stateDescription || "",
          state: state,
        },
      });
      message.success(
        t("advertisements:notification.advertisementUpdatedSuccessfully")
      );
      message.info(t("advertisements:approval.processDuration"));
      await history.push(Routes.ADVERTISEMENTS);
    } catch (e) {
      message.error(e);
    }
  };

  if (job?.state === AdvertisementState.APPROVED)
    return <IsApproved>{t("advertisements:approval.isApproved")}</IsApproved>;

  if (job?.state === AdvertisementState.DISAPPROVED)
    return (
      <IsApproved>{t("advertisements:approval.isDisapproved")}</IsApproved>
    );

  if (job?.state === AdvertisementState.WAITING_FOR_APPROVAL)
    return (
      <SpaceBetween>
        <LinkTo to={Routes.ADVERTISEMENTS} title={t("common:button.back")} />
        <Row>
          <DialogForm
            title={t("advertisements:approval.justification")}
            okText={t("advertisements:disapprove")}
            cancelText={t("profile:credits.cancel")}
            formik={formik}
            form={
              <form onSubmit={formik.handleSubmit}>
                <AdminTextArea
                  title={t("advertisements:approval.reasonsForDisapproval")}
                  name="stateDescription"
                  formik={formik}
                />
              </form>
            }
          >
            <Button type="button" variant="outline">
              {t("advertisements:disapprove")}
            </Button>
          </DialogForm>
          <Container>
            <SubmitButton
              type="submit"
              variant="primary"
              onClick={() => handleStateChange(AdvertisementState.APPROVED)}
            >
              {t("advertisements:approve")}
            </SubmitButton>
          </Container>
        </Row>
      </SpaceBetween>
    );
  return <span></span>;
};

const IsApproved = styled(RowEnd)`
  color: ${(props) => props.theme.primary};
`;

const Container = styled.span`
  margin-left: 1em;
`;
