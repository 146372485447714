import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import { Routes } from "./enum";
import { Advertisements } from "../modules/admin/portal/advertisements";
import { Articles } from "../modules/admin/portal/articles";
import { Carousels } from "../modules/admin/portal/carousels";
import { Settings } from "../modules/admin/portal/settings";
import { Taxonomy } from "../modules/admin/portal/taxonomy";
import { Users } from "../modules/admin/portal/users";
import { AdminLayout } from "../components/Layout";
import { Help } from "../modules/admin/portal/help";
import { PrivateRoute } from "./ProtectedRoute";
import { EditAdvertisement } from "../modules/admin/portal/advertisements/advertisementById";
import { CreateArticle } from "../modules/admin/portal/articles/createArticle";
import { CreateCarousel } from "../modules/admin/portal/carousels/createCarousel";
import { CreateCategory } from "../modules/admin/portal/articles/createCategory";
import { CreateAdvertisementSettings } from "../modules/admin/portal/settings/createAdvertisementSetting";
import { CreateServiceSettings } from "../modules/admin/portal/settings/createServiceSetting";
import { CreateAdvertisementSettingType } from "../modules/admin/portal/settings/createAdvertisementSettingType";
import { CreateWorkTypeTaxonomy } from "../modules/admin/portal/taxonomy/createWorkTypeTaxonomy";
import { CreateEmployerTaxonomy } from "../modules/admin/portal/taxonomy/createEmployerTaxonomy";
import { Groups } from "../modules/admin/portal/signIn";
import { ProfileSettings } from "../modules/admin/portal/profile";
import { CreateSuitableForTaxonomy } from "../modules/admin/portal/taxonomy/createSuitableForTaxonomy";

const AdminRoutes = () => {
  return (
    <AdminLayout>
      <Switch>
        <PrivateRoute
          path={Routes.ADVERTISEMENTS}
          component={Advertisements}
          exact={true}
          groups={[Groups.SUPER_ADMIN, Groups.ADMIN, Groups.EDITOR]}
        />
        <PrivateRoute
          path={Routes.EDIT_ADVERTISEMENT}
          component={EditAdvertisement}
          exact={true}
          groups={[Groups.SUPER_ADMIN, Groups.ADMIN, Groups.EDITOR]}
        />
        <PrivateRoute
          path={Routes.ARTICLES}
          component={Articles}
          exact={true}
          groups={[Groups.SUPER_ADMIN, Groups.ADMIN, Groups.PUBLICIST]}
        />
        <PrivateRoute
          path={Routes.CREATE_ARTICLE}
          component={CreateArticle}
          exact={true}
          groups={[Groups.SUPER_ADMIN, Groups.ADMIN, Groups.PUBLICIST]}
        />
        <PrivateRoute
          path={Routes.CAROUSELS}
          component={Carousels}
          exact={true}
          groups={[Groups.SUPER_ADMIN, Groups.ADMIN]}
        />
        <PrivateRoute
          path={Routes.CREATE_CAROUSEL}
          component={CreateCarousel}
          exact={true}
          groups={[Groups.SUPER_ADMIN, Groups.ADMIN]}
        />
        <PrivateRoute
          path={Routes.CREATE_CATEGORY}
          component={CreateCategory}
          exact={true}
          groups={[Groups.SUPER_ADMIN, Groups.ADMIN, Groups.PUBLICIST]}
        />
        <PrivateRoute
          path={Routes.CREATE_ADVERTISEMENT_SETTING}
          component={CreateAdvertisementSettings}
          exact={true}
          groups={[Groups.SUPER_ADMIN, Groups.ADMIN]}
        />
        <PrivateRoute
          path={Routes.CREATE_SERVICE_SETTING}
          component={CreateServiceSettings}
          exact={true}
          groups={[Groups.SUPER_ADMIN, Groups.ADMIN]}
        />
        <PrivateRoute
          path={Routes.CREATE_ADVERTISEMENT_SETTING_TYPE}
          component={CreateAdvertisementSettingType}
          exact={true}
          groups={[Groups.SUPER_ADMIN, Groups.ADMIN]}
        />
        <PrivateRoute
          path={Routes.SETTINGS}
          component={Settings}
          exact={true}
          groups={[Groups.SUPER_ADMIN, Groups.ADMIN]}
        />
        <PrivateRoute
          path={Routes.CREATE_WORKER_TYPE_TAXONOMY}
          component={CreateWorkTypeTaxonomy}
          exact={true}
          groups={[Groups.SUPER_ADMIN, Groups.ADMIN]}
        />
        <PrivateRoute
          path={Routes.EDIT_WORKER_TYPE_TAXONOMY}
          component={CreateWorkTypeTaxonomy}
          exact={true}
          groups={[Groups.SUPER_ADMIN, Groups.ADMIN]}
        />
        <PrivateRoute
          path={Routes.CREATE_SUITABLE_FOR_TAXONOMY}
          component={CreateSuitableForTaxonomy}
          exact={true}
          groups={[Groups.SUPER_ADMIN, Groups.ADMIN]}
        />
        <PrivateRoute
          path={Routes.EDIT_SUITABLE_FOR_TAXONOMY}
          component={CreateSuitableForTaxonomy}
          exact={true}
          groups={[Groups.SUPER_ADMIN, Groups.ADMIN]}
        />
        <PrivateRoute
          path={Routes.CREATE_EMPLOYER_TAXONOMY}
          component={CreateEmployerTaxonomy}
          exact={true}
          groups={[Groups.SUPER_ADMIN, Groups.ADMIN]}
        />
        <PrivateRoute
          path={Routes.EDIT_EMPLOYER_TAXONOMY}
          component={CreateEmployerTaxonomy}
          exact={true}
          groups={[Groups.SUPER_ADMIN, Groups.ADMIN]}
        />
        <PrivateRoute
          path={Routes.TAXONOMY}
          component={Taxonomy}
          exact={true}
          groups={[Groups.SUPER_ADMIN, Groups.ADMIN]}
        />
        <PrivateRoute
          path={Routes.USERS}
          component={Users}
          exact={true}
          groups={[Groups.SUPER_ADMIN, Groups.ADMIN]}
        />
        <PrivateRoute
          path={Routes.HELP}
          component={Help}
          exact={true}
          groups={[Groups.SUPER_ADMIN, Groups.ADMIN]}
        />
        <PrivateRoute
          path={Routes.PORTAL}
          component={Advertisements}
          exact={true}
          groups={[Groups.SUPER_ADMIN, Groups.ADMIN, Groups.EDITOR]}
        />
        <PrivateRoute
          path={Routes.PROFILE_SETTINGS}
          component={ProfileSettings}
          exact={true}
          groups={[
            Groups.SUPER_ADMIN,
            Groups.ADMIN,
            Groups.EDITOR,
            Groups.PUBLICIST,
          ]}
        />
      </Switch>
    </AdminLayout>
  );
};

export { Routes, AdminRoutes };
