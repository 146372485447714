import React, { useEffect } from "react";
import { Card, Col, Row, Select } from "optimify-mediportal-components";
import AdminInput from "src/components/Form/AdminInput";
import { Routes } from "../../../../../routes";
import { EditEntityLayout } from "../../../../../components/Layout/EditEntityLayout";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FooterButtons } from "../../../../../components/Form/FooterButtons";
import { routeTo } from "../../../../../utils/createRoute";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "../../../../../utils/useQuery";
import {
  CreateAdvertisementTypeInput,
  EntityType,
  GetAdvertisementTypeQuery,
} from "../../../../../API";
import { getAdvertisementType } from "../../../../../graphql/queries";
import { handleSubmit } from "./_services";
import { Form } from "../../../../../constants/globalStyles";
import { SettingPage } from "../index";
import { ContentEditor } from "../../../../../components/Form/Editor";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { Switch } from "src/components/Form/Switch";
import { CheckboxGroup } from "src/components/Form/CheckBox";
import { ApprovalRequired } from "../../../../../types/_types";
import { FormSelect } from "../../../../../components/Form/Select";

export const CreateAdvertisementSettingType = () => {
  const { t } = useTranslation(["common", "settings"]);
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const { mutation, data, mutationLoading } =
    useQuery<GetAdvertisementTypeQuery>(getAdvertisementType, {
      id: id,
    });

  const formik = useFormik<CreateAdvertisementTypeInput>({
    initialValues: data?.getAdvertisementType
      ? {
          id: data?.getAdvertisementType?.id,
          name: data?.getAdvertisementType?.name,
          components: data?.getAdvertisementType?.components
            ? JSON.parse(data?.getAdvertisementType?.components)
            : null,
          approvalRequired: data?.getAdvertisementType?.approvalRequired,
          active: data?.getAdvertisementType?.active,
          entity: data?.getAdvertisementType?.entity,
          order: data?.getAdvertisementType?.order,
          jobDescription: "",
        }
      : {
          name: "",
          jobDescription: "",
          active: true,
          components: null,
          approvalRequired: ApprovalRequired.NO,
          order: 1,
          entity: EntityType.ADVERTISEMENT_TYPE,
        },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(2, t("form:validation.toShort", { count: 2 }))
        .max(50, t("form:validation.toLong", { count: 50 }))
        .required(t("form:validation.required")),
      approvalRequired: Yup.string().required(t("form:validation.required")),
      components: Yup.array()
        .nullable()
        .required(t("form:validation.checkboxAtLeastOneRequired")),
      order: Yup.number()
        .min(1, t("form:validation.toShort", { count: 1 }))
        .max(100000, t("form:validation.toLong", { count: 100000 }))
        .required(t("form:validation.required")),
    }),
    onSubmit: async (values) => {
      await handleSubmit(values, mutation, history, id);
    },
  });

  useEffect(() => {
    const jobDescription = data?.getAdvertisementType?.jobDescription;
    if (jobDescription) {
      const content =
        jobDescription && jobDescription != ""
          ? EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(jobDescription as any) as any
              )
            )
          : "";

      formik.setFieldValue("jobDescription", content);
    }
  }, [data?.getAdvertisementType]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <EditEntityLayout
        title={t(
          id
            ? "settings:editAdvertisementsType"
            : "settings:createAdvertisementsType"
        )}
        footer={
          <FooterButtons
            onClick={() =>
              routeTo(history, Routes.SETTINGS, [
                SettingPage.AdvertisementsTypeSettings,
              ])
            }
            loading={mutationLoading}
          />
        }
        titleRightSection={<Switch name={"active"} formik={formik} />}
      >
        <Row>
          <Col>
            <Card padding fullHeight>
              <AdminInput
                type="text"
                title={t("common:table.name")}
                name="name"
                formik={formik}
              />
              <AdminInput
                type="number"
                title={t("common:table.order")}
                name="order"
                formik={formik}
              />

              <ContentEditor
                name="jobDescription"
                formik={formik}
                label={t("settings:jobPositionDescription")}
              />
            </Card>
          </Col>
          <Col>
            <Card padding>
              <FormSelect
                title={t("common:table.approvalRequired")}
                placeholder={t("common:table.approvalRequired")}
                name="approvalRequired"
                formik={formik}
                options={[
                  {
                    value: ApprovalRequired.YES,
                    title: t("common:checkbox.yes"),
                  },
                  {
                    value: ApprovalRequired.NO,
                    title: t("common:checkbox.no"),
                  },
                ]}
              />
              <CheckboxGroup
                name={"components"}
                formik={formik}
                title={t("settings:components")}
                options={[
                  {
                    value: "suitableFor",
                    label: t("settings:checkbox.suitableFor"),
                  },
                  {
                    value: "workerType",
                    label: t("settings:checkbox.workerType"),
                  },
                  {
                    value: "leadingPosition",
                    label: t("settings:checkbox.leadingPosition"),
                  },
                  {
                    value: "residentialPlace",
                    label: t("settings:checkbox.residentialPlace"),
                  },
                  {
                    value: "salaryFrom",
                    label: t("settings:checkbox.salaryFrom"),
                  },
                  {
                    value: "salaryTo",
                    label: t("settings:checkbox.salaryTo"),
                  },
                  {
                    value: "salarySpecifiedInText",
                    label: t("settings:checkbox.salarySpecifiedInText"),
                  },
                  {
                    value: "jobType",
                    label: t("settings:checkbox.jobType"),
                  },
                  {
                    value: "typeOfCooperation",
                    label: t("settings:checkbox.typeOfCooperation"),
                  },
                  {
                    value: "financialConditions",
                    label: t("settings:checkbox.financialConditions"),
                  },
                  {
                    value: "typeOfInternship",
                    label: t("settings:checkbox.typeOfInternship"),
                  },
                ]}
                all
              />
            </Card>
          </Col>
        </Row>
      </EditEntityLayout>
    </Form>
  );
};
