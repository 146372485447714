import React from "react";
import { AdminInput, Card, Col, Row } from "optimify-mediportal-components";
import { Routes } from "../../../../../routes";
import { EditEntityLayout } from "../../../../../components/Layout/EditEntityLayout";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FooterButtons } from "../../../../../components/Form/FooterButtons";
import { routeTo } from "../../../../../utils/createRoute";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "../../../../../utils/useQuery";
import {
  CreateAdvertisementSettingInput,
  EntityType,
  GetAdvertisementSettingQuery,
  ListAdvertisementTypesQuery,
  PublicAdvertisementSetting,
} from "../../../../../API";
import {
  getAdvertisementSetting,
  listAdvertisementTypes,
} from "../../../../../graphql/queries";
import { handleSubmit } from "./_services";
import { Form } from "../../../../../constants/globalStyles";
import { SettingPage } from "../index";
import { FormSelect } from "../../../../../components/Form/Select";
import { AdminTextArea } from "../../../../../components/Form/TextArea";
import { AppliedTo } from "./components/AppliedTo";
import { Switch } from "../../../../../components/Form/Switch";

export const CreateAdvertisementSettings = () => {
  const { t } = useTranslation(["common", "settings"]);
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const { mutation, data, mutationLoading } =
    useQuery<GetAdvertisementSettingQuery>(getAdvertisementSetting, {
      id: id,
    });
  const advertisementTypes = useQuery<ListAdvertisementTypesQuery>(
    listAdvertisementTypes
  );
  const formik = useFormik<CreateAdvertisementSettingInput>({
    initialValues: data?.getAdvertisementSetting
      ? {
          id: data?.getAdvertisementSetting?.id,
          internalName: data?.getAdvertisementSetting?.internalName,
          name: data?.getAdvertisementSetting?.name,
          description: data?.getAdvertisementSetting?.description,
          creditsCount: data?.getAdvertisementSetting?.creditsCount,
          reactivationCreditsCount:
            data?.getAdvertisementSetting?.reactivationCreditsCount,
          numberOfDays: data?.getAdvertisementSetting?.numberOfDays,
          advertisementTypeID:
            data?.getAdvertisementSetting?.advertisementTypeID,
          appliedTo: data?.getAdvertisementSetting?.appliedTo
            ? JSON.parse(data?.getAdvertisementSetting?.appliedTo)
            : null,
          active: data?.getAdvertisementSetting?.active,
          entity: data?.getAdvertisementSetting?.entity,
          order: data?.getAdvertisementSetting?.order,
          public: data?.getAdvertisementSetting?.public,
        }
      : {
          internalName: "",
          name: "",
          description: "",
          creditsCount: 1000,
          reactivationCreditsCount: 500,
          numberOfDays: 30,
          advertisementTypeID: "",
          appliedTo: "",
          active: true,
          order: 1,
          entity: EntityType.ADVERTISEMENT_SETTING,
          public: PublicAdvertisementSetting.PUBLIC,
        },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      advertisementTypeID: Yup.string().required(t("form:validation.required")),
      name: Yup.string()
        .min(3, t("form:validation.toShort", { count: 3 }))
        .max(100, t("form:validation.toLong", { count: 100 }))
        .required(t("form:validation.required")),
      internalName: Yup.string()
        .min(3, t("form:validation.toShort", { count: 3 }))
        .max(100, t("form:validation.toLong", { count: 100 }))
        .required(t("form:validation.required")),
      description: Yup.string()
        .min(3, t("form:validation.toShort", { count: 3 }))
        .max(200, t("form:validation.toLong", { count: 200 }))
        .required(t("form:validation.required")),
      order: Yup.number()
        .min(1, t("form:validation.toShort", { count: 1 }))
        .max(50, t("form:validation.toLong", { count: 50 }))
        .required(t("form:validation.required")),
      creditsCount: Yup.number()
        .min(0, t("form:validation.toShort", { count: 0 }))
        .max(100000, t("form:validation.toLong", { count: 100000 }))
        .required(t("form:validation.required")),
      reactivationCreditsCount: Yup.number()
        .min(0, t("form:validation.toShort", { count: 0 }))
        .max(100000, t("form:validation.toLong", { count: 100000 }))
        .required(t("form:validation.required")),
      numberOfDays: Yup.number()
        .min(0, t("form:validation.toShort", { count: 0 }))
        .max(100000, t("form:validation.toLong", { count: 100000 }))
        .required(t("form:validation.required")),
    }),
    onSubmit: async (values) => {
      await handleSubmit(values, mutation, history, id);
    },
  });
  return (
    <Form onSubmit={formik.handleSubmit}>
      <EditEntityLayout
        title={t(
          id ? "settings:editAdvertisements" : "settings:createAdvertisements"
        )}
        footer={
          <FooterButtons
            onClick={() =>
              routeTo(history, Routes.SETTINGS, [
                SettingPage.AdvertisementsSettings,
              ])
            }
            loading={mutationLoading}
          />
        }
        titleRightSection={<Switch name={"active"} formik={formik} />}
      >
        <Row>
          <Col>
            <Card padding fullHeight>
              <FormSelect
                title={t("common:input.advertisementsType")}
                placeholder={t("common:input.advertisementsType")}
                name="advertisementTypeID"
                formik={formik}
                options={
                  advertisementTypes?.data?.listAdvertisementTypes?.items?.map(
                    (value, index) => {
                      return {
                        value: value?.id,
                        title: value?.name,
                      };
                    }
                  ) || []
                }
                showSearch
              />
              <AdminInput
                type="text"
                title={t("common:table.internalName")}
                name="internalName"
                formik={formik}
              />
              <AdminInput
                type="text"
                title={t("common:table.name")}
                name="name"
                formik={formik}
              />
              <AdminTextArea
                title={t("common:table.description")}
                name="description"
                formik={formik}
              />
              <AdminInput
                type="number"
                title={t("common:table.order")}
                name="order"
                formik={formik}
              />
            </Card>
          </Col>
          <Col>
            <Card padding>
              <AdminInput
                type="number"
                title={t("common:table.creditsCount")}
                name="creditsCount"
                formik={formik}
              />
              <AdminInput
                type="number"
                title={t("common:table.reactivationPrice")}
                name="reactivationCreditsCount"
                formik={formik}
              />
              <AdminInput
                type="number"
                title={t("common:table.numberOfDays")}
                name="numberOfDays"
                formik={formik}
                required
              />
              <AppliedTo formik={formik} />
            </Card>
          </Col>
        </Row>
      </EditEntityLayout>
    </Form>
  );
};
