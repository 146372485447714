import styled from "styled-components";

export const ButtonStyles = (props: any) => `
    display: flex;
    flex-direction: row;
    align-items: center;

    ${props.small ? "" : "min-width: 160px;"}

    padding: 8px ${props.small ? "10px" : "16px"};

    background-color: ${
      props.variant === "primary"
        ? props.disabled
          ? props.theme.background.gray
          : props.theme.primary
        : "transparent"
    };
    border: 1px solid ${
      props.disabled
        ? props.theme.background.gray
        : props.variant === "plain"
        ? "transparent"
        : props.theme.primary
    };
    border-radius: ${
      props.tab
        ? "0"
        : props.tabStart
        ? "4px 0 0 4px"
        : props.tabEnd
        ? "0 4px 4px 0"
        : "4px"
    };
    ${(props.tab || props.tabEnd) && "border-left: 0;"}
    box-shadow: ${
      props.variant === "plain" || props.disabled
        ? "none"
        : "0px 1px 8px rgba(0, 0, 0, 0.05)"
    };
    color: ${
      props.variant === "primary"
        ? "#fff"
        : props.disabled
        ? props.theme.background.gray
        : props.theme.primary
    };
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;

    ${!props.disabled && "cursor: pointer;"}
    transition: all .3s ease-out, margin 0 linear;

    ${
      !props.disabled &&
      `&:hover {
        background-color: ${
          props.variant === "primary"
            ? props.theme.primaryHover
            : props.theme.primaryLight
        };
        color: ${
          props.variant === "primary" ? "#fff" : props.theme.primaryHover
        };
    }`
    }

    & + a, & + button, & + input {
        margin-left: ${
          props.tab || props.tabStart || props.tabEnd ? "0" : "8px"
        };
    }
`;

export const ChildrenWrapper = styled.div`
  display: inline-block;
  width: 100%;
`;

export const LoaderWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin: -2px 8px 0 0;
`;

export const Icon = styled.button<{ small?: boolean }>`
  cursor: pointer;
  margin-top: -2px;
  margin-right: 4px;
  border: 0;
  background: transparent;
  min-width: ${(props) => (props.small ? "0" : "20px")};
`;
