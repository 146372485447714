import React from "react";
import { Advertisement } from "src/API";
import {
  ColumnContainer,
  Container,
  Description,
  MainContact,
  Name,
} from "./_styles";

export const PersonalContact = ({ job }: { job?: Advertisement | null }) => {
  return (
    <ColumnContainer>
      <Container>
        <MainContact>
          <Name>
            {job?.contactFirstDegree} {job?.contactForename}{" "}
            {job?.contactSurname} {job?.contactSecondDegree}
          </Name>
          {job?.contactPhoneNumber && (
            <Description>m: {job?.contactPhoneNumber}</Description>
          )}
          {job?.contactEmail && (
            <Description>e: {job?.contactEmail}</Description>
          )}
        </MainContact>
      </Container>
    </ColumnContainer>
  );
};
