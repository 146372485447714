import React from "react";
import { Row, SpaceBetween, Title } from "optimify-mediportal-components";
import _ from "lodash";
import { Routes } from "../../../../../routes";
import { EditEntityLayout } from "../../../../../components/Layout/EditEntityLayout";
import { useHistory, useParams } from "react-router-dom";
import { LinkTo } from "../../../../../components/Link";
import { useMutation, useQuery } from "@oasa/amplify-query";
import { getAdvertisement, suitableForByCountry } from "src/graphql/queries";
import {
  Advertisement,
  Country,
  GetAdvertisementQuery,
  SuitableForByCountryQuery,
  UpdateAdvertisementInput,
  UpdateAdvertisementMutation,
} from "src/API";
import {
  Body,
  BodyWYSIWYG,
  Container,
  DescriptionContainer,
  DescriptionContainerColumn,
  DescriptionTitle,
  DescriptionValue,
  Header,
  Href,
  LogoContainer,
  Wrapper,
} from "./_styles";
import { useTranslation } from "react-i18next";
import { Contact } from "./components/contact";
import { RowCenter } from "src/constants/globalStyles";
import { ProfileInfo } from "./components/profile";
import { formatCurrency } from "src/utils/formatCurrency";
import { OfferSize } from "src/types/_types";
import { Taxonomy } from "./components/taxonomy";
import { mapTaxonomy } from "./_services";
import { Button } from "src/components/Form/Button/Button";
import { SubmitButton } from "src/components/Form/Button/Submit";
import { AdvertisementState } from "../components/_types";
import { updateAdvertisement } from "../../../../../graphql/mutations";
import { formatAdvertisementValue } from "../_services";
import { Approval } from "./components/approval";

export const EditAdvertisement = () => {
  const { t } = useTranslation(["form", "settings", "advertisement", "common"]);
  const { id } = useParams<{
    id?: string;
  }>();

  const { data, loading } = useQuery<GetAdvertisementQuery>(getAdvertisement, {
    id: id,
  });

  const job = data?.getAdvertisement;

  const listSuitableForByCountry = useQuery<SuitableForByCountryQuery>(
    suitableForByCountry,
    {
      limit: 1000,
      country: Country.EU,
    }
  );

  const listSuitableForByCountryData =
    listSuitableForByCountry?.data.suitableForByCountry?.items;

  const { suitableFor } = mapTaxonomy(
    listSuitableForByCountryData,
    job as Advertisement
  );

  const components = job?.advertisementSetting?.advertisementType?.components
    ? JSON.parse(job?.advertisementSetting?.advertisementType?.components)
    : null;

  return (
    <EditEntityLayout
      loading={loading}
      titleNode={
        <span>
          {t("advertisements:edit")}
          {process.env.REACT_APP_CLIENT_WEB_ADVERTISEMENT_DETAIL && job?.id && (
            <Href
              href={`${process.env.REACT_APP_CLIENT_WEB_ADVERTISEMENT_DETAIL}?id=${job?.id}`}
              target={"_blank"}
            >
              {job?.jobPosition}
            </Href>
          )}
        </span>
      }
      footer={<Approval job={job as Advertisement} />}
    >
      <Container>
        <Header>
          <Wrapper>
            <Title>{job?.jobPosition}</Title>
            <RowCenter>
              <LogoContainer>
                <ProfileInfo
                  imgKey={
                    job?.company?.id
                      ? job.company?.profileImage
                      : job?.profile?.profileImage
                  }
                />
              </LogoContainer>
            </RowCenter>
          </Wrapper>
          <Wrapper>
            <DescriptionContainer>
              {_.includes(components, "leadingPosition") &&
                job?.leadingPosition && (
                  <DescriptionContainerColumn>
                    <DescriptionTitle>
                      {t("settings:checkbox.leadingPosition")}
                    </DescriptionTitle>
                    <DescriptionValue>
                      {t("common:checkbox.yes")}
                    </DescriptionValue>
                  </DescriptionContainerColumn>
                )}
              {_.includes(components, "residentialPlace") &&
                job?.residentialPlace && (
                  <DescriptionContainerColumn>
                    <DescriptionTitle>
                      {t("settings:checkbox.residentialPlace")}
                    </DescriptionTitle>
                    <DescriptionValue>
                      {t("form:checkbox.yes")}
                    </DescriptionValue>
                  </DescriptionContainerColumn>
                )}
              <DescriptionContainerColumn>
                <DescriptionTitle>{t("form:input.place")}</DescriptionTitle>
                <DescriptionValue>{job?.place}</DescriptionValue>
              </DescriptionContainerColumn>
              {_.includes(components, "jobType") && (
                <DescriptionContainerColumn>
                  <DescriptionTitle>
                    {t("settings:checkbox.jobType")}
                  </DescriptionTitle>
                  <DescriptionValue>
                    {job?.employmentTypesStringified &&
                      JSON.parse(job?.employmentTypesStringified || "")
                        ?.map((e: string) => t(`form:checkbox.${e}`))
                        .join(", ")}
                  </DescriptionValue>
                </DescriptionContainerColumn>
              )}
              {_.includes(components, "salaryFrom") &&
                _.includes(components, "salaryTo") && (
                  <DescriptionContainerColumn>
                    <DescriptionTitle>
                      {t("common:checkbox.salary")}
                    </DescriptionTitle>
                    <DescriptionValue>
                      {`${formatCurrency(job?.salaryFrom)} - ${formatCurrency(
                        job?.salaryTo
                      )}`}
                    </DescriptionValue>
                  </DescriptionContainerColumn>
                )}
              {_.includes(components, "salarySpecifiedInText") &&
                job?.salarySpecifiedInText && (
                  <DescriptionContainerColumn>
                    <DescriptionTitle>
                      {t("common:checkbox.salarySpecifiedInText")}
                    </DescriptionTitle>
                    <DescriptionValue>
                      {t("common:checkbox.yes")}
                    </DescriptionValue>
                  </DescriptionContainerColumn>
                )}
              {_.includes(components, "typeOfCooperation") && (
                <DescriptionContainerColumn>
                  <DescriptionTitle>
                    {t("settings:checkbox.typeOfCooperation")}
                  </DescriptionTitle>
                  <DescriptionValue>
                    {job?.typeOfCooperation === "oneOffAid"
                      ? t("advertisements:select.oneOffAid")
                      : t("advertisements:select.longTermAssistance")}
                  </DescriptionValue>
                </DescriptionContainerColumn>
              )}
              {_.includes(components, "typeOfCooperation") && (
                <DescriptionContainerColumn>
                  <DescriptionTitle>
                    {t("advertisements:mainPage.typeOfCooperation")}
                  </DescriptionTitle>
                  <DescriptionValue>
                    {job?.typeOfInternship === "studentInternship"
                      ? t("advertisements:select.studentInternship")
                      : job?.typeOfInternship === "specialInternship"
                      ? t("advertisements:select.specialInternship")
                      : job?.typeOfInternship === "lifelongEducation"
                      ? t("advertisements:select.lifelongEducation")
                      : t("advertisements:select.otherInternship")}
                  </DescriptionValue>
                </DescriptionContainerColumn>
              )}
              {_.includes(components, "financialConditions") && (
                <DescriptionContainerColumn>
                  <DescriptionTitle>
                    {t("advertisements:mainPage.financialConditions")}
                  </DescriptionTitle>
                  <DescriptionValue>
                    {job?.financialConditions === "studentInternship"
                      ? t("form:select.oneOffAid")
                      : job?.financialConditions === "specialInternship"
                      ? t("form:select.paid")
                      : job?.financialConditions === "lifelongEducation"
                      ? t("form:select.rewarded")
                      : t("form:select.forDiscussion")}
                  </DescriptionValue>
                </DescriptionContainerColumn>
              )}
            </DescriptionContainer>
          </Wrapper>
          <DescriptionContainer>
            {_.includes(components, "suitableFor") && (
              <DescriptionContainerColumn>
                <Taxonomy
                  taxonomy={suitableFor}
                  size={OfferSize.SMALL}
                  label={t("form:input.suitableFor")}
                />
              </DescriptionContainerColumn>
            )}
          </DescriptionContainer>
        </Header>
        <Body>
          <BodyWYSIWYG
            dangerouslySetInnerHTML={{ __html: job?.jobDescription || "" }}
          />
          <Contact job={job as Advertisement} />
        </Body>
      </Container>
    </EditEntityLayout>
  );
};
