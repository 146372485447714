import React from "react";
import { Col, Row, SpaceBetween } from "optimify-mediportal-components";
import styled from "styled-components";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { LoadingLayout } from "../LoadingLayout";

export const EditEntityLayout = ({
  title,
  titleNode,
  children,
  footer,
  titleRightSection,
  hideBackButton,
  loading,
}: {
  title?: string;
  titleNode?: React.ReactNode;
  children: React.ReactNode;
  footer: React.ReactNode;
  titleRightSection?: React.ReactNode;
  hideBackButton?: boolean;
  loading?: boolean;
}) => {
  const history = useHistory();
  return (
    <LoadingLayout loading={loading}>
      <Column>
        <Header>
          <SpaceBetween>
            <Row>
              {!hideBackButton && (
                <Back onClick={() => history.goBack()}>
                  <FontAwesomeIcon icon={faArrowLeft} size={"lg"} />
                </Back>
              )}
              {title && <Title>{title}</Title>}
              {titleNode && <Title>{titleNode}</Title>}
            </Row>
            {titleRightSection && titleRightSection}
          </SpaceBetween>
        </Header>
        <Main>{children}</Main>
        <Footer>{footer}</Footer>
      </Column>
    </LoadingLayout>
  );
};

const Column = styled(Col)`
  display: flex;
  flex-direction: column;

  height: 100%;

  padding: 0;
`;

const Back = styled.div`
  display: flex;
  align-items: center;

  color: ${(props) => props.theme.primary};
  cursor: pointer;
  margin-right: 1em;
`;

const Title = styled.span`
  font-size: 1.5em;

  color: ${(props) => props.theme.primary};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 1em 1.5em;

  width: 100%;

  > div {
    width: 100%;
  }

  margin-bottom: 7px;
  border-bottom: 1px solid ${(props) => props.theme.border.primary};
`;

const Main = styled.div`
  margin: 0 1em 1.5em 1em;
`;

const Footer = styled.div`
  padding: 2em 1.9em 1em 1.9em;
`;
