export const searchAdvertisements = /* GraphQL */ `
  query SearchAdvertisements(
    $filter: SearchableAdvertisementFilterInput
    $sort: SearchableAdvertisementSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchAdvertisements(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        jobPosition
        active
        state
        paymentState
        place
        advertisementSettingID
        advertisementTypeID
        companyID
        profileID
        expirationDate
        createdAt
        updatedAt
        profile {
          email
        }
        company {
          name
        }
        owner
      }
      nextToken
      total
    }
  }
`;
