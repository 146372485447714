import React, { useEffect } from "react";
import { connectAll } from "../_services";
import { Element } from "./Element";
import { useQuery } from "../../../../../utils/useQuery";
import {
  Country,
  WorkerTypeEdge,
  WorkerTypesByCountryQuery,
} from "../../../../../API";
import {
  Column,
  Container,
  ContainerTitle,
  Initial,
  Line,
  ScrollBox,
  StyledRow,
  Svg,
} from "../_styles";
import { workerTypesByCountry } from "../graphql/queries";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Button } from "optimify-mediportal-components";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../../../routes";
import { routeTo } from "../../../../../utils/createRoute";
import { deleteWorkerType } from "../../../../../graphql/mutations";
import { LoadingLayout } from "../../../../../components/Layout/LoadingLayout";
// @ts-ignore
import DoubleScrollbar from "react-double-scrollbar";

export const WorkType = ({ country }: { country: Country }) => {
  const { t } = useTranslation(["common", "taxonomy"]);
  const history = useHistory();

  const { loading, data, error, refetch, mutation } =
    useQuery<WorkerTypesByCountryQuery>(workerTypesByCountry, {
      country: country,
      limit: 1000,
    });

  useEffect(() => {
    connectAll();
  });

  const groupedWorkersByLevel =
    _.groupBy(data.workerTypesByCountry?.items, (e: any) => e?.level) || null;

  return (
    <LoadingLayout loading={loading}>
      <Column>
        <ScrollBox>
          <StyledRow
            arrayCount={Object.entries(groupedWorkersByLevel).length || 0}
          >
            {Object.entries(groupedWorkersByLevel).length !== 0 ? (
              Object.keys(groupedWorkersByLevel).map(
                (key: string, index: number) => {
                  const items: any = groupedWorkersByLevel[key];

                  return (
                    <Container key={index}>
                      <ContainerTitle>
                        {key}. {t("taxonomy:level")}
                      </ContainerTitle>
                      {items && items?.length > 0 ? (
                        _.orderBy(items, (e) => e.order)?.map(
                          (item: any, itIndex: number) => {
                            return (
                              <>
                                <Element
                                  id={item?.id}
                                  title={item?.name}
                                  level={item?.level}
                                  country={country}
                                  mutation={mutation}
                                  refetch={refetch}
                                  objectKey={key}
                                  index={itIndex}
                                  createRoute={
                                    Routes.CREATE_WORKER_TYPE_TAXONOMY
                                  }
                                  editRoute={Routes.EDIT_WORKER_TYPE_TAXONOMY}
                                  deleteQuery={deleteWorkerType}
                                />
                                <Svg>
                                  {item.childEdges.items?.map(
                                    (line: WorkerTypeEdge, index: number) => {
                                      return (
                                        <Line
                                          id={`${item?.id}:${line?.childNodeId}`}
                                          className={"svgLine"}
                                          key={index}
                                        />
                                      );
                                    }
                                  )}
                                </Svg>
                              </>
                            );
                          }
                        )
                      ) : (
                        <></>
                      )}
                    </Container>
                  );
                }
              )
            ) : (
              <Initial>
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    routeTo(history, Routes.CREATE_WORKER_TYPE_TAXONOMY, [
                      country,
                      0,
                    ]);
                  }}
                >
                  {t("taxonomy:initialization")}
                </Button>
              </Initial>
            )}
          </StyledRow>
        </ScrollBox>
      </Column>
    </LoadingLayout>
  );
};
