import { useQuery } from "@oasa/amplify-query";
import { AdvertisementsByProfileQuery } from "src/API";
import { advertisementsByProfile } from "../graphql/queries";

export const PersonalAdvertisementsCount = ({
  profileID,
}: {
  profileID?: string | null;
}) => {
  const { data, loading } = useQuery<AdvertisementsByProfileQuery>(
    advertisementsByProfile,
    {
      profileID: profileID,
    }
  );

  if (loading) <></>;

  if (!data?.advertisementsByProfile?.items) <>0</>;

  return <span>{data?.advertisementsByProfile?.items.length}</span>;
};
