import { MedTable } from "../../../../../../components/Table";
import { Company, Profile } from "../../../../../../API";
import { Name, NameContainer } from "../../_styles";
import { ProfileImage } from "../ProfileImage";
import { DateFormat } from "../../../../../../components/Moment";
import { PersonalAdvertisementsCount } from "../PersonalAdvertisementsCount";
import { Row } from "optimify-mediportal-components";
import { PersonalCredits } from "../PersonalCredits";
import { RemoveButton } from "../../../../../../components/Form/Button/Icons/Remove";
import React from "react";
import { useTranslation } from "react-i18next";

export const Table = ({
  loading,
  removeItem,
  items,
}: {
  loading: boolean;
  removeItem: (id: Profile) => void;
  items: any;
}) => {
  const { t } = useTranslation(["common", "advertisements", "profile"]);
  return (
    <MedTable
      emptyTableText={t("users:privatePersonEmpty")}
      loading={loading}
      columns={[
        {
          title: t("common:table.id"),
          width: "320px",
          dataIndex: "id",
          render: (text: any, record: Profile) => <span>{text}</span>,
        },
        {
          title: t("common:table.forename"),
          dataIndex: "forename",
          render: (text: string, record: Profile) => {
            return (
              <NameContainer>
                <ProfileImage profileImage={record?.profileImage} />
                <Name>{`${record?.forename} ${record?.surname}`}</Name>
              </NameContainer>
            );
          },
        },
        {
          title: t("common:table.email"),
          dataIndex: "email",
          render: (text: any, record: Profile) => <span>{text}</span>,
        },
        {
          title: t("common:table.createdAt"),
          dataIndex: "createdAt",
          render: (text: any, record: Profile) => (
            <span>
              <DateFormat date={text} format={"DD. MM. YYYY"} />
            </span>
          ),
        },
        {
          title: t("common:table.adsCount"),
          render: (text: any, record: Profile) => (
            <PersonalAdvertisementsCount profileID={record?.id} />
          ),
        },
        {
          title: "",
          titleHide: true,
          key: "action",
          render: (_text: any, record: Profile) => (
            <Row>
              <PersonalCredits profileID={record?.id} />
              <RemoveButton
                title={t("profile:removeProfile.title")}
                description={t("profile:removeProfile.description")}
                okText={t("profile:removeProfile.ok")}
                cancelText={t("profile:removeProfile.cancel")}
                handleClick={() => removeItem(record)}
              />
            </Row>
          ),
        },
      ]}
      dataSource={items}
    />
  );
};
