import React, { MouseEventHandler, ReactNode } from "react";
import styled from "styled-components";
import { Loader } from "./Loader";
import { ButtonStyles, ChildrenWrapper, LoaderWrapper } from "./_styles";

type ButtonVariant = "primary" | "outline" | "plain";

interface ButtonProps {
  variant: ButtonVariant;
  children: ReactNode;
  small?: boolean;
  disable?: boolean;
  tab?: boolean;
  tabStart?: boolean;
  tabEnd?: boolean;
  loading?: boolean;
}

interface ButtonElProps {
  type: "button";
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const Button = (props: ButtonProps & ButtonElProps) => {
  return (
    <StyledButton
      onClick={props.onClick}
      variant={props.variant}
      small={props.small}
      disabled={props.disable}
      tab={props.tab}
      tabStart={props.tabStart}
      tabEnd={props.tabEnd}
    >
      <ChildrenWrapper>{props.children}</ChildrenWrapper>
      {props.loading && (
        <LoaderWrapper>
          <Loader size={24} />
        </LoaderWrapper>
      )}
    </StyledButton>
  );
};

const StyledButton = styled.button<{
  variant: ButtonVariant;
  small?: boolean;
  tab?: boolean;
  tabStart?: boolean;
  tabEnd?: boolean;
}>`
  ${(props) => ButtonStyles(props)}
`;
