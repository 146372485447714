import { Column, RowEnd, SpaceBetween } from "src/constants/globalStyles";
import styled from "styled-components";

export const Body = styled.div`
  padding: 2em;
  background-color: ${(props) => props.theme.background.lightGray};
`;

export const BodyWYSIWYG = styled.div`
  column-count: 2;

  span {
    font-family: NeueAlteGrotesk-Regular !important;
    font-size: 0.9em !important;
    background-color: ${(props) => props.theme.background.lightGray} !important;
    color: ${(props) => props.theme.text.blue} !important;
  }

  h2 {
    color: ${(props) => props.theme.text.blue} !important;
  }
`;

export const DescriptionContainer = styled(SpaceBetween)`
  padding: 1em;
  align-items: start;
`;

export const DescriptionContainerColumn = styled(Column)`
  margin-right: 25px;
`;

export const DescriptionTitle = styled.span`
  font-size: 0.7em;
`;

export const DescriptionValue = styled.span`
  font-size: 0.9em;
  font-weight: 700;
`;

export const Wrapper = styled(SpaceBetween)`
  border-bottom: 1px solid ${(props) => props.theme.border.blue};
  padding: 1em;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  border-radius: 12px;
  background-color: ${(props) => props.theme.background.lightGray};
  color: ${(props) => props.theme.text.blue};
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 20px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 5px solid transparent;
    background-clip: content-box;
    background-color: ${(props) => props.theme.background.blue};
  }
`;

export const Header = styled.div`
  border-radius: 12px 12px 0 0;
  background-color: ${(props) => props.theme.background.lightGreen};
  padding: 2em;
`;

export const Title = styled.div`
  font-family: NeueAlteGrotesk-Bold;
  font-size: 2em;
`;

export const LogoContainer = styled(RowEnd)`
  margin-right: 1em;
`;

export const Logo = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 30px;
  background-color: ${(props) => props.theme.background.blue};
`;

export const Href = styled.a`
  &:hover {
    text-decoration: underline;
  }
`;
