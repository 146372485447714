export const theme = {
  primary: "#1321b1",
  secondary: "#1321b1",
  primaryHover: "#1321b1",
  primaryLightActive: "#1321b1",
  primaryLight: "rgba(148,158,220,0.57)",
  primaryLightest: "rgba(168,174,239,0.51)",
  border: {
    primary: "#ebebeb",
    gray: "#c6c6c6",
    darkerGray: "#909090",
    green: "#19d2b1",
    blue: "#1321b1",
    darkBlue: "#0c1875",
    white: "#fff",
  },
  background: {
    blue: "#1321b1",
    darkBlue: "#132165",
    green: "#19d2b1",
    lightGreen: "rgba(25,210,177,0.5)",
    lightGray: "#ededed",
    darkGray: "#D8D8D8",
    offers: "#f6f6f6",
    gray: "#909090",
    white: "#fff",
  },
  hover: {
    blue: "#132196",
    darkBlue: "#0c1875",
    green: "#19bea0",
    lightGray: "#dcdcdc",
  },
  text: {
    label: "#555555",
    white: "#fff",
    black: "#000",
    gray: "#c6c6c6",
    darkerGray: "#909090",
    blue: "#1321b1",
    green: "#19d2b1",
    lightGreen: "rgba(25,210,177,0.7)",
  },
  button: {
    gray: "#808080",
    lightGray: "#D8D8D8",
  },
};
