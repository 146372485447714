import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Popconfirm } from "antd";
import { Icon } from "../_styles";
import { ConfirmAlert } from "src/components/ConfirmAlert";
import { useTranslation } from "react-i18next";
import { DialogForm } from "../../../DialogForm";
import { useFormik } from "formik";
import { CalendarInput } from "../../Datepicker";
import {
  Advertisement,
  GetAdvertisementQuery,
  UpdateAdvertisementInput,
  UpdateAdvertisementMutation,
} from "../../../../API";
import { useMutation, useQuery } from "@oasa/amplify-query";
import { getAdvertisement } from "../../../../graphql/queries";
import { formatAdvertisementValue } from "../../../../modules/admin/portal/advertisements/_services";
import { updateAdvertisement } from "../../../../graphql/mutations";
import _ from "lodash";

export const EditExpiration = ({
  id,
  refetch,
}: {
  id: string;
  refetch: any;
}) => {
  const { t } = useTranslation(["advertisements"]);

  const { data } = useQuery<GetAdvertisementQuery>(getAdvertisement, {
    id: id,
  });

  const [update] = useMutation<
    UpdateAdvertisementInput,
    UpdateAdvertisementMutation
  >(updateAdvertisement);

  const formik = useFormik<UpdateAdvertisementInput | any>({
    initialValues: formatAdvertisementValue(
      data?.getAdvertisement as Advertisement
    ),
    enableReinitialize: true,
    onSubmit: async (values: UpdateAdvertisementInput) => {
      await update({ input: values });
      _.delay(() => {
        refetch();
      }, 2000);
    },
  });

  return (
    <DialogForm
      title={t("advertisements:expiration.title")}
      okText={t("advertisements:expiration.ok")}
      cancelText={t("advertisements:expiration.cancel")}
      formik={formik}
      form={
        <form onSubmit={formik.handleSubmit}>
          <CalendarInput
            value={formik.values.expirationDate}
            formik={formik}
            name={"expirationDate"}
          />
        </form>
      }
    >
      <Icon>
        <FontAwesomeIcon icon={faClock} color={"#1321b1"} />
      </Icon>
    </DialogForm>
  );
};
