import { useTranslation } from "react-i18next";
import { Title } from "optimify-mediportal-components";
import { CarouselsTable } from "./components/CarouselsTable";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../../routes";
import { LayoutHeader, Wrap } from "../../../../constants/globalStyles";
import { routeTo } from "../../../../utils/createRoute";
import { Button } from "src/components/Form/Button/Button";

export const Carousels = () => {
  const history = useHistory();
  const { t } = useTranslation(["common", "carousel"]);

  return (
    <Wrap>
      <LayoutHeader>
        <Title noMargin>{t("carousel:title")} </Title>
        <Button
          type="button"
          variant="outline"
          onClick={() => routeTo(history, Routes.CREATE_CAROUSEL)}
        >
          {t("carousel:addCarousel")}
        </Button>
      </LayoutHeader>
      <CarouselsTable />
    </Wrap>
  );
};
