import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "optimify-mediportal-components";
import { Routes } from "../../../../../routes";
import { EditEntityLayout } from "../../../../../components/Layout/EditEntityLayout";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "../../../../../utils/useQuery";
import {
  CarouselType,
  CompaniesByStateQuery,
  CreateCarouselInput,
  EntityType,
  GetCarouselQuery,
  ModelSortDirection,
} from "../../../../../API";
import { companiesByState, getCarousel } from "../../../../../graphql/queries";
import { DragAndDrop } from "../../../../../components/Form/File";
import { Form } from "../../../../../constants/globalStyles";
import { routeTo } from "../../../../../utils/createRoute";
import { Storage } from "aws-amplify";
import { handleSubmit } from "./_services";
import { FooterButtons } from "../../../../../components/Form/FooterButtons";
import { FormSelect } from "../../../../../components/Form/Select";
import { Switch } from "src/components/Form/Switch";
import { EntityState } from "../../../../../types/_types";
import { CheckboxGroup } from "src/components/Form/CheckBox";

export enum Advertiser {
  PERSONAL = "PERSONAL",
  COMPANY = "COMPANY",
}

export const CreateCarousel = () => {
  const { t } = useTranslation(["common", "carousel"]);
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const [url, setUrl] = useState<string | null>(null);
  const { mutation, data, mutationLoading } = useQuery<GetCarouselQuery>(
    getCarousel,
    {
      id: id,
    }
  );

  const { data: companies } = useQuery<CompaniesByStateQuery>(
    companiesByState,
    {
      entityState: EntityState.ACTIVE,
      sort: ModelSortDirection.ASC,
      limit: 1000,
    }
  );

  const formik = useFormik<CreateCarouselInput & { advertiser?: string }>({
    initialValues: data?.getCarousel
      ? {
          id: data?.getCarousel?.id,
          title: data?.getCarousel?.title,
          active: data?.getCarousel?.active,
          entity: data?.getCarousel?.entity,
          type: data?.getCarousel?.type,
          image: data?.getCarousel?.image,
          companyID: data?.getCarousel?.companyID,
        }
      : {
          title: "",
          image: "",
          type: CarouselType.GLOBAL,
          entity: EntityType.CAROUSEL,
          companyID: "",
          active: true,
        },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      companyID: Yup.string()
        .min(2, t("form:validation.toShort", { count: 2 }))
        .max(200, t("form:validation.toLong", { count: 200 }))
        .required(t("form:validation.required")),
      image: Yup.string()
        .test("hasImage", t("form:validation.imageRequired"), (value) => {
          if (!value) return false;
          return true;
        })
        .typeError(t("form:validation.imageRequired")),
    }),
    onSubmit: async (values: CreateCarouselInput & { advertiser?: string }) => {
      let formattedValues: any = {
        id: values?.id,
        active: values?.active,
        entity: values?.entity,
        image: values?.image,
        title: values?.title,
        type: values?.type,
        companyID: values?.companyID,
      };
      await handleSubmit(formattedValues, mutation, history, id);
    },
  });
  useEffect(() => {
    const initCarousel = async () => {
      if (data?.getCarousel?.image) {
        const image = await Storage.get(data?.getCarousel?.image);
        setUrl(image as string);
      }
    };

    initCarousel();
  }, [data?.getCarousel]);
  console.log(formik.values);
  return (
    <Form onSubmit={formik.handleSubmit}>
      <EditEntityLayout
        title={t(id ? "carousel:editCarousel" : "carousel:createCarousel")}
        footer={
          <FooterButtons
            onClick={() => routeTo(history, Routes.CAROUSELS)}
            loading={mutationLoading}
          />
        }
        titleRightSection={<Switch name={"active"} formik={formik} />}
      >
        <Row>
          <Col>
            <Card padding>
              <FormSelect
                title={t("carousel:companyName")}
                placeholder={t("carousel:companyName")}
                name="companyID"
                formik={formik}
                options={
                  companies?.companiesByState?.items?.map((e: any) => {
                    return { value: e?.id, title: e?.name };
                  }) || []
                }
                showSearch
              />
              <CheckboxGroup
                name={"type"}
                formik={formik}
                singleChoice
                title={t("carousel:type.title")}
                options={[
                  {
                    value: CarouselType.GLOBAL,
                    label: t("carousel:type.global"),
                  },
                  {
                    value: CarouselType.MAIN_PAGE,
                    label: t("carousel:type.mainPage"),
                  },
                  {
                    value: CarouselType.ARTICLES,
                    label: t("carousel:type.articles"),
                  },
                ]}
              />
              <DragAndDrop
                formik={formik}
                name={"image"}
                url={url}
                imageName={data?.getCarousel?.image}
              />
            </Card>
          </Col>
        </Row>
      </EditEntityLayout>
    </Form>
  );
};
