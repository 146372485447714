import { End, Row, SpaceBetween } from "optimify-mediportal-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { SubmitButton } from "./Button/Submit";
import { Button } from "./Button/Button";
import styled from "styled-components";

export const FooterButtons = ({
  onClick,
  loading,
  formik,
  hideBackButton,
}: {
  onClick?: () => void;
  loading: boolean;
  formik?: any;
  hideBackButton?: boolean;
}) => {
  const { t } = useTranslation(["common"]);

  const submit = () => (
    <SubmitButton
      onClick={formik && formik?.handleSubmit}
      type="submit"
      variant="primary"
      loading={loading}
      disable={loading}
    >
      {t("common:button.save")}
    </SubmitButton>
  );

  if (hideBackButton) {
    return <End>{submit()}</End>;
  }

  if (onClick) {
    return (
      <Wrap>
        <Row>
          <Button onClick={() => onClick()} type={"button"} variant={"plain"}>
            {t("common:button.back")}
          </Button>
        </Row>
        {submit()}
      </Wrap>
    );
  }

  return <div></div>;
};

const Wrap = styled(SpaceBetween)`
  margin-bottom: 2em;
`;
