import { useQuery } from "@oasa/amplify-query";
import { AdvertisementsByCompanyQuery } from "src/API";
import { advertisementsByCompany } from "src/graphql/queries";

export const CompanyAdvertisementsCount = ({
  companyID,
}: {
  companyID?: string | null;
}) => {
  const { data, loading } = useQuery<AdvertisementsByCompanyQuery>(
    advertisementsByCompany,
    {
      companyID: companyID,
    }
  );

  if (loading) <></>;

  if (!data?.advertisementsByCompany?.items) <>0</>;

  return <span>{data?.advertisementsByCompany?.items.length}</span>;
};
