import { useLazyQuery } from "@oasa/amplify-query";
import { UsersByCompanyIDQuery } from "src/API";
import { usersByCompanyID } from "src/graphql/queries";
import { useEffect } from "react";
import _ from "lodash";

export const Owner = ({ companyID }: { companyID?: string | null }) => {
  const [execute, { data, loading }] =
    useLazyQuery<UsersByCompanyIDQuery>(usersByCompanyID);

  useEffect(() => {
    execute({
      companyID: companyID,
      filter: {
        role: { eq: "OWNER" },
      },
    });
  }, []);

  if (loading || !companyID || data?.usersByCompanyID?.items) <></>;

  const owner = _.find(data?.usersByCompanyID?.items);

  return <span>{owner?.email}</span>;
};
