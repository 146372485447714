import React, { useEffect } from "react";
import { Row } from "optimify-mediportal-components";
import { useTranslation } from "react-i18next";
import { Name, NameContainer, SearchUsersWrapper } from "../_styles";
import {
  Company,
  RemoveAllCompanyDataMutation,
  RemoveAllCompanyDataMutationVariables,
  SearchableCompanySortableFields,
  SearchableSortDirection,
  SearchCompanysQuery,
} from "../../../../../API";
import { searchCompanys } from "../graphql/queries";
import { DateFormat } from "../../../../../components/Moment";
import { MedTable } from "src/components/Table";
import { useFormik } from "formik";
import { FormData } from "../_types";
import Search from "src/components/Form/Search";
import { ProfileImage } from "./ProfileImage";
import { CompanyExtraServices } from "./CompanyExtraServices";
import { CompanyUsers } from "./CompanyUsers";
import { CompanyAdvertisementsCount } from "./CompanyAdvertisementsCount";
import { CompanyCredits } from "./CompanyCredits";
import { useLazyQuery, useMutation } from "@oasa/amplify-query";
import { removeAllCompanyData } from "../../../../../graphql/mutations";
import _ from "lodash";
import { RemoveButton } from "src/components/Form/Button/Icons/Remove";
import { EntityState } from "../../../../../types/_types";
import { Owner } from "./Owner";
import { Pagination } from "../../../../../components/Table/Pagination";
import { message } from "antd";

export const Corporation = () => {
  const { t } = useTranslation([
    "common",
    "advertisements",
    "profile",
    "users",
  ]);
  const [page, setPage] = React.useState<number>(0);

  const [remove] = useMutation<
    RemoveAllCompanyDataMutationVariables,
    RemoveAllCompanyDataMutation
  >(removeAllCompanyData);

  const formik = useFormik<FormData>({
    initialValues: {
      name: undefined,
    },
    enableReinitialize: true,
    onSubmit: async (values: FormData) => {},
  });

  useEffect(() => {
    refetch(0);
  }, [formik.values?.name]);

  const mapValue = (value: string | undefined) => {
    if (!value || value.length === 0) {
      return undefined;
    }
    return value;
  };

  const value = mapValue(formik.values?.name);

  const [query, { loading, data, totalPages, nextTokens }] =
    useLazyQuery<SearchCompanysQuery>(searchCompanys);

  const refetch = async (currentPage: number) => {
    console.log("current page", currentPage);
    const token = currentPage ? nextTokens[currentPage] : undefined;
    query({
      filter: !!value
        ? {
            entityState: { eq: EntityState.ACTIVE },
            or: [
              { name: { matchPhrasePrefix: formik.values?.name } },
              { forename: { matchPhrasePrefix: formik.values?.name } },
              { surname: { matchPhrasePrefix: formik.values?.name } },
            ],
          }
        : { entityState: { eq: EntityState.ACTIVE } },
      sort: {
        field: SearchableCompanySortableFields.createdAt,
        direction: SearchableSortDirection.desc,
      },
      name: "searchCompanys",
      limit: 10,
      nextToken: token,
      page: currentPage,
    });
  };

  const removeItem = async (item: Company) => {
    message.info(t("profile:deletingAllCompanyData"), 10);
    const result = await remove({
      companyID: item?.id,
    });
    _.delay(() => {
      refetch(0);
    }, 2000);
  };

  return (
    <>
      <SearchUsersWrapper>
        <Search formik={formik} name={"name"} />
      </SearchUsersWrapper>
      <MedTable
        emptyTableText={t("users:corporationEmpty")}
        loading={loading}
        columns={[
          {
            title: t("common:table.id"),
            width: "320px",
            dataIndex: "id",
            render: (text: any, record: Company) => <span>{text}</span>,
          },
          {
            title: t("common:table.name"),
            dataIndex: "name",
            width: "280px",
            render: (text: any, record: Company) => {
              return (
                <NameContainer>
                  <ProfileImage profileImage={record?.profileImage} />
                  <Name>{text}</Name>
                </NameContainer>
              );
            },
          },
          {
            title: t("common:table.email"),
            width: "250px",
            render: (text: any, record: Company) => (
              <Owner companyID={record?.id} />
            ),
          },
          {
            title: t("common:table.createdAt"),
            dataIndex: "createdAt",
            width: "150px",
            render: (text: any, record: Company) => (
              <span>
                <DateFormat date={text} format={"DD. MM. YYYY"} />
              </span>
            ),
          },
          {
            title: t("common:table.adsCount"),
            width: "150px",
            render: (text: any, record: Company) => (
              <span>
                <CompanyAdvertisementsCount companyID={record?.id} />
              </span>
            ),
          },
          {
            title: t("common:table.extraService"),
            width: "150px",
            render: (text: any, record: Company) => (
              <span>
                <CompanyExtraServices companyID={record?.id} />
              </span>
            ),
          },
          {
            title: t("common:table.companyAccountsCount"),
            width: "200px",
            render: (text: any, record: Company) => (
              <span>
                <CompanyUsers companyID={record.id} />
              </span>
            ),
          },
          {
            title: "",
            key: "action",
            titleHide: true,
            width: "100px",
            render: (_text: any, record: any) => (
              <Row>
                <CompanyCredits companyID={record?.id} refetch={refetch} />
                <RemoveButton
                  title={t("profile:removeCompany.title")}
                  description={t("profile:removeCompany.description")}
                  okText={t("profile:removeCompany.ok")}
                  cancelText={t("profile:removeCompany.cancel")}
                  handleClick={() => removeItem(record)}
                />
              </Row>
            ),
          },
        ]}
        dataSource={data?.searchCompanys?.items || []}
      />
      <Pagination
        totalPages={totalPages}
        page={page}
        setPage={setPage}
        nextTokens={nextTokens}
        customHandler={refetch}
      />
    </>
  );
};
