import { PopOver } from "../../../../../../components/Form/PopOver";
import { CheckboxGroup } from "../../../../../../components/Form/CheckBox";
import React from "react";
import { FormData } from "../_types";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { useQuery } from "../../../../../../utils/useQuery";
import {
  CompaniesByNameQuery,
  Company,
  EntityType,
  ModelSortDirection,
} from "../../../../../../API";
import { companiesByName } from "../../../../../../graphql/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { Row } from "src/constants/globalStyles";

export const CompanyFilter = ({
  formik,
}: {
  formik: FormikProps<FormData>;
}) => {
  const { t } = useTranslation(["form"]);

  const { data } = useQuery<CompaniesByNameQuery>(companiesByName, {
    entity: EntityType.COMPANY,
    sortDirection: ModelSortDirection.ASC,
    limit: 1000,
  });

  const options = (data?.companiesByName?.items as Company[])?.map?.(
    (e: Company) => {
      return { value: e?.id, label: e?.name };
    }
  );

  return (
    <Row>
      <PopOver
        title={t("form:filter.company")}
        content={
          <CheckboxGroup
            formik={formik}
            name={"companyID"}
            customTrigger={() => formik.setFieldValue("profileID", null)}
            options={options || []}
            singleChoice
          />
        }
      />
      {formik.values["companyID"] && (
        <UnSelect onClick={() => formik.setFieldValue("companyID", undefined)}>
          <FontAwesomeIcon icon={faTimes} />
        </UnSelect>
      )}
    </Row>
  );
};

const UnSelect = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.primary};
  margin-top: 8px;
  margin-right: 10px;
`;
