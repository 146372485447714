import greenIcon from "src/images/green-icon.svg";
import React from "react";
import styled from "styled-components";

export const ProfileInfo = ({ imgKey }: { imgKey?: string | null }) => {
  const src =
    process.env.REACT_APP_PUBLIC_AWS_S3 && imgKey
      ? process.env.REACT_APP_PUBLIC_AWS_S3 + imgKey
      : "";

  return (
    <Container>
      {imgKey ? (
        <BackgroundAvatar src={src} />
      ) : (
        <Avatar>
          <img src={greenIcon} alt="Avatar" />
        </Avatar>
      )}
    </Container>
  );
};

const Image = styled.img`
  object-fit: contain;
`;

export const BackgroundAvatar = styled.div<{ src?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-size: contain;
  border-radius: 50%;
  background-image: url(${(props) => props.src});
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.background.blue};
  border-radius: 35px;
  width: 50px;
  height: 50px;

  img {
    width: 28px !important;
  }
`;

export const Container = styled.div``;
