import { Avatar } from "antd";
import greenIcon from "../../../../../images/green-icon.svg";
import React from "react";
import styled from "styled-components";

export const ProfileImage = ({
  profileImage,
}: {
  profileImage: string | undefined | null;
}) => {
  if (process.env.REACT_APP_PUBLIC_AWS_S3 && profileImage) {
    return <Avatar src={process.env.REACT_APP_PUBLIC_AWS_S3 + profileImage} />;
  }
  return (
    <CustomAvatar>
      <img src={greenIcon} alt="Avatar" />
    </CustomAvatar>
  );
};

const CustomAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.background.blue};
  border-radius: 35px;
  width: 32px;
  height: 32px;

  img {
    width: 20px !important;
  }
`;
