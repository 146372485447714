import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Icon, Page, Wrapper } from "./_styles";
import { IconDefinition } from "@fortawesome/free-regular-svg-icons";

export const Pagination = ({
  totalPages,
  page,
  setPage,
  customHandler,
  nextTokens,
}: {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  totalPages: number;
  page: number;
  customHandler?: any;
  nextTokens?: any;
}) => {
  const nextPage = async () => {
    if (nextTokens && !nextTokens[page + 1]) return;
    const nextPage = page + 1;
    await changePage(nextPage);
    await fetchData(nextPage);
  };

  const previousPage = async () => {
    if (page === 0) return;
    const nextPage = page - 1;
    await changePage(nextPage);
    await fetchData(nextPage);
  };

  const specificPage = async (selectedPage: number) => {
    await changePage(selectedPage);
    await fetchData(selectedPage);
  };

  const fetchData = async (selectedPage: number) => {
    if (!customHandler) return;
    await customHandler(selectedPage);
  };

  const changePage = async (page: number) => {
    if (!setPage) return;

    await setPage(page);
  };

  return (
    <Wrapper>
      <Arrow
        icon={faArrowLeft}
        onClick={() => previousPage()}
        disabled={page === 0}
      />
      {Array(nextTokens.length)
        .fill(0)
        .map((token, i: number) => {
          if (token === null) return <span></span>;
          return (
            <Page onClick={() => specificPage(i)} active={page == i}>
              {i + 1}
            </Page>
          );
        })}
      <Arrow
        icon={faArrowRight}
        onClick={() => nextPage()}
        disabled={nextTokens ? !!!nextTokens[page + 1] : false}
      />
    </Wrapper>
  );
};

const Arrow = ({
  icon,
  onClick,
  disabled,
}: {
  icon: IconDefinition;
  disabled: boolean;
  onClick: () => void;
}) => {
  return (
    <Icon onClick={onClick} disabled={disabled}>
      <FontAwesomeIcon icon={icon} />
    </Icon>
  );
};
