import { useTranslation } from "react-i18next";
import { Title } from "optimify-mediportal-components";
import { StyledTabs, Wrap } from "../../../../constants/globalStyles";
import { Tabs } from "antd";
import { Routes } from "../../../../routes";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { WorkType } from "./components/WorkType";
import { Employer } from "./components/Employer";
import styled from "styled-components";
import { routeTo } from "../../../../utils/createRoute";
import { Legislative, TitleContainer } from "./_styles";
import { Country } from "../../../../API";
import { SuitableFor } from "./components/SuitableFor";
import { useFormik } from "formik";
import { FormData } from "../advertisements/components/_types";
import { FilterSelect } from "../advertisements/_styles";

const { TabPane } = Tabs;

export enum TaxonomyPage {
  WorkerType = "workerType",
  SuitableFor = "suitableFor",
  Employer = "employer",
}

export const Taxonomy = () => {
  const { t } = useTranslation(["common", "taxonomy"]);
  const { key } = useParams<{ key?: TaxonomyPage }>();
  const [page, setPage] = useState(TaxonomyPage.SuitableFor);
  const history = useHistory();

  const formik = useFormik<any>({
    initialValues: {
      country: Country.EU,
    },
    enableReinitialize: true,
    onSubmit: async (values: any) => {},
  });
  useEffect(() => {
    key && setPage(key);
  }, [key]);

  const callback = (key: string) => {
    routeTo(history, Routes.TAXONOMY, [key]);
  };

  return (
    <Wrap>
      <TitleContainer>
        <Title>
          {t("taxonomy:title")} -{" "}
          {t(
            page === TaxonomyPage.WorkerType
              ? "taxonomy:tab.workerTypeLowerCase"
              : "taxonomy:tab.corporationTypeLowerCase"
          )}
        </Title>
        <FilterSelect
          options={[
            { value: Country.EU, label: t("common:checkbox.eu") },
            { value: Country.RUSSIA, label: t("common:checkbox.russia") },
            { value: Country.USA, label: t("common:checkbox.usa") },
            { value: Country.JAPAN, label: t("common:checkbox.japan") },
          ]}
          formik={formik}
          name={"country"}
        />
      </TitleContainer>
      <StyledTabs defaultActiveKey={key} onChange={callback}>
        <Tab tab={t("taxonomy:tab.suitableFor")} key={TaxonomyPage.SuitableFor}>
          <SuitableFor country={formik.values?.country} />
        </Tab>
        <Tab tab={t("taxonomy:tab.workerType")} key={TaxonomyPage.WorkerType}>
          <WorkType country={formik.values?.country} />
        </Tab>
        <Tab
          tab={t("taxonomy:tab.corporationType")}
          key={TaxonomyPage.Employer}
        >
          <Employer country={formik.values?.country} />
        </Tab>
      </StyledTabs>
    </Wrap>
  );
};

const Tab = styled(TabPane)``;
