const setAttributes = (el: any, options: any) => {
  Object.keys(options).forEach(function (attr) {
    el.setAttribute(attr, options[attr]);
  });
};

/**
 * create svg lines
 */
export function connectAll() {
  const lines = document.getElementsByClassName("svgLine");

  if (lines && lines.length > 0) {
    Array.from(lines).forEach((item) => {
      if (item) {
        const lineIds = item?.id?.split(":");

        const from = getElementById(lineIds[0]);
        const to = getElementById(lineIds[1]);
        const line = getElementById(`${from?.id}:${to?.id}`);

        if (from && to && line) {
          const x1 = from?.offsetLeft + from.offsetWidth / 2;
          const y1 = from?.offsetTop + from.offsetHeight / 2;
          const x2 = to?.offsetLeft + to.offsetWidth / 2;
          const y2 = to?.offsetTop + to.offsetHeight / 2;
          setAttributes(line, { x1: x1, y1: y1, x2: x2, y2: y2 });
        }
      }
    });
  }
}

const getElementById = (id: string): HTMLElement | null => {
  return document.getElementById(id);
};

export function connectAllCorporation() {
  const line1: HTMLElement | null = document.getElementById(
    "corporation_type_line1"
  );
  const line2: HTMLElement | null = document.getElementById(
    "corporation_type_line2"
  );
  const div1: HTMLElement | null = document.getElementById(
    "corporation_type_one"
  );
  const div2: HTMLElement | null = document.getElementById(
    "corporation_type_two"
  );
  const div3: HTMLElement | null = document.getElementById(
    "corporation_type_three"
  );

  if (line1 && div1 && div2 && div3) {
    const x1 = div1?.offsetLeft + div1.offsetWidth / 2;
    const y1 = div1?.offsetTop + div1.offsetHeight / 2;
    const x2 = div2?.offsetLeft + div2.offsetWidth / 2;
    const y2 = div2?.offsetTop + div2.offsetHeight / 2;
    const x3 = div3?.offsetLeft + div3.offsetWidth / 2;
    const y3 = div3?.offsetTop + div3.offsetHeight / 2;
    setAttributes(line1, { x1: x1, y1: y1, x2: x2, y2: y2 });
    setAttributes(line2, { x1: x1, y1: y1, x2: x3, y2: y3 });
  }
}
