import React from "react";
import { ConfigProvider, DatePicker } from "antd";
import csCZ from "antd/lib/locale/cs_CZ";
import "moment/locale/cs";
import { Moment } from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { Row } from "src/constants/globalStyles";
import moment from "moment";

const { RangePicker } = DatePicker;

const CalendarRangeInput = ({
  formik,
  onChange,
}: {
  formik: any;
  onChange: (
    dates: [Moment, Moment] | any,
    dateStrings: [string, string],
    info: { range: string }
  ) => void;
  direction?: "down" | "up";
  value: any;
}) => {
  const dateFormat = "YYYY-MM-DD";

  return (
    <Wrapper>
      <ConfigProvider locale={csCZ}>
        <RangePicker
          locale={csCZ as any}
          format={dateFormat}
          bordered={false}
          onCalendarChange={onChange}
          suffixIcon={null}
          value={[
            formik.values && formik.values["dateFrom"]
              ? moment(formik.values["dateFrom"])
              : null,
            formik.values && formik.values["dateTo"]
              ? moment(formik.values["dateTo"])
              : null,
          ]}
        />
      </ConfigProvider>
      {formik.values["dateFrom"] && formik.values["dateTo"] && (
        <UnSelect
          onClick={() => {
            formik.setFieldValue("dateFrom", undefined);
            formik.setFieldValue("dateTo", undefined);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </UnSelect>
      )}
    </Wrapper>
  );
};

export default CalendarRangeInput;

const UnSelect = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.primary};
  margin-left: 10px;
`;

const Wrapper = styled(Row)`
  .ant-picker-clear {
    display: none;
  }
`;
