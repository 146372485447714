import { OfferSize } from "src/types/_types";
import { Column, Row } from "src/constants/globalStyles";
import styled from "styled-components";

export const Taxonomy = ({
  taxonomy,
  label,
  size,
}: {
  taxonomy?: any;
  label: string;
  size?: OfferSize;
}) => {
  if (!taxonomy) return <div></div>;
  return (
    <Container>
      <Title>{label}</Title>
      {Object.keys(taxonomy)?.map((key: any) => {
        return (
          <Row key={key}>
            {taxonomy[key]?.map((row: any, index: number) => {
              if (row?.level < 2) return <></>;

              return (
                <JobTaxonomyBox key={index} level={row?.level} size={size}>
                  {row?.name}
                </JobTaxonomyBox>
              );
            })}
          </Row>
        );
      })}
    </Container>
  );
};

const Title = styled.span`
  color: ${(props) => props.theme.text.blue};
  font-size: 13px;
`;

const Container = styled(Column)`
  margin-bottom: 14px;
`;

const JobTaxonomyBox = styled.span<{ level: number; size?: OfferSize }>`
  width: fit-content;
  font-size: ${(props) => (props.size === OfferSize.SMALL ? "11px" : "13px")};
  padding: ${(props) =>
    props.size === OfferSize.SMALL ? "3px 8px" : "4px 13px"};
  margin: 2px;
  border-radius: 13px;
  color: ${(props) => (props.level < 4 ? "#fff" : props.theme.text.blue)};
  background-color: ${(props) =>
    props.level < 3 ? "#717AA3" : props.level < 4 ? "#A1A6C1" : "#D0D3E0"};
`;
