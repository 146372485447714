import { Advertisement } from "src/API";

export const CreatedBy = ({
  advertisement,
}: {
  advertisement: Advertisement;
}) => {
  if (advertisement?.companyID) {
    return <span>{advertisement?.company?.name}</span>;
  }
  return <span>{advertisement?.profile?.email}</span>;
};
