import React from "react";
import { AdminInput, Card, Col, Row } from "optimify-mediportal-components";
import { Routes } from "../../../../../routes";
import { EditEntityLayout } from "../../../../../components/Layout/EditEntityLayout";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FooterButtons } from "../../../../../components/Form/FooterButtons";
import { routeTo } from "../../../../../utils/createRoute";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "../../../../../utils/useQuery";
import {
  CreateServiceSettingInput,
  EntityType,
  GetServiceSettingQuery,
  PublicAdvertisementSetting,
} from "../../../../../API";
import { getServiceSetting } from "../../../../../graphql/queries";
import { handleSubmit } from "./_services";
import { Form } from "../../../../../constants/globalStyles";
import { SettingPage } from "../index";
import { AppliedTo } from "./components/AppliedTo";
import { Switch } from "src/components/Form/Switch";
import { CheckboxGroup } from "src/components/Form/CheckBox";

export const CreateServiceSettings = () => {
  const { t } = useTranslation(["common", "settings"]);
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const { mutation, data, mutationLoading } = useQuery<GetServiceSettingQuery>(
    getServiceSetting,
    {
      id: id,
    }
  );

  const formik = useFormik<CreateServiceSettingInput>({
    initialValues: data?.getServiceSetting
      ? {
          id: data?.getServiceSetting?.id,
          name: data?.getServiceSetting?.name,
          creditsCount: data?.getServiceSetting?.creditsCount,
          appliedTo: data?.getServiceSetting?.appliedTo,
          serviceType: data?.getServiceSetting?.serviceType,
          active: data?.getServiceSetting?.active,
          entity: data?.getServiceSetting?.entity,
          public: data.getServiceSetting?.public,
        }
      : {
          name: "",
          active: true,
          creditsCount: 0,
          appliedTo: "",
          serviceType: "",
          entity: EntityType.SERVICE_SETTING,
          public: PublicAdvertisementSetting.PUBLIC,
        },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(3, t("form:validation.toShort", { count: 5 }))
        .max(50, t("form:validation.toLong", { count: 50 }))
        .required(t("form:validation.required")),
    }),
    onSubmit: async (values: CreateServiceSettingInput) => {
      await handleSubmit(values, mutation, history, id);
    },
  });
  return (
    <Form onSubmit={formik.handleSubmit}>
      <EditEntityLayout
        title={t(id ? "settings:createService" : "settings:editService")}
        footer={
          <FooterButtons
            onClick={() =>
              routeTo(history, Routes.SETTINGS, [SettingPage.ServiceSettings])
            }
            loading={mutationLoading}
          />
        }
        titleRightSection={<Switch name={"active"} formik={formik} />}
      >
        <Row>
          <Col>
            <Card padding fullHeight>
              <AdminInput
                type="text"
                title={t("common:table.name")}
                name="name"
                formik={formik}
                required
              />
              <AdminInput
                type="text"
                title={t("common:table.creditsCount")}
                name="creditsCount"
                formik={formik}
                required
              />
            </Card>
          </Col>
          <Col>
            <Card padding>
              <AppliedTo formik={formik} />
              <CheckboxGroup
                name={"serviceType"}
                formik={formik}
                title={t("common:table.serviceType")}
                options={[
                  {
                    value: "pageHighlighting",
                    label: t("settings:pageHighlighting"),
                  },
                ]}
                singleChoice
              />
            </Card>
          </Col>
        </Row>
      </EditEntityLayout>
    </Form>
  );
};
