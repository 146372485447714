import { uuidv4 } from "../../../../../utils/guid";
import { getFileExtension } from "../../../../../utils/file";
import { Storage } from "aws-amplify";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import { createArticle, updateArticle } from "../../../../../graphql/mutations";
import { message } from "antd";
import { routeTo } from "../../../../../utils/createRoute";
import { Routes } from "../../../../../routes";
import { ArticlePages } from "../index";
import { Article, CreateArticleInput } from "../../../../../API";

/**
 * Create article
 * @param values
 * @param mutation
 * @param id
 * @param history
 */
export const handleSubmit = async (
  values: CreateArticleInput,
  mutation: any,
  history: any,
  id?: string
) => {
  try {
    let name = "";

    if (values.image) {
      name =
        typeof values.image === "string"
          ? values.image
          : `articles/${Date.now()}-${uuidv4()}.${getFileExtension(
              (values.image as any)?.name
            )}`;

      if (typeof values.image !== "string") {
        Storage.put(name, values.image, {
          contentType: "image/jpeg", // contentType is optional
        });
      }
    }
    const slug = values?.id
      ? values?.slug
      : values.title
          .replace(/[`~!@#$%^&*()_|+\-=?;:'"<>\{\}\[\]\\\/]/gi, "")
          .replace(/\s|_|\(|\)/g, "-")
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .toLowerCase()
          .concat(`-${new Date().getTime()}`);

    const formattedValues = {
      ...values,
      image: name,
      slug: slug,
      hashtags: JSON.stringify(values?.hashtags),
      content: values?.content
        ? draftToHtml(
            convertToRaw((values?.content as any)?.getCurrentContent())
          )
        : "",
    };

    await mutation(id ? updateArticle : createArticle, {
      input: formattedValues,
    });

    message.success(`Článek byl úspěšně uložen.`);
    message.info(
      `Uložené změny budou na portálu viditelné po první revalidaci. Může trvat několik minut.`
    );

    routeTo(history, Routes.ARTICLES, [ArticlePages.ArticlesTable]);
  } catch (e) {
    message.error(`Exception: ${e}`);
  }
};
