export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        credits
        email
        forename
        surname
        createdAt
        owner
      }
      nextToken
    }
  }
`;

export const searchProfiles = /* GraphQL */ `
  query SearchProfiles(
    $filter: SearchableProfileFilterInput
    $sort: SearchableProfileSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProfiles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        credits
        email
        forename
        surname
        createdAt
        owner
      }
      nextToken
    }
  }
`;

export const advertisementsByProfile = /* GraphQL */ `
  query AdvertisementsByProfile(
    $profileID: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdvertisementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advertisementsByProfile(
      profileID: $profileID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;

export const searchCompanys = /* GraphQL */ `
  query SearchCompanys(
    $filter: SearchableCompanyFilterInput
    $sort: SearchableCompanySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCompanys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        entity
        entityState
        credits
        name
        logo
        forename
        surname
        address
        phone
        email
        crn
        vatRegNumber
        street
        buildingIdentificationNumber
        municipality
        postCode
        language
        employerTaxonomy
        profileImage
        qualification
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
    }
  }
`;

export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      credits
    }
  }
`;
