import React, { ReactNode } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { RowEnd } from "src/constants/globalStyles";
import styled from "styled-components";
import { FormikProps } from "formik";

export const DialogForm = ({
  children,
  title,
  okText,
  cancelText,
  minWidth,
  form,
  formik,
  customCancel,
}: {
  children: ReactNode;
  title: string;
  okText?: string;
  cancelText?: string;
  minWidth?: number;
  form: ReactNode;
  formik: FormikProps<any>;
  loading?: boolean;
  customCancel?: () => void;
}) => {
  const submit = () => {
    confirmAlert({
      closeOnClickOutside: false,
      // eslint-disable-next-line react/display-name
      customUI: (customUiOptions: any) => {
        return (
          <AlertBox minWidth={minWidth} className="custom-ui">
            <Title>{title}</Title>
            {form && <Description>{form}</Description>}
            <RowEnd>
              {cancelText && (
                <Button
                  label={cancelText}
                  color={"#1321b1"}
                  background={"#fff"}
                  border={"#1321b1"}
                  handleClick={() => {
                    customCancel && customCancel();
                    customUiOptions.onClose();
                  }}
                />
              )}
              {okText && (
                <Button
                  label={okText}
                  color={"#fff"}
                  background={"#1321b1"}
                  handleClick={() => {
                    formik.handleSubmit();
                    customUiOptions?.onClose();
                    customCancel && customCancel();
                  }}
                />
              )}
            </RowEnd>
          </AlertBox>
        );
      },
    });
  };

  return <div onClick={() => submit()}>{children}</div>;
};

export const Button = ({
  label,
  background,
  border,
  color,
  handleClick,
}: {
  label: string;
  background: string;
  color: string;
  border?: string;
  handleClick: () => void;
}) => {
  return (
    <InputButton
      background={background}
      color={color}
      border={border}
      onClick={() => handleClick()}
    >
      {label}
    </InputButton>
  );
};

const AlertBox = styled.div<{ minWidth?: number }>`
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : "600px")};
`;

export const Title = styled.h2`
  font-size: 25px;
  color: #1321b1;
  font-family: NeueAlteGrotesk-Medium;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  font-size: 15px;
  color: #1321b1;
  margin-bottom: 20px;
`;

export const InputButton = styled.button<{
  color: string;
  background: string;
  border?: string;
}>`
  background-color: ${(props) => props.background};
  border: 1px solid
    ${(props) => (props.border ? props.border : props.background)};
  border-radius: 18px;
  text-align: center;
  font-size: 13px;
  color: ${(props) => props.color};
  padding: 6px 14px;
  box-shadow: none;
  margin: 1em 0 0.5em 0.7em;
  cursor: pointer;
  position: relative;

  min-width: 100px;
  min-height: 35px;

  > div {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: -7px;
    bottom: 0;
    text-align: center;
    position: absolute;
  }
`;
