import React from "react";
import { Row } from "optimify-mediportal-components";
import { useTranslation } from "react-i18next";
import { Routes } from "../../../../../routes";
import {
  AdvertisementSetting,
  AdvertisementSettingsByOrderQuery,
  DeleteAdvertisementSettingInput,
  DeleteAdvertisementSettingMutation,
  EntityType,
  ModelSortDirection,
} from "../../../../../API";
import { advertisementSettingsByOrder } from "../graphql/queires";
import { deleteAdvertisementSetting } from "../../../../../graphql/mutations";
import { createRoute } from "../../../../../utils/createRoute";
import { Parsed } from "../../../../../components/Table/DataFormat";
import { MedTable } from "src/components/Table";
import { EditButton } from "src/components/Form/Button/Icons/Edit";
import { RemoveButton } from "src/components/Form/Button/Icons/Remove";
import { useMutation, usePaginatedQuery } from "@oasa/amplify-query";
import { Pagination } from "../../../../../components/Table/Pagination";
import { AppliedTo } from "./AppliedTo";
import { ApprovalRequired } from "../../../../../types/_types";

export const AdSettingsTable = () => {
  const { t } = useTranslation(["common", "advertisements", "settings"]);

  const { loading, data, totalPages, page, setPage, refetch, nextTokens } =
    usePaginatedQuery<AdvertisementSettingsByOrderQuery>(
      advertisementSettingsByOrder,
      {
        entity: EntityType.ADVERTISEMENT_SETTING,
        sortDirection: ModelSortDirection.ASC,
        limit: 10,
        name: "advertisementSettingsByCreatedAt",
      }
    );

  const [remove] = useMutation<
    DeleteAdvertisementSettingInput,
    DeleteAdvertisementSettingMutation
  >(deleteAdvertisementSetting);

  const removeItem = async (id?: string) => {
    if (id) {
      await remove({ input: { id: id } });
      refetch();
    }
  };

  return (
    <>
      <MedTable
        emptyTableText={t("settings:empty.ad")}
        loading={loading}
        dataSource={data?.advertisementSettingsByOrder?.items || []}
        columns={[
          {
            title: t("common:table.internalName"),
            render: (text: any, record: AdvertisementSetting) => (
              <span>{record.internalName}</span>
            ),
          },
          {
            title: t("common:table.name"),
            render: (text: any, record: AdvertisementSetting) => (
              <span>{record.name}</span>
            ),
          },
          {
            title: t("common:table.adPrice"),
            render: (text: any, record: AdvertisementSetting) => (
              <span>{record.creditsCount}</span>
            ),
          },
          {
            title: t("common:table.reactivationPrice"),
            render: (text: any, record: AdvertisementSetting) => (
              <span>{record.reactivationCreditsCount}</span>
            ),
          },
          {
            title: t("common:table.appliedTo"),
            render: (text: any, record: AdvertisementSetting) => (
              <AppliedTo
                isPublic={record?.public}
                appliedTo={record?.appliedTo}
              />
            ),
          },
          {
            title: t("common:table.status"),
            render: (text: any, record: AdvertisementSetting) => (
              <span>
                {t(
                  record?.active
                    ? "common:table.active"
                    : "common:table.inactive"
                )}
              </span>
            ),
          },
          {
            title: t("common:table.approvalRequired"),
            width: "200px",
            render: (text: any, record: AdvertisementSetting) => (
              <span>
                {t(
                  record.advertisementType?.approvalRequired ===
                    ApprovalRequired.YES
                    ? "common:checkbox.yes"
                    : "common:checkbox.no"
                )}
              </span>
            ),
          },
          {
            title: t("common:table.type"),
            render: (text: any, record: AdvertisementSetting) => (
              <span>{record.advertisementType?.name}</span>
            ),
          },
          {
            title: "",
            titleHide: true,
            key: "action",
            render: (_text: any, record: AdvertisementSetting) => (
              <Row>
                <EditButton
                  route={createRoute(Routes.CREATE_ADVERTISEMENT_SETTING, [
                    record?.id,
                  ])}
                />
                <RemoveButton
                  title={t("settings:removeAdvertisementSetting.title")}
                  description={t(
                    "settings:removeAdvertisementSetting.description"
                  )}
                  okText={t("settings:removeAdvertisementSetting.ok")}
                  cancelText={t("settings:removeAdvertisementSetting.cancel")}
                  handleClick={() => removeItem(record?.id)}
                />
              </Row>
            ),
          },
        ]}
      />
      <Pagination
        totalPages={totalPages}
        page={page}
        setPage={setPage}
        nextTokens={nextTokens}
      />
    </>
  );
};
