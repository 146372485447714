import { Select } from "antd";
import styled from "styled-components";

const { Option } = Select;

export const HashTagSelect = ({
  title,
  name,
  formik,
}: {
  title: string;
  name: string;
  formik: any;
}) => {
  const children = [];
  const childrenDefaultValue = [
    "Medicína",
    "Právo",
    "Rady a typy",
    "ARO",
    "Kardiochirurgie",
  ];
  for (let i = 0; i < 5; i++) {
    children.push(
      <Option key={i.toString(36)} value={childrenDefaultValue[i]}>
        {childrenDefaultValue[i]}
      </Option>
    );
  }
  const handleChange = (value: any) => {
    formik.setFieldValue(name, value);
  };
  return (
    <Column>
      <Label>{title}</Label>
      <Select
        mode="tags"
        style={{ width: "100%" }}
        placeholder={title}
        onChange={handleChange}
        value={formik.values[name]}
      >
        {children}
      </Select>
    </Column>
  );
};

const Label = styled.span`
  font-size: 13px;
  color: #555555;
  padding-bottom: 3px;
  padding-left: 1px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 13px;

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: ${(props) => props.theme.border.green};
    box-shadow: none;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #c3c3c3;
    border-radius: 7px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.05);
    padding: 8px 4px 4px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 43px;
  }

  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 39px;
  }
`;
