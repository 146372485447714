import React, { MouseEventHandler, ReactNode } from "react";
import styled from "styled-components";

const Header = ({
  portals,
  content,
}: {
  portals: Array<{ name: string; onClick: MouseEventHandler; active: boolean }>;
  content: ReactNode;
}) => {
  return (
    <Wrap>
      <Portals>
        {portals.map((portal, key) => (
          <Portal key={key} onClick={portal.onClick} active={portal.active}>
            {portal.name}
          </Portal>
        ))}
      </Portals>
      <Content>{content}</Content>
    </Wrap>
  );
};

export default Header;

const Wrap = styled.div`
  width: 100%;

  display: flex;
  align-items: stretch;

  height: 80px;

  padding: 0 32px;

  background-color: #ececec;
`;

const Portals = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  flex-grow: 1;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    width: 24px !important;
    height: 24px;

    color: ${(props) => props.theme.primary};
  }

  > div {
    display: flex;
    align-items: center;

    svg + * {
      margin-left: 8px;
    }
  }

  > *:not(:first-child) {
    margin-left: 16px;
  }
`;

const Portal = styled.button<{ active: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 123px;
  height: 41px;

  color: ${(props) => (props.active ? "#fff" : props.theme.primary)};
  border: 2px solid ${(props) => props.theme.primary};
  background-color: ${(props) => (props.active ? props.theme.primary : "#fff")};

  border-radius: 99px;
  font-size: 20px;
  font-weight: ${(props) => (props.active ? "700" : "400")};

  ${(props) => !props.active && "cursor: pointer;"}
  transition: all .3s ease-out;

  & + & {
    margin-left: 16px;
  }
`;
