import { useTranslation } from "react-i18next";
import { Temp } from "../../../../Temp";

export const Help = () => {
  const { t } = useTranslation(["articles"]);
  return (
    <div>
      <Temp />
    </div>
  );
};
