export const suitableForByCountry = /* GraphQL */ `
  query SuitableForByCountry(
    $country: Country
    $sortDirection: ModelSortDirection
    $filter: ModelSuitableForFilterInput
    $limit: Int
    $nextToken: String
  ) {
    suitableForByCountry(
      country: $country
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        country
        level
        order
        childEdges {
          items {
            id
            childNodeId
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const workerTypesByCountry = /* GraphQL */ `
  query WorkerTypesByCountry(
    $country: Country
    $sortDirection: ModelSortDirection
    $filter: ModelWorkerTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workerTypesByCountry(
      country: $country
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        country
        level
        order
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const employersByCountry = /* GraphQL */ `
  query EmployersByCountry(
    $country: Country
    $sortDirection: ModelSortDirection
    $filter: ModelEmployerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employersByCountry(
      country: $country
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        country
        level
        order
        childEdges {
          items {
            id
            parentNodeId
            childNodeId
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
