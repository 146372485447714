import React from "react";
import { useTranslation } from "react-i18next";
import { PaymentState } from "../../../../../types/_types";
import { AdvertisementState } from "./_types";

export const IsActive = ({
  expirationDate,
  paymentState,
  state,
}: {
  expirationDate: string;
  paymentState?: string | null;
  state?: string | null;
}) => {
  const { t } = useTranslation(["table", "common"]);

  if (
    new Date() > new Date(expirationDate) ||
    paymentState !== PaymentState.PAYMENT_SUCCESSFUL ||
    state !== AdvertisementState.APPROVED
  ) {
    return <span>{t(`common:awsJson.INACTIVE`)}</span>;
  }
  return <span>{t(`common:awsJson.ACTIVE`)}</span>;
};
