export const advertisementSettingsByOrder = /* GraphQL */ `
  query AdvertisementSettingsByOrder(
    $entity: EntityType
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdvertisementSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advertisementSettingsByOrder(
      entity: $entity
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        creditsCount
        description
        internalName
        name
        numberOfDays
        reactivationCreditsCount
        order
        public
        appliedTo
        advertisementType {
          name
          approvalRequired
        }
      }
      nextToken
    }
  }
`;

export const advertisementTypesByOrder = /* GraphQL */ `
  query AdvertisementTypesByOrder(
    $entity: EntityType
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdvertisementTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advertisementTypesByOrder(
      entity: $entity
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        name
        order
        approvalRequired
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const serviceSettingsByCreatedAt = /* GraphQL */ `
  query ServiceSettingsByCreatedAt(
    $entity: EntityType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelServiceSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    serviceSettingsByCreatedAt(
      entity: $entity
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        creditsCount
        entity
        name
        public
        appliedTo
        serviceType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
